import {
    Component,
    Injectable,
    ComponentFactoryResolver,
    ElementRef,
    ApplicationRef,
    Injector,
    ViewContainerRef,
    InjectionToken,
    OnDestroy,
    inject
} from "@angular/core";
import { DomPortalOutlet, ComponentPortal } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    IDialogComponent,
    LgDialogFactory,
    getDialogFactoryBase,
    LgDialogRef
} from "@logex/framework/ui-core";
import { LgDownloadFormComponent, DOWNLOAD_PARAMS } from "./lg-download-form.component";
import { takeUntil } from "rxjs/operators";
import { Subject, Observable } from "rxjs";

@Component({
    selector: "lg-export-dialog",
    template: `
        <div class="form-group">
            <lg-progress-bar></lg-progress-bar>
        </div>
    `
})
export class LgExportDialogComponent
    implements IDialogComponent<LgExportDialogComponent>, OnDestroy
{
    private _appRef = inject(ApplicationRef);
    private _componentFactoryResolver = inject(ComponentFactoryResolver);
    private _dialogRef = inject(LgDialogRef<LgExportDialogComponent>);
    private _elementRef = inject(ElementRef);
    private _injector = inject(Injector);
    private _lgTranslate = inject(LgTranslateService);
    private _viewContainerRef = inject(ViewContainerRef);

    _title: string;

    private _portalOutlet!: DomPortalOutlet;
    private readonly _destroyed$ = new Subject<void>();
    private _downloadForm!: LgDownloadFormComponent;

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        this._title = this._lgTranslate.translate("FW._Common.ExportingInProgress");
    }

    // ----------------------------------------------------------------------------------
    //
    show(url: string, params: object): Observable<void> {
        if (this._portalOutlet && this._portalOutlet.hasAttached()) {
            this._portalOutlet.detach();
        }

        this._portalOutlet = new DomPortalOutlet(
            this._elementRef.nativeElement,
            this._componentFactoryResolver,
            this._appRef,
            this._injector
        );

        const componentPortal = new ComponentPortal(
            LgDownloadFormComponent,
            this._viewContainerRef,
            this._createInjector(DOWNLOAD_PARAMS, {
                url,
                params
            })
        );

        this._downloadForm = this._portalOutlet.attach(componentPortal).instance;

        this._downloadForm
            .onDone()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._dialogRef.close();
            });

        return this._downloadForm.onDone();
    }

    _tryClose(): boolean {
        this._downloadForm?.cancel();
        return true;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _createInjector(injectionToken: InjectionToken<any>, dataToPass: object): Injector {
        this._injector = Injector.create({
            providers: [{ provide: injectionToken, useValue: dataToPass }]
        });

        return this._injector;
    }
}

// ---------------------------------------------------------------------------------------------
// Factory
@Injectable({ providedIn: "root" })
export class LgExportDialog extends getDialogFactoryBase(LgExportDialogComponent, "show") {}
