import { Component, EventEmitter, Input } from "@angular/core";

import { LgFilterSet, IFilterDefinition } from "@logex/framework/lg-filterset";

@Component({
    selector: "lg-filterset-form-row",
    templateUrl: "./lg-filterset-form-row.component.html",
    host: {
        "[class.form-row]": "true"
    }
})
export class LgFiltersetFormRowComponent {
    /**
     * @Required
     * All filters definitions
     * @param value
     */
    @Input({ required: true })
    public set filterSet(value: LgFilterSet) {
        this._setFilterSet(value);
    }

    public get filterSet(): LgFilterSet {
        return this._filterSet;
    }

    public readonly onFilterChange = new EventEmitter<void>();

    _filterSet: LgFilterSet = null;

    public _filterChanged(entry: IFilterDefinition): void {
        this._filterSet.triggerOnChanged(entry);
        this.onFilterChange.next();
    }

    public _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }

    private _setFilterSet(value: LgFilterSet): void {
        if (value === this._filterSet) return;
        this._filterSet = value;
    }
}
