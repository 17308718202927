import { Injectable, inject } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import {
    LgLoaderTabComponent,
    IOverlayResultApi,
    LgOverlayService
} from "@logex/framework/ui-core";
import { IAppLoadIndicatorService } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class LgLoaderService implements IAppLoadIndicatorService {
    protected _parent = inject(LgLoaderService, { optional: true, skipSelf: true });
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _overlayApi: IOverlayResultApi = null;
    private _showCount = 0;
    private _activeSet = new Map<string, Subscription | null>();

    show(id?: string, hideWhen?: Observable<any>): void {
        if (id && this._activeSet.has(id)) {
            console.error("ID " + id + " already showing loader");
            return;
        }

        ++this._showCount;
        if (this._showCount === 1) {
            this._showLoader();
        }

        if (id) {
            if (hideWhen) {
                this._activeSet.set(
                    id,
                    hideWhen.pipe(take(1)).subscribe(() => {
                        if (this._activeSet.has(id)) this.hide(id);
                    })
                );
            } else {
                this._activeSet.set(id, null);
            }
        }
    }

    hide(id?: string): void {
        if (id && !this._activeSet.has(id)) {
            console.error("ID " + id + " loader not visible");
            return;
        }

        if (id) {
            const hideWhenSubscription = this._activeSet.get(id);
            if (hideWhenSubscription) {
                hideWhenSubscription.unsubscribe();
            }

            this._activeSet.delete(id);

            if (this._showCount === 0) {
                console.error("Hiding hidden loader");
                return;
            }
        }

        --this._showCount;
        if (!this._showCount) {
            this._overlayApi.hide();
            this._overlayApi = null;
        }
    }

    private _showLoader(): void {
        const strategy = this._overlay
            .position()
            .global()
            .top("0px")
            .bottom("0px")
            .right("0x")
            .left("0px");

        this._overlayApi = this._overlayService.show({
            panelClass: "lg-loader-panel-holder",
            hasBackdrop: true,
            trapFocus: true,
            positionStrategy: strategy
        });

        //        let portal = new ComponentPortal( LgLoaderComponent );
        const portal = new ComponentPortal(LgLoaderTabComponent);
        this._overlayApi.overlayRef.attach(portal);
    }
}
