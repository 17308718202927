import { ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    TemplateRef
} from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgTooltipService, TooltipApi } from "../../lg-tooltip";

import { LgHelpInfoPopupComponent } from "./lg-help-info-popup.component";
import { HelpInfoDelayOptions, IHelpInfoLinks } from "./lg-help-info.types";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-help-info",
    template: `<lg-icon icon="icon-help"></lg-icon> `,
    host: {
        class: "lg-help-info",
        "[class.lg-tooltip-visible]": "_visible"
    },
    viewProviders: [useTranslationNamespace("FW._HelpInfo")],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [LgIconComponent]
})
export class LgHelpInfoComponent implements OnChanges, OnDestroy {
    private _element = inject(ElementRef);
    private _tooltipService = inject(LgTooltipService);

    /**
     * Specifies header of help tooltip.
     */
    @Input() header?: string;

    /**
     * Specifies main content of help tooltip.
     */
    @Input() description?: string | TemplateRef<any> | TemplatePortal<any>;

    /**
     * Specifies links to be displayed at the bottom of help tooltip.
     */
    @Input() infoLinks?: Partial<IHelpInfoLinks>;
    @Input() delayOptions?: HelpInfoDelayOptions;

    _visible = false;

    private _tooltip: TooltipApi;

    constructor() {
        this._initTooltip();
    }

    private _initTooltip(): void {
        this._tooltip = this._tooltipService.create({
            content: new ComponentPortal(LgHelpInfoPopupComponent),
            position: "top-right",
            target: this._element,
            stay: false,
            ensureVisibility: true,
            tooltipClass: "lg-tooltip lg-tooltip--simple",
            postShow: () => {
                this._getTooltipInstance()._initialize({
                    header: this.header,
                    description: this.description,
                    links: this.infoLinks
                });
            }
        });
        this._tooltip.afterVisibilityChanged().subscribe(visible => (this._visible = visible));
    }

    private _getTooltipInstance(): LgHelpInfoPopupComponent {
        if (!this._tooltip || !this._tooltip.getPortalReference()) {
            return null;
        }

        return this._tooltip.getPortalReference<ComponentRef<LgHelpInfoPopupComponent>>().instance;
    }

    @HostListener("mouseenter")
    _showPopup(): void {
        this._tooltip.scheduleShow();
    }

    @HostListener("mouseleave")
    _hidePopup(): void {
        this._tooltip.scheduleHide();
    }

    @HostListener("click", ["$event"])
    onClick($event: Event): void {
        $event.stopPropagation();
        $event.preventDefault();

        this._tooltip.show();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.delayOptions && changes.delayOptions.currentValue) {
            this._tooltip.options(changes.delayOptions.currentValue);
        }
    }

    ngOnDestroy(): void {
        this._tooltip.destroy();
    }
}
