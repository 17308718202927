import { inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { urlConcat } from "@logex/framework/utilities";

export const LG_AUTHORIZATION_SERVICE_V1 = new InjectionToken<ILgAuthorizationServiceV1>(
    "LG_AUTHORIZATION_SERVICE_V1"
);

// ----------------------------------------------------------------------------------
export interface AuthorizationServiceConfig {
    instance: string;
    environment: string;
    url: string;
}

export interface UserProfile {
    title: string;
    id: string;
    displayName: string;
    organizationId: number;
    name: string;
    email: string;
    note: string;
    isDisabled: boolean;
    impersonator: string | null;
}

// ----------------------------------------------------------------------------------
export interface ILgAuthorizationServiceV1 {
    initialize(cfg: AuthorizationServiceConfig): void;
    getUserProfile(): Observable<UserProfile>;
    checkProductInstanceAccess(): Observable<boolean>;
    getUserPermissions(): Observable<string[]>;
    getUserResources(
        resourceType: string,
        operation: "read" | "modify"
    ): Observable<string[] | null>;
}

// ----------------------------------------------------------------------------------
@Injectable()
export class LgAuthorizationServiceV1 implements ILgAuthorizationServiceV1 {
    private _http = inject(HttpClient);

    private _instance!: string;
    private _environment!: string;
    private _url!: string;

    // ----------------------------------------------------------------------------------
    initialize(cfg: AuthorizationServiceConfig): void {
        this._instance = cfg.instance;
        this._environment = cfg.environment;
        this._url = urlConcat(cfg.url, "/v1.3");
    }

    getUserProfile(): Observable<UserProfile> {
        return this._http
            .get<{ profile: UserProfile }>(urlConcat(this._url, "/user/profile"))
            .pipe(map(data => data.profile));
    }

    checkProductInstanceAccess(): Observable<boolean> {
        return this._http
            .get<{ allowed: boolean }>(urlConcat(this._url, "/checkAccess/productInstance"), {
                params: {
                    productInstance: this._instance,
                    environment: this._environment
                }
            })
            .pipe(map(data => data.allowed));
    }

    getUserPermissions(): Observable<string[]> {
        return this._http
            .get<{ permissions: string[] }>(urlConcat(this._url, "/user/permissions"), {
                params: {
                    productInstance: this._instance,
                    environment: this._environment
                }
            })
            .pipe(map(x => x.permissions));
    }

    getUserResources(resourceType: string, operation: "read" | "modify"): Observable<string[]> {
        return this._http
            .get<{ resources: string[] }>(urlConcat(this._url, "/user/resources"), {
                params: {
                    productInstance: this._instance,
                    environment: this._environment,
                    resourceType,
                    operation
                }
            })
            .pipe(map(x => x.resources));
    }
}
