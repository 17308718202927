import _ from "lodash";
import { ComponentType } from "@angular/cdk/portal";
import { Component, Injectable } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { ILgFormatterOptions } from "@logex/framework/core";
import { IRange, LgFormatTypePipe } from "@logex/framework/ui-core";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";

// Range renderer --------------------------------------------------------------------------------------------------
export interface IRangeFilterValue {
    enabled: boolean;
    from: number;
    to: number;
}

export interface IRangeFilterDefinition extends IFilterDefinition {
    filterType: "range";

    min: number;
    minusInfinity?: boolean;
    max: number;
    plusInfinity?: boolean;
    checkboxVisible?: boolean;
    showFixedSelectedValues?: boolean;
    resetValues?: boolean;
    step?: number;

    debounceTime?: number;
    tickerStep?: number;
    highlightStep?: number;
    keyboardStep?: number;

    formatter?: string;
    formatterOptions?: ILgFormatterOptions;
    minusInfinityText?: string;
    plusInfinityText?: string;
    useJoinedTooltip?: boolean;

    default?: IRangeFilterValue;
}

// @dynamic
export class RangeFilterRenderer implements IFilterRenderer {
    public default: IRangeFilterValue;

    constructor(
        private _definition: IRangeFilterDefinition,
        private _filters: any,
        private _lgTranslate: LgTranslateService,
        private _formatPipe: LgFormatTypePipe
    ) {
        if (_definition.highlightStep == null && _definition.tickerStep != null)
            _definition.highlightStep = _definition.tickerStep * 10;
        if (_definition.keyboardStep == null && _definition.tickerStep != null)
            _definition.keyboardStep = _definition.tickerStep / 10;
        this.default = <any>_.extend(
            {
                from: this._definition.min,
                to: this._definition.max,
                enabled: false
            },
            this._definition.default
        );

        if (!_definition.formatter) {
            _definition.formatter = "int";
            _definition.formatterOptions = {
                decimals: 0,
                forceSign: true
            };
        }
        if (!_definition.plusInfinityText)
            _definition.plusInfinityText = _lgTranslate.translate(
                "FW._Directives.RangeFilterRenderer_plus_infinity"
            );
        if (!_definition.minusInfinityText)
            _definition.minusInfinityText = _lgTranslate.translate(
                "FW._Directives.RangeFilterRenderer_minus_infinity"
            );

        _definition.checkboxVisible =
            _definition.checkboxVisible == null ? true : _definition.checkboxVisible;

        _definition.resetValues = _definition.resetValues == null ? false : _definition.resetValues;
    }

    createStorage(): void {
        if (this._filters[this._definition.storage] == null) {
            this._filters[this._definition.storage] = {
                from: this.default.from,
                to: this.default.to,
                enabled: this.default.enabled
            };
        }
    }

    clear(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        if (!current) {
            // shouldn't happen
            this.createStorage();
            return false;
        }
        const changed =
            current.from !== this.default.from ||
            current.to !== this.default.to ||
            current.enabled !== this.default.enabled;

        if (this._definition.resetValues) {
            current.from = this.default.from;
            current.to = this.default.to;
        }
        current.enabled = this.default.enabled;

        this._filters[this._definition.storage] = { ...current };

        return changed;
    }

    active(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        return current.enabled;
    }

    previewVisible(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        return current.enabled;
    }

    getFilterLineComponent(): ComponentType<RangeFilterRendererLineComponent> {
        return RangeFilterRendererLineComponent;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name,
            activeFn: () => this.active(),
            exportFn: () => {
                const format = this._definition.formatter;
                let from: string, to: string;
                const current: IRangeFilterValue = this._filters[this._definition.storage];
                if (current.from === -Infinity) {
                    from = this._definition.minusInfinityText;
                } else {
                    from = this._formatPipe.transform(
                        current.from.toString(),
                        format,
                        this._definition.formatterOptions || {}
                    );
                }
                if (current.to === Infinity) {
                    to = this._definition.plusInfinityText;
                } else {
                    to = this._formatPipe.transform(
                        current.to.toString(),
                        format,
                        this._definition.formatterOptions || {}
                    );
                }

                return [
                    from +
                        this._lgTranslate.translate(
                            "FW._Directives.RangeFilterRenderer_Export_To"
                        ) +
                        to
                ];
            }
        };
    }

    getPopupComponent(): ComponentType<any> {
        return RangeFilterRendererPopupComponent;
    }

    sliderChanged(values: IRange): void {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        const defaultValue = this._definition.default;
        current.enabled =
            current.enabled || values.from !== defaultValue.from || values.to !== defaultValue.to;
        current.from = current.enabled ? values.from : defaultValue.from;
        current.to = current.enabled ? values.to : defaultValue.to;

        this._filters[this._definition.storage] = { ...current };
    }

    /**
     * Utility generator for the filtering function. Example usage:
     * filters:( context: any) => {
     *    ...
     *    margin: RangeFilterRenderer.getFilterFunction( () => context.filterDefinition.filters["margin"] ),
     *   ...
     *  }
     */
    static getFilterFunction(getStorage: () => any): (val?: number) => boolean | "$empty" {
        return function (val: number): boolean | "$empty" {
            const current: IRangeFilterValue = getStorage() || { enabled: false, min: 0, max: 0 };
            if (arguments.length === 0) return current.enabled ? true : "$empty";
            return !current.enabled || (current.from <= val && current.to >= val);
        };
    }

    serialize(): string {
        if (!this.active()) return null;
        return JSON.stringify(this._filters[this._definition.storage]);
    }

    deserialize(state: string): boolean {
        const newState = JSON.parse(state);
        if (!newState) return false;
        const parsed: IRangeFilterValue = {
            enabled: newState.enabled,
            from: newState.from === undefined ? this.default.from : newState.from,
            to: newState.to === undefined ? this.default.to : newState.to
        };

        if (isNaN(parsed.from) || isNaN(parsed.to)) return false;
        if (parsed.from > parsed.to) return false;
        if (this._definition.min != null) {
            parsed.from = Math.max(this._definition.min, parsed.from);
            parsed.to = Math.max(this._definition.min, parsed.to);
        }
        if (this._definition.max != null) {
            parsed.from = Math.min(this._definition.max, parsed.from);
            parsed.to = Math.min(this._definition.max, parsed.to);
        }
        if (!_.isEqual(parsed, this._filters[this._definition.storage])) {
            this._filters[this._definition.storage] = parsed;
            return true;
        }
        return false;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class RangeFilterRendererFactory implements IFilterRendererFactory {
    public readonly name: string = "range";

    public create(
        definition: IRangeFilterDefinition,
        filters: _.Dictionary<any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet,
        formatPipe: LgFormatTypePipe
    ): IFilterRenderer {
        return new RangeFilterRenderer(definition, filters, filterSet.lgTranslate, formatPipe);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-range-filter-renderer-line-component",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <input
            type="checkbox"
            lgStyledCheckbox
            [(ngModel)]="_filters[_definition.storage].enabled"
            (change)="_checkboxChanged()"
            *ngIf="_definition.checkboxVisible"
        />
        <label lgSimpleTooltip="{{ _definition.tooltip }}" [tooltipPosition]="'bottom-left'"
            >{{ _definition.label }}:</label
        >
        <lg-range-slider
            [min]="_definition.min"
            [max]="_definition.max"
            [debounceTime]="_definition.debounceTime"
            [highlightStep]="_definition.highlightStep"
            [tickerStep]="_definition.tickerStep"
            [lgFormatter]="_definition.formatter"
            [lgFormatterOptions]="_definition.formatterOptions"
            [minusInfinity]="_definition.minusInfinity || false"
            [minusInfinityText]="_definition.minusInfinityText || ''"
            [plusInfinity]="_definition.plusInfinity || false"
            [plusInfinityText]="_definition.plusInfinityText || ''"
            [showFixedSelectedValues]="_definition.showFixedSelectedValues"
            [ngModel]="_filters[_definition.storage]"
            (ngModelChange)="_sliderChanged($event)"
            [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
            [step]="_definition.step"
            [useJoinedTooltip]="_definition.useJoinedTooltip"
        ></lg-range-slider>
    `,
    host: {
        "[class.lg-filterset-list__item__range]": "true"
    }
})
export class RangeFilterRendererLineComponent extends FilterRendererComponentBase<
    IRangeFilterDefinition,
    RangeFilterRenderer
> {
    _checkboxChanged(): void {
        if (this._definition.resetValues) this._renderer.sliderChanged(this._definition.default);
        this._triggerChange();
    }

    _sliderChanged(value: IRange): void {
        this._renderer.sliderChanged(value);
        this._triggerChange();
    }
}

// Popup template  ---------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-range-filter-renderer-popup-component",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ "FW._Directives.RangeFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.RangeFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-range-slider
            [min]="_definition.min"
            [max]="_definition.max"
            [debounceTime]="_definition.debounceTime"
            [highlightStep]="_definition.highlightStep"
            [tickerStep]="_definition.tickerStep"
            [lgFormatter]="_definition.formatter"
            [lgFormatterOptions]="_definition.formatterOptions"
            [minusInfinity]="_definition.minusInfinity || false"
            [minusInfinityText]="_definition.minusInfinityText || ''"
            [plusInfinity]="_definition.plusInfinity || false"
            [plusInfinityText]="_definition.plusInfinityText || ''"
            [showFixedSelectedValues]="_definition.showFixedSelectedValues"
            [ngModel]="_filters[_definition.storage]"
            (ngModelChange)="_sliderChanged($event)"
            [ngClass]="{ inactive: !_filters[_definition.storage].enabled }"
            [step]="_definition.step"
            [useJoinedTooltip]="_definition.useJoinedTooltip"
        ></lg-range-slider>
    `
})
export class RangeFilterRendererPopupComponent extends FilterRendererComponentBase<
    IRangeFilterDefinition,
    RangeFilterRenderer
> {
    _sliderChanged(value: IRange): void {
        this._renderer.sliderChanged(value);
        this._triggerChange();
    }
}
