import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { Observable, Subject, asapScheduler } from "rxjs";

import { LgObserveSizeService } from "@logex/framework/ui-core";

import { LgSidebarService } from "./lg-sidebar.service";
import { SidebarButton } from "./sidebar-button-base";
import { ISidebar } from "./sidebar-context";
import { SidebarWidget } from "./sidebar-widget-base";
import { LG_FW_UI_STATE_SERVICE } from "../lg-fw-ui-state/lg-fw-ui-state.types";
import { distinctUntilChanged, observeOn, takeUntil, takeWhile } from "rxjs/operators";

@Component({
    selector: "lg-sidebar",
    templateUrl: "./lg-sidebar.component.html",
    host: {
        class: "lg-sidebar",
        "[class.lg-sidebar--pinned]": "_pinned && _visible"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgSidebarComponent implements OnInit, OnDestroy, ISidebar {
    private _service = inject(LgSidebarService);
    private _sizeObserver = inject(LgObserveSizeService);
    private _uiState = inject(LG_FW_UI_STATE_SERVICE);

    @Output("pinToggled") readonly pinToggled = new EventEmitter<boolean>(null);

    _topWidgets: Observable<SidebarWidget[]>;
    _bottomWidgets: Observable<SidebarWidget[]>;
    _pinned = false;
    _visible = false;
    _selectedButton: SidebarButton;
    private $destroyed = new Subject<void>();

    constructor() {
        this._topWidgets = this._service.topWidgets().pipe(observeOn(asapScheduler));
        this._bottomWidgets = this._service.bottomWidgets().pipe(observeOn(asapScheduler));
    }

    async ngOnInit(): Promise<void> {
        await this._initWithUiState();
    }

    ngOnDestroy(): void {
        this.$destroyed.next();
        this.$destroyed.complete();
    }

    _toggle(button: SidebarButton): void {
        if (button.disabled) return;
        if (this._selectedButton === button) {
            button.clicked(false);
            this._selectedButton = null;
            this._setUiStateSidebarButton(null);
        } else if (button.panelTemplate) {
            button.clicked(true);
            this._visible = true;
            this._selectedButton = button;
            this._setUiStateSidebarButton(button);
        } else {
            button.clicked(undefined);
            if (button.url) {
                window.location.replace(button.url);
            }
        }
    }

    _togglePin(): void {
        this._pinned = !this._pinned;
        this._uiState.setLeftSidebarPinned(this._pinned);
        this._sizeObserver.recalculate();
        this.pinToggled.emit(this._pinned);
    }

    _panelHidden(): void {
        if (this._selectedButton === null) this._visible = false;
    }

    _clamp(value: number): number {
        return Math.min(value, 99);
    }

    dismissPanel(): void {
        if (this._selectedButton && !this._pinned) {
            this._toggle(this._selectedButton);
        }
    }

    private async _initWithUiState(): Promise<void> {
        const pinned = await this._uiState.getLeftSidebarPinned();
        if (pinned == null) return;

        this._pinned = pinned;
        const initButton = await this._uiState.getSidebarButton();
        let initFinished = false;

        this.pinToggled.emit(this._pinned);

        this._topWidgets
            .pipe(
                takeWhile(() => !initFinished),
                takeUntil(this.$destroyed),
                distinctUntilChanged()
            )
            .subscribe(widgets => {
                if (this._pinned && initButton?.top) {
                    const initSidebarButton = widgets.find(
                        widget => widget.id === initButton.id
                    ) as SidebarButton;

                    if (initSidebarButton && initSidebarButton.panelTemplate) {
                        initSidebarButton.clicked(true);
                        this._selectedButton = initSidebarButton;
                        this._visible = true;
                        initFinished = true;
                    }
                }
            });

        this._bottomWidgets
            .pipe(
                takeWhile(() => !initFinished),
                takeUntil(this.$destroyed),
                distinctUntilChanged()
            )
            .subscribe(widgets => {
                if (this._pinned && !initButton?.top) {
                    const initSidebarButton = widgets.find(
                        widget => widget.id === initButton.id
                    ) as SidebarButton;

                    if (initSidebarButton && initSidebarButton.panelTemplate) {
                        initSidebarButton.clicked(true);
                        this._selectedButton = initSidebarButton;
                        this._visible = true;
                        initFinished = true;
                    }
                }
            });
    }

    private _setUiStateSidebarButton(button: SidebarButton): void {
        if (button && button.id) {
            this._uiState.setSidebarButton({
                top: button.onTop,
                id: button.id
            });
        } else {
            this._uiState.setSidebarButton(null);
        }
    }
}
