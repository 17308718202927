import { Injectable } from "@angular/core";

/**
 * Service providing some helper functions related to browser measuees
 */
@Injectable({ providedIn: "root" })
export class LgMeasurementsService {
    private _scrollbarWidth: number | null = null;
    private _scrollbarHeight: number | null = null;

    private _measureScrollbars(): void {
        const div = document.createElement("div");
        div.style.width = "100px";
        div.style.height = "100px";
        div.style.overflow = "scroll";
        div.style.position = "absolute";
        div.style.top = "-9999px";
        document.body.appendChild(div);

        this._scrollbarWidth = div.offsetWidth - div.clientWidth;
        this._scrollbarHeight = div.offsetHeight - div.clientHeight;
        div.parentNode.removeChild(div);
    }

    /**
     * Returns width of the standard scrollbar
     */
    public scrollbarWidth(): number {
        if (this._scrollbarWidth === null) {
            this._measureScrollbars();
        }
        return this._scrollbarWidth!;
    }

    /**
     * Returns height of the standard scrollbar
     */
    public scrollbarHeight(): number {
        if (this._scrollbarHeight === null) {
            this._measureScrollbars();
        }
        return this._scrollbarHeight!;
    }
}
