import { Input, Component, Output, EventEmitter } from "@angular/core";
import { LgMonthRangeSelectorComponent } from "./lg-month-range-selector.component";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-month-range-preview",
    template: `
        <div class="lg-filterset-preview__item" [title]="source.previewMonthInterval">
            {{ source.previewMonthInterval }}
            <lg-icon icon="icon-close" (click)="_clear($event)"></lg-icon>
        </div>
    `,
    imports: [LgIconComponent],
    host: {
        class: "lg-filterset-preview",
        "[style.display]": "source.allMonthsSelected ? 'none' : 'inline-block'"
    }
})
export class LgMonthRangePreviewComponent {
    /**
     * Source component (required).
     */
    @Input({ required: true }) source!: LgMonthRangeSelectorComponent;

    @Output("clear") readonly clear = new EventEmitter<boolean>();

    _clear($event: UIEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.clear.emit(true);
    }
}
