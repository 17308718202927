/* eslint-disable @typescript-eslint/no-shadow */
import _ from "lodash";
import { Injectable } from "@angular/core";

import * as types from "@logex/framework/types";
import {
    lgSortByPrepare,
    LgPreparedSortBy,
    filterByColumnPrepare,
    filterByColumnPrepared,
    scopedOrderByFilter
} from "@logex/framework/utilities";

import type {
    ILogexPivotDefinition,
    ILogexPivotDefinitionOptions,
    INormalizedLogexPivotDefinition,
    INormalizedLogexPivotDefinitionLocalOptions,
    INormalizedCalculationDefinition,
    IBuildDataExtractorCallback,
    IFullPathElement,
    ICalculationCallback,
    IEachLeafCallback,
    IEachNodeCallback,
    IEachNodeCallbackWithCancel,
    ICanDescendCallback,
    IGatherFilterIdCallback,
    IGatherFilterNameCallback,
    IGatherFilterOrderCallback,
    IGatherFilterFactoryOptions,
    IGatherFilterFactoryResult,
    IGatherFilterIdsFactoryOptions,
    IGatherFilterIdsFactoryResult,
    ISimpleOrderBySpecification,
    ISimpleOrderByPredicate,
    IOrderByPerLevelSpecification,
    IOrderBySpecification,
    INodeStateStore,
    IMixInOperator,
    IMixInCallback
} from "./lg-pivot.types";

import { registerCommonFunctions } from "./lg-pivot-calculator-functions";
import { CalculatorCompiler } from "./lg-pivot-calculator";
import type {
    ICalculationBlock,
    ICalculatorFunction,
    ICompiledFunction,
    ICalculatorLevelStore
} from "./lg-pivot-calculator.types";

/*
export * from "./lg-pivot.types";
export { ICalculatorCallback, ICalculatorCallbackDefinition, ICalculationBlock } from "./lg-pivot-calculator";
*/

interface IBuildContext {
    definition: INormalizedLogexPivotDefinition;
    storage: any[];
    lookup?: types.ILookup<BuildLookupNode>;
    row: any;
    options: INormalizedLogexPivotDefinitionLocalOptions;
    context: any;
    buildDataStore: any;

    parentNode?: any;
    level?: number;
}

class BuildLookupNode {
    public lookup: types.ILookup<BuildLookupNode>;

    constructor(public node: any) {
        this.lookup = {};
    }
}

/**
 * Logex pivot service implementation.
 */
@Injectable({ providedIn: "root" })
export class LogexPivotService {
    public calculatorCompiler = new CalculatorCompiler();

    // ---------------------------------------------------------------------------------------------
    constructor() {
        registerCommonFunctions(this.calculatorCompiler);
    }

    // ---------------------------------------------------------------------------------------------
    protected createAggregationFunction(name: string): ICalculationCallback {
        switch (name) {
            case "sum":
                return function (data, _store, targetKey, parameters): number {
                    const src: string = parameters[0] || targetKey;
                    let i: number,
                        l: number,
                        sum = 0;
                    for (i = 0, l = data.length; i < l; ++i) sum += data[i][src] || 0;
                    return sum;
                };
            case "min":
                return function (data, _store, targetKey, parameters): number {
                    const src: string = parameters[0] || targetKey;
                    let undefined,
                        i: number,
                        l: number,
                        res: number = data.length ? data[0][src] : undefined;
                    for (i = 1, l = data.length; i < l; ++i) res = Math.min(res, data[i][src]);
                    return res;
                };
            case "any":
                return function (data, _store, targetKey, parameters): any {
                    const src: string = parameters[0] || targetKey;
                    let undefined;
                    return data.length ? data[0][src] : undefined;
                };
            case "max":
                return function (data, _store, targetKey, parameters): number {
                    const src = parameters[0] || targetKey;
                    let undefined,
                        i: number,
                        l: number,
                        res: number = data.length ? data[0][src] : undefined;
                    for (i = 1, l = data.length; i < l; ++i) res = Math.max(res, data[i][src]);
                    return res;
                };
            case "count":
                return function (data): number {
                    return data.length;
                };
            case "product":
                return function (data, _store, targetKey, parameters): number {
                    const src: string = parameters[0] || targetKey;
                    let i: number,
                        l: number,
                        sum = 1;
                    for (i = 0, l = data.length; i < l; ++i) sum *= data[i][src] || 0;
                    return sum;
                };
            case "avg":
                return function (data, store, targetKey, parameters): number {
                    if (data.length === 0) return undefined;
                    const src: string = parameters[0] || targetKey;
                    const sumKey = src + "__avgsum$";
                    const countKey = src + "__avgcount$";
                    let i: number,
                        l: number,
                        sum = 0,
                        cnt = 0;
                    if (Object.prototype.hasOwnProperty.call(data[0], sumKey)) {
                        // higher level
                        for (i = 0, l = data.length; i < l; ++i) {
                            sum += data[i][sumKey];
                            cnt += data[i][countKey];
                        }
                    } else {
                        // lowest level
                        for (i = 0, l = data.length; i < l; ++i) {
                            sum += data[i][src] || 0;
                            cnt += 1;
                        }
                    }
                    store[sumKey] = sum;
                    store[countKey] = cnt;
                    return sum / cnt;
                };
            case "lookup":
                // Build lookup of the items based on the column definition, or if missing ,then the first function parameter (or if that's missing, then "id")
                // The lookup is stored under name composed of the calculated value's name, and the definition's store.
                // Example1: { store: "activities", calculate: { lookup:["lookup","activity_id"] }
                // Example2: { column:"zorgactiviteit", calculate: { definition:"lookup" } }
                // Example3: { column:"zorgactiviteit", store: "activities", calculate: { definition:["lookup","activity_id"] }
                // Example 1 would calculate lookup of items based on activity_id, and store them to field named activitiesLookup
                // Example 2 would calculate lookup of items based on zorgactiviteit, and store them to field named definition
                // Example 3 would calculate lookup of items based on zorgactiviteit, and store them to field named activitiesDefinition
                // Note: the column has precedence over the parameter (example3). This is done so that the calculated column's definition can be meaningfully pushed up the hierarchy
                return function (data, store, targetKey, parameters, definition): void {
                    const src: string = definition.column || parameters[0] || "id";
                    const targetPart = definition.store;
                    let target: string;
                    if (targetPart) {
                        target =
                            targetPart +
                            targetKey.substring(0, 1).toUpperCase() +
                            targetKey.substring(1);
                    } else {
                        target = targetKey;
                    }
                    const result: any = {};
                    let i: number, l: number, e: any;
                    for (i = 0, l = data.length; i < l; ++i) {
                        e = data[i];
                        result[e[src]] = e;
                    }
                    store[target] = result;
                };
            case "set":
                // Construct a unique list of the specified values, in the form of { value1: count, value2: count } (where count is how many times the value occured)
                // The sets are correctly merged at higher positions of the pivot hierarchy, unless disabled
                // Full specification is  storage:["set","valuename",mergeMode] where storage is name of the calculated set, valuename is the field where we get the values,
                // and mergeMode describes, how is the merging in hierarchy working
                // missing/truthy:  normal merge: calculate sets at lowest level and merge them higher up. Makes sense when the field exists only at the lowest level
                // "full":        full merge: merge sets from lowest hierarchy AND set calculated for current level. Makes sense only when the field exists on all levels
                // otherwise:     no merge, calculate set for each level individually. Makes sense only when the field exists on all levels
                // Note: when field exists only on lowest level, true and "full" should yield about the same results, but the later will be a bit slower
                return function (
                    data,
                    _store,
                    targetKey,
                    parameters,
                    _definition
                ): types.ILookup<number> {
                    const src: string = parameters[0];
                    let mergeMode = 1;
                    if (parameters[1]) {
                        if (parameters[1] === "full") {
                            mergeMode = 2;
                        } else if (!parameters[1]) {
                            mergeMode = 0;
                        }
                    }
                    let undefined;
                    const result: types.ILookup<number> = {};
                    let i: number, l: number;
                    let subset: types.ILookup<number>, mergeLower: boolean;
                    if (data.length) {
                        mergeLower = mergeMode && _.isObject(data[0][targetKey]);
                        if (mergeLower) {
                            // higher level, merge subsets
                            for (i = 0, l = data.length; i < l; ++i) {
                                subset = data[i][targetKey];
                                // eslint-disable-next-line guard-for-in
                                for (const key in subset) {
                                    result[key] = (result[key] || 0) + subset[key];
                                }
                            }
                        }
                        if (mergeMode !== 1 || !mergeLower) {
                            // merge values from current level
                            for (i = 0, l = data.length; i < l; ++i) {
                                const key = data[i][src];
                                if (key === undefined) continue;
                                result[key] = (result[key] || 0) + 1;
                            }
                        }
                    }
                    return result;
                };
            default:
                throw new Error(`Aggregate function not supported: ${name}`);
        }
    } // createAggregationFunction

    // ---------------------------------------------------------------------------------------------
    protected wrapNewstyleCalculation(calculation: ICompiledFunction): ICalculationCallback {
        return function (nodes, store, _key, _parameters, _definition, context, onTopLevel) {
            calculation(nodes, store, context, onTopLevel);
        };
    }

    // ---------------------------------------------------------------------------------------------
    private runCalculationsOnEmptyArray: any[] = [];
    protected runCalculationOn(
        definition: INormalizedLogexPivotDefinition,
        data: any[],
        store: any,
        type: string,
        context: any,
        onTopLevel: boolean
    ): void {
        const calculation: INormalizedCalculationDefinition = definition[type];
        if (calculation) {
            for (const k in calculation) {
                if (k === "$options") continue;
                const arr = calculation[k];
                if (!arr) continue;
                const fn = <ICalculationCallback>arr[0];
                const result = fn(
                    data,
                    store,
                    k,
                    arr.length > 1 ? arr.slice(1) : this.runCalculationsOnEmptyArray,
                    definition,
                    context,
                    onTopLevel
                );
                if (result !== undefined && k !== "__helper") store[k] = result;
            }
        }
    } // runCalculationOn

    // ----------------------------------------------------------------------------------
    protected placeRowImpl(ctx: IBuildContext, building: boolean): object {
        ctx.parentNode = null;
        ctx.level = 0;
        let buildData = null;
        if (ctx.options.buildDataExtractor) {
            buildData = ctx.options.buildDataExtractor(ctx.row, ctx.buildDataStore, ctx.context);
            if (buildData === undefined) buildData = ctx.buildDataStore;
        }

        while (ctx.definition.children) {
            // Get key column at this level
            let rowKey: string | number;
            if (ctx.definition.mergedKey) {
                rowKey = ctx.definition.mergedKey(ctx.row, ctx.context, buildData);
            } else if (buildData) {
                rowKey = buildData[ctx.definition.column];
                if (rowKey === undefined) rowKey = ctx.row[ctx.definition.column];
            } else {
                rowKey = ctx.row[ctx.definition.column];
            }

            // Get parent for this key
            const useLookup = !!ctx.lookup;
            let lookupNode: BuildLookupNode;
            let parent: any;
            if (useLookup) {
                lookupNode = ctx.lookup[rowKey];
                parent = lookupNode ? lookupNode.node : undefined;
            } else {
                parent = _.find(ctx.storage, x => x[ctx.definition.column] === rowKey);
            }

            // If parent is not found, create
            if (!parent) {
                // Create parent node
                if (ctx.definition.nodeClassSelector) {
                    const fn = ctx.definition.nodeClassSelector(
                        ctx.row,
                        rowKey,
                        ctx.definition,
                        buildData
                    );
                    parent = new fn(ctx.row, ctx.parentNode, ctx.context, {
                        buildData,
                        rowKey,
                        definition: ctx.definition,
                        level: ctx.level
                    });
                    if (!(ctx.definition.column in parent)) parent[ctx.definition.column] = rowKey;
                    if (!(ctx.definition.children.store in parent)) {
                        parent[ctx.definition.children.store] = [];
                    }
                    this.addExtraColumns(
                        ctx.definition,
                        ctx.options,
                        parent,
                        ctx.row,
                        ctx.parentNode,
                        ctx.level,
                        buildData,
                        ctx.context,
                        true
                    );
                } else {
                    parent = {};
                    parent[ctx.definition.column] = rowKey;
                    parent[ctx.definition.children.store] = [];
                    this.addExtraColumns(
                        ctx.definition,
                        ctx.options,
                        parent,
                        ctx.row,
                        ctx.parentNode,
                        ctx.level,
                        buildData,
                        ctx.context,
                        false
                    );
                }

                // Add parent node to storage
                ctx.storage.push(parent);

                // Add lookup for newNode's children
                if (useLookup) {
                    ctx.lookup[rowKey] = lookupNode = new BuildLookupNode(parent);
                }
            }
            // Else parent node is found

            // Go down
            ctx.parentNode = parent;
            ctx.definition = ctx.definition.children;
            ctx.storage = parent[ctx.definition.store];
            ctx.lookup = useLookup ? lookupNode.lookup : undefined;
            ctx.level++;
        }

        // Process node being added and add it to the container
        let leafInstance = ctx.row;
        if (ctx.definition.nodeClassSelector) {
            let rowKey = null;
            if (ctx.definition.column) {
                if (ctx.definition.mergedKey) {
                    rowKey = ctx.definition.mergedKey(ctx.row, ctx.context, buildData);
                } else if (buildData) {
                    rowKey = buildData[ctx.definition.column];
                    if (rowKey === undefined) rowKey = ctx.row[ctx.definition.column];
                } else {
                    rowKey = ctx.row[ctx.definition.column];
                }
            }
            const fn = ctx.definition.nodeClassSelector(ctx.row, rowKey, ctx.definition, {
                buildData,
                rowKey,
                definition: ctx.definition,
                level: ctx.level
            });
            let reattaching = false;
            if (!building) {
                if (!(ctx.row instanceof fn)) {
                    if (ctx.row.constructor.name !== "Object") {
                        throw new Error("reattachLeaf cannot change the leaf's class");
                    } else {
                        leafInstance = new fn(ctx.row, ctx.parentNode, ctx.context, {
                            buildData,
                            rowKey,
                            definition: ctx.definition,
                            level: ctx.level
                        });
                    }
                } else {
                    reattaching = true; // since constuctor is not called again, we force application of the properties
                    if (ctx.definition.mergedKey) leafInstance[ctx.definition.column] = rowKey;
                }
            } else {
                leafInstance = new fn(ctx.row, ctx.parentNode, ctx.context, {
                    buildData,
                    rowKey,
                    definition: ctx.definition,
                    level: ctx.level
                });
            }
            if (ctx.definition.column && !(ctx.definition.column in leafInstance)) {
                leafInstance[ctx.definition.column] = rowKey;
            }
            this.addExtraColumns(
                ctx.definition,
                ctx.options,
                leafInstance,
                ctx.row,
                ctx.parentNode,
                ctx.level,
                buildData,
                ctx.context,
                !reattaching
            );
            // note: we don't copy inherited properties With the expected usage this shouldn't be needed
            if (!reattaching) {
                Object.keys(ctx.row).forEach(key => {
                    if (key in leafInstance) return;
                    leafInstance[key] = ctx.row[key];
                });
            }
        } else {
            if (ctx.definition.mergedKey && ctx.definition.column) {
                ctx.row[ctx.definition.column] = ctx.definition.mergedKey(
                    ctx.row,
                    ctx.context,
                    buildData
                );
            } else if (buildData && ctx.definition.column) {
                const extracted = buildData[ctx.definition.column];
                if (extracted !== undefined) ctx.row[ctx.definition.column] = extracted;
            }
            this.addExtraColumns(
                ctx.definition,
                ctx.options,
                ctx.row,
                ctx.row,
                ctx.parentNode,
                ctx.level,
                buildData,
                ctx.context,
                false
            );
        }

        ctx.storage.push(leafInstance);
        return leafInstance;
    }

    // ---------------------------------------------------------------------------------------------
    protected addExtraColumns(
        definition: INormalizedLogexPivotDefinition,
        options: INormalizedLogexPivotDefinitionLocalOptions,
        target: any,
        row: any,
        parentNode: any,
        level: number,
        extractedBuildData: any,
        context: any,
        skipExisting: boolean
    ): void {
        if (
            options.parentLink &&
            (!skipExisting || !((definition.parentAs || options.parentLink) in target))
        ) {
            target[definition.parentAs || options.parentLink] = parentNode;
        }
        if (options.injectLevel && (!skipExisting || !(options.injectLevel in target))) {
            target[options.injectLevel] = level;
        }
        if (options.injectDefinition && (!skipExisting || !(options.injectDefinition in target)))
            target[options.injectDefinition] = definition;

        const attached = definition.attachedColumns;
        if (attached) {
            if (target === row) {
                for (let i = 0, l = attached.length; i < l; ++i) {
                    const entry = attached[i];
                    if (typeof entry === "string") {
                        if (options.buildDataExtractor && extractedBuildData) {
                            const val = extractedBuildData[entry];
                            if (val !== undefined) target[entry] = val;
                        }
                    } else {
                        entry(target, row, parentNode, context, extractedBuildData);
                    }
                }
            } else {
                for (let i = 0, l = attached.length; i < l; ++i) {
                    const entry = attached[i];
                    if (typeof entry === "string") {
                        if (skipExisting && entry in target) continue;
                        if (options.buildDataExtractor && extractedBuildData) {
                            const val = extractedBuildData[entry];
                            if (val === undefined) {
                                target[entry] = row[entry];
                            } else {
                                target[entry] = val;
                            }
                        } else {
                            target[entry] = row[entry];
                        }
                    } else {
                        entry(target, row, parentNode, context, extractedBuildData);
                    }
                }
            }
        }
    }

    protected implementDefinitionMethods(definition: INormalizedLogexPivotDefinition): void {
        definition.getDefinitionByOffset = (offset: number) => {
            if (offset === 0) return definition;
            let current = definition;
            if (offset > 0) {
                while (offset && current.children) {
                    --offset;
                    current = current.children;
                }
                if (offset) throw new Error(`getDefinitionByOffset: Invalid offset specified`);
                return current;
            } else {
                while (offset && current.$parent) {
                    ++offset;
                    current = current.$parent;
                }
                if (offset) throw new Error(`getDefinitionByOffset: Invalid offset specified`);
                return current;
            }
        };

        definition.getRootDefinition = () => {
            let last = null;
            let current = definition;
            while (current) {
                last = current;
                current = current.$parent;
            }
            return last;
        };

        definition.getNodeName = (levelId: any, node: any, context?: any) => {
            let level = definition;
            if (node === undefined && context === undefined) {
                node = levelId;
                levelId = null;
            } else if (node && context === undefined) {
                if (!_.isNumber(levelId) && !_.isString(levelId)) {
                    context = node;
                    node = levelId;
                    levelId = null;
                }
            }
            if (levelId != null) level = definition.$levels[levelId];
            return level.nodeNameFn(node, context, level);
        };

        if (definition.children) {
            definition.eachChild = (
                node: any,
                callback: (childNode: any, index: number, context: any) => void,
                context?: any
            ) => {
                const store = definition.children.store;
                const children: any[] = node[store];
                for (let i = 0, l = children.length; i < l; ++i) {
                    callback(children[i], i, context);
                }
            };

            definition.eachChildFiltered = (
                filteredNode: any,
                callback: (childNode: any, index: number, context: any) => void,
                context?: any
            ) => {
                const store = definition.children.filteredStore;
                const children: any[] = filteredNode[store];
                for (let i = 0, l = children.length; i < l; ++i) {
                    callback(children[i], i, context);
                }
            };
        } else {
            definition.eachChild = definition.eachChildFiltered = () => {
                throw new Error(`Node has no children`);
            };
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Get path describing steps from top to the immediate container of the leafNode
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param leafNode is the leaf to be found
     * @return the full path to the node
     *
     * @todo: consider supporting parent link if present.  But note that the code in its present form
     * can also process node that's  not currently allocated to the tree: that would not work.
     */
    public getLeafParents(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        leafNode: any,
        context?: any
    ): IFullPathElement[] {
        const path: IFullPathElement[] = [];
        let extractedBuildData = null;
        if (definition.$options && definition.$options.buildDataExtractor) {
            const store = {};
            extractedBuildData = definition.$options.buildDataExtractor(leafNode, store, context);
            if (extractedBuildData === undefined) extractedBuildData = store;
        }
        while (definition.children) {
            // Get key column at this level
            let rowKey: any;
            if (definition.mergedKey) {
                rowKey = definition.mergedKey(leafNode, context, extractedBuildData);
            } else if (extractedBuildData) {
                rowKey = extractedBuildData[definition.column];
                if (rowKey === undefined) rowKey = leafNode[definition.column];
            } else {
                rowKey = leafNode[definition.column];
            }

            // Get parent for this key
            const parent = _.find(nodes, x => x[definition.column] === rowKey);
            path.push({
                definition,
                node: parent
            });

            // Go down
            definition = definition.children;
            nodes = parent[definition.store];
        }
        return path;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Register a new function generator. The name is automatically converted to uppercase
     */
    public registerCalculatorFunction(name: string, fn: new () => ICalculatorFunction): void {
        this.calculatorCompiler.registerFunction(name, fn);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Prepare the pivot definition for usage. This includes adding default values to some missing properties, handling of
     * merged keys. It also includes a whole lots of the calculation processing - normalizing the definition, attaching
     * the built-in aggregate functions, and propagation of calculation upwards in the tree.
     *
     * @param definition is the original pivot definition
     * @options specifies additional configuration (for example non-standard calculations that should be normalized)
     * @return the normalized definition, usable for the rest of the api
     */
    public prepareDefinition(
        definition: ILogexPivotDefinition,
        options?: ILogexPivotDefinitionOptions
    ): INormalizedLogexPivotDefinition {
        options = options || {};
        const lookup: types.ILookup<INormalizedLogexPivotDefinition> = {};
        const compiled: types.IStringLookup<boolean> = {};

        const impl = (
            definition: ILogexPivotDefinition,
            hidden: boolean,
            parent: INormalizedLogexPivotDefinition,
            level: number
        ): void => {
            const fixed: types.IStringLookup<boolean> = {};

            definition.hidden = definition.hidden || hidden;
            const normalizedDefinition = <INormalizedLogexPivotDefinition>definition;
            normalizedDefinition.$parent = parent;
            normalizedDefinition.$levelIndex = level;
            normalizedDefinition.$levels = lookup;

            lookup[normalizedDefinition.$levelIndex] = normalizedDefinition;
            if (definition.levelId != null) {
                if (
                    _.isNumber(definition.levelId) ||
                    definition.levelId === "" + +definition.levelId
                ) {
                    throw new Error(`Pivot levelId cannot be numeric: ${definition.levelId}`);
                }
                lookup[definition.levelId] = normalizedDefinition;
            }

            this.implementDefinitionMethods(normalizedDefinition);

            if (definition.children)
                impl(definition.children, definition.hidden, normalizedDefinition, level + 1);

            if (!definition.store || !definition.store.length) definition.store = "pivot";
            if (!definition.filteredStore || !definition.filteredStore.length)
                definition.filteredStore =
                    "filtered" +
                    definition.store.substring(0, 1).toUpperCase() +
                    definition.store.substring(1);
            if (definition.mergedKey && _.isArray(definition.mergedKey)) {
                // If mergedKey is array, it's an array of column names
                let i: number, e: string;
                // all the parts of the mergedKey must be attached too
                if (!definition.attachedColumns) definition.attachedColumns = [];
                for (i = 0; i < definition.mergedKey.length; ++i) {
                    e = definition.mergedKey[i];
                    if (!_.includes(definition.attachedColumns, e))
                        definition.attachedColumns.unshift(e);
                }
                definition.mergedKey = (function (def: string[]) {
                    if (options.buildDataExtractor) {
                        return function getMergedKey(row: any, _context: any, extraBuildData: any) {
                            let key = "";
                            for (const entry of def) {
                                if (key !== "") key += "__";
                                let part = extraBuildData ? extraBuildData[entry] : undefined;
                                if (part === undefined) part = row[entry];
                                if (part === undefined) part = null;
                                key += part;
                            }
                            return key;
                        };
                    } else {
                        return function getMergedKey(row: any) {
                            let key = "";
                            for (const entry of def) {
                                if (key !== "") key += "__";
                                let part = row[entry];
                                if (part === undefined) part = null;
                                key += part;
                            }
                            return key;
                        };
                    }
                })(<string[]>definition.mergedKey);
            }

            if (definition.nodeClass || definition.nodeClassSelector) {
                if (definition.nodeClass && !definition.nodeClassSelector) {
                    definition.nodeClassSelector = () => definition.nodeClass;
                }
            }

            // convert the calculations
            const fixCalculations = (type: string): void => {
                fixed[type] = true;
                // Use explicit null to remove the whole calculation higher in the hierarchy
                if (definition[type] === null) return;
                if (definition[type]) {
                    // convert function to object with one entry. This is for calculations that contain just one function, ie updatePrices:{ update:function(){...} }
                    if (_.isFunction(definition[type])) {
                        definition[type] = { __helper: definition[type] };
                    }
                    for (const k in definition[type]) {
                        if (k === "$options") continue;
                        let e = definition[type][k];
                        if (!e) continue;
                        // the full format is target_value:[function, source_value]. When only function is specified, we set the source value equal to target
                        if (!_.isArray(e)) {
                            e = [e, k];
                            definition[type][k] = e;
                        }
                        // fn(data, store, k, arr.slice(1));
                        if (!_.isFunction(e[0])) {
                            e[0] = this.createAggregationFunction(e[0]);
                        }
                    }
                }
                if (definition.children && definition.children[type]) {
                    if (definition[type]) {
                        definition[type] = _.extend(
                            {},
                            definition.children[type],
                            definition[type]
                        );
                    } else {
                        definition[type] = definition.children[type];
                    }
                }
            };
            fixCalculations("calculate");
            fixCalculations("calculateOnce");
            if (options.calculations) {
                for (const calculation of options.calculations) {
                    fixCalculations(calculation);
                }
            }
            if (options.calculationChains) {
                _.each(options.calculationChains, (followUp, start) => {
                    if (!fixed[start]) fixCalculations(start);
                    if (!fixed[followUp]) fixCalculations(followUp);
                });
            }
        };

        const compileCalculations = (
            definition: INormalizedLogexPivotDefinition,
            name: string
        ): ICalculatorLevelStore => {
            compiled[name] = true;
            let childHelper: ICalculatorLevelStore = null;
            if (definition.children) childHelper = compileCalculations(definition.children, name);

            const blockDefinition = definition[name];
            // the block is not of the new type
            if (blockDefinition && !(blockDefinition.steps && _.isArray(blockDefinition.steps)))
                return null;
            // the block is not defined, and not inherited from lower level
            if (!blockDefinition && !childHelper) return null;

            let block: ICalculationBlock = blockDefinition;
            if (!block) {
                // no block definition, but we have some inherited stuff, so create suitable empty block
                block = {
                    thisLevelOnly: false,
                    merge: true,
                    unfiltered: childHelper.unfiltered,
                    debug: childHelper.debug,
                    steps: []
                };
            } else {
                // we have block, fill in default for the options, if missing
                if (block.merge == null) block.merge = true;
                if (block.thisLevelOnly == null) block.thisLevelOnly = false;
                if (block.unfiltered == null)
                    block.unfiltered = childHelper ? childHelper.unfiltered : null;
                if (block.debug == null) block.debug = childHelper ? childHelper.debug : false;
            }
            // compile the block
            const [compiledFn, levelStore] = this.calculatorCompiler.compile(
                block,
                definition,
                childHelper,
                name,
                options.calculatorFunctions
            );
            // now emulate the old-style calculation block
            definition[name] = {
                compiled: compiledFn ? [this.wrapNewstyleCalculation(compiledFn)] : null,
                $options: {
                    unfiltered: block.unfiltered,
                    compiled: true
                }
            };
            return levelStore;
        };

        // note: the definition is not normalized yet, but it will be at the time the calculations are evaluated
        compileCalculations(<INormalizedLogexPivotDefinition>definition, "calculate");
        compileCalculations(<INormalizedLogexPivotDefinition>definition, "calculateOnce");
        if (options.calculations) {
            for (const calculation of options.calculations) {
                compileCalculations(<INormalizedLogexPivotDefinition>definition, calculation);
            }
        }

        if (definition.$options && definition.$options.calculationChains) {
            options.calculationChains = _.extend(
                {},
                options.calculationChains || {},
                definition.$options.calculationChains
            ) as types.IStringLookup<string>;
            // console.log(options.calculationChains);
        }
        if (options.calculationChains) {
            // use this to detect cycles
            const dependsOn: types.IStringLookup<types.IStringLookup<boolean>> = {};
            const usedIn: types.IStringLookup<string[]> = {};
            _.each(options.calculationChains, (followUp, start) => {
                if (dependsOn[followUp]) {
                    const extended = _.clone(dependsOn[followUp]);
                    if (extended[start])
                        throw new Error(
                            `calculationChain loop detected, starting with '${start}' then '${followUp}'`
                        );
                    extended[followUp] = true;
                    dependsOn[start] = extended;
                    usedIn[followUp].push(start);
                } else {
                    dependsOn[start] = { [followUp]: true };
                    usedIn[followUp] = [start];
                }
                const previousUses = usedIn[start];
                if (previousUses) {
                    for (const other of previousUses) {
                        dependsOn[other][followUp] = true;
                    }
                } else {
                    usedIn[start] = [];
                }
                // And compile both source and target
                if (!compiled[start])
                    compileCalculations(<INormalizedLogexPivotDefinition>definition, start);
                if (!compiled[followUp])
                    compileCalculations(<INormalizedLogexPivotDefinition>definition, followUp);
            });
        }

        impl(definition, false, null, 0);

        if (options.options || options.calculationChains || options.buildDataExtractor) {
            const input = options.options || {};
            const localOptions = definition.$options || {};
            localOptions.parentLink = localOptions.parentLink || input.parentLink || false;
            if (localOptions.parentLink && !_.isString(localOptions.parentLink)) {
                localOptions.parentLink = "$parent";
            }
            localOptions.injectLevel = localOptions.injectLevel || input.injectLevel || false;
            if (localOptions.injectLevel && !_.isString(localOptions.injectLevel)) {
                localOptions.injectLevel = "$level";
            }
            localOptions.injectDefinition =
                localOptions.injectDefinition || input.injectDefinition || false;
            if (localOptions.injectDefinition && !_.isString(localOptions.injectDefinition)) {
                localOptions.injectDefinition = "$definition";
            }
            localOptions.calculationChains = options.calculationChains;
            definition.$options = localOptions;
            if (options.buildDataExtractor) {
                (
                    definition.$options as INormalizedLogexPivotDefinitionLocalOptions
                ).buildDataExtractor = options.buildDataExtractor;
            }
        }
        return <INormalizedLogexPivotDefinition>definition;
    } // prepareDefinition

    // ---------------------------------------------------------------------------------------------
    /**
     * Construct a pivot tree from the specified data, using the definition. If define, run the "calculateOnce" calculation
     * on the result. Note that no filtering is done, and thus the properties pointing to filtered tree won't be defined.
     *
     * Note that the code doesn't remove the properties used as key from the rows themselves. The leafs of the tree will be
     * therefore constructed of the rows themselves.
     *
     * @param definition is the normalized tree definition
     * @param data is array or rows that will be turned into the tree
     * @param totals is an object that will contain result of the calculations done on the top level. When not specified,
     * the result is not stored.
     * @param context optional context passed to the attachedColumns callbacks (typically this of the owner class)
     * @return the build tree (that is, array of the top level nodes)
     */
    public build(
        definition: INormalizedLogexPivotDefinition,
        data: any[],
        totals?: any,
        context?: any
    ): any[] {
        const result: any[] = [];

        const lookup: types.ILookup<any> = {};
        const buildContext: IBuildContext = {
            definition,
            storage: result,
            lookup,
            options: definition.$options || {},
            row: null,
            buildDataStore: {},
            context // Callbacks call context
        };

        for (let i = 0, l = data.length; i < l; ++i) {
            buildContext.definition = definition;
            buildContext.storage = result;
            buildContext.lookup = lookup;
            buildContext.row = data[i];
            this.placeRowImpl(buildContext, true);
        }

        if (this.hasCalculationName(definition, "calculateOnce")) {
            this.evaluateCalculations(definition, result, totals, "calculateOnce", true, context);
        }
        return result;
    } // build

    // ---------------------------------------------------------------------------------------------
    /**
     * Process a pivot tree that has been already built, most likely at the backend. In practice this means that we process
     * the attachedColumns defintions, run the "calculateOnce" calculation, and optionally inject the parentLink/level/definition
     * properties if specified by the definition.
     *
     * Note: in the normal process the nodes higher in the hierarchy have opportunity to extract data from the individual
     * rows (which end up being leafs of the tree). We simulate this by going depth-first, and passing back the first leaf
     * in the branch (which is the row that would be spawning the creation of the parent in the normal build)
     *
     * Also note that if the leafs don't contain all the properties used as keys higher in the hierarchy, we don't try
     * to inject them back.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes
     * @param totals is an object that will contain result of the calculations done on the top level. When not specified,
     * the result is not stored.
     * @param context optional context passed to the attachedColumns callbacks (typically this of the owner class)
     * @return the build tree (that is, array of the top level nodes)
     */
    buildAlreadyPivoted(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        totals?: any,
        context?: any
    ): any[] {
        // run through the pivoted hierarchy, only processing the attachedColumns and calculateOnce definition.
        const options = definition.$options || {};
        function buildPivotedImpl(
            definition: INormalizedLogexPivotDefinition,
            data: any[],
            parentNode: any,
            level: number
        ): any {
            let first = null,
                result;
            const store = definition.children && definition.children.store;
            const attached = definition.attachedColumns;
            const lAttached = attached && attached.length;
            data = data || []; // Treat undefined children
            // Note: in the normal process the nodes higher in the hierarchy have opportunity to extract data from the individual rows (which end up being leafs of the tree)
            // We simulate this by going depth-first, and passing back the first leaf in the branch (which is the row that would be spawning the creation of the parent in the normal build)
            for (let i = 0, l = data.length; i < l; ++i) {
                const entry = data[i];
                if (definition.children) {
                    result = buildPivotedImpl(definition.children, entry[store], entry, level + 1);
                    if (i === 0) first = result;
                } else if (i === 0) {
                    first = entry;
                }
                if (options.parentLink) entry[options.parentLink] = parentNode;
                if (options.injectLevel) entry[options.injectLevel] = level;
                if (options.injectDefinition) entry[options.injectDefinition] = definition;
                if (lAttached) {
                    for (let j = 0; j < lAttached; ++j) {
                        const attachedEntry = attached[j];
                        if (typeof attachedEntry === "string") {
                            const firstValue = first[attachedEntry];
                            entry[attachedEntry] =
                                firstValue !== undefined ? firstValue : entry[attachedEntry];
                        } else {
                            attachedEntry(entry, first, parentNode, context);
                        }
                    }
                }
            }
            return first;
        }

        if (!nodes) return nodes;
        buildPivotedImpl(definition, nodes, null, 0);

        if (this.hasCalculationName(definition, "calculateOnce")) {
            this.evaluateCalculations(definition, nodes, totals, "calculateOnce", true, context);
        }
        return nodes;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Filters the tree using the current filter definition, storing the filtered levels in the appropriate properties as defined.
     * Normally, a node is removed if all its children are filtered away. This can be overriden by the filtersKeepChildless parameter
     * of the definition.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level
     * @param context optional context passed to the filters callbacks (typically this of the owner class)
     * @return the top nodes of the filtered tree
     */
    public filter(definition: INormalizedLogexPivotDefinition, nodes: any[], context?: any): any[] {
        const filters: types.ICleanedColumnFilter[][] = [];
        let current = definition;
        while (current) {
            filters.push(current.filters ? filterByColumnPrepare(current.filters(context)) : null);
            current = current.children;
        }

        const filterImplInternal = (
            definition: INormalizedLogexPivotDefinition,
            data: any[],
            level: number
        ): any[] => {
            let result = filters[level] ? filterByColumnPrepared(data, filters[level]) : data;
            if (!definition.children) return result;

            data = result || [];
            result = [];
            const store = definition.children.store;
            const filteredStore = definition.children.filteredStore;
            for (let i = 0, l = data.length; i < l; ++i) {
                const entry = data[i];
                const partial = filterImplInternal(definition.children, entry[store], level + 1);
                entry[filteredStore] = partial;
                if (!definition.filtersKeepChildless && (partial == null || partial.length === 0))
                    continue;
                result.push(entry);
            }
            return result;
        };

        return filterImplInternal(definition, nodes, 0);
    } // filter

    // ---------------------------------------------------------------------------------------------
    /**
     * Run the specified calculation on the whole tree and return results from the top level. If filtered calculation is requested,
     * it is assumed the tree has been already filtered
     *
     * Note that the calculation doesn't use any children->parent links (as those are optional). Therefore if you specify only a subtree
     * of the tree, the node immediately above the subtree will not be affected.
     *
     * Calculation chains are automatically followed. If unfiltered is not specified, then all the calculations in the chain must have
     * the same unfiltered option.
     *
     * @param definition is the normalized tree definition
     * @param nodes are either the filtered or unfiltered nodes of the top level, depending on the unfiltered flag (or calculation option)
     * @param store is the object to which the result of the top-level calculations will be stored. If not specified, an empty one is created
     * @param calculationName defines the name of the calculation to run. Defaults to "calculate"
     * @param unfiltered specifies, whether the filtered or unfiltered tree should be used. Defaults to false (so filtered tree).
     * @param context optional context passed to the calculation callbacks (typically this of the owner class)
     * @return the object containing results of the top level calculations (equivalent to store if specified)
     */
    public evaluateCalculations(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        store?: any,
        calculationName?: string,
        unfiltered?: boolean,
        context?: any
    ): any;

    // ---------------------------------------------------------------------------------------------
    /**
     * Run the specified calculation on the whole tree and return results from the top level. If filtered calculation is requested,
     * it is assumed the tree has been already filtered
     *
     * Note that the calculation doesn't use any children->parent links (as those are optional). Therefore if you specify only a subtree
     * of the tree, the node immediately above the subtree will not be affected.
     *
     * Calculation chains are automatically followed, and can mix filtered / unfiltered settings.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level
     * @param store is the object to which the result of the top-level calculations will be stored. If not specified, an empty one is created
     * @param calculationName defines the name of the calculation to run. Defaults to "calculate"
     * @param unfiltered specifies, whether the filtered or unfiltered tree should be used. Defaults to false (so filtered tree).
     * @param context optional context passed to the calculation callbacks (typically this of the owner class)
     * @return the object containing results of the top level calculations (equivalent to store if specified)
     */
    public evaluateCalculations(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        filteredNodes: any[],
        store?: any,
        calculationName?: string,
        unfiltered?: boolean,
        context?: any
    ): any;

    public evaluateCalculations(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        filteredNodes: any[] | any,
        store?: any | string,
        calculationName?: string | boolean,
        unfiltered?: boolean | any,
        context?: any
    ): any {
        if (filteredNodes && _.isArray(filteredNodes)) {
            calculationName = calculationName || "calculate";
            store = store || {};
            // both node sets were passed, just go ahead
            this._evaluateCalculationsImpl(
                definition,
                nodes,
                filteredNodes,
                <any>store,
                <string>calculationName,
                <boolean | undefined>unfiltered,
                <any>context,
                true
            );
        } else {
            // we got only 1 set of nodes, shift the parameters
            context = unfiltered;
            unfiltered = calculationName;
            calculationName = store;
            store = filteredNodes;
            calculationName = calculationName || "calculate";
            store = store || {};
            // we have only 1 set of nodes, decide which one it is based on the first calculation
            let unfilteredLocal = unfiltered;
            if (
                unfilteredLocal == null &&
                definition[<string>calculationName] &&
                definition[<string>calculationName].$options
            ) {
                unfilteredLocal = definition[<string>calculationName].$options.unfiltered;
            }

            this._evaluateCalculationsImpl(
                definition,
                unfilteredLocal ? nodes : null,
                unfilteredLocal ? null : nodes,
                <any>store,
                <string>calculationName,
                <boolean | undefined>unfiltered,
                <any>context,
                true
            );
        }
        return store;
    } // evaluateCalculations

    private _evaluateCalculationsImpl(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        filteredNodes: any[],
        store: any,
        calculationName: string,
        unfiltered: boolean | undefined,
        context: any,
        onTopLevel: boolean
    ): void {
        const unfilteredOriginal = unfiltered;
        if (
            unfiltered == null &&
            definition[calculationName] &&
            definition[calculationName].$options
        )
            unfiltered = definition[calculationName].$options.unfiltered;
        const targetNodes = unfiltered ? nodes : filteredNodes;
        if (!targetNodes) {
            throw new Error(
                (unfiltered ? "Unfiltered " : "Filtered ") +
                    " calculation evaluated, but the required nodes not passed"
            );
        }
        // depth first
        if (definition.children) {
            for (let i = 0, l = targetNodes.length; i < l; ++i) {
                const entry = targetNodes[i];
                this._evaluateCalculationsImpl(
                    definition.children,
                    entry[definition.children.store] || [],
                    entry[definition.children.filteredStore] || [],
                    entry,
                    calculationName,
                    unfiltered,
                    context,
                    false
                );
            }
        }
        this.runCalculationOn(definition, targetNodes, store, calculationName, context, onTopLevel);

        // If we are on top of the hierarchy, try to follow the chain
        if (onTopLevel) {
            const rootDefinition = definition.$levels[0];
            const calculationChains =
                rootDefinition.$options && rootDefinition.$options.calculationChains;
            const next = calculationChains && calculationChains[calculationName];
            if (next) {
                // note that we let the next calculation decide on the unfiltered flag
                this._evaluateCalculationsImpl(
                    definition,
                    nodes,
                    filteredNodes,
                    store,
                    next,
                    unfilteredOriginal,
                    context,
                    true
                );
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Determintes, if calculation of given name exists on the pivot. Note that this basically checks if property exists anywhere on the definition - we
     * do very little to determine if it's actually an calculation
     *
     * @param definition is the root of the normalized pivot definition
     * @param calculationName is the name of the calculation to check
     */
    // ---------------------------------------------------------------------------------------------
    public hasCalculationName(
        definition: INormalizedLogexPivotDefinition,
        calculationName: string
    ): boolean {
        while (definition) {
            const stored = definition[calculationName];
            if (stored && _.isObject(stored)) return true;
            definition = definition.children;
        }
        return false;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Run the specified calculation on the specified path, and return results from the top level. If filtered calculation is requested,
     * it is assumed the tree has been already filtered.
     *
     * The path is array of nodes that should be processed. It should end up eiter with "true" (process all nodes on this level and below), "false" (process
     * this level only) or simply be cut short (which is equivalent of true). Null value in the path will be treated the same as "true".
     * So to optimize calculations only for a visible part of data, you might pass something like [this.selectedSpecialisation, this.selectedProduct].
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level
     * @param path is the path defining where to run the calculation
     * @param store is the object to which the result of the top-level calculations will be stored. If not specified, an empty one is created
     * @param calculationName defines the name of the calculation to run. Defaults to "calculate"
     * @param unfiltered specifies, whether the filtered or unfiltered tree should be used. Defaults to false (so filtered tree).
     * @param context optional context passed to the calculation callbacks (typically this of the owner class)
     * @return the object containing results of the top level calculations (equivalent to store if specified)
     */
    public evaluateCalculationsOnPath(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        path: any[],
        store?: any,
        calculationName?: string,
        unfiltered?: boolean,
        context?: any
    ): any {
        calculationName = calculationName || "calculate";
        const unfilteredOriginal = unfiltered;
        if (
            unfiltered == null &&
            definition[calculationName] &&
            definition[calculationName].$options
        ) {
            unfiltered = definition[calculationName].$options.unfiltered;
        }
        store = store || {};

        return this._evaluateCalculationsOnPathImpl(
            definition,
            nodes,
            path,
            store,
            calculationName!,
            unfiltered ?? false,
            context,
            true,
            unfilteredOriginal
        );
    } // evalucateCalculationsOnPath

    private _evaluateCalculationsOnPathImpl(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        path: any[],
        store: any,
        calculationName: string,
        unfiltered: boolean,
        context: any,
        onTopLevel: boolean,
        unfilteredOriginal: boolean | undefined
    ): any {
        if (path.length === 0 || path[0] === true || path[0] == null) {
            return this._evaluateCalculationsImpl(
                definition,
                unfiltered ? nodes : null,
                unfiltered ? null : nodes,
                store,
                calculationName,
                unfiltered,
                context,
                onTopLevel
            );
        }

        if (path[0] !== false) {
            if (!definition.children)
                throw new Error("Path specified, but the definition has no children");

            const storeName =
                definition.children &&
                (unfiltered ? definition.children.store : definition.children.filteredStore);
            this._evaluateCalculationsOnPathImpl(
                definition.children,
                path[0][storeName],
                path.slice(1),
                path[0],
                calculationName,
                unfiltered,
                context,
                false,
                unfilteredOriginal
            );
        }
        this.runCalculationOn(definition, nodes, store, calculationName, context, onTopLevel);

        if (onTopLevel) {
            const rootDefinition = definition.$levels[0];
            const calculationChains =
                rootDefinition.$options && rootDefinition.$options.calculationChains;
            const next = calculationChains && calculationChains[calculationName];
            if (next) {
                // note that we let the next calculation decide on the unfiltered flag
                this.evaluateCalculationsOnPath(
                    definition,
                    nodes,
                    path,
                    store,
                    next,
                    unfilteredOriginal,
                    context
                );
            }
        }

        return store;
    } // _evalucateCalculationsOnPathImpl

    // ---------------------------------------------------------------------------------------------
    /**
     * Run the specified calculation on path from root to the target node, and optionally on the subtree under. If filtered calculation is requested,
     * it is assumed the tree has been already filtered
     *
     * This call requires that the tree was built with the parentLink option enabled. Also, given the way that option is stored (on root level of definiton only, you
     * cannot currently use it on any subtree, unless you patch in the $options property.
     *
     * @param node is the target node in the tree
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level
     * @param store is the object to which the result of the top-level calculations will be stored. If not specified, an empty one is created
     * @param calculationName defines the name of the calculation to run. Defaults to "calculate"
     * @param unfiltered specifies, whether the filtered or unfiltered tree should be used. Defaults to false (so filtered tree).
     * @param stopAtNode specifies, if the calculations should stop at the target node, not evaluation its subtree. Defaults to false
     * @param context optional context passed to the calculation callbacks (typically this of the owner class)
     * @return the object containing results of the top level calculations (equivalent to store if specified)
     */
    public evaluateCalculationsOnPathToNode(
        node: any,
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        store?: any,
        calculationName?: string,
        unfiltered?: boolean,
        stopAtNode?: boolean,
        context?: any
    ): any {
        const parentName = definition.$options.parentLink;
        if (!parentName) throw new Error("evaluateCalculationsOnPathToNode() requires parent link");
        // convert to path
        const path = [];
        if (node) {
            do {
                path.unshift(node);
            } while ((node = node[parentName]));
        }
        if (stopAtNode) path.push(false);
        return this.evaluateCalculationsOnPath(
            definition,
            nodes,
            path,
            store,
            calculationName,
            unfiltered,
            context
        );
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Visit every leaf of the unfiltered tree. Note that based on the parameters, a "leaf" is considered either the node at real bottom level, or the
     * last visible node!
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the unfiltered top level
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, the last visible nodes will be
     * visited instead of the real leafs.
     * @param callback is the function triggered for every leaf
     * @param context is optional context, passed to the callback
     */
    public eachLeaf(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        includeHidden: boolean,
        callback: IEachLeafCallback,
        context?: any
    ): void {
        this._eachLeafImpl(definition, nodes, includeHidden, callback, [], context);
    } // eachLeaf

    private _eachLeafImpl(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        includeHidden: boolean,
        callback: IEachLeafCallback,
        parents: any[],
        context?: any
    ): void {
        const store = definition.children && definition.children.store;
        const goDeeper = definition.children && (includeHidden || !definition.children.hidden);
        for (let i = 0, l = nodes.length; i < l; ++i) {
            const entry = nodes[i];
            if (goDeeper) {
                parents.push(entry);
                this._eachLeafImpl(
                    definition.children,
                    entry[store],
                    includeHidden,
                    callback,
                    parents,
                    context
                );
                parents.pop();
            } else {
                callback(entry, i, context, parents);
            }
        }
    } // eachLeaf

    // ---------------------------------------------------------------------------------------------
    /**
     * Visit every leaf of the already filtered tree.
     *
     * Note that based on the parameters, a "leaf" is considered either the node at real bottom level, or the
     * last visible node!
     * Note: it is now possible to pass a single object as data, in which case it will visit its children (unless the object itself is leaf)
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the filtered top level, or one node
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, the last visible nodes will be
     * visited instead of the real leafs.
     * @param callback is the function triggered for every leaf
     * @param context is optional context, passed to the callback
     */
    public eachLeafFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[] | any,
        includeHidden: boolean,
        callback: IEachLeafCallback,
        context?: any
    ): void {
        const initialParents: any = [];

        if (!_.isArray(filteredNodes)) {
            if (definition.children && (includeHidden || !definition.children.hidden)) {
                definition = definition.children;
                filteredNodes = filteredNodes[definition.store];
                initialParents.push(filteredNodes);
            } else {
                callback(filteredNodes, 0, context, []);
            }
        }
        this._eachLeafFilteredImpl(
            definition,
            filteredNodes,
            includeHidden,
            callback,
            initialParents,
            context
        );
    } // eachLeafFiltered

    private _eachLeafFilteredImpl(
        definition: INormalizedLogexPivotDefinition,
        data: any[],
        includeHidden: boolean,
        callback: IEachLeafCallback,
        parents: any[],
        context: any
    ): void {
        const store = definition.children && definition.children.filteredStore;
        const goDeeper = definition.children && (includeHidden || !definition.children.hidden);
        for (let i = 0, l = data.length; i < l; ++i) {
            const entry = data[i];
            if (goDeeper) {
                parents.push(entry);
                this._eachLeafFilteredImpl(
                    definition.children,
                    entry[store],
                    includeHidden,
                    callback,
                    parents,
                    context
                );
                parents.pop();
            } else {
                callback(entry, i, context, parents);
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Visit every leaf of the partially filtered tree. The filtering is done as part of the call, and doesn't affect the tree's state.
     *
     * Note that based on the parameters, a "leaf" is considered either the node at real bottom level, or the
     * last visible node!
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the unfiltered top level
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, the last visible nodes will be
     * visited instead of the real leafs.
     * @param ignoredFilter is name of the filter which should be ignored
     * @param callback is the function triggered for every leaf
     * @param context is optional context, passed to the callback
     */
    public eachLeafPartiallyFiltered(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        includeHidden: boolean,
        ignoredFilter: string | string[],
        callback: IEachLeafCallback,
        context?: any
    ): number {
        const filters: types.ICleanedColumnFilter[][] = [];
        let current = definition;
        while (current) {
            filters.push(
                current.filters
                    ? filterByColumnPrepare(current.filters(context), ignoredFilter)
                    : null
            );
            current = current.children;
        }

        const eachLeafPartiallyFilteredImpl = (
            definition: INormalizedLogexPivotDefinition,
            nodes: any[],
            includeHidden: boolean,
            level: number,
            callback: IEachLeafCallback,
            parents: any[],
            context: any
        ): number => {
            const partial = filters[level] ? filterByColumnPrepared(nodes, filters[level]) : nodes;
            if (!partial) return 0;
            if (definition.children) {
                const iterate = !includeHidden && definition.children.hidden;
                const store = definition.children.store;
                let total = 0;
                for (let i = 0, l = partial.length; i < l; ++i) {
                    const entry = partial[i];
                    parents.push(entry);
                    const size = eachLeafPartiallyFilteredImpl(
                        definition.children,
                        entry[store],
                        includeHidden,
                        level + 1,
                        callback,
                        parents,
                        context
                    );
                    parents.pop();
                    if (iterate && (size || definition.filtersKeepChildless))
                        callback(entry, i, context, parents);
                    total += size;
                }
                return total;
            } else if (includeHidden || !definition.hidden) {
                for (let i = 0, l = partial.length; i < l; ++i) {
                    const entry = partial[i];
                    callback(entry, i, context, parents);
                }
            }
            return partial.length;
        };
        return eachLeafPartiallyFilteredImpl(
            definition,
            nodes,
            includeHidden,
            0,
            callback,
            [],
            context
        );
    } // eachLeafPartiallyFiltered

    // ---------------------------------------------------------------------------------------------
    /**
     * Visit every leaf of the unfiltered tree under the path specified. Path is an array of element, one for each level of the tree. Null in the
     * path is the same as end of path (this is to simplify the path creation for callers who might have nullable arguments as is often our case).
     * If the path is empty (null, 0-length, or contains only null), this is simply equivalent to eachLeaf() call.
     *
     * The code doesn't do any checks on the correctness of the path (are the elements in the tree, isn't the path deeper than the tree?). It also
     * lets you walk into the hidden part of the tree (if includeHidden=false).
     *
     * Note that based on the parameters, a "leaf" is considered either the node at real bottom level, or the
     * last visible node!
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the unfiltered top level. This is necessary only if the path can be empty.
     * @param path is the path to the branch of tree that will be walked.
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, the last visible nodes will be
     * visited instead of the real leafs.
     * @param callback is the function triggered for every leaf
     * @param context is optional context, passed to the callback
     */
    public eachLeafUnderPath(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        path: any[],
        includeHidden: boolean,
        callback: IEachLeafCallback,
        context?: any
    ): void {
        if (path == null || path.length === 0 || path[0] == null) {
            this.eachLeaf(definition, nodes, includeHidden, callback, context);
            return;
        }

        let pathStep = 1;
        let target = path[0];
        const parents: any[] = [];
        while (pathStep < path.length && path[pathStep] != null) {
            parents.push(target);
            target = path[pathStep];
            definition = definition.children;
            ++pathStep;
        }
        this._eachLeafImpl(definition, [target], includeHidden, callback, parents, context);
    } // eachLeafUnderPath

    // ---------------------------------------------------------------------------------------------
    /**
     * Visit every leaf of the already filtered tree under the path specified. Path is an array of element, one for each level of the tree. Null in the
     * path is the same as end of path (this is to simplify the path creation for callers who might have nullable arguments as is often our case).
     * If the path is empty (null, 0-length, or contains only null), this is simply equivalent to eachLeaf() call.
     *
     * The code doesn't do any checks on the correctness of the path (are the elements in the filtered tree, isn't the path deeper than the tree?). It also
     * lets you walk into the hidden part of the tree (if includeHidden=false).
     *
     * Note that based on the parameters, a "leaf" is considered either the node at real bottom level, or the
     * last visible node!
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the filtered top level. This is necessary only if the path can be empty.
     * @param path is the path to the branch of tree that will be walked.
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, the last visible nodes will be
     * visited instead of the real leafs.
     * @param callback is the function triggered for every leaf
     * @param context is optional context, passed to the callback
     */
    public eachLeafUnderPathFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        path: any[],
        includeHidden: boolean,
        callback: IEachLeafCallback,
        context?: any
    ): void {
        if (path == null || path.length === 0 || path[0] == null) {
            this.eachLeafFiltered(definition, filteredNodes, includeHidden, callback, context);
            return;
        }
        let pathStep = 1;
        let target = path[0];
        const parents = [];
        while (pathStep < path.length && path[pathStep] != null) {
            parents.push(target);
            target = path[pathStep];
            definition = definition.children;
            ++pathStep;
        }
        this._eachLeafFilteredImpl(definition, [target], includeHidden, callback, parents, context);
    } // eachLeafUnderPath

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the unfiltered tree, and call the specified callback on every node, from bottom up. Hidden nodes are visited only if specified.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     * @param context is optional context, passed to the callback
     * @param parent is the parent of the top level (if any)
     */
    eachNodeBottomUp(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        includeHidden: boolean,
        callback: IEachNodeCallback,
        context?: any,
        parent?: any
    ): void {
        if (definition.children && (includeHidden || !definition.children.hidden)) {
            for (let i = 0, l = nodes.length; i < l; ++i) {
                this.eachNodeBottomUp(
                    definition.children,
                    nodes[i][definition.children.store],
                    includeHidden,
                    callback,
                    context,
                    nodes[i]
                );
            }
        }
        for (let i = 0, l = nodes.length; i < l; ++i) {
            callback(nodes[i], i, definition, context, parent);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the unfiltered tree, and call the specified callback on every node, from top to bottom. Hidden nodes are visited only if specified. If the
     * callback returns false, its children won't be visited.
     *
     * @param definition is the normalized tree definition
     * @param parentNodes is the parent node whose children will be visited
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     */
    public eachNodeTopDown(
        definition: INormalizedLogexPivotDefinition,
        parentNode: any,
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel
    ): void;

    /**
     * Walk the unfiltered tree, and call the specified callback on every node, from top to bottom. Hidden nodes are visited only if specified. If the
     * callback returns false (exact type), its children won't be visited.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree, or node whose children we want to walk
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeTopDown(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel,
        parent?: any
    ): void;

    public eachNodeTopDown(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[] | any,
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel,
        parent?: any
    ): void {
        function eachNodeTopDownImpl(
            definition: INormalizedLogexPivotDefinition,
            data: any[],
            includeHidden: boolean,
            callback: IEachNodeCallbackWithCancel,
            parent?: any
        ): void {
            for (let i = 0, l = data.length; i < l; ++i) {
                const e = data[i];
                const result = callback(e, i, parent, definition);
                if (
                    result !== false &&
                    definition.children &&
                    (includeHidden || !definition.children.hidden)
                ) {
                    eachNodeTopDownImpl(
                        definition.children,
                        e[definition.children.store],
                        includeHidden,
                        callback,
                        e
                    );
                }
            }
        }

        if (!_.isArray(nodes)) {
            // assume we want to run through the children
            if (definition.children && (includeHidden || !definition.children.hidden)) {
                eachNodeTopDownImpl(
                    definition.children,
                    nodes[definition.children.store],
                    includeHidden,
                    callback,
                    nodes
                );
            }
        } else {
            eachNodeTopDownImpl(definition, nodes, includeHidden, callback, parent);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the unfiltered tree under the path specified, and call the specified callback on every node, from top to bottom. Path is an array
     * of element, one for each level of the tree. Null in the path is the same as end of path (this is to simplify the path creation for callers
     * who might have nullable arguments as is often our case).
     * If the path is empty (null, 0-length, or contains only null), this is simply equivalent to eachNodeTopDown() call.
     *
     * The code doesn't do any checks on the correctness of the path (are the elements in the tree, isn't the path deeper than the tree?). It also
     * lets you walk into the hidden part of the tree (if includeHidden=false).
     *
     * Hidden nodes are visited only if specified. If the callback returns false, its children won't be visited.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param path is the path to the branch of tree that will be walked.
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeUnderPathTopDown(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        path: any[],
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel,
        parent?: any
    ): void {
        if (path == null || path.length === 0 || path[0] == null) {
            this.eachNodeTopDown(definition, nodes, includeHidden, callback, parent);
            return;
        }
        let pathStep = 1;
        let target = path[0];
        while (pathStep < path.length && path[pathStep] != null) {
            target = path[pathStep];
            definition = definition.children;
            ++pathStep;
        }
        this.eachNodeTopDown(definition, target, includeHidden, callback, parent);
    } // eachNodeUnderPathTopDown

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the unfiltered tree, and call the specified callback on every node (or leaf) at the specified level. Hidden levels are walked too, however
     * the optional callback can be used to limit the depth.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param atLevel specifies the level we're interested in (starting at 0)
     * @param callback is the function triggered for every node
     * @param context is optional context, passed to the callback
     * @param canDescend is optional callback, which can decide whether the specified node should be descended. The callback will be called on every node
     * on the path.
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeAtLevel(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        atLevel: number,
        callback: IEachNodeCallback,
        context?: any,
        canDescend?: ICanDescendCallback,
        parent?: any
    ): void {
        function eachNodeAtLevelImpl(
            definition: INormalizedLogexPivotDefinition,
            data: any[],
            level: number,
            parent: any
        ): void {
            if (level === atLevel) {
                for (let i = 0, l = data.length; i < l; ++i) {
                    callback(data[i], i, definition, context, parent);
                }
                return;
            }
            if (definition.children) {
                for (let i = 0, l = data.length; i < l; ++i) {
                    if (canDescend && !canDescend(data[i], level, definition)) continue;
                    eachNodeAtLevelImpl(
                        definition.children,
                        data[i][definition.children.store],
                        level + 1,
                        data[i]
                    );
                }
            }
        }
        eachNodeAtLevelImpl(definition, nodes, 0, parent);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the already filtered tree, and call the specified callback on every node (or leaf) at the specified level. Hidden levels are walked too, however
     * the optional callback can be used to limit the depth.
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the top level of the filtered tree
     * @param atLevel specifies the level we're interested in (starting at 0)
     * @param callback is the function triggered for every node
     * @param context is optional context, passed to the callback
     * @param canDescend is optional callback, which can decide whether the specified node should be descended. The callback will be called on every node
     * on the path.
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeAtLevelFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        atLevel: number,
        callback: IEachNodeCallback,
        context?: any,
        canDescend?: ICanDescendCallback,
        parent?: any
    ): void {
        function eachNodeAtLevelFilteredImpl(
            definition: INormalizedLogexPivotDefinition,
            filteredNodes: string | any[],
            level: number,
            parent: any
        ): void {
            let i, l;
            if (level === atLevel) {
                for (i = 0, l = filteredNodes.length; i < l; ++i) {
                    callback(filteredNodes[i], i, definition, context, parent);
                }
                return;
            }
            if (definition.children) {
                for (i = 0, l = filteredNodes.length; i < l; ++i) {
                    if (canDescend && !canDescend(filteredNodes[i], level, definition)) continue;
                    eachNodeAtLevelFilteredImpl(
                        definition.children,
                        filteredNodes[i][definition.children.filteredStore],
                        level + 1,
                        filteredNodes[i]
                    );
                }
            }
        }
        eachNodeAtLevelFilteredImpl(definition, filteredNodes, 0, parent);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the tree, partially filtering it, and call the specified callback on every node (or leaf) at the specified level. Hidden levels are walked too.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the (unfiltered) tree
     * @param atLevel specifies the level we're interested in (starting at 0)
     * @param ignoredFilter specifies the name of the filter that should be ignored.
     * @param callback is the function triggered for every node
     * @param context is optional context, passed to the callback
     * @param parent is the parent of the top level (if any)
     *
     * @todo implement canDescend callback?
     */
    public eachNodeAtLevelPartiallyFiltered(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        atLevel: number,
        ignoredFilter: string | string[],
        callback: IEachNodeCallback,
        context?: any,
        parent?: any
    ): void {
        const filters: types.ICleanedColumnFilter[][] = [];
        let current = definition;
        while (current) {
            filters.push(
                current.filters
                    ? filterByColumnPrepare(current.filters(context), ignoredFilter)
                    : null
            );
            current = current.children;
        }

        const eachNodeAtLevelPartiallyFilteredImpl = (
            definition: INormalizedLogexPivotDefinition,
            data: any[],
            level: number,
            parent: any
        ): number => {
            const partial = filters[level] ? filterByColumnPrepared(data, filters[level]) : data;
            if (!partial) return 0;
            let total = 0;
            if (definition.children) {
                const store = definition.children.store;
                for (let i = 0, l = partial.length; i < l; ++i) {
                    const entry = partial[i];
                    const size = eachNodeAtLevelPartiallyFilteredImpl(
                        definition.children,
                        entry[store],
                        level + 1,
                        entry
                    );
                    if ((size || definition.filtersKeepChildless) && level === atLevel) {
                        callback(entry, i, definition, context, parent);
                    }
                    total += size;
                }
                return total;
            } else {
                if (level === atLevel) {
                    for (let i = 0, l = partial.length; i < l; ++i) {
                        const entry = partial[i];
                        callback(entry, i, definition, context, parent);
                    }
                }
                return partial.length;
            }
        };
        eachNodeAtLevelPartiallyFilteredImpl(definition, nodes, 0, parent);
    } // eachNodeAtLevelPartiallyFiltered

    /*
    eachNodeBottomUp2: function (definition, data, includeHidden, callback, context) {
    var i, l;
    var stack = [];
    stack.push({ data: data, definition: definition, processed: false });
    while (stack.length) {
    var element = stack.pop();
    data = element.data;
    definition = element.definition;
    if (definition.children && (includeHidden || !definition.children.hidden) && !element.processed) {
    stack.push({ data: data, definition: definition, processed: true });
    for (i = 0, l = data.length; i < l; ++i) {
    stack.push({ data: data[i][definition.children.store], definition: definition.children, processed: false });
    }
    continue;
    }
    for (i = 0, l = data.length; i < l; ++i) {
    callback(data[i], i, definition, context);
    }
    }
    },*/

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the prefiltered tree, and call the specified callback on every node, from top to bottom. Hidden nodes are visited only if specified. If the
     * callback returns false (the exact type), its children won't be visited.
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the top level of the unfiltered tree
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeTopDownFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel,
        parent?: any
    ): void;

    /**
     * Walk the prefiltered tree, starting at children of specified node, and call the specified callback on every node, from top to bottom.
     * Hidden nodes are visited only if specified. If the callback returns false (the exact type), its children won't be visited.
     *
     * @param definition is the normalized tree definition
     * @param filteredNode is the node whose children will be visited
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     */
    public eachNodeTopDownFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNode: any,
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel
    ): void;

    public eachNodeTopDownFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[] | any,
        includeHidden: boolean,
        callback: IEachNodeCallbackWithCancel,
        parent?: any
    ): void {
        function eachNodeTopDownFilteredImpl(
            definition: INormalizedLogexPivotDefinition,
            filteredNodes: any[],
            includeHidden: boolean,
            callback: IEachNodeCallbackWithCancel,
            parent: any
        ): void {
            for (let i = 0, l = filteredNodes.length; i < l; ++i) {
                const e = filteredNodes[i];
                const result = callback(e, i, parent, definition);
                if (
                    result !== false &&
                    definition.children &&
                    (includeHidden || !definition.children.hidden)
                ) {
                    eachNodeTopDownFilteredImpl(
                        definition.children,
                        e[definition.children.filteredStore],
                        includeHidden,
                        callback,
                        e
                    );
                }
            }
        }

        if (!_.isArray(filteredNodes)) {
            // assume we want to run through the children
            if (definition.children && (includeHidden || !definition.children.hidden)) {
                eachNodeTopDownFilteredImpl(
                    definition.children,
                    filteredNodes[definition.children.filteredStore],
                    includeHidden,
                    callback,
                    filteredNodes
                );
            }
        } else {
            eachNodeTopDownFilteredImpl(definition, filteredNodes, includeHidden, callback, parent);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the prefiltered tree, and call the specified callback on every node, from bottom up. Hidden nodes are visited only if specified.
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the top level of the filtered tree
     * @param includeHidden specifies, whether we should ignore the hidden flag in the pivot's definition. If false, hidden nodes are not visited.
     * @param callback is the function triggered for every node
     * @param context is optional context, passed to the callback
     * @param parent is the parent of the top level (if any)
     */
    public eachNodeBottomUpFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        includeHidden: boolean,
        callback: IEachNodeCallback,
        context?: any,
        parent?: any
    ): void {
        if (definition.children && (includeHidden || !definition.children.hidden)) {
            for (let i = 0, l = filteredNodes.length; i < l; ++i) {
                this.eachNodeBottomUpFiltered(
                    definition.children,
                    filteredNodes[i][definition.children.filteredStore],
                    includeHidden,
                    callback,
                    context,
                    filteredNodes[i]
                );
            }
        }
        for (let i = 0, l = filteredNodes.length; i < l; ++i) {
            callback(filteredNodes[i], i, definition, context, parent);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the direct unfiltered children of the specified node. Throws error if the node has no children. Ignores hidden flag
     *
     * @param definition is the normalized tree definition (corresponding to the level the node is at)
     * @param node is the node whose children will be visited
     * @param callback is the function triggered for every node
     * @param context is the optional context, passed to the callback
     */
    public eachChild(
        definition: INormalizedLogexPivotDefinition,
        node: any,
        callback: IEachNodeCallback,
        context?: any
    ): void {
        if (!definition.children) {
            throw new Error("Node has no children");
        }
        const children = node[definition.children.store];
        const l = children.length;
        for (let i = 0; i < l; ++i) {
            callback(children[i], i, definition.children, context, node);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Walk the direct unfiltered children of the specified node. Throws error if the node has no children. Ignores hidden flag
     *
     * @param definition is the normalized tree definition (corresponding to the level the node is at)
     * @param node is the node whose children will be visited
     * @param callback is the function triggered for every node
     * @param context is the optional context, passed to the callback
     */
    public eachChildFiltered(
        definition: INormalizedLogexPivotDefinition,
        node: any,
        callback: IEachNodeCallback,
        context?: any
    ): void {
        if (!definition.children) {
            throw new Error("Node has no children");
        }
        const children = node[definition.children.filteredStore];
        const l = children.length;
        for (let i = 0; i < l; ++i) {
            callback(children[i], i, definition.children, context, node);
        }
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather the available options for a filter from leafs of partially filtered tree.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param optionId is either a name of the property contaning the IDs to be used for the filter, or callback that extracts said ID
     * @param optionName is either a name of the property containing the name for the related ID, or callback that provides the name
     * @param ignoredFilter is the name of the filter which will be ignored during the filtering. This will be typically the filter for which we're gathering the
     * available options
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return array containing all the currently available options, sorted by their name. The option IDs in the array will be unique, and null IDs will be eliminated.
     * The order field won't be filled.
     */
    public gatherFilterOptions<T extends number | string>(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        optionId: string | IGatherFilterIdCallback<T>,
        optionName: string | IGatherFilterNameCallback<T>,
        ignoredFilter: string,
        context?: any
    ): types.IFilterOption[] {
        const optionIsString = typeof optionId === "string";
        const optionNameIsString = typeof optionName === "string";
        const touched: types.ILookup<boolean> = {};
        const result: types.IFilterOption[] = [];
        this.eachLeafPartiallyFiltered(
            definition,
            nodes,
            true,
            ignoredFilter,
            function (e) {
                let id: number | string;
                if (optionIsString) {
                    id = e[<string>optionId];
                } else {
                    id = (<IGatherFilterIdCallback<T>>optionId)(e);
                }
                if (id == null) return;
                if (touched[id]) return;
                touched[id] = true;
                let name: string;
                if (optionNameIsString) {
                    name = e[<string>optionName];
                } else {
                    name = (<IGatherFilterNameCallback<T>>optionName)(e, <T>id);
                }
                result.push({ id, name });
            },
            context
        );
        return _.sortBy(result, "name");
    } // gatherFilterOptions

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather the available options for a filter from leafs of partially filtered tree. The options will be sorted according to the provided parameter.
     * Note that this traverses the hidden nodes as well.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param optionId is either a name of the property contaning the IDs to be used for the filter, or callback that extracts said ID
     * @param optionName is either a name of the property containing the name for the related ID, or callback that provides the name
     * @param optionOrder is either a name of the property which will be used for sorting, or callback that returns such value. Note that the values are always
     * sorted in ascending order. If ommited, the option name will be used
     * @param ignoredFilter is the name of the filter which will be ignored during the filtering. This will be typically the filter for which we're gathering the
     * available options
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return array containing all the currently available options, sorted by their name. The option IDs in the array will be unique, and null IDs will be eliminated.
     */
    gatherFilterOptionsSorted<T extends number | string>(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        optionId: string | IGatherFilterIdCallback<T>,
        optionName: string | IGatherFilterNameCallback<T>,
        optionOrder: string | IGatherFilterOrderCallback<T>,
        ignoredFilter: string,
        context?: any
    ): types.IFilterOption[] {
        const optionIsString = typeof optionId === "string";
        const optionNameIsString = typeof optionName === "string";
        const optionOrderIsString = optionOrder && typeof optionOrder === "string";
        const optionOrderIsName = optionOrder == null || optionOrder === optionName;
        const optionOrderIsId = optionOrder != null && optionOrder === optionId;
        const touched: types.ILookup<boolean> = {};
        const result: types.IFilterOption[] = [];

        this.eachLeafPartiallyFiltered(
            definition,
            nodes,
            true,
            ignoredFilter,
            function (e) {
                let id: number | string;
                if (optionIsString) {
                    id = e[<string>optionId];
                } else {
                    id = (<IGatherFilterIdCallback<T>>optionId)(e);
                }
                if (id == null) return;
                if (touched[id]) return;
                touched[id] = true;
                let name: string;
                if (optionNameIsString) {
                    name = e[<string>optionName];
                } else {
                    name = (<IGatherFilterNameCallback<T>>optionName)(e, <T>id);
                }
                let order: number | string;
                if (optionOrderIsId) {
                    order = id;
                } else if (optionOrderIsName) {
                    order = name;
                } else if (optionOrderIsString) {
                    order = e[<string>optionOrder];
                } else {
                    order = (<IGatherFilterOrderCallback<T>>optionOrder)(e, <T>id, name);
                }
                result.push({ id, name, order });
            },
            context
        );
        return _.sortBy(result, "order");
    } // gatherFilterOptions

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather the available options for a filter from specified level of partially filtered tree. The options will be sorted according to the provided parameter.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param optionId is either a name of the property contaning the IDs to be used for the filter, or callback that extracts said ID
     * @param optionName is either a name of the property containing the name for the related ID, or callback that provides the name
     * @param optionOrder is either a name of the property which will be used for sorting, or callback that returns such value. Note that the values are always
     * sorted in ascending order. If ommited, the option name will be used
     * @param ignoredFilter is the name of the filter which will be ignored during the filtering. This will be typically the filter for which we're gathering the
     * available options
     * @param level specifies the level from which the options should be gathered
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return array containing all the currently available options, sorted by their name. The option IDs in the array will be unique, and null IDs will be eliminated.
     */
    public gatherFilterOptionsFromLevelSorted<T extends number | string>(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        optionId: string | IGatherFilterIdCallback<T>,
        optionName: string | IGatherFilterNameCallback<T>,
        optionOrder: string | IGatherFilterOrderCallback<T>,
        ignoredFilter: string,
        level: number,
        context?: any
    ): types.IFilterOption[] {
        const optionIsString = typeof optionId === "string";
        const optionNameIsString = typeof optionName === "string";
        const optionOrderIsString = optionOrder && typeof optionOrder === "string";
        const optionOrderIsName = optionOrder == null || optionOrder === optionName;
        const optionOrderIsId = optionOrder != null && optionOrder === optionId;
        const touched: types.ILookup<boolean> = {};
        const result: types.IFilterOption[] = [];

        this.eachNodeAtLevelPartiallyFiltered(
            definition,
            nodes,
            level,
            ignoredFilter,
            function (e) {
                let id: number | string;
                if (optionIsString) {
                    id = e[<string>optionId];
                } else {
                    id = (<IGatherFilterIdCallback<T>>optionId)(e);
                }
                if (id == null) return;
                if (touched[id]) return;
                touched[id] = true;
                let name: string;
                if (optionNameIsString) {
                    name = e[<string>optionName];
                } else {
                    name = (<IGatherFilterNameCallback<T>>optionName)(e, <T>id);
                }
                let order: number | string;
                if (optionOrderIsId) {
                    order = id;
                } else if (optionOrderIsName) {
                    order = name;
                } else if (optionOrderIsString) {
                    order = e[<string>optionOrder];
                } else {
                    order = (<IGatherFilterOrderCallback<T>>optionOrder)(e, <T>id, name);
                }
                result.push({ id, name, order });
            },
            context
        );
        return _.sortBy(result, "order");
    } // gatherFilterOptionsFromLevelSorted

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather the available option IDs for a filter from specified level of partially filtered tree.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the top level of the unfiltered tree
     * @param optionId is either a name of the property contaning the IDs to be used for the filter, or callback that extracts said ID
     * @param ignoredFilter is the name of the filter which will be ignored during the filtering. This will be typically the filter for which we're gathering the
     * available options
     * @param level specifies the level from which the options should be gathered
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return array containing all the currently available options ids. The IDs will be unique, and null IDs will be eliminated.
     */
    public gatherFilterOptionIdsFromLevel<T extends number | string>(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        optionId: string | IGatherFilterIdCallback<T>,
        ignoredFilter: string | string[],
        level: number,
        context?: any
    ): T[] {
        const optionIsString = typeof optionId === "string";
        const touched: types.ILookup<boolean> = {};
        const result: T[] = [];

        this.eachNodeAtLevelPartiallyFiltered(
            definition,
            nodes,
            level,
            ignoredFilter,
            function (e) {
                let id: any;
                if (optionIsString) {
                    id = e[<string>optionId];
                } else {
                    id = (<IGatherFilterIdCallback<T>>optionId)(e);
                }
                if (id == null) return;
                if (touched[id]) return;
                touched[id] = true;
                result.push(id);
            },
            context
        );
        return result;
    } // gatherFilterOptionIdsFromLevel

    // ---------------------------------------------------------------------------------------------
    /**
     * Based on the configuration provided, create and return a function which will gather filter options. The function will internally handle obtaining
     * the pivot definition and data. Based on the parameters specified, it will be a wrapper around gatherFilterOptionsFromLevelSorted,
     * gatherFilterOptions or gatherFilterOptionsSorted.
     *
     * Using callbacks or scoped property names allows us to create the factory even before the target pivot tree is built - the data necessary are
     * gathered only at the time of the call of the created function; not when the factory is called.
     *
     * Example usage:
     * $scope.getKostenplaatsFilterOptions = logexPivot.gatherFilterOptionsFactory($scope, {
     * definitionProperty: "rootPivot", dataProperty: "ledger", optionId: "kostenplaats_id", optionOrder: "=", level: 1, ignoredFilter: "=",
     * nameSource: "kostenplaatsDefinition", nameProperty: "omschrijving", nameMissing: "???", nameDash: true
     * });
     * The parameters object is compulsory (and so is $scope) and may/must contain the following.
     * - definition: either value containing the pivot definition, or callback function accepting $scope which returns said definition. If null, then definitionProperty is required [required/alternative]
     * - definitionProperty: name of the $scope property that stores the pivot definition. Used only if definition is missing [required/alternative]
     * - data: either value containing the data, or callback function accepting $scope which returns the data. If null, then dataProperty is required [required/alternative]
     * - dataProperty: name of the $scope property that stores the pivoted data structure. Used only if data is missing [required/alternative]
     * - optionId: name of the ID property, or function that gets it. This mirrors the optionId behaviour of the other gather* methods [required]
     * - optionName: name of the option name property, or function that gets it. This mirrors the optionName behavoiour of the other gather* methods. [optional]
     * - optionOrder: name of the option sort-by property, or function that gets it. If null, will be equal to optionName. This mirrors the other gather* methods. In
     * addition, when "=" will be the same as optionId.  [optional]
     * - noSort: if specified, no sorting is done. Note that this will throw error if level is specified (as we currently don't have level method without sorting). [optional]
     * - ignoredFilter: name of the ignored filter, or null. If "=", will equal to optionId. [required]
     *
     * As alternative to specify the name property, use the following ("required" applies only if optionName is not specified and nameSource is)
     * - nameSource: name of the $scope property that stores the lookup table (such as kostenplaatsDefinition), or the lookup itself, or callback accepting $scope that returns it
     * - nameProperty: name of the option name property within the lookup table. Typically "omschrijving", but there is no default [required]
     * - nameMissing: name to use, if the ID doesn't exist in the lookup. Defaults to empty string [optional]
     * - nameDash: if true, the name will be formed as ID-NAME
     *
     * @param $scope represents the controller's scope. The scope will be referred when property names are specified for some of the parameters; and the
     * callbacks can refer to it as well
     * @param parameters specifies the main configuration
     * @param defaultParameters optionally provides defaults for options missing in the main configuration. This can be used in a way to provide tool-global
     * definition for different useful filters (specialisations, products etc), which is only adjusted for concrete page using the parameters
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return function which, when called, gathers all options for the filter
     */
    gatherFilterOptionsFactory<T extends number | string>(
        parameters: IGatherFilterFactoryOptions<T>,
        defaultParameters?: IGatherFilterFactoryOptions<T>,
        context?: any
    ): IGatherFilterFactoryResult {
        if (defaultParameters) parameters = _.extend({}, defaultParameters, parameters);
        if (parameters.ignoredFilter === "=") parameters.ignoredFilter = <any>parameters.optionId;
        if (parameters.optionOrder === "=") parameters.optionOrder = <any>parameters.optionId;
        let optionName = parameters.optionName;
        if (!optionName && parameters.nameSource) {
            let optionNameInner: Function;
            if (parameters.nameMissing == null) parameters.nameMissing = "";
            const nameSourceGet = _.isFunction(parameters.nameSource)
                ? "nameSourceParam()"
                : "nameSourceParam";
            if (parameters.nameDash) {
                // eslint-disable-next-line no-new-func
                optionNameInner = new Function(
                    "e",
                    "id",
                    "nameSourceParam",
                    "var definition = " +
                        nameSourceGet +
                        "[id];\n" +
                        'return id + " - " + (definition ? definition.' +
                        parameters.nameProperty +
                        ' : "' +
                        (parameters.nameMissing || "") +
                        '" );'
                );
            } else {
                // eslint-disable-next-line no-new-func
                optionNameInner = new Function(
                    "e",
                    "id",
                    "nameSourceParam",
                    "var definition = " +
                        nameSourceGet +
                        "[id];\n" +
                        "return definition ? definition." +
                        parameters.nameProperty +
                        ' : "' +
                        parameters.nameMissing +
                        '";'
                );
            }
            optionName = function (e, id) {
                return optionNameInner(e, id, parameters.nameSource);
            };
        }
        let getDefinitionFn: () => INormalizedLogexPivotDefinition;
        let getDataFn: () => any[];

        if (typeof parameters.definition === "function") {
            getDefinitionFn = <() => INormalizedLogexPivotDefinition>parameters.definition;
        } else {
            getDefinitionFn = () => <INormalizedLogexPivotDefinition>parameters.definition;
        }

        if (typeof parameters.data === "function") {
            getDataFn = <() => any[]>parameters.data;
        } else {
            getDataFn = () => <any[]>parameters.data;
        }

        if (parameters.level != null) {
            if (parameters.noSort) throw new Error("Level-based options are always sorted");
            return () => {
                return this.gatherFilterOptionsFromLevelSorted<T>(
                    getDefinitionFn(),
                    getDataFn(),
                    parameters.optionId,
                    optionName,
                    parameters.optionOrder,
                    parameters.ignoredFilter,
                    parameters.level,
                    context
                );
            };
        } else if (parameters.noSort) {
            return () => {
                return this.gatherFilterOptions<T>(
                    getDefinitionFn(),
                    getDataFn(),
                    parameters.optionId,
                    optionName,
                    parameters.ignoredFilter,
                    context
                );
            };
        } else {
            return () => {
                return this.gatherFilterOptionsSorted<T>(
                    getDefinitionFn(),
                    getDataFn(),
                    parameters.optionId,
                    optionName,
                    parameters.optionOrder,
                    parameters.ignoredFilter,
                    context
                );
            };
        }
    } // gatherFilterOptionsFactory

    // ---------------------------------------------------------------------------------------------
    /**
     * Based on the configuration provided, create and return a function which will gather filter option IDs. The function will internally handle obtaining
     * the pivot definition and data.
     *
     * Using callbacks or scoped property names allows us to create the factory even before the target pivot tree is built - the data necessary are
     * gathered only at the time of the call of the created function; not when the factory is called.
     *
     * Example usage:
     * $scope.getKostenplaatsFilterOptions = logexPivot.gatherFilterOptionsFactory($scope, {
     * definitionProperty: "rootPivot", dataProperty: "ledger", optionId: "kostenplaats_id", optionOrder: "=", level: 1, ignoredFilter: "=",
     * nameSource: "kostenplaatsDefinition", nameProperty: "omschrijving", nameMissing: "???", nameDash: true
     * });
     * The parameters object is compulsory (and so is $scope) and may/must contain the following.
     * - definition: either value containing the pivot definition, or callback function accepting $scope which returns said definition. If null, then definitionProperty is required [required/alternative]
     * - definitionProperty: name of the $scope property that stores the pivot definition. Used only if definition is missing [required/alternative]
     * - data: either value containing the data, or callback function accepting $scope which returns the data. If null, then dataProperty is required [required/alternative]
     * - dataProperty: name of the $scope property that stores the pivoted data structure. Used only if data is missing [required/alternative]
     * - optionId: name of the ID property, or function that gets it. This mirrors the optionId behaviour of the other gather* methods [required]
     * - ignoredFilter: name of the ignored filter, or null. If "=", will equal to optionId. [required]
     *
     * The counterpart of this method is UtilityService.TranslateFilterOptionFactory
     *
     * @param $scope represents the controller's scope. The scope will be referred when property names are specified for some of the parameters; and the
     * callbacks can refer to it as well
     * @param parameters specifies the main configuration
     * @param defaultParameters optionally provides defaults for options missing in the main configuration. This can be used in a way to provide tool-global
     * definition for different useful filters (specialisations, products etc), which is only adjusted for concrete page using the parameters
     * @param context is optional context, passed to the callbacks (including filters() )
     * @return function which, when called, gathers all option IDs for the filter
     */
    gatherFilterIdsFactory<T extends number | string>(
        parameters: IGatherFilterIdsFactoryOptions<T>,
        defaultParameters?: IGatherFilterIdsFactoryOptions<T>,
        context?: any
    ): IGatherFilterIdsFactoryResult<T> {
        if (defaultParameters) parameters = _.extend({}, defaultParameters, parameters);
        if (parameters.ignoredFilter === "=") parameters.ignoredFilter = <any>parameters.optionId;
        let getDefinitionFn: () => INormalizedLogexPivotDefinition;
        let getDataFn: () => any[];

        if (typeof parameters.definition === "function") {
            getDefinitionFn = <() => INormalizedLogexPivotDefinition>parameters.definition;
        } else {
            getDefinitionFn = () => <INormalizedLogexPivotDefinition>parameters.definition;
        }

        if (typeof parameters.data === "function") {
            getDataFn = <() => any[]>parameters.data;
        } else {
            getDataFn = () => <any[]>parameters.data;
        }

        const optionIsString = typeof parameters.optionId === "string";

        if (parameters.level != null) {
            return () => {
                const touched: any = {};
                const result: T[] = [];

                this.eachNodeAtLevelPartiallyFiltered(
                    getDefinitionFn(),
                    getDataFn(),
                    parameters.level,
                    parameters.ignoredFilter,
                    function (e) {
                        let id: T;
                        if (optionIsString) {
                            id = e[<string>parameters.optionId];
                        } else {
                            id = (<IGatherFilterIdCallback<T>>parameters.optionId)(e);
                        }
                        if (id == null) return;
                        if (touched[id]) return;
                        touched[id] = true;
                        result.push(id);
                    },
                    context
                );

                return result;
            };
        } else {
            return () => {
                const touched: any = {};
                const result: T[] = [];

                this.eachLeafPartiallyFiltered(
                    getDefinitionFn(),
                    getDataFn(),
                    true,
                    parameters.ignoredFilter,
                    function (e) {
                        let id: T;
                        if (optionIsString) {
                            id = e[<string>parameters.optionId];
                        } else {
                            id = (<IGatherFilterIdCallback<T>>parameters.optionId)(e);
                        }
                        if (id == null) return;
                        if (touched[id]) return;
                        touched[id] = true;
                        result.push(id);
                    },
                    context
                );

                return result;
            };
        }
    } // gatherFilterIdsFactory

    // ---------------------------------------------------------------------------------------------
    /**
     * Sorts unfiltered tree according to the specification, and return the sorted nodes (the tree itself is sorted internally!) The specification is
     * global (applied for every level), though the defaultOrderBy and alwaysOrderBy per-level definition is taken into account.
     *
     * Note that hidden levels are never sorted.
     * (Please note that this method currently does NOT support scoped references, not even in the defaultOrderBy/alwaysOrderBy specification)
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the unfiltered top level
     * @param orderBy is the specification of the sorting criteria
     * @param scope is the scope (necessary only if the sorters refer to it)
     * @return the sorted top level
     */
    public orderBy(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        orderBy: ISimpleOrderBySpecification
    ): any[] {
        const sorters = this._prepareSimpleOrderBy(definition, orderBy);

        const orderByImpl = (
            definition: INormalizedLogexPivotDefinition,
            nodes: any[],
            level: number
        ): any[] => {
            const result = sorters[level](nodes);
            if (definition.children && !definition.children.hidden) {
                for (let i = 0, l = result.length; i < l; ++i) {
                    result[i][definition.children.store] = orderByImpl(
                        definition.children,
                        result[i][definition.children.store],
                        level + 1
                    );
                }
            }
            return result;
        };

        return orderByImpl(definition, nodes, 0);
    } // orderBy

    private _prepareSimpleOrderBy(
        definition: INormalizedLogexPivotDefinition,
        orderBy: ISimpleOrderBySpecification
    ): Array<LgPreparedSortBy<any>> {
        let orderBySpec: string[];
        if (_.isArray(orderBy)) {
            orderBySpec = orderBy.slice(0);
        } else {
            orderBySpec = orderBy ? [<ISimpleOrderByPredicate>orderBy] : [];
        }

        let current = definition;
        const prepared: Array<LgPreparedSortBy<any>> = [];

        while (current && !current.hidden) {
            let defaultCount = 0;
            if (current.defaultOrderBy) {
                if (_.isArray(current.defaultOrderBy)) {
                    orderBySpec = orderBySpec.concat(current.defaultOrderBy);
                    defaultCount = current.defaultOrderBy.length;
                } else {
                    orderBySpec.push(current.defaultOrderBy);
                    defaultCount = 1;
                }
            }
            if (current.alwaysOrderBy) orderBySpec.unshift(current.alwaysOrderBy);
            prepared.push(lgSortByPrepare(orderBySpec, current.sorters));
            if (current.alwaysOrderBy) orderBySpec.shift();
            if (defaultCount) {
                orderBySpec.splice(orderBySpec.length - defaultCount, defaultCount);
            }
            current = current.children;
        }
        return prepared;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Sorts filtered tree according to the specification, and return the sorted nodes (the tree itself is sorted internally!) The specification is
     * global (applied for every level), though the defaultOrderBy and alwaysOrderBy per-level definition is taken into account.
     *
     * Note that hidden levels are never sorted.
     * (Please note that this method currently does NOT support scoped references, not even in the defaultOrderBy/alwaysOrderBy specification)
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the filtered top level
     * @param orderBy is the specification of the sorting criteria
     * @param scope is the scope (necessary only if the sorters refer to it)
     * @return the sorted top level
     */
    public orderByFiltered(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        orderBy: ISimpleOrderBySpecification
    ): any[] {
        const sorters = this._prepareSimpleOrderBy(definition, orderBy);

        const orderByImpl = (
            definition: INormalizedLogexPivotDefinition,
            filteredNodes: any[],
            level: number
        ): any[] => {
            const result = sorters[level](filteredNodes);
            if (definition.children && !definition.children.hidden) {
                for (let i = 0, l = result.length; i < l; ++i) {
                    result[i][definition.children.filteredStore] = orderByImpl(
                        definition.children,
                        result[i][definition.children.filteredStore],
                        level + 1
                    );
                }
            }
            return result;
        };
        return orderByImpl(definition, filteredNodes, 0);
    } // orderByFiltered

    // ---------------------------------------------------------------------------------------------
    /**
     * Sort already filtered tree, using the specification for every level and return the sorted nodes. Note that hidden levels are never sorted.
     * The tree is sorted internally too (its state is changed)
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the filtered top level
     * @param orderByPerLevel is array of sorting predicates, or eventually single sorting predicate (which will be then used for the top level).
     * The defaultOrderBy specification is used as well, so it is possible to pass in empty array
     * @param maxDepth is the maximum depth that should be sorted. There 1 means sort only the top level
     * @return array of the sorted top level nodes
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public orderByFilteredPerLevel_Legacy(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        orderByPerLevel: IOrderByPerLevelSpecification | IOrderBySpecification,
        maxDepth?: number
    ): any[] {
        let orderByPerLevelArray: IOrderByPerLevelSpecification;
        if (!_.isArray(orderByPerLevel)) {
            orderByPerLevelArray = [<IOrderBySpecification>orderByPerLevel];
        } else {
            orderByPerLevelArray = <IOrderByPerLevelSpecification>orderByPerLevel;
        }
        const orderBySpec: Array<types.IScopedSortPredicate | types.IScopedSortPredicate[]> = [];
        let i = 0,
            current = definition;
        while (current) {
            const perLevel = orderByPerLevelArray[i];
            let orderBySub: types.IScopedSortPredicate[];
            if (!perLevel) {
                orderBySub = [];
            } else if (_.isArray(perLevel)) {
                orderBySub = (<types.IScopedSortPredicate[]>perLevel).slice(0);
            } else {
                orderBySub = [<types.IScopedSortPredicate>perLevel];
            }
            if (current.defaultOrderBy) {
                let defaultOrderBy: types.IScopedSortPredicate[];
                if (_.isArray(current.defaultOrderBy)) {
                    defaultOrderBy = <types.IScopedSortPredicate[]>current.defaultOrderBy;
                } else {
                    defaultOrderBy = [<types.IScopedSortPredicate>current.defaultOrderBy];
                }
                for (const defaultPart of defaultOrderBy) {
                    // this tries to prevent the default to be included twice, and works pretty much just for 1-column sorting criteria.
                    // I am not sure if extending it is worth it
                    if (orderBySub.length && orderBySub[0] === defaultPart) continue;
                    if (
                        orderBySub.length &&
                        typeof defaultPart === "string" &&
                        orderBySub[0] === "-" + defaultPart
                    )
                        continue;
                    orderBySub.push(defaultPart);
                }
            }
            if (current.alwaysOrderBy) orderBySub.unshift(current.alwaysOrderBy);
            orderBySpec[i] = orderBySub;
            ++i;
            current = current.children;
            if (maxDepth && i >= maxDepth) break;
        }
        // console.log(orderBySpec);

        const orderByFilteredPerLevelImpl = (
            definition: INormalizedLogexPivotDefinition,
            filteredNodes: any[],
            level: number
        ): any[] => {
            const result = scopedOrderByFilter(filteredNodes, orderBySpec[level]);
            if (
                definition.children &&
                !definition.children.hidden &&
                (!maxDepth || level < maxDepth - 1)
            ) {
                for (let i = 0, l = result.length; i < l; ++i) {
                    result[i][definition.children.filteredStore] = orderByFilteredPerLevelImpl(
                        definition.children,
                        result[i][definition.children.filteredStore],
                        level + 1
                    );
                }
            }
            return result;
        };
        return orderByFilteredPerLevelImpl(definition, filteredNodes, 0);
    } // orderByFiltered

    // ---------------------------------------------------------------------------------------------
    /**
     * Sort already filtered tree, using the specification for every level and return the sorted nodes. Note that hidden levels are never sorted.
     * The tree is sorted internally too (its state is changed)
     *
     * @param definition is the normalized tree definition
     * @param nodes are the nodes of the filtered top level
     * @param sortFiltered if true, the filtered part will be sorted, otherwise - the unfiltered part
     * @param orderByPerLevel is array of sorting predicates, or eventually single sorting predicate (which will be then used for the top level).
     * The defaultOrderBy specification is used as well, so it is possible to pass in empty array
     * @param maxDepth is the maximum depth that should be sorted. There 1 means sort only the top level
     * @return array of the sorted top level nodes
     */
    public orderByPerLevel(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        sortFiltered: boolean,
        orderByPerLevel: IOrderByPerLevelSpecification | IOrderBySpecification,
        maxDepth?: number
    ): any[] {
        let orderByPerLevelArray: IOrderByPerLevelSpecification;
        if (!_.isArray(orderByPerLevel)) {
            orderByPerLevelArray = [orderByPerLevel];
        } else {
            orderByPerLevelArray = orderByPerLevel;
        }

        let i = 0,
            current = definition;
        const preparedSortBy: Array<LgPreparedSortBy<any>> = [];

        while (current) {
            const perLevel = orderByPerLevelArray[i];
            let orderBySub: string[];
            if (!perLevel) {
                orderBySub = [];
            } else if (_.isArray(perLevel)) {
                orderBySub = perLevel.slice(0);
            } else {
                orderBySub = [perLevel];
            }
            if (current.defaultOrderBy) {
                let defaultOrderBy: string[];
                if (_.isArray(current.defaultOrderBy)) {
                    defaultOrderBy = current.defaultOrderBy;
                } else {
                    defaultOrderBy = [current.defaultOrderBy];
                }
                for (const defaultPart of defaultOrderBy) {
                    // this tries to prevent the default to be included twice, and works pretty much just for 1-column sorting criteria.
                    // I am not sure if extending it is worth it
                    if (orderBySub.length && orderBySub[0] === defaultPart) continue;
                    if (
                        orderBySub.length &&
                        typeof defaultPart === "string" &&
                        orderBySub[0] === "-" + defaultPart
                    )
                        continue;
                    orderBySub.push(defaultPart);
                }
            }
            if (current.alwaysOrderBy) orderBySub.unshift(current.alwaysOrderBy);
            preparedSortBy.push(lgSortByPrepare(orderBySub, current.sorters));
            ++i;
            current = current.children;
            if (maxDepth && i >= maxDepth) break;
        }
        // console.log(orderBySpec);

        const orderByFilteredPerLevelImpl = (
            definition: INormalizedLogexPivotDefinition,
            filteredNodes: any[],
            level: number
        ): any[] => {
            const result = preparedSortBy[level](filteredNodes);
            if (
                definition.children &&
                !definition.children.hidden &&
                (!maxDepth || level < maxDepth - 1)
            ) {
                const storeName = sortFiltered
                    ? definition.children.filteredStore
                    : definition.children.store;
                for (let i = 0, l = result.length; i < l; ++i) {
                    result[i][storeName] = orderByFilteredPerLevelImpl(
                        definition.children,
                        result[i][storeName],
                        level + 1
                    );
                }
            }
            return result;
        };
        return orderByFilteredPerLevelImpl(definition, nodes, 0);
    } // orderByPerLevel

    // ---------------------------------------------------------------------------------------------
    /**
     * Sort already filtered tree, using the specification for every level and return the sorted nodes. Note that hidden levels are never sorted.
     * The tree is sorted internally too (its state is changed)
     *
     * @param definition is the normalized tree definition
     * @param filteredNodes are the nodes of the filtered top level
     * @param orderByPerLevel is array of sorting predicates, or eventually single sorting predicate (which will be then used for the top level).
     * The defaultOrderBy specification is used as well, so it is possible to pass in empty array
     * @param maxDepth is the maximum depth that should be sorted. There 1 means sort only the top level
     * @return array of the sorted top level nodes
     */
    public orderByFilteredPerLevel(
        definition: INormalizedLogexPivotDefinition,
        filteredNodes: any[],
        orderByPerLevel: IOrderByPerLevelSpecification | IOrderBySpecification,
        maxDepth?: number
    ): any[] {
        return this.orderByPerLevel(definition, filteredNodes, true, orderByPerLevel, maxDepth);
    } // orderByFilteredPerLevel

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather default order-by specifications from the tree definition.
     *
     * @param definition is the normalized tree definition
     * @return array of sort predicates, one for every level (or undefined when not specified)
     */
    gatherDefaultOrderByPerLevel(
        definition: INormalizedLogexPivotDefinition,
        initial?: IOrderByPerLevelSpecification
    ): IOrderByPerLevelSpecification {
        const result: IOrderByPerLevelSpecification = initial || [];
        let current = definition;
        let i = 0;
        while (current) {
            if (!result[i] && current.defaultOrderBy) {
                result[i] = current.defaultOrderBy;
            }
            ++i;
            current = current.children;
        }
        return result;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Gather a state (a collection of specified properties) from every node in the unfiltered tree. The result is linked using the same property
     * that links the children in the regular pivot tree (as defined by the definition.store configuration)
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param attribute is a name of the property, or array of names, which should be gathered from every node. This would be typical some state
     * that applies to all levels, such as "$expanded" (used by the pivot table)
     * @param storage is the target storage. If not specified, a new object will be created
     * @return storage with the extracted states. This will be equivalent to the parameter storage, if specified
     */
    public extractNodesState(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        attributes: string | string[],
        storage?: INodeStateStore
    ): INodeStateStore {
        if (!attributes) return null;
        if (!_.isArray(attributes)) attributes = [<string>attributes];
        const la = attributes.length;

        function extractNodeStateImpl(
            definition: INormalizedLogexPivotDefinition,
            nodes: any[],
            storage?: INodeStateStore
        ): INodeStateStore {
            storage = storage || {};
            let state;
            for (let i = 0, l = nodes.length; i < l; ++i) {
                const el = nodes[i];
                state = storage[el[definition.column]] || {};
                for (let j = 0; j < la; ++j) {
                    state[attributes[j]] = el[attributes[j]];
                }
                storage[el[definition.column]] = state;
                if (definition.children && definition.children.column) {
                    state[definition.children.store] = extractNodeStateImpl(
                        definition.children,
                        el[definition.children.store],
                        state[definition.children.store]
                    );
                }
            }
            return storage;
        }
        return extractNodeStateImpl(definition, nodes, storage);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Re-apply a state extracted previously by extractNodesState. This can be also thought of as a left join with another pivot tree with the identical hierarchy.
     *
     * Note: nodes which don't have a matching state will be ignored (specifically, the properties identified by the attributes parameter won't be set to null)
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param attribute is a name of the property, or array of names, which should be restored to every node. This would be typical some state
     * that applies to all levels, such as "$expanded" (used by the pivot table)
     * @param state is the source storage.
     */
    applyNodesState(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        attributes: string | string[],
        state: INodeStateStore
    ): void {
        if (!attributes) return;
        if (!_.isArray(attributes)) attributes = [<string>attributes];
        const la = attributes.length;

        function applyNodeStateImpl(
            definition: INormalizedLogexPivotDefinition,
            nodes: any[],
            state: INodeStateStore
        ): void {
            if (state == null) return;
            for (let i = 0, l = nodes.length; i < l; ++i) {
                const el = nodes[i];
                const itemState = state[el[definition.column]];
                if (!itemState) continue;
                for (let j = 0; j < la; ++j) {
                    el[attributes[j]] = itemState[attributes[j]];
                }
                if (definition.children && definition.children.column) {
                    applyNodeStateImpl(
                        definition.children,
                        el[definition.children.store],
                        itemState[definition.children.store]
                    );
                }
            }
        }
        applyNodeStateImpl(definition, nodes, state);
    }

    // ---------------------------------------------------------------------------------------------
    // mergeFn is either function fn(target,source,definition,level) or array containing elements to merge in. Note that if you want to mix into any level,
    //  then even the deepest definition must contain the column: parameter (which is generally unused by the pivot)
    /*
    mixIn: function (definition, data, mergedData, mergeFn, nullMatchesAll) {
        if (angular.isArray(mergeFn)) {
            mergeFn = (function (src) {
                return function (target, source, definition, level) {
                    var i = 0, l = src.length, e;
                    for (i = 0; i < l; ++i) {
                        e = src[i];
                        target[e] = source[e];
                    }
                }
            })(mergeFn);
        }
        function mixInLevel(definition, data, filteredData, level) {
            var i = 0, l = data.length, e;
            for (i = 0; i < l; ++i) {
                e = data[i];
                var newFiltered = {};
                var found = false;
                // gather data that fit to the current node
                _.each(filteredData, function (el) {
                    if (el.used) return;
                    if (el.value[definition.column] == e[definition.column]) {
                        newFiltered[el.index] = el;
                        found = true;
                        el.matchedAt = Math.max(level, el.matchedAt);
                    } else if (nullMatchesAll && el.value[definition.column] == null) {
                        newFiltered[el.index] = el;
                        found = true;
                    }
                });
                if (!found) continue;
                // if having children, apply to subnodes first
                if (definition.children && definition.children.column) {
                    mixInLevel(definition.children, e[definition.children.store], newFiltered, level + 1);
                }
                // apply data, which were not consumed by the children
                _.each(newFiltered, function (el) {
                    if (el.matchedAt < level) return;
                    if (!el.used) {
                        mergeFn(e, el.value, definition, level);
                        el.used = true;
                    }
                    delete filteredData[el.index];
                });
            }
        }
        var indexedData = {};
        var index = 0;
        _.each(mergedData, function (e) {
            indexedData[index] = { index: index, value: e, used: false, matchedAt: -1 };
            ++index;
        });
        mixInLevel(definition, data, indexedData, 0);
    },
    */
    /**
     * mixIn allows adding data (represented as array of rows) into created pivot tree, as a kind of left-join operation (but not between 2 trees).
     * This can be used for example by inserting comments into the table, if fetched separately. The rows are matched level by level based on the keys
     * of given level, and the target can be any node (not just a leaf). On match, the data are copied to the target either using the specified
     * callback, or (typically) specified as the property names that should be copied.
     *
     * There can be always only one match - we cannot use this to add one row into multple nodes
     *
     * The matching algorithm can be exact, or allow null prefixed on the path. If we have pivot of products under specialisations, we would always
     * use the exact match - any related data structure would store both columns [specialisme,product_id], because product_id itself is not unique.
     *
     * On the other hand when presenting organisation structure, the pivot might have orglevel2/orglevel1/kostenplaats structure. There kostenplaats
     * is always unique, and related data structure typically won't store all the columns: for example in Budgeting tool comments, we store
     * just [organisatielevel2_id, null, null] or [null, organisatielevel1_id, null] or [null, null, kostenplaats_id]. In that case, numMatchesAll
     * should be set to true and the mixing code will try to visit every node on a level, if exact key is not set. Some notes on this scenario
     * - this really applies only to null values. It will not try to visit every node, if the ID is specified, but not found in the tree
     * - this applies only to the prefix of the path. Succesfull match must always end up with one ID which is matched exactly (like the konstenplaats_id
     *   on 3rd level in the example)
     *
     * Note that umatched rows are not reported in any way
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param mergedData are rows with the data that we want to mix into the tree
     * @param mergeFn specifies, how should be data from one matched merge row copied to the target node. This can be either a callback, or array
     *   of names of properties that should be copied.
     * @param nullMatchesAll when this value is true, then a merge data row is potentially joined with given node even if the row is missing
     *   data for the current level. If false, only exact matches are used
     * @param context is optional context, passed to the callbacks (mergedKey)
     * @param buildDataExtractor specifies, if the pivot's data extractor should be used on the mixed-in data (true), or extractor to use. Keep in mind
     *        that such extractor typically has to handle different fields in the data being null
     */
    public mixIn(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        mergedData: any[],
        mergeFn: IMixInOperator,
        nullMatchesAll?: boolean,
        context?: any,
        buildDataExtractor?: boolean | IBuildDataExtractorCallback
    ): void {
        // This should be optimized version of the above (handling of null matches may be a bit slower, but in general
        // it has N+M complexity instead of M*N). I am keeping the old code in case we discover some issue here.

        // if mergeFn is list of properties, prepare the function out of them
        let finalMergeFn: IMixInCallback;

        if (_.isArray(mergeFn)) {
            finalMergeFn = (function (src: string[]) {
                return function mergeFnColumns(target, source) {
                    let i = 0;
                    const l = src.length;
                    for (i = 0; i < l; ++i) {
                        const e = src[i];
                        target[e] = source[e];
                    }
                };
            })(<string[]>mergeFn);
        } else {
            finalMergeFn = <IMixInCallback>mergeFn;
        }

        interface IFilteredData {
            value: any;
            used: boolean; // make sure node isn't matched at multiple places
            keys: any[];
            pathDepth: number;
            extractedBuildData: any | null;
        }

        function mixInLevel(
            definition: INormalizedLogexPivotDefinition,
            levelData: any[],
            mixInData: IFilteredData[],
            level: number
        ): void {
            let i = 0;
            const l = levelData.length;
            const lookup: types.ILookup<any> = {};
            const finalMatches: types.ILookup<IFilteredData[]> = {};
            const childrenMatches: types.ILookup<IFilteredData[]> = {};
            let nullLooksLikeThis = null;

            // build lookup for the pivoted data (current level)
            if (definition.mergedKey) {
                nullLooksLikeThis = definition.mergedKey({}, context, {});
                for (i = 0; i < l; ++i) {
                    const e = levelData[i];
                    //                        lookup[definition.mergedKey(e, context)] = e;
                    lookup[e[definition.column]] = e; // this seems to be sufficient?
                }
            } else {
                for (i = 0; i < l; ++i) {
                    const e = levelData[i];
                    lookup[e[definition.column]] = e;
                }
            }
            let found = false;

            // assign the mixin data to the corresponding items
            for (const el of mixInData) {
                if (el.used) continue;
                const key = el.keys[level];
                const target = lookup[key];

                if (target !== undefined) {
                    // Direct match, assign it to the item
                    found = true;
                    if (el.pathDepth === level) {
                        let matchList: IFilteredData[] = finalMatches[key];
                        if (matchList == null) {
                            matchList = finalMatches[key] = [];
                        }
                        matchList.push(el);
                    } else {
                        let childMatchList = childrenMatches[key];
                        if (childMatchList == null) {
                            childMatchList = childrenMatches[key] = [];
                        }
                        childMatchList.push(el);
                    }
                } else if (nullMatchesAll && key === nullLooksLikeThis && el.pathDepth > level) {
                    // Null match, assign it to all items
                    for (i = 0; i < l; ++i) {
                        //                            const key = definition.mergedKey ? definition.mergedKey(levelData[i], context) : levelData[i][definition.column];
                        const key = levelData[i][definition.column];
                        let childMatchList = childrenMatches[key];
                        if (childMatchList == null) {
                            childMatchList = childrenMatches[key] = [];
                        }
                        childMatchList.push(el);
                    }
                    found = true;
                }
            }
            if (!found) return;

            // if having children, apply to subnodes first
            if (definition.children && definition.children.column) {
                _.each(childrenMatches, function (newFiltered, key) {
                    mixInLevel(
                        definition.children,
                        lookup[key][definition.children.store],
                        newFiltered,
                        level + 1
                    );
                });
            }

            // apply data, which were not consumed by the children
            _.each(finalMatches, function (newFiltered, key) {
                for (const el of newFiltered) {
                    if (!el.used) {
                        finalMergeFn(lookup[key], el.value, definition, level);
                        el.used = true;
                    }
                }
            });
        }

        const preparedData: IFilteredData[] = [];
        const sharedBuildData: any = {};
        const extractor =
            buildDataExtractor === true
                ? definition.$options
                    ? definition.$options.buildDataExtractor
                    : null
                : buildDataExtractor;
        if (extractor) {
            for (const e of mergedData) {
                let extractedData = extractor(e, sharedBuildData, context);
                if (extractedData === undefined) extractedData = sharedBuildData;
                preparedData.push({
                    value: e,
                    keys: [],
                    pathDepth: 0,
                    used: false,
                    extractedBuildData: extractedData
                });
            }
        } else {
            for (const e of mergedData) {
                preparedData.push({
                    value: e,
                    keys: [],
                    pathDepth: 0,
                    used: false,
                    extractedBuildData: null
                });
            }
        }

        // calculate keys for all the items, and also their pathLength (deepest level with non-null key)
        let currentDef = definition;
        let currentDepth = 0;
        while (currentDef && currentDef.column) {
            let nullLooksLikeThis: any;
            if (currentDef.mergedKey) {
                nullLooksLikeThis = currentDef.mergedKey({}, context, {});
            }
            for (const el of preparedData) {
                let key: any;
                if (currentDef.mergedKey) {
                    key = currentDef.mergedKey(el.value, context, el.extractedBuildData);
                } else if (el.extractedBuildData) {
                    key = el.extractedBuildData[currentDef.column];
                    if (key === undefined) key = el.value[currentDef.column];
                } else {
                    key = el.value[currentDef.column];
                }
                el.keys[currentDepth] = key;
                // Note: the original code allows pivot nodes that had keys with the actual null value: this is no longer supported. Is that a problem?
                if (key != null && (!currentDef.mergedKey || key !== nullLooksLikeThis)) {
                    el.pathDepth = currentDepth;
                }
            }
            currentDef = currentDef.children;
            ++currentDepth;
        }

        mixInLevel(definition, nodes, preparedData, 0);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Removes a leaf node from the pivot. If this result in empty parent node, then this node will also be removed (
     * and recursively up to the root). You can turn off deletion by using _useFiltersKeepChildless_ parameter in
     * combination with _filtersKeepChildless_ setting in pivot definition.
     *
     * Note that the code doesn't affect the filtered tree (you should refilter eventually)
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param leafNode is the leaf to be removed
     * @param useFiltersKeepChildless specifies, if the code should respect the "filtersKeepChildless" option in the definition
     *   while pruning the resulting tree. Defaults to false
     */
    public removeLeafNode(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        leafNode: any,
        useFiltersKeepChildless?: boolean,
        context?: any
    ): void {
        const path = this.getLeafParents(definition, nodes, leafNode, context);
        let child = leafNode;
        _.eachRight(path, function (x) {
            const children = x.node[x.definition.children.store];
            _.pull(children, child);
            if (
                children.length === 0 &&
                (!useFiltersKeepChildless || !x.definition.filtersKeepChildless)
            ) {
                // If node has no children, remove it too
                child = x.node;
            } else {
                // If node has other children, then stop operation
                child = null;
                return false;
            }
            return true;
        });

        // There is still a child node to be removed. Remove it from "data"
        if (child != null) _.pull(nodes, child);
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * (Re)attaches a leaf node to the given pivot. If this requires creating new parent nodes, it will be done.
     * Note that this doesn't affect the filtered tree (you should refilter)
     *
     * If the definition leaf level uses nodeClass/nodeClassSelector, new instance won't be created (and constructor won't be called).
     * In this case, we attempt to also inject the standard fields (parent etc), when enabled, since they could change. If your
     * constructor does something odd, this may lead to inconsistent data, so just don't do silly stuff.
     *
     * If the leaf's is already a class (i.e. not just plain object), and the determined class from pivotDefinition is
     * thrown, exception will be thrown (we won't change class).
     *
     * If the leaf is plain object, but node class is expected by the pivot definition, a new instance will be created!
     * This means the original leaf actually won't be part of the tree.
     *
     * @param definition is the normalized tree definition
     * @param nodes are the top level nodes of the unfiltered tree
     * @param leafNode is the leaf to be attached
     * @param context is the optional context (to be passed to attachedColumns callbacks)
     * @return new instance of the leaf actually placed to the tree, or the original leafNode
     */
    public reattachLeafNode(
        definition: INormalizedLogexPivotDefinition,
        nodes: any[],
        leafNode: any,
        context?: any
    ): object {
        return this.placeRowImpl(
            {
                definition,
                storage: nodes,
                options: definition.$options || {},
                row: leafNode,
                buildDataStore: {},
                context
            },
            false
        );
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Based on the root definition, returns definition for the given level of the pivot tree. Note that no safety checks are done
     * (you will get exception if level is too deep)
     *
     * @params definition is the root definition level
     * @level is the level we want (0 being the top)
     */
    getDefinitionForLevel(
        definition: INormalizedLogexPivotDefinition,
        level: number
    ): INormalizedLogexPivotDefinition {
        while (level--) {
            definition = definition.children;
        }
        return definition;
    }
}
