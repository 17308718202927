import _ from "lodash";
import { IDropdownGroup, IDropdownEntryIcon } from "./lg-dropdown.types";

/**
 * Helper function to build a list of groups, as expected by our dropdown (the advanced one). Given a dictionary,
 * the function will filter its items, inject icon definition, group them, and sort the whole result.
 *
 * The function is useful, but rather old and doesn't offer much flexibility. We should probably build
 * a new one on top of it. For example the groupId/groupName parameters do not support a callback.
 *
 * The dropdown always expects a list of groups. When no grouping is desired, just specify non-existant property
 * as groupId/groupName
 *
 * @param input is dictionary containing all options to be added to the dropdown
 * @param groupId is name of the property used to group the entries (for example keyCategory_id). The same property
 *   will hold the id in the created group object.
 * @param groupName is name of the property that contain the name of the group (for example keyCategorie_Description).
 *   The same property will hold the name in the created group object
 * @param groupSortBy is either a callback for comparing 2 groups, or name of the property containing the group sorting
 *   criteria. The callback is called after the grouping is done, so it can access the groups' entry arrays. When not
 *   specified, the groups are not sorted in any way.
 * @param entrySortBy is either a callback for comparing 2 entries, or name of the property containing the entry sorting
 *   criteria. If not specified, the entries are not sorted
 * @param entryEnabled is either a callback accepting an entry and returning bool-like value, or name of the property
 *   used to decide. The name can be prefixed by ! for negating the effect. So "used" will include all used items, while
 *   "!used" will include all unused items
 * @param iconName is name of the property which will contain the result if the iconFunction callback (if any)
 * @param iconFunction is optional callback, which creates icon definition for the target entry
 * @returns array of groups, each containing the array of options under the property "entries"
 */
export function buildDropdownGroups(
    input: _.Dictionary<any>,
    groupId: string,
    groupName: string,
    groupSortBy?: string | ((group1: any, group2: any) => number),
    entrySortBy?: string | ((entry1: any, entry2: any) => number),
    entryEnabled?: string | ((entry: any) => boolean),
    iconName?: string,
    iconFunction?: (entry: any) => IDropdownEntryIcon
): IDropdownGroup[] {
    let enableFunc: (entry: any) => boolean = null;
    const result: IDropdownGroup[] = [];
    const groups: _.Dictionary<IDropdownGroup> = {};

    if (entryEnabled) {
        if (_.isString(entryEnabled)) {
            if (entryEnabled.charAt(0) === "!") {
                entryEnabled = entryEnabled.substring(1);
                enableFunc = e => !e[entryEnabled as string];
            } else {
                enableFunc = e => e[entryEnabled as string];
            }
        } else {
            enableFunc = entryEnabled;
        }
    }

    // create groups
    for (const k in input) {
        if (Object.prototype.hasOwnProperty.call(input, k)) {
            const entry = input[k];
            if (enableFunc && !enableFunc(entry)) continue;
            if (iconFunction) entry[iconName] = iconFunction(entry);
            const gid = entry[groupId];
            let group = groups[gid];
            if (!group) {
                groups[gid] = group = { entries: [] };
                result.push(group);
                group[groupId] = entry[groupId];
                group[groupName] = entry[groupName];
            }
            group.entries.push(entry);
        }
    }

    // sort group content
    if (entrySortBy) {
        let fn = entrySortBy;
        if (_.isString(fn)) {
            fn = (a: any, b: any) => {
                const e1 = a[entrySortBy as string];
                const e2 = b[entrySortBy as string];
                return e1 < e2 ? -1 : e1 > e2 ? 1 : 0;
            };
        }
        for (let i = 0, l = result.length; i < l; ++i) {
            result[i].entries.sort(fn);
        }
    }

    // sort groups
    if (groupSortBy) {
        let fn = groupSortBy;
        if (_.isString(fn)) {
            fn = (a: any, b: any) => {
                const e1 = a.entries[0][groupSortBy as string];
                const e2 = b.entries[0][groupSortBy as string];
                return e1 < e2 ? -1 : e1 > e2 ? 1 : 0;
            };
        }
        result.sort(fn);
    }
    return result;
}
