import { Component, ViewEncapsulation, Input, EventEmitter, Output } from "@angular/core";
import { Subject, Observable } from "rxjs";

import { toBoolean } from "@logex/framework/utilities";

// TODO: store the settings in DB
interface MaximizedItemChangeEvent {
    groupId: string;
    itemId: string;
}

@Component({
    selector: "lg-content-grid",
    template: ` <ng-content></ng-content> `,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: "lgg lgg--full",
        "[class.lgg--vertical]": "!columnMajor",
        "[class.lgg--horizontal]": "columnMajor"
    }
})
export class LgContentGridComponent {
    /**
     * Specifies if grid content column is major.
     * When `true`, the grid is filled column by column, otherwise it's filled row by row (which is the default).
     *
     * @default false
     */
    @Input() public set columnMajor(value: boolean) {
        value = toBoolean(value);
        if (this._columnMajor !== value) {
            this._columnMajor = value;
            this._columnMajorChange$.next(value);
        }
    }

    public get columnMajor(): boolean {
        return this._columnMajor;
    }

    @Output() public readonly maximizedGroupIdChange = new EventEmitter<string | null>();

    public get maximizedGroupId(): string | null {
        return this._maximizedGroupId;
    }

    @Output()
    public readonly maximizedItemChange = new EventEmitter<MaximizedItemChangeEvent | null>();

    public columnMajorChangeAsObservable(): Observable<boolean> {
        return this._columnMajorChange$.asObservable();
    }

    private _columnMajor = false;
    private _maximizedGroupId: string | null = null;
    private readonly _columnMajorChange$ = new Subject<boolean>();

    public constructor() {
        // empty
    }

    public setMaximizedGroupId(id: string, maximized: boolean, courtesyOfItemId: string): void {
        const effective = maximized ? id : null;
        if (this._maximizedGroupId === effective) return;

        this._maximizedGroupId = effective;
        this.maximizedGroupIdChange.next(effective);
        this.maximizedItemChange.next(maximized ? { groupId: id, itemId: courtesyOfItemId } : null);
    }
}
