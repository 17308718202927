import { InjectionToken } from "@angular/core";

export const LG_USER_INFO = new InjectionToken<IUser>("lgUserInfo");

// ----------------------------------------------------------------------------------
//
export interface IUser {
    id: number;
    name: string;
    userid: string;
    email?: string;
    impersonator?: string;
    ziekenhuiscode: number;
    ziekenhuisnaam: string;
    roles: _.Dictionary<boolean>;
    title: string;
    hasPermission(permission: string): boolean;
}
