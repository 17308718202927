import { inject, Injectable } from "@angular/core";
import { LG_APP_CONFIGURATION } from "../../application/index";
import { LG_AUTHENTICATION_SERVICE } from "../../auth/index";
import { IUserflowConfiguration } from "./userflow.types";

@Injectable()
export class LgUserflowConfiguration implements IUserflowConfiguration {
    ready: Promise<void>;
    envinromentKey: string;
    userId: string;

    constructor() {
        const appConfiguration = inject(LG_APP_CONFIGURATION);
        const authService = inject(LG_AUTHENTICATION_SERVICE);
        this.ready = appConfiguration.ready
            .then(() => authService.isLoggedIn())
            .then(isLoggedIn => {
                if (isLoggedIn && appConfiguration.userflowEnvironmentKey) {
                    this.envinromentKey = appConfiguration.userflowEnvironmentKey;
                    this.userId = authService.user.id;
                }
            });
    }
}
