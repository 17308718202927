import * as _ from "lodash";
import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { DELETE, END, HOME, LEFT_ARROW, RIGHT_ARROW, ZERO } from "@angular/cdk/keycodes";
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { fromEvent, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";

import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { atNextFrame, isAnyPropInSimpleChanges } from "@logex/framework/utilities";

import { ValueAccessorBase } from "../inputs/value-accessor-base";
import { getRangeSliderRuler, ITicker } from "./getRangeSliderRuler";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { NgClass, NgForOf, NgIf } from "@angular/common";

const NUMLOCK_ZERO = 96;

export interface IRange {
    from: number;
    to: number;
}

enum HoveredOn {
    None,
    From,
    To
}

@Component({
    standalone: true,
    selector: "lg-range-slider",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgRangeSliderComponent),
            multi: true
        }
    ],
    templateUrl: "./lg-range-slider.component.html",
    host: {
        "(click)": "_onElementClick($event)",
        "[class.lg-range-slider]": "true"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgForOf, NgIf],
    encapsulation: ViewEncapsulation.None
})
export class LgRangeSliderComponent
    extends ValueAccessorBase<IRange>
    implements OnInit, OnChanges, OnDestroy, AfterContentInit, AfterViewInit
{
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _ngZone = inject(NgZone);
    private _translate = inject(LgTranslateService);

    @Input() disabled = false;

    /**
     * Minimum allowed value (required).
     */
    @Input({ required: true }) min!: number;

    /**
     * Maximum allowed value (required).
     */
    @Input({ required: true }) max!: number;

    /**
     * Range step.
     */
    @Input() step?: number;

    /**
     * Ticker step(s) to be highlighted
     */
    @Input() highlightStep = 50;

    @Input() tickerStep?: number;

    @Input() keyboardStep?: number;

    /**
     * Specifies if minus infinity is allowed.
     */
    @Input() minusInfinity = false;

    /**
     * Minus infinity label.
     */
    @Input() minusInfinityText?: string;

    /**
     * Specifies if plus infinity is allowed.
     */
    @Input() plusInfinity = false;

    /**
     * Plus infinity label.
     */
    @Input() plusInfinityText?: string;

    /**
     * Value formatter type.
     */
    @Input("lgFormatter") type = "float";

    /**
     * Value formatter options.
     */
    @Input("lgFormatterOptions") options: ILgFormatterOptions = { decimals: 0 };

    @Input() hideTicks = false;

    @Input() showFixedSelectedValues = false;

    @Input() useJoinedTooltip = false;

    /**
     * Discard changes emit that takes less than the specified time between these changes.
     */
    @Input() debounceTime = 0;

    @HostBinding("class.lg-range-slider--disabled") get isDisabled(): boolean {
        return this.disabled;
    }

    @ViewChild("focusHandle", { static: true }) private _focusHandleRef: ElementRef<HTMLElement>;
    @ViewChild("handleFrom", { static: true }) private _handleFromRef: ElementRef<HTMLElement>;
    @ViewChild("handleTo", { static: true }) private _handleToRef: ElementRef<HTMLElement>;
    @ViewChild("track", { static: true }) private _trackRef: ElementRef<HTMLElement>;
    @ViewChild("fromTooltip", { static: true }) private _fromTooltip: ElementRef<HTMLElement>;
    @ViewChild("toTooltip", { static: true }) private _toTooltip: ElementRef<HTMLElement>;

    @ViewChild("joinedTooltipFrom", { static: true })
    private _joinedTooltipFrom: ElementRef<HTMLElement>;

    @ViewChild("joinedTooltipTo", { static: true })
    private _joinedTooltipTo: ElementRef<HTMLElement>;

    _tickers: ITicker[];
    _focusHandlePosition: number;
    _fromHandlePosition: number;
    _fromHandleTooltip: string;
    _toHandlePosition: number;
    _toHandleTooltip: string;
    _stripLeftPosition: number;
    _stripWidth: number;
    _joinedHandleTooltip: string;
    _state: IRange;
    _stepDecimalCount = 0;

    get _range(): number {
        return this.max - this.min;
    }

    private _formatter: ILgFormatter<any>;
    private _isFromSelected: boolean;
    private _isHoveredOn: HoveredOn;
    private _destroyed = new Subject<void>();
    private _propsToListenTo = [
        "disabled",
        "min",
        "max",
        "highlightStep",
        "tickerStep",
        "keyboardStep",
        "minusInfinity",
        "minusInfinityText",
        "plusInfinity",
        "plusInfinityText",
        "lgFormatter",
        "lgFormatterOptions"
    ];

    private _debouncedValueChange: () => void;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this._debouncedValueChange = _.debounce(() => {
            this.value = { from: this._state.from, to: this._state.to };
        }, this.debounceTime);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const stepCompatibility = this.max % this.step === 0 && this.min % this.step === 0;
        if (this.step) {
            if (stepCompatibility) {
                this._getNumberDecimals();
            } else console.error("Max or Min value is not compatible with Step value!");
        }
        if (isAnyPropInSimpleChanges(this._propsToListenTo, changes)) {
            this.disabled = coerceBooleanProperty(this.disabled);
            this.min = coerceNumberProperty(this.min);
            this.max = coerceNumberProperty(this.max);
            this.highlightStep = coerceNumberProperty(this.highlightStep);
            this.tickerStep = coerceNumberProperty(this.tickerStep);
            this.keyboardStep = coerceNumberProperty(this.keyboardStep);
            this.minusInfinity = coerceBooleanProperty(this.minusInfinity);
            this.plusInfinity = coerceBooleanProperty(this.plusInfinity);

            this._tickers = getRangeSliderRuler(
                this.min,
                this.max,
                this.tickerStep,
                this.highlightStep
            );

            this._initializeFormatter();
        }
    }

    ngAfterContentInit(): void {
        if (!this.value) return;
        this._state = _.cloneDeep(this.value);
        this._updatePosition(this._state.from, this._state.to);
    }

    ngAfterViewInit(): void {
        this._renderer.listen(this._handleFromRef.nativeElement, "mouseover", () => {
            this._isHoveredOn = HoveredOn.From;
            this._focusHandlePosition = this._fromHandlePosition;
            if (!this.disabled)
                this._renderer.setStyle(
                    this._focusHandleRef.nativeElement,
                    "visibility",
                    "visible"
                );
            this._changeDetectorRef.markForCheck();
        });

        this._renderer.listen(this._handleToRef.nativeElement, "mouseover", () => {
            this._isHoveredOn = HoveredOn.To;
            this._focusHandlePosition = this._toHandlePosition;
            if (!this.disabled)
                this._renderer.setStyle(
                    this._focusHandleRef.nativeElement,
                    "visibility",
                    "visible"
                );
            this._changeDetectorRef.markForCheck();
        });

        this._renderer.listen(this._handleFromRef.nativeElement, "mouseleave", () => {
            this._isHoveredOn = HoveredOn.None;
            this._renderer.setStyle(this._focusHandleRef.nativeElement, "visibility", "hidden");
        });

        this._renderer.listen(this._handleToRef.nativeElement, "mouseleave", () => {
            this._isHoveredOn = HoveredOn.None;
            this._renderer.setStyle(this._focusHandleRef.nativeElement, "visibility", "hidden");
        });
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    _onMouseDown(isFromSelected: boolean): boolean {
        if (this.disabled) return false;

        this._isFromSelected = isFromSelected;

        let dragging = true;

        this._ngZone.runOutsideAngular(() => {
            fromEvent(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed),
                    takeWhile(() => dragging)
                )
                .subscribe((event2: Partial<MouseEvent>) => {
                    this._reactToMouseEvent(event2, () => this._isFromSelected);
                    return false;
                });
        });

        fromEvent(document, "mouseup")
            .pipe(takeUntil(this._destroyed), take(1))
            .subscribe(() => {
                this._isFromSelected = null;
                dragging = false;
                return false;
            });

        return false;
    }

    _onElementClick(event: MouseEvent): boolean {
        const clickedOutside = (event.target as HTMLElement).hasAttribute("ignore-click");
        if (this.disabled || clickedOutside) {
            return false;
        }

        this._reactToMouseEvent(event, pos => this._isFromNearest(pos));

        return false;
    }

    _onKeyDown(event: KeyboardEvent, focusFrom: boolean): boolean {
        if (this.disabled) return true;

        let current: number;
        let doFrom: boolean;
        let min: number;
        let max: number;
        if (focusFrom !== event.ctrlKey) {
            current = this._state.from;
            doFrom = true;
            max = this._state.to;
        } else {
            current = this._state.to;
            doFrom = false;
            min = this._state.from;
        }
        if (current === Infinity) {
            current = this.max;
        } else if (current === -Infinity) {
            current = this.min;
        }

        let step = this.keyboardStep || this.tickerStep || 1;
        if (event.shiftKey) {
            step = step * 10;
        }

        let handled = true;

        switch (event.keyCode) {
            case END:
                event.preventDefault();
                current = this.max;
                break;
            case HOME:
                event.preventDefault();
                current = this.min;
                break;
            case LEFT_ARROW:
                event.preventDefault();
                current = current - step;
                if (!event.shiftKey) {
                    current = step * Math.round(current / step);
                }
                break;
            case RIGHT_ARROW:
                event.preventDefault();
                current = current + step;
                if (!event.shiftKey) {
                    current = step * Math.round(current / step);
                }
                break;
            case DELETE:
            case ZERO:
            case NUMLOCK_ZERO:
                event.preventDefault();
                if (this.min <= 0 && this.max >= 0) {
                    current = 0;
                } else {
                    handled = false;
                }
                break;
            default:
                handled = false;
        }

        if (handled) {
            current = this._clampValue(current, min, max);
            if (doFrom) {
                if (current === this._state.from) return false;
                this._state.from = current;
            } else {
                if (current === this._state.to) return false;
                this._state.to = current;
            }
            this._debouncedValueChange();
            this._updatePosition(this._state.from, this._state.to);
        }

        return !handled;
    }

    _onHandleMouseOver(): void {
        this._mergeOrUnmergeTooltips();
    }

    _onHandleFocus(): void {
        this._mergeOrUnmergeTooltips();
    }

    writeValue(value: IRange): void {
        this._writeValue(value);
        this._state = _.cloneDeep(this.value);
        this._updatePosition(this._state.from, this._state.to);
    }

    private _reactToMouseEvent(
        event: Partial<MouseEvent>,
        isFromHandleNearest: (cursorPosition?: number) => boolean
    ): void {
        let changed = false;

        let currentCursorPosition = this._getRelativeCursorPositionFromLeft(event);

        if (isFromHandleNearest(currentCursorPosition)) {
            currentCursorPosition = this._clampValue(currentCursorPosition, null, this._state.to);
            changed = currentCursorPosition !== this._state.from;
            if (changed) this._state = { from: currentCursorPosition, to: this._state.to };
            this._handleFromRef.nativeElement.focus();
        } else {
            currentCursorPosition = this._clampValue(currentCursorPosition, this._state.from, null);
            changed = currentCursorPosition !== this._state.to;
            if (changed) this._state = { from: this._state.from, to: currentCursorPosition };
            this._handleToRef.nativeElement.focus();
        }

        if (changed) {
            this._debouncedValueChange();
            this._updatePosition(this._state.from, this._state.to);
        }
    }

    private _getRelativeCursorPositionFromLeft(event: Partial<MouseEvent>): number {
        const leftOffset = this._trackRef.nativeElement.getBoundingClientRect().left + 5; // xx
        const frac = (event.clientX - leftOffset) / (this._trackRef.nativeElement.offsetWidth - 10); // xx

        return this.min + this._range * frac;
    }

    private _isFromNearest(currentCursorPosition: number): boolean {
        const currentLeft = Math.max(this._state.from, this.min);
        const currentRight = Math.min(this._state.to, this.max);

        if (currentLeft === currentRight) {
            return currentCursorPosition < currentLeft;
        }

        return (
            Math.abs(currentCursorPosition - currentLeft) <
            Math.abs(currentCursorPosition - currentRight)
        );
    }

    private _initializeFormatter(): void {
        this._formatter = this._formatterFactory.getFormatter(this.type, this.options);
    }

    private _getNumberDecimals(): void {
        const numStr = String(this.step);
        if (numStr.includes(".")) {
            this._stepDecimalCount = numStr.split(".")[1].length;
        }
    }

    private _validateStep(number: number): number {
        const formatedNumber = this._stepDecimalCount
            ? Math.round(number * 10 ** this._stepDecimalCount)
            : number;
        const formatedStep = this._stepDecimalCount
            ? this.step * 10 ** this._stepDecimalCount
            : this.step;
        const remaindedNumber = formatedNumber % formatedStep;
        const isIncreasing = Math.round(remaindedNumber / formatedStep);

        let result = formatedNumber - remaindedNumber;
        if (isIncreasing) {
            result += formatedStep;
        }
        return this._stepDecimalCount ? result / 10 ** this._stepDecimalCount : result;
    }

    private _updatePosition(from: number, to: number): void {
        this._ngZone.run(() => {
            if (this.step) {
                if (from) from = this._validateStep(from);
                if (to) to = this._validateStep(to);
            }

            this._fromHandleTooltip = this._getTooltipText(from);
            this._toHandleTooltip = this._getTooltipText(to);

            if (this._fromHandleTooltip === this._toHandleTooltip) {
                this._joinedHandleTooltip = this._fromHandleTooltip;
            } else {
                this._joinedHandleTooltip = `${this._fromHandleTooltip} ${this._translate.translate(
                    "FW._RangeFilter.Until"
                )}  ${this._toHandleTooltip}`;
            }

            from = Math.max(this.min, Math.min(this.max, from));
            to = Math.max(this.min, Math.min(this.max, to));

            this._fromHandlePosition = (100 * (from - this.min)) / this._range;
            this._toHandlePosition = (100 * (to - this.min)) / this._range;
            this._focusHandlePosition =
                this._isHoveredOn === HoveredOn.From
                    ? this._fromHandlePosition
                    : this._toHandlePosition;
            this._stripLeftPosition = (100 * (from - this.min)) / this._range;
            this._stripWidth = (100 * (to - from)) / this._range;

            this._mergeOrUnmergeTooltips();
        });
    }

    private _mergeOrUnmergeTooltips(): void {
        atNextFrame(() => {
            if (this._fromTooltip) {
                const fromRight = this.useJoinedTooltip
                    ? this._fromTooltip.nativeElement.getBoundingClientRect().right + 50
                    : this._fromTooltip.nativeElement.getBoundingClientRect().right;
                const toLeft = this._toTooltip.nativeElement.getBoundingClientRect().left;

                if (toLeft !== 0 && fromRight !== 0 && toLeft <= fromRight) {
                    this._renderer.setStyle(
                        this._fromTooltip.nativeElement,
                        "visibility",
                        "hidden"
                    );
                    this._renderer.setStyle(this._toTooltip.nativeElement, "visibility", "hidden");
                    this._renderer.setStyle(
                        this._joinedTooltipTo.nativeElement,
                        "visibility",
                        "visible"
                    );
                    this._renderer.setStyle(
                        this._joinedTooltipFrom.nativeElement,
                        "visibility",
                        "hidden"
                    );
                    this._changeDetectorRef.markForCheck();
                } else {
                    this._renderer.setStyle(
                        this._fromTooltip.nativeElement,
                        "visibility",
                        this.useJoinedTooltip ? "hidden" : "visible"
                    );
                    this._renderer.setStyle(
                        this._toTooltip.nativeElement,
                        "visibility",
                        this.useJoinedTooltip ? "hidden" : "visible"
                    );
                    this._renderer.setStyle(
                        this._joinedTooltipTo.nativeElement,
                        "visibility",
                        this.useJoinedTooltip ? "visible" : "hidden"
                    );
                    this._renderer.setStyle(
                        this._joinedTooltipFrom.nativeElement,
                        "visibility",
                        this.useJoinedTooltip ? "visible" : "hidden"
                    );
                    this._changeDetectorRef.markForCheck();
                }
            }
        });
    }

    private _getTooltipText(val: number): string {
        if (this.minusInfinity && val === -Infinity) {
            return this.minusInfinityText;
        }

        if (this.plusInfinity && val === Infinity) {
            return this.plusInfinityText;
        }

        return this._formatter.format(val);
    }

    // ---------------------------------------------------------------------------------------------
    //  Clamp the value within range, optionally snapping to infinities. Also optionally use
    //  a second set of min/max, to make sure the handles don't cross each other
    // ---------------------------------------------------------------------------------------------
    private _clampValue(value: number, min?: number, max?: number): number {
        if (value >= this.max) {
            value = this.max > 0 && this.plusInfinity ? +Infinity : this.max;
        } else if (value <= this.min) {
            value = this.min < 0 && this.minusInfinity ? -Infinity : this.min;
        }
        if (min != null && value < min) return min;
        if (max != null && value > max) return max;

        return value;
    }
}
