import { Component, Input, HostBinding, inject } from "@angular/core";

import { toInteger } from "@logex/framework/utilities";

import { LgPivotTableRowDirective } from "./templates/lg-pivot-table-row.directive";

@Component({
    selector: "lg-pivot-table-empty-column",
    template: ``,
    host: {
        class: "lg-pivot-table__column__empty"
    }
})
export class LgPivotTableEmptyColumnComponent {
    _row = inject(LgPivotTableRowDirective);

    /**
     * Row level.
     *
     * @default -1
     */
    @Input() set level(value: number) {
        this._level = toInteger(value);
    }

    public get level(): number {
        return this._level;
    }

    private _level = -1;

    @HostBinding("class.lg-pivot-table__column__empty--bottom")
    public get _bottomClass(): boolean {
        return this._row.context.nextLevel <= this._level;
    }
}
