import _ from "lodash";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { toBoolean } from "@logex/framework/utilities";
import { MultilevelDefinition, MultilevelDefinitionRestricted } from "./lg-multilevel-select-types";
import {
    LgQuickSettingsMenuHost,
    IQuickSettingsMenuChoice,
    IQuickSettingsMenuItem,
    LgQuickSettingsMenuPopupComponent,
    IQuickSettingsMenuRegularItem,
    IQuickSettingsMenuSeparator,
    IQuickSettingsSubmenu,
    QuickSettingsMenuType
} from "../lg-quick-settings-menu";
import { NgClass } from "@angular/common";
import { LgMarkFocusOnDirective, lgTableInputNavigatorDirective } from "../../behavior";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-multilevel-select",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="lg-multilevel-select {{ _alignClass }}"
            [ngClass]="{
                'lg-multilevel-select--disabled': _isDropdownDisabled,
                'lg-multilevel-select--unassigned': !_assigned,
                'lg-multilevel-select--active': _popupActive
            }"
            title="{{ _valueName }}"
            tabindex="{{ focusEnabled ? 0 : -1 }}"
            lgTableInputNavigator
            [lgMarkFocusOn]="markFocusOn"
        >
            {{ _valueName }}
            <lg-icon icon="icon-arrow-down" class="lg-multilevel__arrow"></lg-icon>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass, LgMarkFocusOnDirective, LgIconComponent, lgTableInputNavigatorDirective],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgMultilevelSelectComponent),
            multi: true
        }
    ]
})
export class LgMultilevelSelectComponent<T extends number | string>
    extends LgQuickSettingsMenuHost
    implements ControlValueAccessor, OnDestroy
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);

    // ---------------------------------------------------------------------------------------------
    //  Inputs and outputs
    // ---------------------------------------------------------------------------------------------

    /**
     * Apply css class to host
     */
    @HostBinding("class")
    @Input("class")
    className: string | null = null;

    /**
     * List of definitions for all select options.
     *
     * @required
     * @param value
     */
    @Input({ required: true })
    set definition(value: Array<MultilevelDefinition<T>>) {
        this._definition = value;
        this._convertDefinition();
        this._updateValue();
    }

    get definition(): Array<MultilevelDefinition<T>> {
        return this._definition;
    }

    /**
     * Current selection.
     */
    @Input() set current(value: T) {
        this._current = value;
        this._updateValue();
    }

    get current(): T {
        return this._current;
    }

    @Output("currentChange") readonly currentChange = new EventEmitter<T>();

    @Input() set disabled(value: boolean) {
        this._isDisabled = toBoolean(value);
        this._updateValue();
    }

    get disabled(): boolean {
        return this._isDisabled;
    }

    /**
     * Specifies if width should be matched.
     *
     * @default false
     */
    @Input() set matchWidth(value: boolean) {
        this._matchWidth = toBoolean(value);
    }

    get matchWidth(): boolean {
        return this._matchWidth;
    }

    /**
     * Specifies if tooltip icons of select items should be hidden.
     *
     * @default false
     */
    @Input() set hideTooltipIcons(value: boolean | "true" | "false") {
        this._hideTooltipIcons = toBoolean(value);
    }

    get hideTooltipIcons(): boolean {
        return this._hideTooltipIcons;
    }

    @Input() set placeholder(value: string) {
        this._placeholder = value;
        this._updateValue();
    }

    get placeholder(): string {
        return this._placeholder;
    }

    /**
     * Specifies if short name of select options should be used.
     *
     * @default false
     */
    @Input() set useShortName(value: boolean) {
        this._useShortName = toBoolean(value);
    }

    get useShortName(): boolean {
        return this._useShortName;
    }

    @Input() popupPosition: "left" | "right" = "left";

    @Input() set align(value: "left" | "right" | "center") {
        switch (value) {
            case "left":
                this._alignClass = "";
                break;
            case "right":
                this._alignClass = "lg-multilevel-select--align-right";
                break;
            case "center":
                this._alignClass = "lg-multilevel-select--align-center";
                break;
            default:
                this._alignClass = "";
                value = "left";
                break;
        }
        this._align = value;
    }

    get align(): "left" | "right" | "center" {
        return this._align;
    }

    @Input() focusEnabled = true;

    public get _allowFocus(): boolean {
        return this.focusEnabled && !this._isDropdownDisabled;
    }

    /**
     * Selector of the closest element on which to set class 'lg-contains-focus' when input is focused
     * (closest = https://developer.mozilla.org/en-US/docs/Web/API/Element/closest)
     *
     * Defaults to `".table__row"` but it's a no-op if not used inside the table so you don't need to
     * pass in `null` or `""`
     */
    @Input() markFocusOn = ".table__row";

    private _current: T;
    private _definition!: Array<MultilevelDefinition<T>>;

    private _placeholder: string;
    private _isDisabled: boolean;
    private _matchWidth = false;
    private _useShortName = false;
    private _hideTooltipIcons = false;
    private _align: "left" | "right" | "center" = "left";
    _alignClass = "";

    // ---------------------------------------------------------------------------------------------
    //  State
    // ---------------------------------------------------------------------------------------------
    private _lookup: _.Dictionary<MultilevelDefinitionRestricted> = {};
    private _menuDefinition: IQuickSettingsMenuItem[] = [];
    private _pathLookup: _.Dictionary<IQuickSettingsMenuRegularItem[]> = {};

    // used when event is used to show the dropdown
    private _mustPick: boolean;
    private _onCancelFn: (source: LgMultilevelSelectComponent<T>) => void;

    _assigned = false;
    _currentValueName: string;
    _isDropdownDisabled = false;

    _valueName: string;

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    triggerSelect(onCancel?: (source: LgMultilevelSelectComponent<T>) => void): void {
        this._onCancelFn = onCancel;
        this._mustPick = onCancel == null;
        setTimeout(() => this._doShow(), 10);
    }

    @HostListener("click")
    _onClick(): boolean {
        if (this._isDropdownDisabled) {
            return true;
        }

        this._mustPick = false;
        this._onCancelFn = null;
        this._doShow();
        return false;
    }

    @HostListener("keydown.enter")
    _onEnter(): boolean {
        if (!this.focusEnabled) return true;

        return this._onClick();
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    // ---------------------------------------------------------------------------------------------
    //  ngModel integration
    // ---------------------------------------------------------------------------------------------
    private _onChangeFn: (value: T) => void;
    private _onTouchedFn: () => void;

    writeValue(obj: any): void {
        this.current = obj as T;
        this._changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: T) => void): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    // ---------------------------------------------------------------------------------------------
    //  Convert the definition
    // ---------------------------------------------------------------------------------------------
    private _convertDefinition(): void {
        if (!this._definition) return;

        const lookup: _.Dictionary<MultilevelDefinition<T>> = {};
        const pathLookup: _.Dictionary<IQuickSettingsMenuRegularItem[]> = {};

        const impl = (
            level: Array<MultilevelDefinition<T>>,
            path: IQuickSettingsMenuItem[]
        ): Array<
            IQuickSettingsMenuChoice | IQuickSettingsMenuSeparator | IQuickSettingsSubmenu
        > => {
            const result: Array<
                IQuickSettingsMenuChoice | IQuickSettingsMenuSeparator | IQuickSettingsSubmenu
            > = [];
            for (const element of level) {
                let item: IQuickSettingsMenuItem;
                if (element.children) {
                    item = {
                        type: QuickSettingsMenuType.Submenu,
                        name: element.name,
                        children: []
                    };
                } else if (element.name === "-") {
                    item = {
                        type: QuickSettingsMenuType.Separator
                    };
                } else {
                    item = {
                        type: QuickSettingsMenuType.Choice,
                        name: element.name,
                        help: element.helpLC
                            ? this._translateService.translate(element.helpLC)
                            : element.help,
                        onClick: () => {
                            this._doSelect(element.id);
                        }
                    };
                }

                const subpath = [...path, item];

                if (item.type === QuickSettingsMenuType.Submenu) {
                    item.children = impl(element.children, subpath);
                } else if (element.id != null) {
                    lookup[element.id as any] = element;
                    pathLookup[element.id as any] = subpath as any;
                }
                result.push(item);
            }
            return result;
        };

        this._menuDefinition = impl(this._definition, []);
        this._lookup = lookup;
        this._pathLookup = pathLookup;
    }

    // ---------------------------------------------------------------------------------------------
    //  Update currently selected value
    // ---------------------------------------------------------------------------------------------
    private _updateValue(): void {
        if (!this._definition) {
            this._assigned = false;
            this._isDropdownDisabled = true;
            this._valueName = this._placeholder || "-";
            return;
        }
        this._isDropdownDisabled = this._isDisabled;

        const target = this._lookup && this._lookup["" + this.current];
        if (target) {
            this._valueName =
                (this._useShortName ? target.shortName : target.fullName) || target.name;
            this._changeDetectorRef.detectChanges();
            this._assigned = true;
        } else {
            this._valueName = this._placeholder || "-";
            this._assigned = false;
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Make a selection
    // ---------------------------------------------------------------------------------------------
    private _doSelect(id: T): void {
        this.current = id;
        this.currentChange.next(id);

        if (this._onChangeFn) {
            this._onChangeFn(id);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Close the popup
    // ---------------------------------------------------------------------------------------------
    protected override _hideSettingsPopup(): void {
        if (!this._popupActive) return;

        super._hideSettingsPopup();

        if (this._onTouchedFn) this._onTouchedFn();

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    private _doShow(): void {
        const alignment = this.popupPosition === "right" ? "start" : "end";

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: alignment, originY: "bottom", overlayX: alignment, overlayY: "top" },
                { originX: alignment, originY: "top", overlayX: alignment, overlayY: "bottom" }
            ]);

        let selected = false;
        super
            ._showSettingsPopup(this._elementRef, strategy, true, {
                definition: this._menuDefinition,
                compact: true,
                matchWidth: this._matchWidth,
                hideTooltipIcons: this._hideTooltipIcons,
                selectedItems: this._pathLookup[this._current],
                canCancel: () => !this._mustPick
            })
            .subscribe({
                next: () => {
                    this._hideSettingsPopup();
                    selected = true;
                },
                error: () => {
                    this._hideSettingsPopup();
                    if (!selected && this._onCancelFn) this._onCancelFn(this);
                }
            });

        this._changeDetectorRef.markForCheck();
    }
}
