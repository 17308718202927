import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DELETE, END, HOME, LEFT_ARROW, RIGHT_ARROW, ZERO } from "@angular/cdk/keycodes";
import { fromEvent, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";

import { isAnyPropInSimpleChanges } from "@logex/framework/utilities";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { getRangeSliderRuler, ITicker } from "../lg-range-slider/getRangeSliderRuler";
import { ValueAccessorBase } from "../inputs";
import { NgClass, NgForOf } from "@angular/common";

const NUMLOCK_ZERO = 96;

@Component({
    standalone: true,
    selector: "lg-slider",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgSliderComponent),
            multi: true
        }
    ],
    templateUrl: "./lg-slider.component.html",
    host: {
        "(click)": "_onElementClick($event)",
        "[class.lg-range-slider]": "true",
        // TODO: Refactor shared styles for lg-slider and lg-range-slider
        "[class.lg-range-slider--simple]": "true"
    },
    imports: [NgClass, NgForOf],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSliderComponent
    extends ValueAccessorBase<number>
    implements OnChanges, OnDestroy, AfterViewInit
{
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _ngZone = inject(NgZone);

    @Input() disabled = false;

    /**
     * Minimum allowed value (required).
     */
    @Input({ required: true }) min!: number;

    /**
     * Maximum allowed value (required).
     */
    @Input({ required: true }) max!: number;

    /**
     * Range step.
     */
    @Input({ required: true }) step!: number;

    /**
     * Ticker step(s) to be highlighted
     */
    @Input() highlightStep = 50;

    /**
     * Ticker step
     */
    @Input() tickerStep?: number;

    @Input() keyboardStep?: number;

    /**
     * Value formatter type.
     */
    @Input("lgFormatter") type = "float";

    /**
     * Value formatter options.
     */
    @Input("lgFormatterOptions") options: ILgFormatterOptions = { decimals: 0 };

    @Input() hideTicks = false;

    /**
     * Specifies if minus infinity is allowed.
     */
    @Input() allowMinusInfinity = false;

    /**
     * Minus infinity label.
     */
    @Input() minusInfinityText?: string;

    /**
     * Specifies if plus infinity is allowed.
     */
    @Input() allowPlusInfinity = false;

    /**
     * Plus infinity label.
     */
    @Input() plusInfinityText?: string;

    @HostBinding("class.lg-range-slider--disabled") get isDisabled(): boolean {
        return this.disabled;
    }

    @ViewChild("focusHandle", { static: true }) private _focusHandleRef: ElementRef<HTMLElement>;
    @ViewChild("handle", { static: true }) private _handleRef: ElementRef<HTMLElement>;
    @ViewChild("track", { static: true }) private _trackRef: ElementRef<HTMLElement>;
    @ViewChild("tooltip", { static: true }) private _tooltipRef: ElementRef<HTMLElement>;
    _tickers: ITicker[];
    _focusHandlePosition: number;
    _fromHandlePosition: number;
    _handleTooltip: string;
    _stripWidth: number;
    _state: number;
    _stepDecimalCount = 0;

    get _range(): number {
        return this.max - this.min;
    }

    private _formatter: ILgFormatter<any>;
    private _destroyed = new Subject<void>();
    private _propsToListenTo = [
        "disabled",
        "min",
        "max",
        "highlightStep",
        "tickerStep",
        "keyboardStep",
        "minusInfinity",
        "minusInfinityText",
        "plusInfinity",
        "plusInfinityText",
        "lgFormatter",
        "lgFormatterOptions"
    ];

    constructor() {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const stepCompatibility = this.max % this.step === 0 && this.min % this.step === 0;
        if (this.step) {
            if (stepCompatibility) {
                this._getNumberDecimals();
            } else console.error("Max or Min value is not compatible with Step value!");
        }
        if (isAnyPropInSimpleChanges(this._propsToListenTo, changes)) {
            this._tickers = getRangeSliderRuler(
                this.min,
                this.max,
                this.tickerStep,
                this.highlightStep
            );

            this._initializeFormatter();
        }
    }

    ngAfterViewInit(): void {
        this._renderer.listen(this._handleRef.nativeElement, "mouseenter", () => {
            this._focusHandlePosition = this._fromHandlePosition;
            if (!this.disabled)
                this._renderer.setStyle(
                    this._focusHandleRef.nativeElement,
                    "visibility",
                    "visible"
                );
            this._changeDetectorRef.markForCheck();
        });

        this._renderer.listen(this._handleRef.nativeElement, "mouseleave", () => {
            this._renderer.setStyle(this._focusHandleRef.nativeElement, "visibility", "hidden");
        });
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    _onMouseDown(): boolean {
        if (this.disabled) return false;

        let dragging = true;

        this._ngZone.runOutsideAngular(() => {
            fromEvent(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed),
                    takeWhile(() => dragging)
                )
                .subscribe((event2: Partial<MouseEvent>) => {
                    this._reactToMouseEvent(event2);
                    return false;
                });
        });

        fromEvent(document, "mouseup")
            .pipe(takeUntil(this._destroyed), take(1))
            .subscribe(() => {
                dragging = false;
                return false;
            });

        return false;
    }

    _onElementClick(event: MouseEvent): boolean {
        const clickedOutside = (event.target as HTMLElement).hasAttribute("ignore-click");
        if (this.disabled || clickedOutside) {
            return false;
        }

        this._reactToMouseEvent(event);

        return false;
    }

    _onKeyDown(event: KeyboardEvent): boolean {
        if (this.disabled) return true;

        let current = this._state;

        let step = this.keyboardStep || this.tickerStep || 1;
        if (event.shiftKey) {
            step = step * 10;
        }

        let handled = true;

        switch (event.keyCode) {
            case END:
                event.preventDefault();
                current = this.max;
                break;
            case HOME:
                event.preventDefault();
                current = this.min;
                break;
            case LEFT_ARROW:
                event.preventDefault();
                current = current - step;
                if (!event.shiftKey) {
                    current = step * Math.round(current / step);
                }
                break;
            case RIGHT_ARROW:
                event.preventDefault();
                current = current + step;
                if (!event.shiftKey) {
                    current = step * Math.round(current / step);
                }
                break;
            case DELETE:
            case ZERO:
            case NUMLOCK_ZERO:
                event.preventDefault();
                if (this.min <= 0 && this.max >= 0) {
                    current = 0;
                } else {
                    handled = false;
                }
                break;
            default:
                handled = false;
        }

        if (handled) {
            current = this._clampValue(current);
            if (current === this._state) return false;
            this._state = current;
            this.value = this._state;
            this._updatePosition(this._state);
        }

        return !handled;
    }

    writeValue(value: number): void {
        this._writeValue(value);
        this._state = this.value;
        this._updatePosition(this._state);
    }

    private _reactToMouseEvent(event: Partial<MouseEvent>): void {
        let currentCursorPosition = this._getRelativeCursorPositionFromLeft(event);

        currentCursorPosition = this._clampValue(currentCursorPosition);
        const changed = currentCursorPosition !== this._state;
        if (changed) this._state = currentCursorPosition;
        this._handleRef.nativeElement.focus();

        if (changed) {
            this.value = this._state;
            this._updatePosition(this._state);
        }
    }

    private _getRelativeCursorPositionFromLeft(event: Partial<MouseEvent>): number {
        const leftOffset = this._trackRef.nativeElement.getBoundingClientRect().left + 5; // xx
        const frac = (event.clientX - leftOffset) / (this._trackRef.nativeElement.offsetWidth - 10); // xx

        return this.min + this._range * frac;
    }

    private _initializeFormatter(): void {
        this._formatter = this._formatterFactory.getFormatter(this.type, this.options);
    }

    private _getNumberDecimals(): void {
        const numStr = String(this.step);
        if (numStr.includes(".")) {
            this._stepDecimalCount = numStr.split(".")[1].length;
        }
    }

    private _validateStep(number: number): number {
        if (this.step === undefined || !isFinite(number)) return number;

        const formattedNumber = this._stepDecimalCount
            ? Math.round(number * 10 ** this._stepDecimalCount)
            : number;
        const formattedStep = this._stepDecimalCount
            ? this.step * 10 ** this._stepDecimalCount
            : this.step;
        const remindedNumber = formattedNumber % formattedStep;
        const isIncreasing = Math.round(remindedNumber / formattedStep);

        let result = formattedNumber - remindedNumber;
        if (isIncreasing) {
            result += formattedStep;
        }
        return this._stepDecimalCount ? result / 10 ** this._stepDecimalCount : result;
    }

    private _updatePosition(value: number): void {
        this._ngZone.run(() => {
            if (this.step) {
                value = this._validateStep(value);
            }

            this._handleTooltip = this._getTooltipText(value);

            const from = Math.max(this.min, Math.min(this.max, value));
            this._fromHandlePosition = (100 * (from - this.min)) / this._range;
            this._focusHandlePosition = this._fromHandlePosition;
            this._stripWidth = this._fromHandlePosition;

            this._changeDetectorRef.markForCheck();
        });
    }

    private _getTooltipText(val: number): string {
        if (this.allowMinusInfinity && val === -Infinity) {
            return this.minusInfinityText;
        }

        if (this.allowPlusInfinity && val === Infinity) {
            return this.plusInfinityText;
        }

        return this._formatter.format(val);
    }

    // ---------------------------------------------------------------------------------------------
    //  Clamp the value within range, optionally snapping to infinities. Also optionally use
    //  a second set of min/max, to make sure the handles don't cross each other
    // ---------------------------------------------------------------------------------------------
    private _clampValue(value: number): number {
        if (value >= this.max) {
            value = value > this.max && this.allowPlusInfinity ? +Infinity : this.max;
        } else if (value <= this.min) {
            value = value < this.min && this.allowMinusInfinity ? -Infinity : this.min;
        }

        return this._validateStep(value);
    }
}
