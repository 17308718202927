<div class="flex-stretch flexcol lg-sidebar-panel-scrollable lg-sidebar-panel-scrollable--full">
    <div
        [lgScrollable]
        [lgScrollableWrapper]="false"
        lgScrollbarClass="lg-scrollbar lg-scrollbar--sidebar"
        class="flex flexcol flex-stretch"
    >
        <div class="lg-sidebar-help__top">
            <div *ngIf="isChecklistReady$ | async" class="lg-sidebar-help__top__support">
                <button (click)="showChecklist($event)" class="lg-sidebar-help__button">
                    {{ ".TrainingOverview" | lgTranslate }}
                </button>
            </div>
            <div class="lg-sidebar-help__top__support">
                <a [href]="supportLink" target="_blank" class="lg-sidebar-help__button">{{
                    ".Support" | lgTranslate
                }}</a>
            </div>
            <ul *ngIf="_standardLinks" class="lg-sidebar-help__links lg-sidebar-help__links--first">
                <li *ngFor="let link of _standardLinks" class="lg-sidebar-help__links__item">
                    <a [href]="link.url" target="_blank">
                        <lg-icon
                            [icon]="link.icon"
                            [inline]="true"
                            [class]="'lg-sidebar-help__icon'"
                        >
                        </lg-icon>
                        {{ link.name | lgTranslate }}
                    </a>
                </li>
            </ul>
            <ul *ngIf="additionalItems" class="lg-sidebar-help__links">
                <li *ngFor="let link of additionalItems" class="lg-sidebar-help__links__item">
                    <a [href]="link.url" target="_blank">
                        <lg-icon
                            *ngIf="link.icon"
                            [inline]="true"
                            [icon]="link.icon"
                            class="lg-sidebar-help__icon"
                        >
                        </lg-icon>
                        {{ link.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="lg-sidebar-help__bottom" *ngIf="!_versionTemplateRef && this._version">
            <div class="lg-sidebar-help__bottom-group">
                <div
                    class="lg-sidebar-help__bottom-group__header lg-sidebar-help__bottom-group__header--main"
                >
                    {{ ".AdditionalInformation" | lgTranslate }}
                </div>
            </div>
            <div class="lg-sidebar-help__bottom-group">
                <div class="lg-sidebar-help__bottom-group__header">
                    {{ ".Data_Version" | lgTranslate }}
                </div>
                <div class="lg-sidebar-help__bottom-group__text">
                    {{ _version.data_version_naam }}
                </div>
            </div>
            <div class="lg-sidebar-help__bottom-group">
                <div class="lg-sidebar-help__bottom-group__header">
                    {{ ".Scenario" | lgTranslate }}
                </div>
                <div class="lg-sidebar-help__bottom-group__text">{{ _version.scenario_naam }}</div>
            </div>
            <div class="lg-sidebar-help__bottom-group">
                <div class="lg-sidebar-help__bottom-group__header">
                    {{ ".Version_ID" | lgTranslate }}
                </div>
                <div class="lg-sidebar-help__bottom-group__text">{{ _displayVersion }}</div>
                <div class="lg-sidebar-help__bottom-group__invisible" *ngIf="!_internal">
                    System ID {{ _version.id }}
                </div>
            </div>
            <div class="lg-sidebar-help__bottom-group" *ngIf="_internal">
                <div class="lg-sidebar-help__bottom-group__header">System ID</div>
                <div class="lg-sidebar-help__bottom-group__text">
                    {{ _version.id }}&nbsp; {{ _version.is_test ? "test" : "" }}&nbsp;&nbsp; ({{
                        _version.ziekenhuiscode
                    }})
                </div>
            </div>
            <div class="lg-sidebar-help__bottom-group lg-sidebar-help__bottom-group--version">
                <button (click)="_showReleaseNotes()" class="lg-sidebar-help__button">
                    {{ ".Version" | lgTranslate : { version: appVersion.version } }}
                </button>
            </div>
        </div>

        <div class="lg-sidebar-help__bottom" *ngIf="_versionTemplateRef">
            <div class="lg-sidebar-help__bottom-group">
                <div
                    class="lg-sidebar-help__bottom-group__header lg-sidebar-help__bottom-group__header--main"
                >
                    {{ ".AdditionalInformation" | lgTranslate }}
                </div>
            </div>
            <ng-container *ngIf="_version">
                <ng-container
                    *ngTemplateOutlet="_versionTemplateRef; context: _context"
                ></ng-container>
            </ng-container>
            <div class="lg-sidebar-help__bottom-group lg-sidebar-help__bottom-group--version">
                <button
                    (click)="_showReleaseNotes()"
                    class="lg-sidebar-help__button"
                    [class.lg-sidebar-help__button--disabled]="!versionClickable"
                >
                    {{ ".Version" | lgTranslate : { version: appVersion.version } }}
                </button>
            </div>
        </div>

        <ng-container *ngTemplateOutlet="_extensionTemplateRef; context: _context"></ng-container>
    </div>
</div>
