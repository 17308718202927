import {
    Directive,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import { Subscription } from "rxjs";
import { LgNavigationService } from "./lg-navigation.service";
import { ProcessedNavNode } from "./navigation.types";

export class LgForRoutesContext {
    constructor(public $implicit: ProcessedNavNode) {
        // empty
    }

    get name(): string {
        return this.$implicit.data.name;
    }
}

@Directive({
    standalone: true,
    selector: "[lgForRoutes]"
})
export class LgForRoutesDirective implements OnChanges, OnDestroy {
    private _navigationService = inject(LgNavigationService);
    private _template = inject(TemplateRef<LgForRoutesContext>);
    private _viewContainer = inject(ViewContainerRef);

    @Input("lgForRoutes")
    @Input("lgForRoutesUnder")
    _routeId: string;

    private _subscription: Subscription | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        const val = changes._routeId;
        if (val && val.currentValue) {
            this._unsubscribe();

            this._subscription = this._navigationService
                .getNavigation$(val.currentValue, ".", true)
                .subscribe(nodes => this._render(nodes));
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe();
    }

    private _unsubscribe(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }

    private _render(nodes: ProcessedNavNode[]): void {
        if (this._viewContainer.length) {
            this._viewContainer.clear();
        }
        if (!nodes) return;

        for (const node of nodes) {
            this._viewContainer.createEmbeddedView(this._template, new LgForRoutesContext(node));
        }
    }
}
