import { inject, Injectable, LOCALE_ID, NgZone } from "@angular/core";
import { Router } from "@angular/router";

import { Observable, ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";

import { LgNavigationService } from "../../navigation/index";
import userflow from "userflow.js";

import {
    IUserflowConfigurationBase,
    IUserflowService,
    LG_USERFLOW_CONFIGURATION,
    LgUserflow
} from "./userflow.types";

/**
 * LgUserflowService is a service which wraps userflow.js script.
 * it is initialized with dummy object until the valid configuration is added in providers
 * in app.module.ts, f.ex:
 *
 *  {
 *      provide: LG_USERFLOW_CONFIGURATION,
 *      useClass: LgUserflowConfiguration
 *  }
 *
 * or until initialize(config: IUserflowConfigurationBase) method is called.
 * This means you don't need to worry about tool and do some extra action if you don't need userflow
 * functionality. All scripts will be loaded only after initialization of real userflow object.
 * */
@Injectable({ providedIn: "root" })
export class LgUserflowService implements IUserflowService {
    protected _configuration = inject(LG_USERFLOW_CONFIGURATION, { optional: true });
    protected _locale = inject(LOCALE_ID);
    protected _navigationService = inject(LgNavigationService);
    protected _ngZone = inject(NgZone);
    protected _router = inject(Router);

    private _userflow: LgUserflow = {
        updateUser: () => Promise.resolve(),
        group: () => Promise.resolve(),
        isIdentified: () => false,
        start: () => Promise.resolve(),
        endAll: () => Promise.resolve(),
        reset: () => undefined
    };

    private _ready = new ReplaySubject<void>(1);

    constructor() {
        this._configuration?.ready.then(() => {
            this._init(this._configuration);
        });
    }

    async initialize(config: IUserflowConfigurationBase): Promise<void> {
        if (!this._userflow.isIdentified()) {
            this._init(config);
        }
    }

    /* override default userflow.js navigation */
    private _navigate(url: string): void {
        const fullUrlRegex = /^https?:\/\//i;
        if (fullUrlRegex.test(url)) {
            window.location.href = url;
        }
        let newUrl = url;
        if (newUrl.indexOf(window.location.pathname) === 0) {
            newUrl = newUrl.substr(window.location.pathname.length);
        }
        if (newUrl.indexOf(this._navigationService.getUrlPrefix()) === 0) {
            newUrl = newUrl.substr(this._navigationService.getUrlPrefix().length);
        }

        this._ngZone.run(() => this._router.navigateByUrl(newUrl));
    }

    async showContent(contentId: string): Promise<void> {
        this._userflow.start(contentId);
    }

    async endAll(): Promise<void> {
        this._userflow.endAll();
    }

    reset(): void {
        this._userflow.reset();
    }

    async patchUserAttrs(attrs: { [key: string]: unknown }): Promise<void> {
        this._userflow.updateUser(attrs);
    }

    async setUserGroup(groupId: string, attrs: { [key: string]: unknown }): Promise<void> {
        this._userflow.group(groupId, attrs);
    }

    private async _init(configuration: IUserflowConfigurationBase): Promise<void> {
        if (configuration.envinromentKey) {
            if (configuration.inferenceClassNames) {
                userflow.setInferenceClassNameFilter(configuration.inferenceClassNames);
            }
            if (configuration.inferenceAttributeNames) {
                userflow.setInferenceAttributeNames(configuration.inferenceAttributeNames);
            }
            if (configuration.inferenceAttributesFilters) {
                Object.keys(configuration).forEach(id => {
                    userflow.setInferenceAttributeFilter(
                        id,
                        configuration.inferenceAttributesFilters[id]
                    );
                });
            }

            if (configuration.setNavigation) {
                userflow.setCustomNavigate(url => configuration.setNavigation(url));
            } else userflow.setCustomNavigate(url => this._navigate(url));

            /* userflow initialization*/
            userflow.init(configuration.envinromentKey);

            /* identify user with minimum required configuration. If usedId doesn't exist in userflow database, it will be created */
            userflow.identify(configuration.userId, {
                locale_code: this._locale
            });

            this._userflow = userflow;
            this._ready.next();
        } else this._ready.complete();
    }

    get ready$(): Observable<void> {
        return this._ready.pipe(take(1));
    }
}
