import {
    Directive,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    Input,
    isDevMode,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2
} from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Observable, Subject } from "rxjs";
import { auditTime, map, takeUntil } from "rxjs/operators";

import { toBoolean } from "@logex/framework/utilities";

import { LgObserveSizeService, LgObserveSizeType } from "../behavior";
import {
    LgScrollerService,
    ScrollbarVisibility,
    ScrollerApi,
    ScrollerType
} from "./lg-scroller.service";
import {
    ILgResizedEvent,
    ILgScrollableContainer,
    ILgScrolledEvent,
    LG_SCROLLABLE_CONTAINER
} from "./lg-scrollable-container";

@Directive({
    standalone: true,
    selector: "[lgScrollable]",
    exportAs: "lgScrollable",
    providers: [
        { provide: LG_SCROLLABLE_CONTAINER, useExisting: forwardRef(() => LgScrollableDirective) }
    ]
})
export class LgScrollableDirective implements OnInit, OnDestroy, ILgScrollableContainer {
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _resizeObserver = inject(LgObserveSizeService);
    private _scrollDispatcher = inject(ScrollDispatcher);
    private _scrollerService = inject(LgScrollerService);

    @Input() set lgScrollable(value: string) {
        this._preferBottom = value === "bottom";
    }

    get lgScrollable(): string {
        return this._preferBottom ? "bottom" : "top";
    }

    @Input("lgScrollableAutoHide") set autoHide(value: boolean | "true" | "false") {
        const newValue = toBoolean(value);
        if (newValue !== this._autoHide && this._scroller) {
            console.error("Cannot modify lgScrollableAutoHide");
            return;
        }
        this._autoHide = newValue;
    }

    get autoHide(): boolean {
        return this._autoHide;
    }

    @Input("lgScrollableWrapper") set createWrapper(value: boolean | "true" | "false") {
        value = toBoolean(value);
        if (this._scroller) {
            if (value !== this._createWrapper && isDevMode()) {
                console.warn("lgScrollable: cannot change wrapper parameter after initialization");
            }
            return;
        }
        this._createWrapper = value;
    }

    get createWrapper(): boolean {
        return this._createWrapper;
    }

    /**
     * N.B. Setting the wrapper class if the wrapper is already created will remove the marker classes that indicate scrollbar visibility.
     * Because of that it's better to use a constant value that do not change after component initialization.
     *
     * The setter cannot be used if `createWrapper` is false (`lgScrollableWrapper` attribute).
     *
     * @param value
     */
    @Input("lgScrollableWrapperClass") set wrapperClass(value: string) {
        if (!value) return;

        if (this._scroller && value !== this._wrapperClass) {
            if (this._createWrapper) {
                this._renderer.setProperty(this._wrapper, "className", value);
            } else if (isDevMode()) {
                console.log("lgScrollable: cannot set wrapper class if creation is disabled");
            }
        }
        this._wrapperClass = value;
    }

    get wrapperClass(): string {
        return this._wrapperClass;
    }

    @Input("lgScrollbarClass") set scrollbarClass(value: string) {
        if (this._scroller && value !== this._scrollbarClass) {
            this._scroller.scrollbarClass(value);
        }
        this._scrollbarClass = value;
    }

    get scrollbarClass(): string {
        return this._scrollbarClass;
    }

    @Input("lgScrollableDirection") set direction(value: ScrollerType) {
        if (this._scroller) {
            if (value !== this._direction && isDevMode()) {
                console.warn(
                    "lgScrollable: cannot change scrollable direction after initialization"
                );
            }
            return;
        }
        this._direction = value;
    }

    get direction(): ScrollerType {
        return this._direction;
    }

    @Output("lgScrollableVisibilityChange") readonly scrollableVisibilityChange =
        new EventEmitter<ScrollbarVisibility>();

    private readonly _destroyed = new Subject<void>();
    private readonly _resized = new Subject<ILgResizedEvent>();

    private _lastWidth: number;
    private _lastHeight: number;
    private _lastScrollWidth: number;
    private _lastScrollHeight: number;

    private _scroller: ScrollerApi;

    private _isAtBottom = true;
    private _preferBottom = false;
    private _autoHide = true;
    private _createWrapper = true;
    private _direction: ScrollerType = "vertical";
    private _scrollbarClass: string = null;
    private _wrapperClass = "lg-scrollable";

    private _wrapper: HTMLElement | null = null;

    ngOnInit(): void {
        this._createScrollable();
    }

    getScrollContainerInfo(): {
        position: ILgScrolledEvent;
        size: ILgResizedEvent;
    } {
        const computed = getComputedStyle(this._elementRef.nativeElement);
        const maxWidth = parseFloat(computed.maxWidth);
        const maxHeight = parseFloat(computed.maxHeight);
        const width = this._elementRef.nativeElement.clientWidth;
        const height = this._elementRef.nativeElement.clientHeight;
        return {
            position: {
                x: this._elementRef.nativeElement.scrollLeft,
                y: this._elementRef.nativeElement.scrollTop
            },
            size: {
                width,
                height,
                maxWidth,
                maxHeight
            }
        };
    }

    @HostListener("window:resize")
    _windowResize(): void {
        this._checkResize();
    }

    ngOnDestroy(): void {
        this._scrollDispatcher.deregister(this as any);

        if (this._scrollListener) {
            this._elementRef.nativeElement.removeEventListener("scroll", this._scrollListener);
        }

        this._scroller.destroy(true);

        if (this._wrapper && this._renderer.destroyNode) {
            this._renderer.destroyNode(this._wrapper);
        }

        this._destroyed.next();
        this._destroyed.complete();
        this._scroller = null;
    }

    // ---------------------------------------------------------------------------------------------
    // ILgScrollableContainer implementation
    // ---------------------------------------------------------------------------------------------
    scrolled(auditTimeInMs?: number): Observable<ILgScrolledEvent> {
        const observable = this._scroller
            .onScroll()
            .pipe(map(e => ({ x: e.horizontal.position, y: e.vertical.position })));
        if (auditTimeInMs) {
            return observable.pipe(auditTime(auditTimeInMs));
        } else {
            return observable;
        }
    }

    resized(auditTimeInMs?: number): Observable<ILgResizedEvent> {
        const observable = this._resized.asObservable();
        if (auditTimeInMs) {
            return observable.pipe(auditTime(auditTimeInMs));
        } else {
            return observable;
        }
    }

    ensureVisible(el: ElementRef, buffer?: number): void {
        if (this._scroller) {
            this._scroller.ensureVisible(el, false, buffer);
        }
    }

    scrollTo(x: number | undefined | null, y: number | undefined | null): void {
        if (this._scroller && y != null && this._direction !== "horizontal") {
            this._scroller.scrollTop(y);
        }
        if (this._scroller && x != null && this._direction !== "vertical") {
            this._scroller.scrollLeft(x);
        }
    }

    getScrollWidth(): number {
        return this._scroller.documentWidth();
    }

    getScrollHeight(): number {
        return this._scroller.documentHeight();
    }

    updateSize(): void {
        this._checkResize();
    }

    // ---------------------------------------------------------------------------------------------
    // cdkScrollable reimplementation
    // ---------------------------------------------------------------------------------------------

    private _elementScrolled: Subject<Event> = new Subject();
    private _scrollListener = (event: Event): void => this._elementScrolled.next(event);

    elementScrolled(): Observable<Event> {
        return this._elementScrolled.asObservable();
    }

    getElementRef(): ElementRef<HTMLElement> {
        return this._elementRef;
    }

    // ---------------------------------------------------------------------------------------------
    // Implementation
    // ---------------------------------------------------------------------------------------------
    private _createScrollable(): void {
        this._ngZone.onStable.pipe(takeUntil(this._destroyed)).subscribe(() => this._checkResize());

        this._renderer.addClass(this._elementRef.nativeElement, "lg-scrollable__holder");
        if (this._createWrapper) {
            this._wrapper = this._renderer.createElement("div");
            this._renderer.setProperty(this._wrapper, "className", this._wrapperClass);
            this._renderer.addClass(this._wrapper, "lg-scrollbar-is-hidden");
            this._renderer.addClass(this._wrapper, "lg-horizontal-scrollbar-is-hidden");
            this._renderer.insertBefore(
                this._renderer.parentNode(this._elementRef.nativeElement),
                this._wrapper,
                this._elementRef.nativeElement
            );

            this._renderer.appendChild(this._wrapper, this._elementRef.nativeElement);
        } else {
            this._renderer.addClass(
                this._renderer.parentNode(this._elementRef.nativeElement),
                "lg-scrollable"
            );
        }

        this._scroller = this._scrollerService.create(this._elementRef, this._direction, {
            auto: this._autoHide,
            className: this._scrollbarClass
        });

        this._scroller
            .onScroll()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => {
                this._isAtBottom = this._scroller.isAtBottom();
            });

        this._scroller
            .onScrollbarVisibilityChange()
            .pipe(takeUntil(this._destroyed))
            .subscribe(visibility => {
                this.scrollableVisibilityChange.next(visibility);

                if (this._wrapper != null) {
                    const markerClass =
                        visibility.direction === "vertical"
                            ? "lg-scrollbar-is-hidden"
                            : "lg-horizontal-scrollbar-is-hidden";
                    if (visibility.visible) {
                        this._renderer.removeClass(this._wrapper, markerClass);
                    } else {
                        this._renderer.addClass(this._wrapper, markerClass);
                    }
                }
            });

        this._ngZone.runOutsideAngular(() => {
            this._elementRef.nativeElement.addEventListener("scroll", this._scrollListener);
        });

        this._scrollDispatcher.register(this as any);

        const observerType: LgObserveSizeType =
            this._direction === "vertical"
                ? "width"
                : this._direction === "horizontal"
                  ? "height"
                  : "all";
        // in theory we could watch also the innner element to catch scrollHeight changes, but that seems to be captured well by the zone stability
        this._resizeObserver
            .observe(this._elementRef, this._renderer)
            .change({ auditTime: 0, type: observerType })
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._checkResize());
    }

    private _checkResize(): void {
        const clientWidth = this._elementRef.nativeElement.clientWidth;
        const clientHeight = this._elementRef.nativeElement.clientHeight;
        const scrollWidth = this._elementRef.nativeElement.scrollWidth;
        const scrollHeight = this._elementRef.nativeElement.scrollHeight;
        const changed =
            (this._direction !== "horizontal" &&
                (this._lastHeight !== clientHeight || this._lastScrollHeight !== scrollHeight)) ||
            (this._direction !== "vertical" &&
                (this._lastWidth !== clientWidth || this._lastScrollWidth !== scrollWidth));
        if (changed) {
            this._lastScrollWidth = scrollWidth;
            this._lastScrollHeight = scrollHeight;
            this._lastWidth = clientWidth;
            this._lastHeight = clientHeight;

            this._scroller.resize();

            if (this._isAtBottom && this._preferBottom) {
                this._scroller.scrollTop(scrollHeight);
            }

            const computed = getComputedStyle(this._elementRef.nativeElement);
            const maxWidth = parseFloat(computed.maxWidth);
            const maxHeight = parseFloat(computed.maxHeight);

            this._resized.next({
                width: clientWidth,
                height: clientHeight,
                maxWidth: isNaN(maxWidth) ? undefined : maxWidth,
                maxHeight: isNaN(maxHeight) ? undefined : maxHeight
            });
        }
    }
}
