import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export interface IErrorServiceError {
    type: string;
    shortDescription: string;
    fullDescription: string;
    time: Date;
}

@Injectable({ providedIn: "root" })
export class ErrorService {
    readonly errors: Observable<IErrorServiceError[]>;

    private _errors: BehaviorSubject<IErrorServiceError[]>;

    constructor() {
        this._errors = new BehaviorSubject<IErrorServiceError[]>([]);
        this.errors = this._errors.asObservable();
    }

    add(error: IErrorServiceError): void {
        const newErrors = this._errors.getValue().slice();
        newErrors.push(error);

        this._errors.next(newErrors);
    }

    clearAll(): void {
        this._errors.next([]);
    }
}
