import { Injectable, OnDestroy, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
    LG_APP_VERSION,
    LgUserStorageService,
    StorageBinding
} from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class LgReleaseNotesService implements OnDestroy {
    private _appVersion = inject(LG_APP_VERSION);
    private _httpClient = inject(HttpClient);
    private _userStorage = inject(LgUserStorageService);

    get version(): string {
        return this._appVersion.version;
    }

    get isNew(): Observable<boolean> {
        return this._isNew$.asObservable();
    }

    private _destroyed$ = new Subject<void>();
    private _isNew$ = new BehaviorSubject<boolean | null>(null);

    constructor() {
        this._userStorage
            .get("/global", StorageBinding.Hospital)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                this._isNew$.next(data.version !== this.version);
            });
    }

    markAsRead(): void {
        this._userStorage
            .get("/global", StorageBinding.Hospital)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(data => {
                data.version = this.version;
            });
        this._isNew$.next(false);
    }

    getReleaseNotes(): Observable<string> {
        return this._httpClient.get("dist/app/releaseNotes.html", { responseType: "text" });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._isNew$.complete();
    }
}
