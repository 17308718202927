import { isArray } from "lodash";
import {
    Component,
    AfterViewInit,
    ViewChild,
    ElementRef,
    InjectionToken,
    ChangeDetectionStrategy,
    inject
} from "@angular/core";
import { Subject, Observable, Subscription } from "rxjs";
import { catchError, first } from "rxjs/operators";

import { LG_MESSAGE_BUS_SERVICE } from "@logex/framework/lg-application";

import { LgDownloadService } from "./lg-download.service";
import { LgConsole } from "@logex/framework/core";

export const DOWNLOAD_PARAMS = new InjectionToken<{}>("DOWNLOAD_PARAMS");

// TODO: Add encodeURI and GET option
export interface IDownloadReportArgs {
    url: string;
    params: Record<string, any>;
}

interface ParamDetail {
    key: string;
    isArray: boolean;
}

@Component({
    selector: "lg-download-form",
    templateUrl: "./lg-download-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgDownloadFormComponent implements AfterViewInit {
    protected _download = inject(LgDownloadService);
    private _console = inject(LgConsole);
    private _downloadParams = inject<IDownloadReportArgs>(DOWNLOAD_PARAMS);
    private _messageBusService = inject(LG_MESSAGE_BUS_SERVICE);

    @ViewChild("downloadForm", { static: true }) downloadForm!: ElementRef<HTMLFormElement>;

    _url: string;
    _params: any;
    _paramsKeys: ParamDetail[] = [];
    private readonly _done$ = new Subject<void>();
    private _downloadSub: Subscription | null = null;

    constructor() {
        this._url = this._downloadParams.url.trim().replace(/\/$/, "");

        this._params = this._downloadParams.params;
        const keys = Object.keys(this._params);

        this._paramsKeys = keys.reduce((result, key) => {
            result.push({
                key,
                isArray: isArray(this._params[key])
            });
            return result;
        }, [] as ParamDetail[]);
    }

    ngAfterViewInit(): void {
        // TODO: Upgrade all tools to use the new message bus and remove this waiting. Message bus is not reliable
        // enough, so we should not wait for the connection to be established - it is possible that it will never
        // happen. Exactly this happens with some UK clients (because of yet unknown reasons). For now we worked
        // that around with emitting true/false in connectionEstablished$ observer, but this is just
        // a temporary measure.
        this._messageBusService.connectionEstablished$.pipe(first()).subscribe(() => {
            if (/^https?:\/\//i.test(this._url)) {
                this._downloadSub = this._download
                    .do(this._url, this._params)
                    .pipe(
                        catchError(error => {
                            this._console.error("ExportFailed", error);
                            this._done$.next();
                            this._done$.complete();

                            return error;
                        })
                    )
                    .subscribe(() => {
                        this._done$.next();
                        this._done$.complete();
                    });
            } else {
                this._messageBusService.on("OnExportFinished", (doneUrl: string) => {
                    if (this._url.endsWith(doneUrl)) {
                        this._done$.next();
                        this._done$.complete();
                    }
                });

                this._messageBusService.on("OnExportFailed", (doneUrl: string, error: any) => {
                    if (this._url.endsWith(doneUrl)) {
                        this._console.error("ExportFailed", error);
                        this._done$.next();
                        this._done$.complete();
                    }
                });

                this.downloadForm.nativeElement.submit();
            }
        });
    }

    onDone(): Observable<void> {
        return this._done$.asObservable();
    }

    cancel(): void {
        this._downloadSub?.unsubscribe();
        this._done$.next();
        this._done$.complete();
    }
}
