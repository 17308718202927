import _ from "lodash";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { IStringLookup } from "@logex/framework/types";
import { LgConsole } from "@logex/framework/core";

import { KeyValuePair, SettingKey, UserSettingsService } from "./user-settings.service";
import {
    IStoreIdentifier,
    IUserStorageForSync,
    IUserStorageGetResponse,
    IUserStorageServiceGateway,
    StorageBinding
} from "./lg-user-storage.types";
import { LG_APP_SESSION } from "../application/app-session";

@Injectable({ providedIn: "root" })
export class LgUserStorageGatewayV2 implements IUserStorageServiceGateway {
    private _lgConsole = inject(LgConsole);
    private _session = inject(LG_APP_SESSION);
    private _userSettingsService = inject(UserSettingsService);

    get(keysToLoad: IStoreIdentifier[]): Observable<IUserStorageGetResponse> {
        this._lgConsole.debug("LgUserStorageGatewayV2.get", keysToLoad);

        return this._userSettingsService
            .get(
                ..._.map(keysToLoad, (x: IStoreIdentifier) => this._toSettingKey(x.key, x.binding))
            )
            .pipe(
                map(data =>
                    _.reduce(
                        data,
                        (a, x) => {
                            a.list[x.key.storageId] = x.value;
                            return a;
                        },
                        { list: {} } as IUserStorageGetResponse
                    )
                )
            );
    }

    set(toSync: IStringLookup<IUserStorageForSync>): Observable<any> {
        this._lgConsole.debug("LgUserStorageGatewayV2.set", toSync);

        const data: KeyValuePair[] = _.map(toSync, (x, name) => ({
            key: this._toSettingKey(name, x.binding),
            value: x.data
        }));
        return this._userSettingsService.set(...data).pipe(
            map(() => ({
                ok: true
            }))
        );
    }

    private _toSettingKey(name: string, binding: StorageBinding): SettingKey {
        const key: SettingKey = {
            storageId: name
        };

        if (binding === StorageBinding.Version) {
            key.clientId = this._session.clientId;
        }

        if (binding === StorageBinding.Version && this._session.scenarioId) {
            key.scenarioId = this._session.scenarioId;
        }

        if (binding === StorageBinding.Hospital) {
            key.clientId = this._session.clientId;
        }

        return key;
    }
}
