import { Observable } from "rxjs";
import {
    ApplicationInsights,
    ICustomProperties,
    ITelemetryItem
} from "@microsoft/applicationinsights-web";
import { InjectionToken } from "@angular/core";

export const LG_APPLICATION_INSIGHTS_CONFIGURATION = new InjectionToken<
    | IApplicationInsightsPostponeConfiguration
    | IApplicationInsightsConfiguration
    | Promise<IApplicationInsightsConfiguration>
>("lgApplicationInsightsConfiguration");

export interface IApplicationInsightsPostponeConfiguration {
    autoInit: false;
}

export const LG_POSTPONE_APLICATION_INSIGHTS_CONFIGURATION: IApplicationInsightsPostponeConfiguration =
    {
        autoInit: false
    };

/**
 * Configuration for the framework implementation of ApplicationInsights tracker.
 * For details see also https://github.com/microsoft/ApplicationInsights-JS
 */
// TODO: should we consider supporting all of the native parameters, for special situations?
export interface IApplicationInsightsConfiguration {
    /**
     * Specify, whether AI should be auto-initialized on first page change. When false,
     * you'll have to call its init method yourself.
     *
     * @see LG_POSTPONE_APLICATION_INSIGHTS_CONFIGURATION
     */
    autoInit: boolean;

    /**
     * Callback determining, if ApplicationInsights should be disabled. This can serve f.ex to disable it when running
     * on localhost
     *
     * @return  {boolean} [return description]
     */
    doNotDoAiTracking?(): boolean;

    /**
     * Appinsights instrumentation key
     */
    instrumentationKey: string;

    /**
     * Specifies, whether AI cors correlation should be enabled. Defaults to true
     */
    enableCorsCorrelation?: boolean;

    /**
     * Specifies, whether correlation headers should be disabled. Defaults to false. You might
     * want to disable it in case your backend is unable to accept them in its CORS setting
     */
    disableCorrelationHeaders?: boolean;

    /***
     * Specifies list of domains, for which correlation headers should be skipped. You might
     * want to use this for 3rd party apis with strict CORS settings. When using Auth0, this
     * is required!
     */
    correlationHeaderExcludedDomains?: string[];

    /**
     * Specifies list of domains, for which correlation headers should be sent.
     */
    correlationHeaderDomains?: string[];

    /**
     * ID of the user (this will be sent as setAuthenticatedUserContext)
     */
    userId?: string | null | Observable<string | null>;

    /**
     * List of telemetry initializers that will be registered with AI. For advanced use only
     */
    telemetryInitializers?: Array<(item: ITelemetryItem) => boolean | void>;

    /**
     * Custom data, or callback returning them. When specified, these will be inserted to the data
     * field of ITelemetryItem, as if you've defined your own initializer.
     *
     * @return  {<ICustomProperties>}[return description]
     */
    customData?: ICustomProperties | (() => ICustomProperties);

    /**
     * Callback called right after appinsights is initialized. Use in case you need to interact
     * with the api directly.
     */
    finishInitialization?: (appInsights: ApplicationInsights) => void;

    /**
     * If you want to use autoInit, and the configuration is a class, and you cannot guarantee that it's
     * been fully initialized before first navigation; you can indicate the readiness using this property.
     * Note that such situation may lead to potentially missing some events; preferably you'll not
     * need it.
     */
    ready?: Promise<void>;
}

export const enum ApplicationInsightsCustomData {
    ApplicationInstance = "applicationInstance",
    ClientId = "clientId",
    ClientCode = "clientCode",
    Environment = "environment",
    Localization = "localization",
    Registry = "registry",
    ScenarioId = "scenarioId"
}
