<ng-container *ngIf="showPanelWithoutSlideout; then filtersSlideout"></ng-container>

<ng-template #filtersSlideoutTabButton>
    <button class="button button--primary lg-filters-panel__header__button" (click)="_hide()">
        {{ ".Filters" | lgTranslate }}
        <span *ngIf="_totalCount > 0" class="lg-filters-panel__header__button__circle">
            {{ _totalCount }}
        </span>
    </button>
</ng-template>

<ng-template #filtersSlideoutHeaderIcons>
    <lg-icon
        [ngClass]="_isAnyFilterActive ? 'lg-icon-menu' : ''"
        icon="icon-erase"
        [type]="_isAnyFilterActive ? 'regular' : 'disabled'"
        (click)="_clearAll()"
    >
    </lg-icon>
    <lg-icon-menu
        *ngIf="!disableViewCustomization"
        class="lg-icon-menu__default"
        [definition]="_viewMenuDefinition"
        [current]="_viewMode"
        (currentChange)="_setViewMode($event)"
        [icon]="null"
    ></lg-icon-menu>
    <lg-bookmarks-menu
        *ngIf="!disableBookmarks && _visibleFilterSet != null"
        [filterSet]="_visibleFilterSet"
        [filterSetStateKey]="_filterSetStateKey"
    >
    </lg-bookmarks-menu>
</ng-template>

<ng-template #filtersSlideout>
    <lg-slideout-holder-header *ngIf="showPanelWithoutSlideout">
        <ng-template [ngTemplateOutlet]="filtersSlideoutHeaderIcons"></ng-template>
    </lg-slideout-holder-header>
    <div
        *ngIf="!_visibleFilterSet || !_filterSetStateKey"
        [lgLoaderOverlay]="true"
        [style.width.%]="100"
        [style.height.%]="100"
    ></div>

    <ng-container *ngIf="_visibleFilterSet && _filterSetStateKey">
        <div class="lg-filters-panel__search" *ngIf="!disableSearch">
            <lg-icon icon="icon-search"></lg-icon>
            <input
                type="text"
                [ngModel]="_quicksearch"
                (ngModelChange)="_updateSearch($event)"
                [placeholder]="'.Search' | lgTranslate"
                lgSelectOnFocus
                class="input input--no-highlight input--no-border input--no-shadow"
                (keydown)="_searchKeyDown($event)"
            />
            <lg-icon
                icon="icon-close"
                (click)="_clearSearch($event)"
                *ngIf="_quicksearch"
                [clickable]="true"
            ></lg-icon>
        </div>

        <div
            class="lg-filters-panel__body flexcol"
            [class.lg-filters-panel__body--with-search]="!disableSearch"
        >
            <div
                lgScrollable
                [lgScrollableWrapper]="false"
                class="flex-stretch"
                #scroller="lgScrollable"
                #scrollerElement
            >
                <div
                    class="lg-filters-panel__group"
                    *ngFor="
                        let group of _groups;
                        let first = first;
                        let last = last;
                        trackBy: _groupIdentity
                    "
                    [class.lg-filters-panel__group--empty]="!group.filters?.length"
                    [ngClass]="{ hasTitle: group.name, first: first, last: last }"
                >
                    <div
                        class="lg-filters-panel__group__title--wrapper"
                        [class.lg-filters-panel__group__title--wrapper_first]="first"
                        [class.lg-filters-panel__group__title--wrapper_empty]="!group.name"
                    >
                        <div
                            class="lg-filters-panel__group__title"
                            *ngIf="group.name"
                            (click)="_toggleGroup(group)"
                            [class.lg-filters-panel__group__title--expanded]="
                                _expanded[group.index]
                            "
                        >
                            {{ group.name }}
                            <span
                                class="lg-filters-panel__group__title__count"
                                *ngIf="_counts[group.index]"
                                >{{ _counts[group.index] }}</span
                            >
                            <lg-icon
                                icon="icon-arrow-down"
                                class="lg-filters-panel__group__title__icon"
                                [class.lg-filters-panel__group__title__icon--expanded]="
                                    _expanded[group.index]
                                "
                            ></lg-icon>
                        </div>
                    </div>
                    <ng-container *ngIf="!group.name || _expanded[group.index]">
                        <div
                            class="lg-filters-panel__row"
                            *ngFor="let entry of group.filters; trackBy: _entryIdentity"
                            [attr.lg-filter-id]="entry.id"
                        >
                            <lg-filterset-list-item
                                [definition]="entry"
                                [list]="_visibleFilterSet"
                                (filterChange)="_filterChanged(entry)"
                            ></lg-filterset-list-item>
                        </div>
                    </ng-container>
                </div>
                <div class="lg-filters-panel__empty" *ngIf="_emptySearch">
                    {{ ".No_matching_filter" | lgTranslate }}
                    <a href="#" (click)="_clearSearch($event)">
                        {{ ".Clear_search" | lgTranslate }}
                    </a>
                </div>
            </div>
        </div>

        <ng-container *ngTemplateOutlet="_customFooterTemplate"></ng-container>
    </ng-container>
</ng-template>
