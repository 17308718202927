import _ from "lodash";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from "@angular/core";
import { ConnectedOverlayPositionChange, ConnectedPosition } from "@angular/cdk/overlay";
import { Observable, Subject } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgScrollableDirective } from "../../scrolling";

import {
    IQuickSettingsIconDefinition,
    IQuickSettingsMenuItem,
    IQuickSettingsMenuRadio,
    IQuickSettingsMenuRadioOption,
    IQuickSettingsMenuRegularItem,
    IQuickSettingsSubmenu,
    QuickSettingsMenuItemId,
    QuickSettingsMenuType
} from "./lg-quick-settings-menu.types";
import { LgQuickSettingsMenuHost } from "./lg-quick-settings-menu-host";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { LgTooltipDirective } from "../../lg-tooltip";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

interface IFlatGroup {
    name?: string | undefined;
    help?: string | undefined;
    items: Array<IFlatItem | "separator">;
}

interface IFlatItem {
    icon: string | undefined;
    name: string;
    help: string | undefined;
    definition: IQuickSettingsMenuItem;
    option?: IQuickSettingsMenuRadioOption;
    isSeparator?: boolean;
    isSubmenu?: boolean;
    index: number;
    rightIcons: IQuickSettingsIconDefinition[];
    context?: any;
}

export interface IQuickSettingsMenuPopupOptions {
    definition: IQuickSettingsMenuItem[];
    iconDefinitions?: IQuickSettingsIconDefinition[];
    alwaysLeftIcon?: boolean;
    depth?: number;
    value?: any;
    menuContext?: any;
    compact?: boolean | null; // use null for "auto"
    selectedItems?: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[];
    matchWidth?: boolean;
    startWithCursor?: boolean;
    hideTooltipIcons?: boolean;

    onKeyboardClose?(): void;

    canCancel?(): boolean;
}

interface IPopupInitialization extends IQuickSettingsMenuPopupOptions {
    target: ElementRef;
    translateService: LgTranslateService;
}

@Component({
    standalone: true,
    selector: "lg-quick-settings-menu-popup",
    imports: [NgIf, NgClass, NgForOf, LgScrollableDirective, LgTooltipDirective, LgIconComponent],
    templateUrl: "./lg-quick-settings-menu-popup.component.html"
})
export class LgQuickSettingsMenuPopupComponent
    extends LgQuickSettingsMenuHost
    implements OnInit, OnDestroy
{
    private _changeDetector = inject(ChangeDetectorRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);

    @HostBinding("class") _className: string;

    @ViewChild("content", { static: true }) _content: ElementRef<HTMLElement>;
    @ViewChild("scrollable", { read: LgScrollableDirective, static: true })
    _scrollable: LgScrollableDirective;

    @HostBinding("style.width.px") public _width: number | undefined;

    _isTop = true;
    _shownAbove = false;
    _alwaysLeftIcon = false;
    _hasLeftIcons = false;
    _hasRightIcons = false;
    _numberOfRightIcons: number;
    _popupGroups: IFlatGroup[];
    _cursorIndex: number | null = null;
    _pathIndex: number | null = null;
    _expandedItem: IFlatItem | null = null;
    _hideTooltipIcons = false;
    _menuContext: any;

    private _depth = 0;
    private _shownOnRight = false;
    private _compact: boolean;
    private _onKeyboardClose: null | (() => void);
    private _selectedItems: null | IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[] =
        null;

    private _itemCount: number;
    private _itemLookup: _.Dictionary<IFlatItem>;
    private _deepest = true;
    private _value: any;
    private _result$ = new Subject<any>();
    private _keyUnlisten: () => void;
    private _iconDefinitions: IQuickSettingsIconDefinition[];

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    // ----------------------------------------------------------------------------------
    initialize(initObject: IPopupInitialization): Observable<any> {
        this._updateClassName();

        this._iconDefinitions = initObject.iconDefinitions;
        this._compact = initObject.compact === undefined ? false : initObject.compact;
        this._depth = initObject.depth || 0;
        this._onKeyboardClose = initObject.onKeyboardClose;
        this._translateService = initObject.translateService;
        this._value = initObject.value;
        this._menuContext = initObject.menuContext;
        this._alwaysLeftIcon = initObject.alwaysLeftIcon || false;
        this._hideTooltipIcons = initObject.hideTooltipIcons || false;
        this._onDefinitionChanged(initObject.definition, initObject.selectedItems);
        this._numberOfRightIcons = _.max(
            _.flatten(this._popupGroups.map(x => x.items)).map(x => {
                if (x === "separator") return 0;
                return x.rightIcons ? x.rightIcons.length : 0;
            })
        );
        if (initObject.startWithCursor && this._itemCount > 0) this._cursorIndex = 0;

        if (initObject.matchWidth) {
            this._width = initObject.target.nativeElement.getBoundingClientRect().width;
        }

        return this._result$.asObservable();
    }

    updateDefinition(
        definition: IQuickSettingsMenuItem[],
        selectedItems: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[]
    ): void {
        this._onDefinitionChanged(definition, selectedItems);
    }

    private _onDefinitionChanged(
        definition: IQuickSettingsMenuItem[],
        selectedItems: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[]
    ): void {
        this._selectedItems = selectedItems;
        const { groups, hasLeftIcon, hasRightIcon, path } = this._convertDefinition(
            definition,
            selectedItems
        );
        this._popupGroups = groups;
        this._hasLeftIcons = hasLeftIcon;
        this._hasRightIcons = hasRightIcon;
        this._pathIndex = path;

        if (this._compact === null) {
            const length = _.reduce(
                groups,
                (count, group) => {
                    if (group.name) count += 1;
                    return count + group.items.length;
                },
                0
            );
            this._compact = length >= 12;
        }
    }

    // ----------------------------------------------------------------------------------
    ngOnInit(): void {
        this._ngZone.runOutsideAngular(() => {
            this._keyUnlisten = this._renderer.listen(document, "keydown", e => this._keyDown(e));
        });
    }

    // ----------------------------------------------------------------------------------
    ngOnDestroy(): void {
        if (this._keyUnlisten) {
            this._keyUnlisten();
            this._keyUnlisten = null;
        }
        super._onDestroy();
    }

    // ----------------------------------------------------------------------------------
    _updatePosition(position: ConnectedOverlayPositionChange): void {
        this._shownOnRight = position.connectionPair.overlayX === "start";
        this._shownAbove = position.connectionPair.overlayY === "bottom";

        this._updateClassName();

        this._ngZone.run(() => {
            // empty
        });
    }

    // ----------------------------------------------------------------------------------
    _itemClick(item: IFlatItem, fromKeyboard?: boolean): boolean {
        let close = true;
        switch (item.definition.type) {
            case QuickSettingsMenuType.Checkbox:
                this._setValue(item.definition.valuePath, !item.icon);
                break;
            case QuickSettingsMenuType.Radio:
                if (item.icon) return false;
                this._setValue(item.definition.valuePath, item.option!.value);
                break;
            case QuickSettingsMenuType.Choice:
                item.definition.onClick(this._menuContext, item.definition);
                break;
            case QuickSettingsMenuType.Submenu:
                close = false;
                if (item !== this._expandedItem) {
                    const target = this._getCurrentElement();
                    if (target) this._showChild(new ElementRef(target), item, fromKeyboard);
                }
                break;
        }
        if (close) {
            this._result$.next(this._value);
        }
        return false;
    }

    // ----------------------------------------------------------------------------------
    _hoverItem(event: MouseEvent, item: IFlatItem): void {
        if (item.isSubmenu) {
            this._showChild(new ElementRef(event.currentTarget), item, false);
        } else {
            this._hideSettingsPopup();
        }
    }

    // ----------------------------------------------------------------------------------
    private _updateClassName(): void {
        const result = [
            "lg-quick-settings-menu-popup",
            this._compact ? "lg-quick-settings-menu-popup--compact" : "",
            this._shownAbove
                ? "lg-quick-settings-menu-popup--top"
                : "lg-quick-settings-menu-popup--bottom"
        ];

        this._className = result.join(" ");
        this._changeDetector.markForCheck();
    }

    // ----------------------------------------------------------------------------------
    private _convertDefinition(
        definition: IQuickSettingsMenuItem[],
        selectedItems: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[]
    ): { groups: IFlatGroup[]; hasLeftIcon: boolean; hasRightIcon: boolean; path: number | null } {
        const groups: IFlatGroup[] = [];
        let currentGroup: IFlatGroup | null = null;
        let hasLeftIcon = false;
        let hasRightIcon = false;
        let path: number | null = null;
        this._itemCount = 0;
        this._itemLookup = {};

        const loop = (levelDefinition: IQuickSettingsMenuItem[], nested: boolean): void => {
            for (const entry of levelDefinition) {
                if (
                    entry.type !== QuickSettingsMenuType.Separator &&
                    entry.visible &&
                    !entry.visible(this._menuContext)
                ) {
                    continue;
                }

                if (
                    !currentGroup &&
                    entry.type !== QuickSettingsMenuType.Group &&
                    entry.type !== QuickSettingsMenuType.Radio
                ) {
                    currentGroup = {
                        name: undefined,
                        help: undefined,
                        items: []
                    };
                    groups.push(currentGroup);
                }

                let item: IFlatItem = null;

                const equal = (
                    a: IQuickSettingsMenuRegularItem | QuickSettingsMenuItemId,
                    b: IQuickSettingsMenuRegularItem
                ): boolean => {
                    return typeof a === "object" ? a === b : a === b.id;
                };

                switch (entry.type) {
                    case QuickSettingsMenuType.Group:
                        if (nested)
                            throw new Error("Invalid definition: group nesting not supported");
                        currentGroup = {
                            name: entry.nameLC
                                ? this._translateService.translate(entry.nameLC)
                                : entry.name,
                            help: entry.helpLC
                                ? this._translateService.translate(entry.helpLC)
                                : entry.help,
                            items: []
                        };
                        if (currentGroup.help) hasRightIcon = true;

                        groups.push(currentGroup);
                        loop(entry.children, true);
                        currentGroup = null;
                        break;

                    case QuickSettingsMenuType.Checkbox:
                        currentGroup.items.push(
                            (item = {
                                name: entry.nameLC
                                    ? this._translateService.translate(entry.nameLC)
                                    : entry.name,
                                help: entry.helpLC
                                    ? this._translateService.translate(entry.helpLC)
                                    : entry.help,
                                definition: entry,
                                icon: this._getValue(entry.valuePath) ? "icon-check" : "icon-empty",
                                index: this._itemCount++,
                                rightIcons: []
                            })
                        );
                        hasLeftIcon = true;
                        break;

                    case QuickSettingsMenuType.Choice:
                        currentGroup.items.push(
                            (item = {
                                name: entry.nameLC
                                    ? this._translateService.translate(entry.nameLC)
                                    : entry.name,
                                help: entry.helpLC
                                    ? this._translateService.translate(entry.helpLC)
                                    : entry.help,
                                definition: entry,
                                icon: entry.icon,
                                index: this._itemCount++,
                                rightIcons: entry.rightIcons
                                    ? entry.rightIcons.map(icon =>
                                          this._iconDefinitions.find(def => def.icon === icon)
                                      )
                                    : [],
                                context: entry.context
                            })
                        );
                        hasRightIcon =
                            hasRightIcon ||
                            currentGroup.items.some(
                                i => typeof i === "object" && i.rightIcons.length
                            );
                        if (selectedItems && selectedItems.length && equal(selectedItems[0], entry))
                            path = item.index;
                        break;

                    case QuickSettingsMenuType.Submenu:
                        currentGroup.items.push(
                            (item = {
                                name: entry.nameLC
                                    ? this._translateService.translate(entry.nameLC)
                                    : entry.name,
                                isSubmenu: true,
                                help: undefined,
                                definition: entry,
                                icon: null,
                                index: this._itemCount++,
                                rightIcons: []
                            })
                        );
                        hasRightIcon = true;
                        if (selectedItems && selectedItems.length && equal(selectedItems[0], entry))
                            path = item.index;
                        break;

                    case QuickSettingsMenuType.Radio:
                        if (nested)
                            throw new Error(
                                "Invalid definition: radios inside group nesting not supported"
                            );
                        const { group: radioGroup, hasHelp: radioHasHelp } =
                            this._convertRadio(entry);
                        groups.push(radioGroup);
                        currentGroup = null;
                        hasLeftIcon = true;
                        hasRightIcon = hasRightIcon || radioHasHelp;
                        break;

                    case QuickSettingsMenuType.Separator:
                        currentGroup.items.push("separator");
                        break;

                    default:
                        throw new Error("Unknown definition type " + (entry as any).type);
                }

                if (item) {
                    hasLeftIcon = hasLeftIcon || !!item.icon;
                    hasRightIcon = hasRightIcon || !!item.help;
                    this._itemLookup[item.index] = item;
                }
            }
        };

        loop(definition, false);
        return { groups, hasLeftIcon, hasRightIcon, path };
    }

    // ----------------------------------------------------------------------------------
    private _convertRadio(definition: IQuickSettingsMenuRadio): {
        group: IFlatGroup;
        hasHelp: boolean;
    } {
        const group: IFlatGroup = {
            name: definition.nameLC
                ? this._translateService.translate(definition.nameLC)
                : definition.name,
            help: definition.helpLC
                ? this._translateService.translate(definition.helpLC)
                : definition.help,
            items: []
        };
        let hasHelp = !!group.help;
        const value = this._getValue(definition.valuePath);

        const options =
            typeof definition.options === "function" ? definition.options() : definition.options;
        for (const option of options) {
            const item: IFlatItem = {
                name: option.nameLC ? this._translateService.translate(option.nameLC) : option.name,
                help: option.helpLC ? this._translateService.translate(option.helpLC) : option.help,
                definition,
                option,
                icon: option.value === value ? "icon-check" : "icon-empty",
                index: this._itemCount++,
                rightIcons: []
            };
            group.items.push(item);
            this._itemLookup[item.index] = item;
            hasHelp = hasHelp || !!item.help;
        }

        return { group, hasHelp };
    }

    // ----------------------------------------------------------------------------------
    private _getValue(valuePath: string): any {
        if (!valuePath) return this._value;
        if (this._value == null) return undefined;

        const path = valuePath.split(".").map(e => e.trim());
        let value = this._value;
        for (const segment of path) {
            value = value[segment];
            if (value == null) return undefined;
        }
        return value;
    }

    // ----------------------------------------------------------------------------------
    private _setValue(valuePath: string, newValue: any): void {
        if (!valuePath) {
            this._value = newValue;
            return;
        }

        if (typeof this._value !== "object") {
            this._value = {};
        }

        const path = valuePath.split(".").map(e => e.trim());
        let value = this._value;
        for (let i = 0; i < path.length - 1; ++i) {
            const segment = path[i];
            if (typeof value[segment] !== "object") {
                value[segment] = {};
            }
            value = value[segment];
        }
        value[path[path.length - 1]] = newValue;
    }

    // ----------------------------------------------------------------------------------
    private _keyDown(event: KeyboardEvent): boolean {
        if (this._itemCount && this._deepest && this._isTop) {
            const keyCode = event.keyCode;
            let update = false;

            if (keyCode === 27 || (this._depth > 0 && keyCode === 37)) {
                // esc cancels, arrow left closes submenu
                if (this._onKeyboardClose) this._onKeyboardClose();
                this._result$.complete();
                this._ngZone.run(() => undefined);
                event.stopImmediatePropagation();
                event.preventDefault();
                return false;
            }

            // First keyboard touch, choose index
            if (
                this._cursorIndex === null &&
                (keyCode === 38 ||
                    keyCode === 40 ||
                    keyCode === 36 ||
                    keyCode === 35 ||
                    keyCode === 13 ||
                    keyCode === 32)
            ) {
                this._cursorIndex = 0;
                // If this was keyboard up or down or enter, do not do anything else
                if (keyCode === 38 || keyCode === 40 || keyCode === 13 || keyCode === 32) {
                    this._ngZone.run(() => undefined);
                    this._ensureVisible();
                    return false;
                }
            }
            if (keyCode === 38 && this._cursorIndex > 0) {
                --this._cursorIndex;
                update = true;
            } else if (keyCode === 40) {
                if (this._cursorIndex < this._itemCount - 1) {
                    ++this._cursorIndex;
                }
                update = true;
            } else if (keyCode === 36) {
                this._cursorIndex = 0;
                update = true;
            } else if (keyCode === 35) {
                this._cursorIndex = this._itemCount - 1;
                update = true;
            } else if (keyCode === 13 || keyCode === 32) {
                this._itemClick(this._itemLookup[this._cursorIndex], true);
                update = true;
            } else if (keyCode === 39) {
                // right expands only submenu
                const item = this._itemLookup[this._cursorIndex];
                if (item && item.isSubmenu) {
                    this._itemClick(item, true);
                    update = true;
                }
            } else {
                return true;
            }

            if (update) {
                this._ngZone.run(() => undefined);
            }
            this._ensureVisible();
            return false;
        }
        return true;
    }

    // ----------------------------------------------------------------------------------
    private _ensureVisible(): void {
        Promise.resolve().then(() => {
            if (this._cursorIndex === 0) {
                this._scrollable.scrollTo(null, 0);
            } else if (this._cursorIndex === this._itemCount - 1) {
                this._scrollable.scrollTo(null, this._scrollable.getScrollHeight());
            } else {
                const target = this._getCurrentElement();
                if (target) this._scrollable.ensureVisible(new ElementRef(target));
            }
        });
    }

    private _getCurrentElement(): Element {
        return this._content.nativeElement.querySelector(
            ".lg-quick-settings-menu-popup__item--cursor"
        );
    }

    // ----------------------------------------------------------------------------------
    _showChild(element: ElementRef, item: IFlatItem, fromKeyboard: boolean): void {
        if (this._expandedItem) {
            if (this._expandedItem === item) return;
            this._hideSettingsPopup();
        }

        this._expandedItem = item;

        const positions: ConnectedPosition[] = [
            {
                originX: "end",
                originY: "top",
                overlayX: "start",
                overlayY: "top",
                offsetY: -8
            },
            {
                originX: "end",
                originY: "bottom",
                overlayX: "start",
                overlayY: "bottom",
                offsetY: 8
            },
            {
                originX: "start",
                originY: "top",
                overlayX: "end",
                overlayY: "top",
                offsetY: -8
            },
            {
                originX: "start",
                originY: "bottom",
                overlayX: "end",
                overlayY: "bottom",
                offsetY: 8
            }
        ];

        // try to follow the direction in which the current was open, unless it's the first level: then go always right
        const firstSet = this._shownOnRight || this._depth === 0 ? 0 : 2;
        const secondSet = (firstSet + 2) % 4;

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(element)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withDefaultOffsetY(positions[firstSet + 0].offsetY)
            .withPositions([
                positions[firstSet + 0],
                positions[firstSet + 1],
                positions[secondSet + 0],
                positions[secondSet + 1]
            ]);

        super
            ._showSettingsPopup(element, strategy, false, {
                definition: (item.definition as IQuickSettingsSubmenu).children,
                value: _.cloneDeep(this._value),
                depth: this._depth + 1,
                compact: this._compact,
                alwaysLeftIcon: this._alwaysLeftIcon,
                hideTooltipIcons: this._hideTooltipIcons,
                selectedItems:
                    item.index === this._pathIndex && this._selectedItems
                        ? this._selectedItems.slice(1)
                        : null,
                onKeyboardClose: () => (this._cursorIndex = this._expandedItem.index),
                startWithCursor: fromKeyboard
            })
            .subscribe(
                result => {
                    this._hideSettingsPopup();
                    this._value = result;
                    this._result$.next(result);
                },
                null,
                () => this._hideSettingsPopup()
            );

        this._deepest = false;
    }

    protected override _hideSettingsPopup(): void {
        if (this._deepest) return;

        this._deepest = true;
        this._expandedItem = null;
        super._hideSettingsPopup();
        this._changeDetector.markForCheck();
    }
}
