import _ from "lodash";
import { Injectable, inject } from "@angular/core";

import { LgDialogService, LgFormatTypePipe, LgDialogRef } from "@logex/framework/ui-core";
import * as types from "@logex/framework/types";
import { INormalizedLogexPivotDefinition } from "@logex/framework/lg-pivot";

// import { atNextFrame } from "@logex/framework/utilities";
// Chrome fails to update the dialog otherwise
function atNextFrame(callback: Function): void {
    setTimeout(callback, 0);
}

import { LgExcelBase64Service } from "./lg-excel-base64";
import { LgExcelExportDialogComponent } from "./lg-excel-export-dialog.component";
import {
    IArraySaveOptions,
    IColumnExportColors,
    IColumnExportDefinition,
    IExportStyle,
    IFilterExportDefinition,
    IMultiPartDefinition,
    IMultiSaveOptions,
    IOnDoneCallback,
    IOnProgressCallback,
    IOnSaveEndCallback,
    IOnSaveErrorCallback,
    IOnSaveProgressCallback,
    IPivotSaveOptions,
    IRowExportParameters,
    IRowFunctionCallback,
    LogexXlsxApi,
    IColumnExportColorCallback
} from "./lg-excel.types";
import { IXlsxCell, IXlsxFile, IXlsxRow, IXlsxWorksheet } from "./xlsx.types";

import xlsx from "./xlsx";
import { getCurrencyMetadata, LG_CURRENCY_CODE, LgCurrencyMetadata } from "@logex/framework/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { triggerBlobFileDownload } from "@logex/framework/utilities";

/*
 * Todo:
 * - per column bold specification
 * - italic specification (styles / per column)
 * - consider making style structured
 * - consider adding 1st column width specification
 * -	consider adding more style per-row (the low level xlsx code)
 * -	look into configuring the automatic calculations
 */

type IColorFunction = (locals: any, style: IExportStyle) => string;

interface IColumnColorFunctions {
    odd: IColorFunction;
    even: IColorFunction;
    totals: IColorFunction;
    header: IColorFunction;
    headerGroup: IColorFunction;
}

interface IColumnExportDefinitionNormalized extends IColumnExportDefinition {
    colorFns?: IColumnColorFunctions;
    bgColorFns?: IColumnColorFunctions;
}

interface IColumnExportDefinitionArray extends Array<IColumnExportDefinitionNormalized> {
    $prepared?: boolean;
    $nameGroups?: boolean;
}

let self: LgExcelFactoryService;

const parsedAccessors: types.IStringLookup<any[]> = {};

@Injectable()
export class LgExcelFactoryService {
    private _currencyCode = inject(LG_CURRENCY_CODE);
    private _base64 = inject(LgExcelBase64Service);
    private _dialog = inject(LgDialogService);
    private _formatPipe = inject(LgFormatTypePipe);
    private _translateService = inject(LgTranslateService);

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        self = this;
    }

    create(): LogexXlsxApi {
        if (!window.atob) window.atob = self._base64.decode;
        if (!window.btoa) window.btoa = self._base64.encode;

        const rRowReference = /\${\s*([rt])\s*([-+]?\s*[0-9]+)?\s*}/gi;
        const rColReference = /\${\s*([c#])\s*([-+]?\s*[0-9\w]+)?\s*}/gi;
        const rColRowReference =
            /\${\s*([c#])\s*([-+]?\s*[0-9\w]+)?\s*,\s*([rt])\s*([-+]?\s*[0-9]+)?\s*}/gi;

        // convert 0-based index into column name
        function getColumnName(columnNumber: number): string {
            let dividend = columnNumber;
            let columnName = "";

            do {
                const modulo = (dividend - (columnName.length === 0 ? 0 : 1)) % 26;
                columnName = String.fromCharCode(65 + modulo) + columnName;

                dividend = Math.floor((dividend - modulo) / 26);
            } while (dividend);
            return columnName;
        }

        const m = getCurrencyMetadata(this._currencyCode);
        const currencyPrefix = getCurrencyPrefix(m);
        const currencySuffix = getCurrencySuffix(m);

        const additionalMappings: _.Dictionary<string> = {};

        function getFormatMapping(): _.Dictionary<string> {
            const mapping: Record<string, string> = {
                money: `${currencyPrefix}#,##0${currencySuffix}`,
                "money:0": `${currencyPrefix}#,##0${currencySuffix}`,
                "money:1": `${currencyPrefix}#,##0.0${currencySuffix}`,
                "money:2": `${currencyPrefix}#,##0.00${currencySuffix}`,
                "money:3": `${currencyPrefix}#,##0.000${currencySuffix}`,
                "money:4": `${currencyPrefix}#,##0.0000${currencySuffix}`,
                "money:0:false": `${currencyPrefix}#,##0${currencySuffix}`,
                "money:1:false": `${currencyPrefix}#,##0.0${currencySuffix}`,
                "money:2:false": `${currencyPrefix}#,##0.00${currencySuffix}`,
                "money:0:true": `${currencyPrefix}+#,##0${currencySuffix};${currencyPrefix}-#,##0${currencySuffix};${currencyPrefix}0${currencySuffix}`,
                "money:1:true": `${currencyPrefix}+#,##0.0${currencySuffix};${currencyPrefix}-#,##0.0${currencySuffix};${currencyPrefix}0.0${currencySuffix}`,
                "money:2:true": `${currencyPrefix}+#,##0.00${currencySuffix};${currencyPrefix}-#,##0.00${currencySuffix};${currencyPrefix}0.00${currencySuffix}`,
                "money:0:false:true": `${currencyPrefix}#,##0${currencySuffix};${currencyPrefix}-#,##0${currencySuffix};-`,
                "money:1:false:true": `${currencyPrefix}#,##0.0${currencySuffix};${currencyPrefix}-#,##0.0${currencySuffix};-`,
                "money:2:false:true": `${currencyPrefix}#,##0.00${currencySuffix};${currencyPrefix}-#,##0.00${currencySuffix};-`,
                "money:0:true:true": `${currencyPrefix}+#,##0${currencySuffix};${currencyPrefix}-#,##0${currencySuffix};-`,
                "money:0:true:false": `${currencyPrefix}+#,##0${currencySuffix};${currencyPrefix}-#,##0${currencySuffix};${currencyPrefix}0${currencySuffix}`,
                "money:1:true:true": `${currencyPrefix}+#,##0.0${currencySuffix};${currencyPrefix}-#,##0.0${currencySuffix};-`,
                "money:2:true:true": `${currencyPrefix}+#,##0.00${currencySuffix};${currencyPrefix}-#,##0.00${currencySuffix};-`,
                number: "#,##0;-#,##0;-",
                "number:0": "#,##0;-#,##0;-",
                "number:1": "#,##0.0;-#,##0.0;-",
                "number:2": "#,##0.00;-#,##0.00;-",
                "number:3": "#,##0.000;-#,##0.000;-",
                "number:4": "#,##0.0000;-#,##0.0000;-",
                "number:0:false": "#,##0;-#,##0;-",
                "number:1:false": "#,##0.0;-#,##0.0;-",
                "number:2:false": "#,##0.00;-#,##0.00;-",
                "number:0:true": "+#,##0;-#,##0;-",
                "number:1:true": "+#,##0.0;-#,##0.0;-",
                "number:2:true": "+#,##0.00;-#,##0.00;-",
                percent: "0%;-0%;-",
                "percent:0": "0%;-0%;-",
                "percent:1": "0.0%;-0.0%;-",
                "percent:2": "0.00%;-0.00%;-",
                "percent:3": "0.000%;-0.0000%;-",
                "percent:4": "0.0000%;-0.0000%;-",
                "percent:0:false": "0%;-0%;-",
                "percent:1:false": "0.0%;-0.0%;-",
                "percent:2:false": "0.00%;-0.00%;-",
                "percent:0:true": "+0%;-0%;-",
                "percent:1:true": "+0.0%;-0.0%;-",
                "percent:2:true": "+0.00%;-0.00%;-",
                percentSimple: "0%"
            };

            _.each(additionalMappings, function (v, k) {
                if (mapping[k]) return;
                if (v[0] === "=") {
                    mapping[k] = mapping[v.substring(1)] || v;
                } else {
                    mapping[k] = v;
                }
            });

            return mapping;
        }

        const defaultStyle: IExportStyle = {
            headerBold: true,
            headerBottomColor: "000000",
            headerGroupBottomColor: "a0a0a0",
            totalsBold: true,
            totalsTopColor: "000000",
            infoLabelBold: true,
            infoTextBold: false,
            rowSeparatorColor: "000000",
            headerSeparatorColor: "000000",
            headerGroupSeparatorColor: "FF0000",

            fontName: "Arial",
            fontSize: 9,

            bigHeaderFontName: "Calibri",
            bigHeaderFontSize: 14,
            bigHeaderBold: true,
            bigHeaderSpaceHeight: 5,
            bigHeaderLineHeight: 1,
            bigHeaderHeight: 50,
            bigHeaderColor: "000000",
            bigHeaderLineColor: "000000",
            bigHeaderBackgroundColor: "FFFFFF",
            minColWidth: undefined
        };

        const defaultLogexStyle: IExportStyle = {
            rowColor: "000000", // odd and even
            oddBgColor: "f5f9ff",
            evenBgColor: "ffffff",
            headerColor: "000000", // column headers
            borderColor: "CCCCCC",
            borderAlternativeColor: "CCCCCC",

            headerHeight: 25,
            headerSeparatorColor: "a5a6a5", // header and group header
            rowSeparatorColor: "dedbde",

            rowBottomColor: "94b2d6",
            headerBottomColor: "18497b",
            headerGroupBottomColor: "528ed6",

            rowBold: false,
            headerBold: true,
            totalsBold: true,

            rowSeparatorAlways: true,
            headerSeparatorAlways: false,
            totalsSeparatorAlways: true,

            showGridLines: false,

            fontName: "Arial",
            fontSize: 9,
            rowHeight: 20,

            infoColor: "777777",
            infoTextColor: "000000",
            infoLabelBold: true,
            infoTextBold: false,

            bigHeaderFontName: "Arial",
            bigHeaderFontSize: 16,
            bigHeaderBold: true,
            bigHeaderSpaceHeight: 5,
            bigHeaderLineHeight: 3,
            bigHeaderHeight: 50,
            bigHeaderColor: "0044ab",
            bigHeaderLineColor: "18497b",
            bigHeaderBackgroundColor: "f5f9ff",
            headerGroupSeparatorColor: "FF0000",

            minColWidth: 18,
            marginColumnWidth: 4,
            paddingColumnWidth: undefined,
            defaultHeaderRowHeight: undefined
        };

        let globalDefaultStyle = defaultStyle;
        // widths for Calibri at 11pt. When character is missing, assume default
        const defaultCharWidth = 7;

        const charWidths = [
            3, 5, 6, 7, 7, 10, 10, 3, 4, 4, 7, 7, 4, 4, 4, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 4, 4, 7,
            7, 7, 7, 13, 8, 8, 8, 9, 7, 7, 9, 9, 4, 5, 8, 6, 13, 9, 10, 8, 10, 8, 7, 7, 9, 8, 13, 8,
            7, 7, 5, 6, 5, 7, 7, 4, 7, 8, 6, 8, 7, 4, 7, 8, 3, 4, 7, 3, 12, 8, 8, 8, 8, 5, 6, 5, 8,
            7, 10, 6, 7, 6, 5, 7, 5, 7
        ];
        const charWidthsBold = [
            3, 5, 6, 7, 7, 11, 10, 3, 5, 5, 7, 7, 4, 4, 4, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 4, 4, 7,
            7, 7, 7, 13, 9, 8, 8, 9, 7, 7, 9, 9, 4, 5, 8, 6, 13, 10, 10, 8, 10, 8, 7, 7, 10, 9, 13,
            8, 8, 7, 5, 6, 5, 7, 7, 4, 7, 8, 6, 8, 7, 5, 7, 8, 4, 4, 7, 4, 12, 8, 8, 8, 8, 5, 6, 5,
            8, 7, 11, 7, 7, 6, 5, 7, 5, 7
        ];

        function estimateWidth(text: string, bold: boolean): number {
            if (text == null) return 0;
            let result = 0;
            let i: number;
            let char: number;
            const def = bold ? charWidthsBold : charWidths;
            for (i = 0; i < text.length; ++i) {
                char = text.charCodeAt(i);
                if (char < 32 || char >= 127) {
                    result += defaultCharWidth;
                } else {
                    result += def[char - 32];
                }
            }
            // console.log("%o: %o (%o)", text, result, text.length*7);
            return result;
        }

        function coalesceColor(c0: string, c1: string, c2?: string): string;
        function coalesceColor(
            c0: string | IColumnExportColorCallback<any>,
            c1: string | IColumnExportColorCallback<any>,
            c2?: string
        ): string | IColumnExportColorCallback<any>;
        function coalesceColor(
            c0: string | IColumnExportColorCallback<any>,
            c1: string | IColumnExportColorCallback<any>,
            c2?: string
        ): string | IColumnExportColorCallback<any> {
            if (c0) return c0;
            if (c0 === "") return null;
            if (c1) return c1;
            if (c1 === "") return null;
            return c2;
        }

        // Create the function used to evaluate per-cell color
        function createColorFunction(
            color: string | IColumnExportColorCallback<any>,
            styleName: string
        ): IColorFunction {
            if (color == null) {
                return function noColor() {
                    return null;
                };
            }
            if (typeof color === "function") {
                return function colorFunction(locals, style: any) {
                    const effective = color(locals);
                    if (
                        effective &&
                        (effective === "s" ||
                            effective === "S" ||
                            effective.toLowerCase() === "style")
                    ) {
                        return style[styleName];
                    }
                    return effective;
                };
            }
            if (/^[0-9a-fA-F]{6,8}$/.test(color)) {
                // shortcut, return the color directly
                return function fixedColor() {
                    return color;
                };
            }
            if (color === "s" || color === "S" || color.toLowerCase() === "style") {
                // shortcut, just use style
                return null;
            } else {
                // TODO: mduracka add support for callbacks
                // let evaluateFn = $parse( color );
                // return function colorFunction( scope, locals, style ) {
                //     let effective = evaluateFn( scope, locals );
                //     if ( effective && (effective == "s" || effective == "S" || effective.toLowerCase() == "style") ) {
                //         return style[styleName];
                //     }
                //     return effective;
                // }
                console.error(`Export color ${color} not supported`);
                return null;
            }
        }

        function prepareDefinitionImpl(
            definition: IColumnExportDefinitionArray,
            _style: IExportStyle
        ): void {
            if (definition.$prepared) return;

            const formatMapping = getFormatMapping();

            _.remove(definition, col => col.remove);

            definition.$nameGroups = false;

            //
            for (const col of definition) {
                let format = col.format;

                if (!col.contentFn) {
                    const pipeFormatMatch = /(.*[^|]+)\s*\|(?!\|)\s*(.*)/.exec(col.content); // try to split the expression into the value and the filter
                    let content = col.content;

                    if (pipeFormatMatch && !col.contentAsIs) {
                        content = pipeFormatMatch[1].trim();
                        col.format = format = format || pipeFormatMatch[2]; // format specified by the format field has preferene over the filter
                    }

                    const contentFn = (contentForClosure: string): ((locals: any) => any) => {
                        const accessor = (parsedAccessors[contentForClosure] =
                            parsedAccessors[contentForClosure] || tokenize(contentForClosure));

                        return function (locals) {
                            return parse(locals, accessor);
                        };
                    };

                    col.contentFn = contentFn(content);
                }

                if (!col.formatExcel && format) {
                    // excel-native format has precedence over the filter-based format
                    format = format.replace(/ /g, "");
                    col.formatExcel = formatMapping[format]; // This should be perhaps generic, but for now let's hardcode the few formatters we're using in our code
                }

                if (col.totalsFormat) {
                    col.totalFormatExcel = formatMapping[col.totalsFormat.replace(/ /g, "")];
                }

                if (!col.totalExcel && col.totals) {
                    switch (col.totalsFunction) {
                        default:
                        case "sum":
                            col.totalExcel = "SUM($1:$2)";
                            break;
                        case "any": // won't work in current release of the lib, because it doesn't support array expressions col.totalExcel = "{INDEX($1:$2;MATCH(FALSE;ISBLANK($1:$2);0))}";
                            break;
                        case "min":
                            col.totalExcel = "MIN($1:$2)";
                            break;
                        case "max":
                            col.totalExcel = "MAX($1:$2)";
                            break;
                        case "count":
                            col.totalExcel = "COUNT($1:$2)";
                            break;
                        case "avg":
                            col.totalExcel = "AVERAGE($1:$2)";
                            break;
                        case "none":
                            break;
                    }
                }
                if (col.nameGroup) definition.$nameGroups = true;
                if (col.colors) {
                    let typedColors: IColumnExportColors<any>;
                    if (_.isString(col.colors)) {
                        typedColors = { oddEven: <string>col.colors };
                    } else {
                        typedColors = col.colors;
                    }
                    col.colorFns = {
                        odd: createColorFunction(
                            coalesceColor(typedColors.odd, typedColors.oddEven, "s"),
                            "oddColor"
                        ),
                        even: createColorFunction(
                            coalesceColor(typedColors.even, typedColors.oddEven, "s"),
                            "evenColor"
                        ),
                        totals: createColorFunction(
                            coalesceColor(typedColors.totals, "s"),
                            "totalsColor"
                        ),
                        header: createColorFunction(
                            coalesceColor(typedColors.header, "s"),
                            "headerColor"
                        ),
                        headerGroup: createColorFunction(
                            coalesceColor(typedColors.headerGroup, typedColors.header, "s"),
                            "headerGroupColor"
                        )
                    };
                }
                if (col.bgColors) {
                    let typedColors: IColumnExportColors<any>;

                    if (_.isString(col.bgColors)) {
                        typedColors = { oddEven: <string>col.bgColors };
                    } else {
                        typedColors = col.bgColors;
                    }
                    col.bgColorFns = {
                        odd: createColorFunction(
                            coalesceColor(typedColors.odd, typedColors.oddEven, "s"),
                            "oddBgColor"
                        ),
                        even: createColorFunction(
                            coalesceColor(typedColors.even, typedColors.oddEven, "s"),
                            "evenBgColor"
                        ),
                        totals: createColorFunction(
                            coalesceColor(typedColors.totals, "s"),
                            "totalsBgColor"
                        ),
                        header: createColorFunction(
                            coalesceColor(typedColors.header, "s"),
                            "headerBgColor"
                        ),
                        headerGroup: createColorFunction(
                            coalesceColor(typedColors.headerGroup, typedColors.header, "s"),
                            "headerGroupBgColor"
                        )
                    };
                }
            }
            definition.$prepared = true;
        }

        // helper function; not useable for header group row
        // function calculateRowCellBorders(
        //     definition: IColumnExportDefinitionArray,
        //     i: number,
        //     alwaysSeparator: boolean,
        //     borderColor: string,
        //     separatorColor: string,
        //     groupSeparatorColor: string,
        //     topColor: string,
        //     bottomColor: string,
        //     isHeaderRow: boolean,
        //     isTotalsRow: boolean,
        //     borders: IXlsxBorders
        // ): boolean {
        //     let col = definition[i];
        //     let hasBorders = false;
        //     if ( borderColor && ( i === 0 || i === definition.length - 1 ) ) {
        //         if ( i === 0 ) {
        //             borders.left = borderColor;
        //         } else {
        //             borders.right = borderColor;
        //         }
        //         hasBorders = true;
        //     }
        //     if ( groupSeparatorColor && ( col.nameGroup && col.nameGroup !== true ) && i > 0 ) {
        //         // first column of a group
        //         borders.left = groupSeparatorColor;
        //         hasBorders = true;
        //     } else if ( groupSeparatorColor && i > 0 && !col.nameGroup && definition[i - 1].nameGroup ) {
        //         // after column group
        //         borders.left = groupSeparatorColor;
        //         hasBorders = true;
        //     } else if ( ( alwaysSeparator || col.separator ) && separatorColor && i > 0 ) {
        //         borders.left = separatorColor;
        //         hasBorders = true;
        //     }
        //     if ( bottomColor ) {
        //         borders.bottom = bottomColor;
        //         hasBorders = true;
        //     } else if ( isTotalsRow && borderColor ) {
        //         borders.bottom = borderColor;
        //         hasBorders = true;
        //     }
        //     if ( isHeaderRow && borderColor && !col.nameGroup ) {
        //         borders.top = borderColor;
        //         hasBorders = true;
        //     } else if ( topColor ) {
        //         borders.top = topColor;
        //         hasBorders = true;
        //     }

        //     return hasBorders;
        // }

        function prepareExcelFormula(
            formula: string,
            row: number,
            column: number,
            columnCode: string,
            startRow: number,
            cell: IXlsxCell,
            patches: IXlsxCell[],
            definition: IColumnExportDefinitionArray
        ): string {
            if (!columnCode) columnCode = getColumnName(column);
            if (!patches) {
                // total rows does not specify patches, replace $2 directly
                formula = formula.replace(/\$2/g, columnCode + (row - 1 + 1));
            } else {
                formula = formula.replace(/\$2/g, columnCode + "$2PATCH$");
                patches.push(cell);
            }

            function getShift(columnIndex: number, offsetCol: number | string): number {
                const diff = columnIndex + Number(offsetCol);
                let steps = Number(offsetCol) * (Number(offsetCol) < 0 ? -1 : 1);
                const direction = columnIndex < diff ? 1 : -1;
                let currentIndex = columnIndex;
                let relativeShift = 0;
                while (steps > 0) {
                    relativeShift += direction;
                    currentIndex += direction;
                    if (!definition[currentIndex] || !definition[currentIndex].separator) {
                        steps--;
                    }
                }
                if (definition[currentIndex] && definition[currentIndex].separator) {
                    relativeShift += direction;
                }
                return columnIndex + relativeShift;
            }

            const idsDict: Record<string, number> = {};
            const firstColumnIndex = 2;

            function getIndexById(id: string): number {
                if (idsDict[id]) {
                    return idsDict[id];
                }

                const ids = definition.map(x => x.id);

                const index = ids.indexOf(id) + firstColumnIndex - 1;

                if (index) {
                    idsDict[id] = index;
                    return idsDict[id];
                }

                return null;
            }

            return formula
                .replace(/\$1/g, columnCode + (startRow + 1))
                .replace(/\$3/g, columnCode + (row - 1 + 1))
                .replace(rRowReference, function (_part, rowType, offset) {
                    if (rowType === "T" || rowType === "t") {
                        if (patches) {
                            patches.push(cell);
                            offset = (offset || "0").replace(/ /g, "");
                            return "${TPATCH" + offset + "}";
                        }
                    }
                    if (offset == null) return (row + 1).toString();
                    offset = offset.replace(/ /g, "");
                    return Math.max(1, row + 1 + parseInt(offset)).toString();
                })
                .replace(rColReference, function (_part, cellType, match) {
                    let result: string;
                    if (match == null) {
                        return columnCode;
                    } else if (cellType === "#") {
                        const index = getIndexById(match);
                        if (index === null) {
                            result = columnCode;
                        }
                        result = getColumnName(Math.max(0, index));
                    } else {
                        const diff = getShift(column, match);
                        result = getColumnName(Math.max(0, diff));
                    }
                    return result;
                })
                .replace(rColRowReference, function (_part, cellType, match, rowType, offsetRow) {
                    let result: string;
                    if (match === undefined) {
                        result = columnCode;
                    } else if (cellType === "#") {
                        const index = getIndexById(match);
                        if (index === null) {
                            result = columnCode;
                        }
                        result = getColumnName(Math.max(0, index));
                    } else {
                        const diff = getShift(column, match);
                        result = getColumnName(Math.max(0, diff));
                    }
                    if (rowType === "T" || rowType === "t") {
                        if (patches) {
                            patches.push(cell);
                            offsetRow = (offsetRow || "0").replace(/ /g, "");
                            return result + "${TPATCH" + offsetRow + "}";
                        }
                    }
                    if (offsetRow === undefined) {
                        result += row + 1;
                    } else {
                        offsetRow = offsetRow.replace(/ /g, "");
                        result += Math.max(1, row + 1 + parseInt(offsetRow));
                    }
                    return result;
                });
        }

        // Implementation of the API render() method
        function renderImpl(
            worksheet: IXlsxWorksheet,
            row: number,
            column: number,
            definition: IColumnExportDefinitionArray,
            _scope: any,
            rowFunction: IRowFunctionCallback,
            onDone: IOnDoneCallback,
            style?: IExportStyle,
            rowParameters?: IRowExportParameters
        ): void {
            let r: IXlsxRow;
            let cell: IXlsxCell;
            let i: number, j: number;
            let col: IColumnExportDefinitionNormalized;
            const startRow = row;
            let locals: any;
            let content: number | string;
            let inGroup: boolean;
            let hasTotals = false;
            if (!rowParameters) rowParameters = {};

            // Prepare
            style = getNormalizedStyle(style);
            prepareDefinitionImpl(definition, style);

            let startValueRow = startRow + 1;
            if (definition.$nameGroups) startValueRow += 1;

            if (rowParameters.headerRowHeight == null)
                rowParameters.headerRowHeight = style.defaultHeaderRowHeight;

            worksheet.showGridLines = style.showGridLines;

            if (style.marginColumnWidth) {
                column += 1;
            }

            // make sure the preceeding rows exist
            for (i = 0; i < row; ++i) {
                r = worksheet.data[i] = worksheet.data[i] || [];
                for (j = 0; j < column + definition.length; ++j) {
                    if (!r[j]) {
                        r[j] = {
                            value: ""
                        };
                    }
                }
            }

            const isGroupStart = (x: string | boolean): x is string => x && x !== true;
            if (definition.$nameGroups) {
                // render the name groups
                r = worksheet.data[row] || (worksheet.data[row] = []);

                r.height = rowParameters.headerRowHeight
                    ? rowParameters.headerRowHeight * 15
                    : style.headerHeight;

                for (i = 0; i < column; ++i) {
                    if (!r[i]) r[i] = { value: "" };
                }
                inGroup = false;
                for (i = 0; i < definition.length; ++i) {
                    col = definition[i];
                    if (isGroupStart(col.nameGroup)) {
                        cell = {
                            // value: $interpolate( <string>col.nameGroup )( scope ),
                            value: col.nameGroup,
                            bold: style.headerGroupBold,
                            // autoWidth: true, RF: auto width on comment column groups
                            fontSize: style.fontSize,
                            fontName: style.fontName,
                            hAlign: "centerContinuous",
                            fontColor:
                                col.colorFns && col.colorFns.headerGroup
                                    ? col.colorFns.headerGroup(null, style)
                                    : style.headerGroupColor,
                            backgroundColor:
                                col.bgColorFns && col.bgColorFns.headerGroup
                                    ? col.bgColorFns.headerGroup(null, style)
                                    : style.headerGroupBgColor,
                            borders: {
                                bottom: style.borderColor,
                                left: style.borderColor,
                                leftStyle: "dotted"
                            }
                        };
                        inGroup = true;
                    } else if (inGroup && col.nameGroup) {
                        cell = {
                            value: "",
                            hAlign: "centerContinuous",
                            backgroundColor:
                                col.bgColorFns && col.bgColorFns.headerGroup
                                    ? col.bgColorFns.headerGroup(null, style)
                                    : style.headerGroupBgColor,
                            borders:
                                style.borderColor || style.headerGroupBottomColor
                                    ? {
                                          bottom: style.borderColor
                                      }
                                    : {}
                        };
                    } else {
                        cell = {
                            value: "",
                            borders: inGroup
                                ? {
                                      leftStyle: "dotted",
                                      left: style.borderColor
                                  }
                                : {}
                        };
                        inGroup = false;
                    }
                    if (cell) {
                        cell.vAlign = "center";
                        cell.borders.top = style.borderColor;
                        if (i === 0) {
                            cell.borders.left = style.borderColor;
                        }
                        if (i === definition.length - 1) {
                            cell.borders.right = style.borderColor;
                        }
                        r[column + i] = cell;
                    }
                }
                ++row;
            }
            r = worksheet.data[row] || (worksheet.data[row] = []);

            r.height = rowParameters.headerRowHeight
                ? rowParameters.headerRowHeight * 15
                : style.headerHeight;

            // Generate the header
            for (i = 0; i < column; ++i) {
                if (!r[i]) r[i] = { value: "" };
            }
            inGroup = false;
            const headerWidthEstimates: number[] = [];
            for (i = 0; i < definition.length; ++i) {
                col = definition[i];
                const nextCol = definition[i + 1];
                cell = {
                    // value: $interpolate( col.name )( scope ),
                    value: col.name,
                    bold: style.headerBold,
                    autoWidth: true,
                    hAlign: col.hAlign,
                    fontSize: style.fontSize,
                    fontName: style.fontName,
                    fontColor:
                        col.colorFns && col.colorFns.header
                            ? col.colorFns.header(null, style)
                            : style.headerColor,
                    backgroundColor:
                        col.bgColorFns && col.bgColorFns.header
                            ? col.bgColorFns.header(null, style)
                            : style.headerBgColor,
                    wrapText: true,
                    borders: {
                        bottom: style.borderColor
                    }
                };
                if (!definition.$nameGroups) {
                    cell.borders.top = style.borderColor;
                }
                if (i === 0) {
                    cell.borders.left = style.borderColor;
                    cell.indent = 1;
                }
                if (i === definition.length - 1) {
                    cell.borders.right = style.borderColor;
                    cell.indent = 1;
                }
                if (isGroupStart(col.nameGroup)) {
                    cell.borders.left = style.borderColor;
                    cell.borders.leftStyle = "dotted";
                    if (!cell.hAlign || cell.hAlign === "left") {
                        cell.indent = 1;
                    }
                    inGroup = true;
                }
                if (!col.nameGroup && inGroup) {
                    cell.borders.left = style.borderColor;
                    cell.borders.leftStyle = "dotted";
                    inGroup = false;
                }
                // add indent to column preceding name group start
                // or preceding a new column if the current one is in a group
                if (nextCol) {
                    if (isGroupStart(nextCol.nameGroup) || (inGroup && !nextCol.nameGroup)) {
                        cell.indent = 1;
                    }
                }
                if (col.width) {
                    cell.width = col.width;
                    cell.autoWidth = false;
                } else {
                    headerWidthEstimates[column + i] = estimateWidth(<string>cell.value, true) + 3; // default spacing
                }
                cell.vAlign = "center";
                r[column + i] = cell;
                hasTotals = hasTotals || !!col.totals;
            }
            ++row;

            // Generate the body
            const colMins: number[] = [];
            const colMaxs: number[] = [];
            let firstRow = true;
            let isOdd = false;
            let lastRowPatches: IXlsxCell[] = [];

            function renderBlock(): void {
                let steps = 10000;
                while (--steps) {
                    locals = rowFunction();
                    if (!locals) break;
                    r = worksheet.data[row] || (worksheet.data[row] = []);

                    r.height = rowParameters.rowHeight
                        ? rowParameters.rowHeight * 15
                        : style.rowHeight;

                    for (i = 0; i < column; ++i) {
                        if (!r[i]) r[i] = { value: "" };
                    }
                    isOdd = !isOdd;
                    let blockInGroup = false;
                    for (i = 0; i < definition.length; ++i) {
                        col = definition[i];
                        const nextCol = definition[i + 1];
                        const colorFn = col.colorFns
                            ? isOdd
                                ? col.colorFns.odd
                                : col.colorFns.even
                            : null;
                        const colorBgFn = col.bgColorFns
                            ? isOdd
                                ? col.bgColorFns.odd
                                : col.bgColorFns.even
                            : null;
                        cell = {
                            autoWidth: true,
                            hAlign: col.hAlign,
                            bold: isOdd ? style.oddBold : style.evenBold,
                            fontSize: style.fontSize,
                            fontName: style.fontName,
                            fontColor: colorFn
                                ? colorFn(locals, style)
                                : isOdd
                                ? style.oddColor
                                : style.evenColor,
                            backgroundColor: colorBgFn
                                ? colorBgFn(locals, style)
                                : isOdd
                                ? style.oddBgColor
                                : style.evenBgColor,
                            vAlign: col.vAlign || rowParameters.vAlign || "center",
                            wrapText: col.wrapText,
                            borders: {}
                        };

                        if (i === 0) {
                            cell.borders.left = style.borderColor;
                            cell.indent = 1;
                        }
                        if (i === definition.length - 1) {
                            cell.borders.right = style.borderColor;
                            cell.indent = 1;
                        }
                        if (isGroupStart(col.nameGroup)) {
                            cell.borders.left = style.borderColor;
                            cell.borders.leftStyle = "dotted";
                            if (cell.hAlign === "left") {
                                cell.indent = 1;
                            }
                            blockInGroup = true;
                        }
                        if (blockInGroup && !col.nameGroup) {
                            cell.borders.left = style.borderColor;
                            cell.borders.leftStyle = "dotted";
                            blockInGroup = false;
                        }
                        if (nextCol) {
                            if (
                                isGroupStart(nextCol.nameGroup) ||
                                (blockInGroup && !nextCol.nameGroup)
                            ) {
                                cell.indent = 1;
                            }
                        }
                        if (steps === 1 && !hasTotals) {
                            cell.borders.bottom = style.borderColor;
                        }

                        if (col.width) {
                            cell.width = col.width;
                            cell.autoWidth = false;
                        }
                        content = col.contentFn(locals);
                        cell.value = content;

                        if (col.formatExcel) cell.formatCode = col.formatExcel;
                        if (col.contentExcelFormula)
                            cell.formula = prepareExcelFormula(
                                col.contentExcelFormula,
                                row,
                                column + i,
                                null,
                                startValueRow,
                                cell,
                                lastRowPatches,
                                definition
                            );
                        r[column + i] = cell;
                        if (col.format) {
                            if (firstRow) {
                                colMins[i] = colMaxs[i] = +content;
                            } else {
                                colMins[i] = Math.min(colMins[i], +content);
                                colMaxs[i] = Math.max(colMaxs[i], +content);
                            }
                        }
                    }
                    ++row;
                    firstRow = false;
                }
                if (!steps) {
                    atNextFrame(renderBlock);
                } else {
                    renderFooter();
                    onDone();
                }
            }

            function renderFooter(): void {
                // Now we know the last row, patch up all the formulas referring to it
                for (i = 0; i < lastRowPatches.length; ++i) {
                    lastRowPatches[i].formula = lastRowPatches[i].formula
                        .replace(/\$2PATCH\$/g, row.toString())
                        .replace(/\${TPATCH([-+]?[0-9]+)}/g, function (_part, offset) {
                            return Math.max(1, row + 1 + parseInt(offset)).toString();
                        });
                }
                lastRowPatches = null;
                r = worksheet.data[row] || (worksheet.data[row] = []);
                if (hasTotals) {
                    // Generate the footer
                    r.height = rowParameters.rowHeight
                        ? rowParameters.rowHeight * 15
                        : style.headerHeight;

                    for (i = 0; i < column; ++i) {
                        if (!r[i]) r[i] = { value: "" };
                    }
                    let footerInGroup = false;
                    for (i = 0; i < definition.length; ++i) {
                        col = definition[i];
                        const nextCol = definition[i + 1];
                        cell = {
                            fontSize: style.fontSize,
                            fontName: style.fontName,
                            value: "",
                            bold: style.totalsBold,
                            autoWidth: true,
                            hAlign: col.hAlign,
                            fontColor:
                                col.colorFns && col.colorFns.totals
                                    ? col.colorFns.totals({ totalValue: col.totalValue }, style)
                                    : style.totalsColor,
                            backgroundColor:
                                col.bgColorFns && col.bgColorFns.totals
                                    ? col.bgColorFns.totals({ totalValue: col.totalValue }, style)
                                    : style.totalsBgColor,
                            vAlign: col.vAlign || rowParameters.vAlign || "center",
                            borders: {
                                top: style.borderAlternativeColor,
                                bottom: style.borderColor
                            }
                        };

                        if (i === 0) {
                            cell.borders.left = style.borderColor;
                            cell.indent = 1;
                        }
                        if (i === definition.length - 1) {
                            cell.borders.right = style.borderColor;
                            cell.indent = 1;
                        }
                        if (col.nameGroup && col.nameGroup !== true) {
                            cell.borders.left = style.borderColor;
                            cell.borders.leftStyle = "dotted";
                            if (!cell.hAlign || cell.hAlign === "left") {
                                cell.indent = 1;
                            }
                            footerInGroup = true;
                        }
                        if (!col.nameGroup && footerInGroup) {
                            cell.borders.left = style.borderColor;
                            cell.borders.leftStyle = "dotted";
                            footerInGroup = false;
                        }
                        // add indent to column preceding name group start
                        // or preceding a new column if the current one is in a group
                        if (nextCol) {
                            if (
                                isGroupStart(nextCol.nameGroup) ||
                                (footerInGroup && !nextCol.nameGroup)
                            ) {
                                cell.indent = 1;
                            }
                        }

                        if (col.width) {
                            cell.width = col.width;
                            cell.autoWidth = false;
                        }
                        if (i === 0) {
                            cell.value = self._translateService.translate("FW._Common.Total");
                        } else if (col.totalExcel) {
                            const columnCode = getColumnName(i + column);
                            cell.formula = prepareExcelFormula(
                                col.totalExcel,
                                row,
                                column + i,
                                columnCode,
                                startValueRow,
                                cell,
                                null,
                                definition
                            );
                            if (col.totalFormatExcel) {
                                cell.formatCode = col.totalFormatExcel;
                            } else if (col.formatExcel) {
                                cell.formatCode = col.formatExcel;
                            }
                            if (col.totalValue != null) cell.value = col.totalValue;
                        } else if (col.totals && col.totalsFunction === "none") {
                            if (col.totalFormatExcel) {
                                cell.formatCode = col.totalFormatExcel;
                            } else if (col.formatExcel) {
                                cell.formatCode = col.formatExcel;
                            }
                            if (col.totalValue != null) cell.value = col.totalValue;
                        }
                        if (i !== 0 && col.totalValue) {
                            if (firstRow) {
                                colMins[i] = colMaxs[i] = +col.totalValue;
                            } else {
                                colMins[i] = Math.min(colMins[i], +col.totalValue);
                                colMaxs[i] = Math.max(colMaxs[i], +col.totalValue);
                            }
                        }
                        r[column + i] = cell;
                    }
                    ++row;
                } else {
                    for (i = 0; i < definition.length; ++i) {
                        col = definition[i];
                        cell = {
                            value: "",
                            borders: {
                                top: style.borderColor
                            }
                        };
                        r[column + i] = cell;
                    }
                }
                const colWidthEstimates = [];
                for (i = 0; i < definition.length; ++i) {
                    const col2 = definition[i];
                    if (!col2.format) {
                        if (style.minColWidth) {
                            colWidthEstimates[column + i] = style.minColWidth + 1;
                        }
                        continue;
                    }
                    let x = 0;
                    if (!isNaN(colMins[i])) {
                        x = Math.max(
                            x,
                            self._formatPipe.transform(colMins[i], col2.format).toString().length
                        );
                    } else {
                        x = NaN;
                    }
                    if (!isNaN(colMaxs[i])) {
                        x = Math.max(
                            x,
                            self._formatPipe.transform(colMaxs[i], col2.format).toString().length
                        );
                    } else {
                        x = NaN;
                    }
                    x = Math.max(x, self._formatPipe.transform(0, col2.format).toString().length);
                    if (!isNaN(x)) {
                        colWidthEstimates[column + i] = Math.max(x, style.minColWidth) + 1;
                    } else if (style.minColWidth) {
                        colWidthEstimates[column + i] = style.minColWidth + 1;
                    }
                }
                worksheet.columnWidthEstimates = colWidthEstimates;
                worksheet.columnPixelWidthEstimates = headerWidthEstimates;
            }

            atNextFrame(renderBlock);
        }

        function createDialog(): LgDialogRef<any> {
            return self._dialog.show(LgExcelExportDialogComponent, {
                title: self._translateService.translate("FW._Directives._exports.Is_Generating"),
                allowClose: false
            });
        }

        function getNormalizedStyle(style: IExportStyle): IExportStyle {
            style = style || globalDefaultStyle;

            return {
                rowColor: style.rowColor,
                rowBgColor: style.rowBgColor,
                oddColor: coalesceColor(style.oddColor, style.rowColor),
                oddBgColor: coalesceColor(style.oddBgColor, style.rowBgColor),
                evenColor: coalesceColor(style.evenColor, style.rowColor),
                evenBgColor: coalesceColor(style.evenBgColor, style.rowBgColor),
                headerColor: coalesceColor(style.headerColor, style.rowColor),
                headerBgColor: coalesceColor(style.headerBgColor, style.rowBgColor),
                headerGroupColor: coalesceColor(
                    style.headerGroupColor,
                    style.headerColor,
                    style.rowColor
                ),
                headerGroupBgColor: coalesceColor(
                    style.headerGroupBgColor,
                    style.headerBgColor,
                    style.rowBgColor
                ),
                totalsColor: coalesceColor(style.totalsColor, style.rowColor),
                totalsBgColor: coalesceColor(style.totalsBgColor, style.rowBgColor),

                borderColor: style.borderColor,
                borderAlternativeColor: style.borderAlternativeColor,

                rowSeparatorColor: style.rowSeparatorColor,
                oddSeparatorColor: coalesceColor(style.oddSeparatorColor, style.rowSeparatorColor),
                evenSeparatorColor: coalesceColor(
                    style.evenSeparatorColor,
                    style.rowSeparatorColor
                ),
                headerHeight: style.headerHeight || style.rowHeight,
                headerSeparatorColor: style.headerSeparatorColor,
                headerGroupSeparatorColor: coalesceColor(
                    style.headerGroupSeparatorColor,
                    style.headerSeparatorColor
                ),
                totalsSeparatorColor: coalesceColor(
                    style.totalsSeparatorColor,
                    style.rowSeparatorColor
                ),

                rowBottomColor: style.rowBottomColor,
                oddBottomColor: coalesceColor(style.oddBottomColor, style.rowBottomColor),
                evenBottomColor: coalesceColor(style.evenBottomColor, style.rowBottomColor),
                headerBottomColor: coalesceColor(style.headerBottomColor, style.rowBottomColor),
                headerGroupBottomColor: coalesceColor(
                    style.headerGroupBottomColor,
                    style.headerBottomColor,
                    style.rowBottomColor
                ),
                totalsTopColor: style.totalsTopColor,
                totalsBottomColor: style.totalsBottomColor,

                rowSeparatorAlways: style.rowSeparatorAlways,
                headerSeparatorAlways: style.headerSeparatorAlways,
                totalsSeparatorAlways: style.totalsSeparatorAlways,

                rowBold: style.rowBold,
                oddBold: style.oddBold != null ? style.oddBold : style.rowBold,
                evenBold: style.evenBold != null ? style.evenBold : style.rowBold,
                headerBold: style.headerBold != null ? style.headerBold : style.rowBold,
                headerGroupBold:
                    style.headerGroupBold != null
                        ? style.headerGroupBold
                        : style.headerBold != null
                        ? style.headerBold
                        : style.rowBold,
                totalsBold: style.totalsBold != null ? style.totalsBold : style.rowBold,

                showGridLines: style.showGridLines,

                fontName: style.fontName,
                fontSize: style.fontSize,
                rowHeight: style.rowHeight,

                infoColor: style.infoColor,
                infoTextColor: style.infoTextColor,
                infoBgColor: style.infoBgColor,
                infoBorderColor: style.infoBorderColor,
                infoLabelBold: style.infoLabelBold,
                infoTextBold: style.infoTextBold,

                bigHeaderFontName: style.bigHeaderFontName,
                bigHeaderFontSize: style.bigHeaderFontSize,
                bigHeaderBold: style.bigHeaderBold,
                bigHeaderSpaceHeight:
                    style.bigHeaderSpaceHeight != null ? style.bigHeaderSpaceHeight : 5,
                bigHeaderLineHeight:
                    style.bigHeaderLineHeight != null ? style.bigHeaderLineHeight : 2.5,
                bigHeaderHeight: style.bigHeaderHeight != null ? style.bigHeaderHeight : 50,
                bigHeaderLineColor: style.bigHeaderLineColor,
                bigHeaderColor: style.bigHeaderColor,
                bigHeaderBackgroundColor: style.bigHeaderBackgroundColor,

                minColWidth: style.minColWidth || 0,
                marginColumnWidth: style.marginColumnWidth || 0,
                paddingColumnWidth: style.paddingColumnWidth || 0,

                defaultHeaderRowHeight: style.defaultHeaderRowHeight
            };
        }

        function getInfoboxHeight(info: Array<string | (() => string)>): number {
            if (!info) return 0;
            return Math.ceil(info.length / 2);
        }

        let api: LogexXlsxApi;

        return (api = {
            // add additional mappings to the table. If the value is prefixed with =, it tries to do map that to existing value (so one can specify {"fmtGrowth":"=fmtPercent:1:true"}).
            // If the lookup fails, the value is stored directly, including the =
            // todo: change the factory to provider, and do this as part of configuration phase
            addFormatMapping(mapping: types.IStringLookup<string>) {
                _.each(mapping, function (v, k) {
                    if (additionalMappings[k]) return;
                    additionalMappings[k] = v;
                });
            },

            create(creator?: string) {
                return {
                    worksheets: [{ data: [], name: "Default" }],
                    creator: creator || "Logex",
                    created: new Date(),
                    lastModifiedBy: creator || "Logex",
                    modified: new Date(),
                    activeWorksheet: 0
                };
            },

            createWorksheet(name?: string) {
                return { data: [], name: name || "Sheet" };
            },

            save(
                file: IXlsxFile,
                filename = "output.xlsx",
                onEnd?: IOnSaveEndCallback,
                onProgress?: IOnSaveProgressCallback,
                onError?: IOnSaveErrorCallback
            ) {
                if (!file) return;

                xlsx(
                    file,
                    function (blob: Blob, _info: any) {
                        triggerBlobFileDownload(blob, filename);
                        onEnd();
                    },
                    {
                        output: "blob",
                        onProgress,
                        onError,
                        useWebWorkers: true
                        // unused: workerScriptsPath: "./"
                    }
                );
            },

            getColumnName,

            render: renderImpl,

            renderArray(
                worksheet: IXlsxWorksheet,
                row: number,
                column: number,
                definition: IColumnExportDefinition[],
                scope: any,
                data: any[],
                itemName: string,
                onDone: IOnDoneCallback,
                onProgress?: IOnProgressCallback,
                style?: IExportStyle,
                rowParameters?: IRowExportParameters
            ) {
                if (!data) return;
                itemName = itemName || "item";
                let i = 0;
                atNextFrame(function () {
                    renderImpl(
                        worksheet,
                        row,
                        column,
                        definition,
                        scope,
                        function () {
                            if (i === data.length) return null;
                            if (onProgress && !(i % 1000)) onProgress(i, data.length);
                            ++i;
                            const result: Record<string, any> = {};
                            result[itemName] = data[i - 1];
                            return result;
                        },
                        onDone,
                        style,
                        rowParameters
                    );
                });
            },

            renderPivot(
                worksheet: IXlsxWorksheet,
                row: number,
                column: number,
                definition: IColumnExportDefinition[],
                scope: any,
                pivotDefinition: INormalizedLogexPivotDefinition,
                data: any[],
                itemNames: string[],
                maxDepth: number,
                includeHidden: boolean,
                onDone: IOnDoneCallback,
                onProgress?: IOnProgressCallback,
                style?: IExportStyle,
                rowParameters?: IRowExportParameters,
                unfiltered?: boolean,
                pivotContext?: any
            ) {
                if (!data) return;
                if (
                    !pivotDefinition.children ||
                    (pivotDefinition.children.hidden && !includeHidden)
                )
                    throw new Error("The pivot must have at least 2 levels");
                maxDepth = maxDepth || 999999;
                const stack: any[] = [];
                stack.push({ definition: pivotDefinition, data, index: 0 });
                let depth = 0;
                const locals: Record<string, any> = {};
                locals[itemNames[0]] = data[0];
                if (pivotDefinition.nodeNameFn) {
                    // locals[itemNames[0] + "_nodeName"] = pivotDefinition.nodeNameFn( data[0], pivotContext, self.appDefinitions );
                    locals[itemNames[0] + "_nodeName"] = pivotDefinition.nodeNameFn(
                        data[0],
                        pivotContext,
                        pivotDefinition
                    );
                }

                // goes all the way down from item specified by pivotDefinition and entry, placing the rows on the stack and to locals (the item identified by the parameter itself is not placed anywhere)
                function goDown(
                    pivotLevelDefinition: INormalizedLogexPivotDefinition,
                    entry: any
                ): void {
                    let levelData;
                    if (!entry) return;
                    while (
                        pivotLevelDefinition.children &&
                        (!pivotLevelDefinition.children.hidden || includeHidden) &&
                        depth < maxDepth - 1
                    ) {
                        const storeKey = unfiltered
                            ? pivotLevelDefinition.children.store
                            : pivotLevelDefinition.children.filteredStore;
                        levelData = entry[storeKey];
                        if (!levelData || levelData.length === 0) {
                            goNext(false);
                            if (depth < 0) return;
                            goDown(stack[depth].definition, stack[depth].data[stack[depth].index]);
                            return;
                        }
                        pivotLevelDefinition = pivotLevelDefinition.children;
                        stack.push({ definition: pivotLevelDefinition, data: levelData, index: 0 });
                        entry = levelData[0];
                        ++depth;
                        locals[itemNames[depth]] = entry;
                        if (pivotLevelDefinition.nodeNameFn) {
                            // locals[itemNames[depth] + "_nodeName"] = pivotLevelDefinition.nodeNameFn( entry, pivotContext, self.appDefinitions );
                            locals[itemNames[depth] + "_nodeName"] =
                                pivotLevelDefinition.nodeNameFn(
                                    entry,
                                    pivotContext,
                                    pivotDefinition
                                );
                        }
                    }
                }

                function goNext(canGoDown: boolean): void {
                    let entry = stack[depth];
                    ++entry.index;
                    if (entry.index === entry.data.length) {
                        // we went through the last leaf in the current branch, go up the stack
                        while (stack[depth].index === stack[depth].data.length) {
                            --depth;
                            stack.pop();
                            if (depth < 0) return null;
                            ++stack[depth].index;
                            if (onProgress && depth === 0)
                                onProgress(stack[depth].index, data.length);
                        }
                        locals[itemNames[depth]] = stack[depth].data[stack[depth].index];
                        if (stack[depth].definition.nodeNameFn) {
                            locals[itemNames[depth] + "_nodeName"] = stack[
                                depth
                            ].definition.nodeNameFn(
                                stack[depth].data[stack[depth].index],
                                pivotContext,
                                pivotDefinition
                            );
                        }
                        if (canGoDown) {
                            goDown(stack[depth].definition, stack[depth].data[stack[depth].index]);
                        }
                        entry = stack[depth];
                    }
                    return entry;
                }

                goDown(pivotDefinition, data[0]);
                // the last index will be immediately incremented in the main loop
                if (depth >= 0) {
                    stack[depth].index = -1;
                }
                if (onProgress) onProgress(0, data.length);

                atNextFrame(function () {
                    renderImpl(
                        worksheet,
                        row,
                        column,
                        definition,
                        scope,
                        function () {
                            if (depth < 0) return null;
                            // at this point, the stack contains the address of the last teturned item. So we immediately increase
                            let entry = stack[depth];
                            ++entry.index;
                            if (entry.index === entry.data.length) {
                                // we went through the last leaf in the current branch, go up the stack
                                while (stack[depth].index === stack[depth].data.length) {
                                    --depth;
                                    stack.pop();
                                    if (depth < 0) return null;
                                    ++stack[depth].index;
                                    if (onProgress && depth === 0)
                                        onProgress(stack[depth].index, data.length);
                                }
                                locals[itemNames[depth]] = stack[depth].data[stack[depth].index];
                                if (stack[depth].definition.nodeNameFn) {
                                    locals[itemNames[depth] + "_nodeName"] = stack[
                                        depth
                                    ].definition.nodeNameFn(
                                        stack[depth].data[stack[depth].index],
                                        pivotContext,
                                        pivotDefinition
                                    );
                                }
                                goDown(
                                    stack[depth].definition,
                                    stack[depth].data[stack[depth].index]
                                );
                                if (depth < 0) return null;
                                entry = stack[depth];
                            }
                            locals[itemNames[depth]] = entry.data[entry.index];
                            if (entry.definition.nodeNameFn) {
                                locals[itemNames[depth] + "_nodeName"] =
                                    entry.definition.nodeNameFn(
                                        entry.data[entry.index],
                                        pivotContext,
                                        pivotDefinition
                                    );
                            }
                            return locals;
                        },
                        onDone,
                        style,
                        rowParameters
                    );
                });
            },

            hasActiveFilters(filters?: IFilterExportDefinition[]): boolean {
                if (!filters) return false;
                let i: number;
                let f: IFilterExportDefinition;
                let list: any[];
                for (i = 0; i < filters.length; ++i) {
                    f = filters[i];
                    if (f.exportFn) {
                        if (f.activeFn) {
                            if (f.activeFn()) return true;
                        } else {
                            list = f.exportFn();
                            if (list && list.length) return true;
                        }
                    } else if (f.filter && !f.filter.$empty) return true;
                }
                return false;
            },

            renderFilters(
                worksheet: IXlsxWorksheet,
                row: number,
                column: number,
                _scope: any,
                filters: IFilterExportDefinition[],
                style?: IExportStyle
            ) {
                let isOdd: boolean;

                function createCell(value: string | number, key: string): IXlsxCell {
                    return {
                        value: f.nameFn ? f.nameFn(key) : value,
                        bold: isOdd ? style.oddBold : style.evenBold,
                        fontColor: isOdd ? style.oddColor : style.evenColor,
                        backgroundColor: isOdd ? style.oddBgColor : style.evenBgColor,
                        borders: {
                            left: style.borderColor,
                            right: style.borderColor,
                            bottom: isOdd ? style.oddBottomColor : style.evenBottomColor
                        }
                    };
                }

                style = getNormalizedStyle(style);
                worksheet.showGridLines = style.showGridLines;

                if (style.marginColumnWidth) ++column;

                let i: number, j: number;
                let r: IXlsxRow;
                let f: IFilterExportDefinition;
                let first = true;
                let list: any[];
                for (i = 0; i < row; ++i) {
                    r = worksheet.data[i] = worksheet.data[i] || [];
                    for (j = 0; j < column + 1; ++j) {
                        if (!r[j]) r[j] = { value: "" };
                    }
                }
                for (i = 0; i < filters.length; ++i) {
                    f = filters[i];
                    if (f.exportFn) {
                        if (f.activeFn && !f.activeFn()) continue;
                        list = f.exportFn();
                        if (!list || !list.length) continue;
                    } else if (!f.filter || f.filter.$empty) continue;
                    if (!first) {
                        // render separator row
                        r = worksheet.data[row] || (worksheet.data[row] = []);
                        for (j = 0; j < column + 1; ++j) {
                            if (!r[j]) r[j] = { value: "" };
                        }
                        ++row;
                    } else {
                        first = false;
                    }
                    // render header
                    r = worksheet.data[row] || (worksheet.data[row] = []);
                    for (j = 0; j < column; ++j) {
                        if (!r[j]) r[j] = { value: "" };
                    }
                    r[column] = {
                        value: f.name,
                        bold: style.headerBold,
                        borders: {
                            bottom: style.headerBottomColor,
                            left: style.borderColor,
                            top: style.borderColor,
                            right: style.borderColor
                        },
                        fontColor: style.headerColor,
                        backgroundColor: style.headerBgColor,
                        autoWidth: true
                    };
                    ++row;
                    // render filter values
                    isOdd = false;
                    let lastCell = null;
                    if (f.exportFn) {
                        _.each(list, function (v) {
                            isOdd = !isOdd;
                            r = worksheet.data[row] || (worksheet.data[row] = []);
                            for (j = 0; j < column; ++j) {
                                if (!r[j]) r[j] = { value: "", autoWidth: true };
                            }
                            lastCell = r[column] = createCell(v, v);
                            ++row;
                        });
                    } else {
                        _.each(f.filter, function (v, k) {
                            isOdd = !isOdd;
                            r = worksheet.data[row] || (worksheet.data[row] = []);
                            for (j = 0; j < column; ++j) {
                                if (!r[j]) r[j] = { value: "", autoWidth: true };
                            }
                            lastCell = r[column] = createCell(v, k);
                            ++row;
                        });
                    }
                    if (lastCell && style.borderColor) {
                        // lastCell.borders.bottom = style.borderColor;
                    }
                }
            },

            renderInfoBox: function renderInfoBox(
                worksheet: IXlsxWorksheet,
                row: number,
                column: number,
                info: string[] | Array<() => string>,
                _scope: any,
                labelSpan?: number,
                textSpan?: number,
                textAlign?: string,
                style?: IExportStyle
            ) {
                if (!info || !info.length) return;

                labelSpan = labelSpan || 1;
                textSpan = textSpan || 1;
                style = getNormalizedStyle(style);
                const rows = Math.ceil(info.length / 2);
                let i: number, j: number;
                let r: IXlsxRow, cell: IXlsxCell;

                if (style.marginColumnWidth) ++column;
                if (style.paddingColumnWidth) ++column;

                for (i = 0; i < row; ++i) {
                    r = worksheet.data[i] = worksheet.data[i] || [];
                }

                for (i = 0; i < rows; ++i) {
                    r = worksheet.data[i + row] = worksheet.data[row + i] || [];

                    for (j = 0; j < column + labelSpan + textSpan; ++j) {
                        if (!r[j]) {
                            r[j] = {
                                value: ""
                            };
                        }
                    }

                    const infoCellValue1 = info[2 * i];

                    cell = {
                        value:
                            infoCellValue1 == null || typeof infoCellValue1 === "string"
                                ? "" + infoCellValue1 // whatever TS
                                : infoCellValue1(),
                        bold: style.infoLabelBold,
                        fontColor: style.infoTextColor,
                        fontSize: style.fontSize,
                        fontName: style.fontName,
                        vAlign: "center",
                        backgroundColor: style.infoBgColor,
                        borders: {
                            left: style.infoBorderColor,
                            top: i === 0 ? style.infoBorderColor : undefined,
                            bottom: i === rows - 1 ? style.infoBorderColor : undefined
                        }
                    };
                    r[column] = cell;
                    if (labelSpan > 1) {
                        // label is always left-aligned, so just generate the additional cells here
                        for (let inner = 1; inner < labelSpan; ++inner) {
                            r[column + inner] = {
                                value: "",
                                backgroundColor: style.infoBgColor,
                                vAlign: "center",
                                borders: {
                                    top: i === 0 ? style.infoBorderColor : undefined,
                                    bottom: i === rows - 1 ? style.infoBorderColor : undefined
                                }
                            };
                        }
                    }
                    // text can be right aligned, so first just generate the cells
                    for (let inner = 0; inner < textSpan; ++inner) {
                        cell = {
                            value: "",
                            backgroundColor: style.infoBgColor,
                            fontSize: style.fontSize,
                            fontName: style.fontName,
                            vAlign: "center",
                            borders: {
                                top: i === 0 ? style.infoBorderColor : undefined,
                                bottom: i === rows - 1 ? style.infoBorderColor : undefined
                            }
                        };
                        r[column + labelSpan + inner] = cell;
                    }
                    r[column + labelSpan + textSpan - 1].borders.right = style.infoBorderColor;
                    // and now store the actual text either in first cell of the range, or the last cell
                    if (textAlign === "right") {
                        cell = r[column + labelSpan + textSpan - 1];
                    } else {
                        cell = r[column + labelSpan];
                    }

                    r.height = style.rowHeight;

                    const infoCellValue2 = info[2 * i + 1];
                    cell.value =
                        infoCellValue2 == null || typeof infoCellValue2 === "string"
                            ? "" + infoCellValue2 // whatever TS
                            : infoCellValue2();
                    cell.bold = style.infoTextBold;
                    cell.fontColor = style.infoTextColor || style.infoColor;
                    cell.hAlign = textAlign || "left";
                }
            },

            renderBigHeader(
                worksheet: IXlsxWorksheet,
                row: number,
                column: number,
                text: string,
                _scope: any,
                style?: IExportStyle
            ) {
                style = getNormalizedStyle(style);
                let i: number, j: number;
                let r: IXlsxRow;

                if (style.marginColumnWidth) ++column;
                if (style.paddingColumnWidth) ++column;

                // Force no text on the target lines
                worksheet.data[row] = [];
                worksheet.data[row + 1] = [];
                for (i = 0; i < row + 1; ++i) {
                    r = worksheet.data[i] = worksheet.data[i] || [];
                    for (j = 0; j < column + 1; ++j) {
                        if (!r[j]) {
                            r[j] = {
                                value: "",
                                backgroundColor: style.bigHeaderBackgroundColor
                            };
                        }
                    }
                }
                r = worksheet.data[row];
                r[column] = {
                    // value: $interpolate( text || "" )( scope ),
                    value: text || "",
                    fontName: style.bigHeaderFontName,
                    fontSize: style.bigHeaderFontSize,
                    bold: style.bigHeaderBold,
                    fontColor: style.bigHeaderColor,
                    formatCode: "@",
                    backgroundColor: style.bigHeaderBackgroundColor,
                    vAlign: "center"
                };
                r.height = style.bigHeaderFontSize * 1.2614 + 0.78; // just linear extrapolation of the actual values; there's probably more precise magic somewhere
                worksheet.data[row].height = style.bigHeaderHeight;
                worksheet.data[row].backgroundColor = style.bigHeaderBackgroundColor;
            },

            saveArray(
                options: IArraySaveOptions,
                definition: IColumnExportDefinition[],
                filterDefinition: IFilterExportDefinition[],
                scope: any,
                data: any[]
            ) {
                const opt: IArraySaveOptions = {
                    ...{
                        filename: "table.xlsx",
                        creator: "Logex",
                        sheetName: "Output",
                        filterSheetName: "Filters",
                        itemName: "item",
                        row: 0,
                        column: 0,
                        headerFunction: null,
                        infobox: null,
                        style: null
                    },
                    ...options
                };

                const dlg = createDialog();
                const file = api.create(opt.creator);
                let ws = file.worksheets[0];
                ws.name = opt.sheetName;

                let row = opt.row || 0;

                if (opt.bigHeader) {
                    row += 2;
                }

                if (opt.infobox && opt.infobox.info && opt.infobox.info.length) {
                    row +=
                        (opt.infobox.row || 0) +
                        getInfoboxHeight(opt.infobox.info) +
                        (opt.infobox.spacing == null ? 1 : opt.infobox.spacing);
                }

                api.renderArray(
                    ws,
                    row,
                    opt.column,
                    definition,
                    scope,
                    data,
                    opt.itemName,
                    function onDone() {
                        if (opt.headerFunction) {
                            opt.headerFunction(ws, 0, definition);
                        }

                        let infoboxRow = opt.infobox ? opt.infobox.row || 0 : 0;
                        if (opt.bigHeader) {
                            api.renderBigHeader(
                                ws,
                                0,
                                opt.column || 0,
                                opt.bigHeader,
                                scope,
                                opt.style
                            );
                            infoboxRow += 2;
                        }

                        if (opt.infobox) {
                            api.renderInfoBox(
                                ws,
                                infoboxRow,
                                opt.infobox.column || 0,
                                opt.infobox.info,
                                scope,
                                opt.infobox.labelSpan || 1,
                                opt.infobox.textSpan || 1,
                                opt.infobox.textAlign,
                                opt.style
                            );
                        }

                        const style = opt.style || globalDefaultStyle;

                        if (style && style.marginColumnWidth && ws.data.length) {
                            ws.columnWidthEstimates = ws.columnWidthEstimates || [];
                            ws.columnWidthEstimates[0] = Math.max(
                                ws.columnWidthEstimates[0] || style.marginColumnWidth
                            );
                            ws.data[0][0].autoWidth = true;
                        }

                        if (api.hasActiveFilters(filterDefinition)) {
                            ws = api.createWorksheet(opt.filterSheetName);
                            file.worksheets.push(ws);
                            api.renderFilters(ws, opt.row, opt.column, scope, filterDefinition);

                            if (opt.headerFunction) {
                                opt.headerFunction(ws, 1, filterDefinition);
                            }

                            if (style && style.marginColumnWidth && ws.data.length) {
                                ws.columnWidthEstimates = ws.columnWidthEstimates || [];
                                ws.columnWidthEstimates[0] = Math.max(
                                    ws.columnWidthEstimates[0] || style.marginColumnWidth
                                );
                                ws.data[0][0].autoWidth = true;
                            }
                        }

                        api.save(
                            file,
                            opt.filename,
                            () => dlg.close(),
                            function (phase, index, total) {
                                // dlg.$scope.index = phase ? ( 0.5 + 0.5 * index / total ) : ( 0.25 + 0.25 * index / total );
                                const relativeProgress = index / total / 2;
                                dlg.componentInstance.progress =
                                    phase === 0 ? relativeProgress : 0.5 + relativeProgress;
                                dlg.componentInstance.state = self._translateService.translate(
                                    phase === 0 ? "FW._Common.Processing" : "FW._Common.Saving"
                                );
                            },
                            () => dlg.close()
                        );
                    },
                    function onProgress(_index, _total) {
                        // TODO: mduracka figure out what needs to be done here
                        // dlg.$scope.index = 0.25 * index / total;
                        // dlg.$scope.safeApply();
                    },
                    opt.style,
                    {
                        headerRowHeight: opt.headerRowHeight,
                        rowHeight: opt.rowHeight,
                        vAlign: opt.vAlign
                    }
                );
            },

            savePivot(
                options: IPivotSaveOptions,
                definition: IColumnExportDefinition[],
                filterDefinition: IFilterExportDefinition[],
                scope: any,
                pivotDefinition: INormalizedLogexPivotDefinition,
                nodes: any[],
                itemNames: string[]
            ) {
                const opt: IPivotSaveOptions = {
                    ...{
                        filename: "table.xlsx",
                        creator: "Logex",
                        sheetName: "Output",
                        filterSheetName: "Filters",
                        row: 0,
                        column: 0,
                        maxDepth: null,
                        headerFunction: null,
                        style: null
                    },
                    ...options
                };
                const dlg = createDialog();
                console.log("create dialog called");
                const file = api.create(opt.creator);
                let ws = file.worksheets[0];
                ws.name = opt.sheetName;

                let row = opt.row || 0;
                if (opt.bigHeader) {
                    row += 2;
                }
                if (opt.infobox && opt.infobox.info && opt.infobox.info.length) {
                    row +=
                        (opt.infobox.row || 0) +
                        getInfoboxHeight(opt.infobox.info) +
                        (opt.infobox.spacing == null ? 1 : opt.infobox.spacing);
                }
                api.renderPivot(
                    ws,
                    row,
                    opt.column,
                    definition,
                    scope,
                    pivotDefinition,
                    nodes,
                    itemNames,
                    opt.maxDepth,
                    opt.includeHidden,
                    function () {
                        if (opt.headerFunction) {
                            opt.headerFunction(ws, 0, definition);
                        }
                        let infoboxRow = opt.infobox ? opt.infobox.row || 0 : 0;
                        if (opt.bigHeader) {
                            api.renderBigHeader(
                                ws,
                                0,
                                opt.column || 0,
                                opt.bigHeader,
                                scope,
                                opt.style
                            );
                            infoboxRow += 2;
                        }
                        if (opt.infobox) {
                            api.renderInfoBox(
                                ws,
                                infoboxRow,
                                opt.infobox.column || 0,
                                opt.infobox.info,
                                scope,
                                opt.infobox.labelSpan || 1,
                                opt.infobox.textSpan || 1,
                                opt.infobox.textAlign,
                                opt.style
                            );
                        }
                        const style = opt.style || globalDefaultStyle;
                        if (style && style.marginColumnWidth && ws.data.length) {
                            ws.columnWidthEstimates = ws.columnWidthEstimates || [];
                            ws.columnWidthEstimates[0] = Math.max(
                                ws.columnWidthEstimates[0] || style.marginColumnWidth
                            );
                            ws.data[0][0].autoWidth = true;
                        }
                        if (api.hasActiveFilters(filterDefinition)) {
                            ws = api.createWorksheet(opt.filterSheetName);
                            file.worksheets.push(ws);
                            api.renderFilters(ws, opt.row, opt.column, scope, filterDefinition);
                            if (opt.headerFunction) {
                                opt.headerFunction(ws, 1, filterDefinition);
                            }
                            if (style && style.marginColumnWidth && ws.data.length) {
                                ws.columnWidthEstimates = ws.columnWidthEstimates || [];
                                ws.columnWidthEstimates[0] = Math.max(
                                    ws.columnWidthEstimates[0] || style.marginColumnWidth
                                );
                                ws.data[0][0].autoWidth = true;
                            }
                        }
                        api.save(
                            file,
                            opt.filename,
                            () => dlg.close(),
                            function (phase, index, total) {
                                // dlg.$scope.index = phase ? ( 0.5 + 0.5 * index / total ) : ( 0.25 + 0.25 * index / total );
                                const relativeProgress = index / total / 2;
                                dlg.componentInstance.progress =
                                    phase === 0 ? relativeProgress : 0.5 + relativeProgress;
                                dlg.componentInstance.state = self._translateService.translate(
                                    phase === 0 ? "FW._Common.Processing" : "FW._Common.Saving"
                                );
                            },
                            () => dlg.close()
                        );
                    },
                    function (_index, _total) {
                        // TODO: mduracka figure out what needs to be done here
                        // dlg.$scope.index = 0.25 * index / total;
                        // dlg.$scope.safeApply();
                    },
                    opt.style,
                    {
                        headerRowHeight: opt.headerRowHeight,
                        rowHeight: opt.rowHeight,
                        vAlign: opt.vAlign
                    },
                    opt.unfiltered
                );
            },

            saveMultiple(options: IMultiSaveOptions, parts: IMultiPartDefinition[]) {
                // todo: mduracka remove all references to scope
                const scope = {};

                const opt: IMultiSaveOptions = {
                    ...{
                        filename: "table.xlsx",
                        creator: "Logex",
                        headerFunction: null
                    },
                    ...options
                };
                const dlg = createDialog();
                const file = api.create(opt.creator);
                let ws = file.worksheets[0];
                let partStep = -1;
                let partNotRendered = false;

                function renderProgress(_index: number, _total: number): void {
                    // TODO: mduracka figure out what needs to be done here
                    // dlg.$scope.index = 0.25 * index / ( total * parts.length );
                    // dlg.$scope.safeApply();
                }

                function renderPart(): void {
                    if (partStep >= 0 && !partNotRendered) {
                        const currentPart = parts[partStep];
                        const wsIndex = file.worksheets.length - 1;
                        const wsHelp = file.worksheets[wsIndex];
                        if (opt.headerFunction) {
                            opt.headerFunction(wsHelp, partStep, parts[partStep]);
                        }
                        let infoboxRow = currentPart.infobox ? currentPart.infobox.row || 0 : 0;
                        if (currentPart.bigHeader) {
                            api.renderBigHeader(
                                wsHelp,
                                0,
                                currentPart.column || 0,
                                currentPart.bigHeader,
                                scope,
                                currentPart.style || options.style
                            );
                            infoboxRow += 2;
                        }
                        if (currentPart.infobox) {
                            api.renderInfoBox(
                                wsHelp,
                                infoboxRow,
                                currentPart.infobox.column || 0,
                                currentPart.infobox.info,
                                scope,
                                currentPart.infobox.labelSpan || 1,
                                currentPart.infobox.textSpan || 1,
                                currentPart.infobox.textAlign,
                                currentPart.style || options.style
                            );
                        }
                        const style = currentPart.style || options.style || globalDefaultStyle;
                        if (style && style.marginColumnWidth && wsHelp.data.length) {
                            wsHelp.columnWidthEstimates = wsHelp.columnWidthEstimates || [];
                            wsHelp.columnWidthEstimates[0] = Math.max(
                                wsHelp.columnWidthEstimates[0] || style.marginColumnWidth
                            );
                            wsHelp.data[0][0].autoWidth = true;
                        }
                    }
                    ++partStep;
                    if (partStep === parts.length) {
                        finish();
                        return;
                    }
                    partNotRendered = false;
                    const part = parts[partStep];
                    let row = part.row || 0;
                    if (part.bigHeader) {
                        row += 2;
                    }
                    if (part.infobox && part.infobox.info && part.infobox.info.length) {
                        row +=
                            (part.infobox.row || 0) +
                            getInfoboxHeight(part.infobox.info) +
                            (part.infobox.spacing == null ? 1 : part.infobox.spacing);
                    }
                    const headerRowHeight =
                        part.headerRowHeight == null ? opt.headerRowHeight : part.headerRowHeight;
                    const rowHeight = part.rowHeight == null ? opt.rowHeight : part.rowHeight;
                    const vAlign = part.vAlign == null ? opt.vAlign : part.vAlign;
                    switch (part.type) {
                        case "pivot":
                            if (partStep > 0) {
                                ws = api.createWorksheet();
                                file.worksheets.push(ws);
                            }
                            ws.name = part.sheetName || "Output";
                            api.renderPivot(
                                ws,
                                row,
                                part.column || 0,
                                part.definition,
                                scope,
                                part.pivotDefinition,
                                part.data,
                                part.itemNames,
                                part.maxDepth,
                                part.includeHidden,
                                renderPart,
                                renderProgress,
                                part.style || options.style,
                                {
                                    headerRowHeight,
                                    rowHeight,
                                    vAlign
                                },
                                part.unfiltered,
                                part.pivotContext
                            );
                            break;
                        case "array":
                            if (partStep > 0) {
                                ws = api.createWorksheet();
                                file.worksheets.push(ws);
                            }
                            ws.name = part.sheetName || "Output";
                            api.renderArray(
                                ws,
                                row,
                                part.column || 0,
                                part.definition,
                                scope,
                                part.data,
                                part.itemName,
                                renderPart,
                                renderProgress,
                                part.style || options.style,
                                {
                                    headerRowHeight,
                                    rowHeight,
                                    vAlign
                                }
                            );
                            break;
                        case "filters":
                            if (api.hasActiveFilters(part.definition)) {
                                if (partStep > 0) {
                                    ws = api.createWorksheet(part.sheetName);
                                    file.worksheets.push(ws);
                                }
                                ws.name = part.sheetName || "Filters";
                                api.renderFilters(
                                    ws,
                                    row,
                                    part.column || 0,
                                    scope,
                                    part.definition,
                                    part.style || options.style
                                );
                            } else {
                                partNotRendered = true;
                            }
                            renderPart();
                            break;
                        default:
                            console.error("Unknown part type: " + part.type);
                            if (partStep === 0) ws.name = part.sheetName || "Unknown";
                            partNotRendered = true;
                            renderPart();
                            break;
                    }
                }

                function finish(): void {
                    api.save(
                        file,
                        opt.filename,
                        () => dlg.close(),
                        function (phase, index, total) {
                            // dlg.componentInstance.currentProgress = phase ? ( 0.5 + 0.5 * index / total ) : ( 0.25 + 0.25 * index / total );
                            const relativeProgress = index / total / 2;
                            dlg.componentInstance.progress =
                                phase === 0 ? relativeProgress : 0.5 + relativeProgress;
                            dlg.componentInstance.state = self._translateService.translate(
                                phase === 0 ? "FW._Common.Processing" : "FW._Common.Saving"
                            );
                        },
                        () => dlg.close()
                    );
                }

                renderPart();
            },

            setDefaultStyle(style: IExportStyle) {
                if (!style) throw new Error("Style is null");

                globalDefaultStyle = style;
            },

            getNormalizedStyle,

            styles: {
                default: defaultStyle,
                logex: defaultLogexStyle
            }
        });
    }
}

function tokenize(accessor: string): any[] {
    // : string[] | string[][] {
    let isBracketOpen = false;

    return accessor
        .split(/([[\]])/)
        .map(x => (x === "[" || x === "]" ? "" : x))
        .map(x => x.split("."))
        .reduce((accumulator: any[], current) => {
            if (current.length === 1 && current[0] === "") {
                isBracketOpen = !isBracketOpen;

                return accumulator;
            }

            if (isBracketOpen) {
                accumulator.push(current);
            } else {
                current.forEach(x => x !== "" && accumulator.push(x));
            }

            return accumulator;
        }, []);
}

function parse(object: object, tokens: any[]): string {
    return tokens.reduce((accumulator, current) => {
        if (typeof current === "string") {
            return accumulator[current];
        }

        const accessor = current.reduce((acc: any, curr: string | number) => {
            return acc[curr];
        }, object);

        return accumulator[accessor];
    }, object);
}

function getCurrencyPrefix(m: LgCurrencyMetadata): string {
    if (m.position === "before") {
        return m.symbol + m.separator;
    }
    return "";
}

function getCurrencySuffix(m: LgCurrencyMetadata): string {
    if (m.position === "after") {
        return m.separator + m.symbol;
    }
    return "";
}
