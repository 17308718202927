import { Component, ViewEncapsulation, Input, inject } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
    LG_APP_CONFIGURATION,
    LG_USER_INFO,
    IAuthenticationService,
    LG_AUTHENTICATION_SERVICE,
    LG_APP_SESSION,
    LG_APP_CONTROL,
    LG_USERFLOW_SERVICE
} from "@logex/framework/lg-application";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { LgReleaseNotesDialogFactory } from "../../lg-release-notes/lg-release-notes-dialog.component";
import { LgReleaseNotesService } from "../../lg-release-notes/lg-release-notes.service";

@Component({
    selector: "lg-sidebar-account",
    templateUrl: "./lg-sidebar-account.component.html",
    encapsulation: ViewEncapsulation.None,
    host: {
        class: "lg-sidebar-account lg-sidebar-panel"
    },
    viewProviders: [useTranslationNamespace("FW._Sidebar._Account")]
})
export class LgSidebarAccountComponent {
    _appControlService = inject(LG_APP_CONTROL);
    _session = inject(LG_APP_SESSION);
    appConfiguration = inject(LG_APP_CONFIGURATION);
    releaseNotes = inject(LgReleaseNotesService);
    userInfo = inject(LG_USER_INFO);
    userflowService = inject(LG_USERFLOW_SERVICE);
    private _authService = inject<IAuthenticationService>(LG_AUTHENTICATION_SERVICE);
    private _releaseNotesDialog = inject(LgReleaseNotesDialogFactory);

    _internal = false;
    _roles: string[] = [];
    _showRoles = false;
    _userMessages = 0;
    private _destroyed$ = new Subject<void>();

    @Input() hideVersionNotifications = false;

    constructor() {
        this._internal = this.userInfo.hasPermission("internal");
        if (this._internal) {
            this._roles = Object.keys(this.userInfo.roles).sort();
        }

        this.releaseNotes.isNew.pipe(takeUntil(this._destroyed$)).subscribe(val => {
            if (this.hideVersionNotifications) return;
            this._userMessages = val ? 1 : 0;
        });
    }

    public _toggle(): void {
        this._showRoles = !this._showRoles;
    }

    _showReleaseNotes(): void {
        this._releaseNotesDialog.show();
    }

    async _logout(): Promise<void> {
        this.userflowService.reset();
        await this._authService.logout();
    }

    async _switchHospital(): Promise<void> {
        this._appControlService.showContextSelector();
    }
}
