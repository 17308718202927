import { InjectionToken } from "@angular/core";

export const LG_APP_CONFIGURATION = new InjectionToken<IAppConfiguration>("lgAppConfiguration");

export interface IAppConfiguration {
    instance: string;
    environment: string;
    applicationInsightsInstrumentationKey?: string;
    applicationInsightsExcludeDomains?: string[];
    userflowEnvironmentKey?: string;
    userflowContentId?: string;

    logoutUrl?: string;
    switchHospital?: boolean;
    switchHospitalUrl?: string;
    switchMergedHospitals?: boolean;
    testMachine?: string;

    applicationRoot: string;

    ready: Promise<void>;

    /**
     * Url leading to My Account application. Specify this in your application config to show My account button in sidebar-account.
     */
    myAccountAppUrl?: string;
}
