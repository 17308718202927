import _ from "lodash";
import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnDestroy,
    Output
} from "@angular/core";

import {
    IQuickSettingsMenuItem,
    LgQuickSettingsMenuHost,
    LgQuickSettingsMenuPopupComponent
} from "@logex/framework/ui-core";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-pivot-table-quick-menu",
    template: `
        <lg-icon
            [icon]="icon"
            class="table__column-icon {{ active ? 'table__column-icon--active' : '' }}"
            [clickable]="true"
        ></lg-icon>
    `
})
export class LgPivotTableQuickMenuComponent extends LgQuickSettingsMenuHost implements OnDestroy {
    private _elementRef = inject(ElementRef);

    /**
     * Definition of menu items (required).
     */
    @Input({ required: true }) definition!: IQuickSettingsMenuItem[];

    @Input() value: any = null;

    /**
     * Menu context.
     *
     * @default null
     */
    @Input() menuContext: any = null;

    /**
     * Specifies if menu disabled or not.
     *
     * @default false
     */
    @Input() set disabled(value: boolean | "true" | "false") {
        this._disabled = toBoolean(value, false);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Menu icon.
     *
     * @default "icon-dots"
     */
    @Input()
    public icon = "icon-dots";

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output("change") readonly change = new EventEmitter<any>();

    @HostBinding("class.lg-pivot-table-quick-menu") menu = true;

    @HostBinding("style.display")
    get display(): string {
        if (!this.definition || this.definition.length === 0) {
            return "none";
        }
        let displayValue = "none";
        for (const item of this.definition) {
            if (_.isNil(item.visible)) {
                return "inherit";
            } else if (typeof item.visible === "function") {
                if (item.visible(this.menuContext)) {
                    displayValue = "inherit";
                }
            } else if (item.visible) {
                displayValue = "inherit";
            }
        }
        return displayValue;
    }

    @HostBinding("class.lg-pivot-table-quick-menu--active")
    get active(): boolean {
        return this._popupActive;
    }

    @HostListener("click", ["$event"])
    click($event?: MouseEvent): void {
        this._showMenu($event);
    }

    _disabled = false;
    _fullClassName = "";

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showMenu($event: MouseEvent): boolean {
        if (this._popupActive || this._disabled) return true;

        $event.stopPropagation();

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]);

        super
            ._showSettingsPopup(this._elementRef, strategy, true, {
                definition: this.definition,
                value: _.cloneDeep(this.value),
                menuContext: this.menuContext,
                depth: 0,
                compact: true,
                alwaysLeftIcon: true // TODO : do we want this modifiable?
            })
            .subscribe(
                result => {
                    this._close();
                    if (!_.isEqual(this.value, result)) {
                        this.value = result;
                        this.change.next(result);
                    }
                },
                null,
                () => this._close()
            );

        return false;
    }

    private _close(): void {
        this._hideSettingsPopup();
    }
}
