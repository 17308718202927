import { InjectionToken, TemplateRef } from "@angular/core";
import { Observable } from "rxjs";

import type { RowTemplateCollection, IRowTemplateBase } from "./helpers/row-template-collection";
import type { HeaderContext } from "./helpers/header-context";
import type { EmptyRowContext } from "./helpers/empty-row-context";

// ---------------------------------------------------------------------------------------------
//  Types used by the row templates
// ---------------------------------------------------------------------------------------------
export type ItemsMap<T> = Map<string | number, T>;
export type LgPivotTableRowVisibility<T, P> = (item: T, items: ItemsMap<P>) => boolean;
export type LgPivotTableRowHeight<T, P> = (item: T, items: ItemsMap<P>) => number;

/**
 * Should return one of the following values:
 * "always" or 2  = always render separator (even after last row)
 * truthy value = render separator
 * falsy value = do not render separator
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type LgPivotTableRowSeparatorType<T, P> = (items: ItemsMap<P>) => boolean | string | number;

export const enum RowType {
    Row = 0,
    RowFooter = 1,
    Separator = 2
}

export enum RowSeparatorType {
    None = 0,
    Between = 1,
    Always = 2
}

export interface IRowSeparatorTemplate extends IRowTemplateBase {
    getVisible<T, P>(item: T, items: ItemsMap<P>): boolean;
    getHeight<T, P>(item: T, items: ItemsMap<P>, defaultHeight: number): number;
}

export interface IRowFooterTemplate extends IRowSeparatorTemplate {
    // empty
}

export interface IRowTemplate extends IRowSeparatorTemplate {
    getSeparatorType<T>(items: ItemsMap<T>, hasTemplate: boolean): RowSeparatorType;
}

export interface IHeaderFooterTemplate<T> {
    template: TemplateRef<HeaderContext<T>>;
}

export interface IEmptyRowTemplate {
    template: TemplateRef<EmptyRowContext>;
}

// ---------------------------------------------------------------------------------------------
//  Interface for holder of the row templates
// ---------------------------------------------------------------------------------------------
export interface IBodyRegister {
    rowTemplates: RowTemplateCollection<IRowTemplate>;
    rowSeparatorTemplates: RowTemplateCollection<IRowSeparatorTemplate>;
    rowFooterTemplates: RowTemplateCollection<IRowSeparatorTemplate>;
    addEmptyRowTemplate(template: IEmptyRowTemplate): void;
    removeEmptyRowTemplate(template: IEmptyRowTemplate): void;
    _notifyHover(element: any, level: number, hover: boolean): void;
    _hoverObservable(): Observable<any>;
    _hoverLevelObservable(): Observable<number | null>;
}

export const LgPivotTableBodyRegister = new InjectionToken<IBodyRegister>(
    "LgPivotTableBodyRegister"
);

// ---------------------------------------------------------------------------------------------
//  Interface for global level expansion
// ---------------------------------------------------------------------------------------------
export interface ILevelExpander {
    getAllExpanded(level: number): boolean;
    toggleLevel(level: number, expanded?: boolean): void;
}

export const LgPivotTableLevelExpander = new InjectionToken<ILevelExpander>(
    "LgPivotTableLevelExpander"
);

export interface IItemExpander {
    toggleExpand(item: any, state?: boolean): void;
}

export const LgPivotTableItemExpander = new InjectionToken<IItemExpander>(
    "LgPivotTableItemExpander"
);

// ---------------------------------------------------------------------------------------------
//  Event types
// ---------------------------------------------------------------------------------------------
export interface IToggleEvent {
    item: any;
    expanded: boolean;
    maxVisibleLevel: number;
}

export interface IToggleLevelEvent {
    level: number;
    expanded: boolean;
    maxVisibleLevel: number;
}

// ---------------------------------------------------------------------------------------------
//  Level definitions
// ---------------------------------------------------------------------------------------------
export interface IPivotTableLevelHeader {
    id?: string;
    type?: string;
    headerCls?: string;
    columnCls?: string;
    width?: string | number;
    headerType?: string;
    headerLc?: string;
    header?: string;
    headerHtml?: string;
    orderBy?: string | string[];
    hidden?: boolean;
}

export type IPivotTableLevelHeaderDefinition = Array<
    null | undefined | true | string[] | IPivotTableLevelHeader
>;

export type VerticalPosition = "top" | "bottom" | "middle";
