import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ComponentRef,
    Injector,
    OnDestroy,
    HostListener,
    inject
} from "@angular/core";
import { ComponentPortal } from "@angular/cdk/portal";
import { Observable, isObservable, Subscription } from "rxjs";

import { LgTooltipService, TooltipApi } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "../filter-definition";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";

@Component({
    selector: "lg-filterset-preview-item",
    template: `
        {{ _observedName || _name }}
        <lg-icon icon="icon-close" (click)="_clear()"></lg-icon>
    `,
    host: {
        class: "lg-filterset-preview__item",
        "[class.lg-filterset-preview__item--no-popup]": "!_hasPopup",
        "[class.lg-filterset-preview__item--dynamic]": "_observableName != null",
        "[attr.lg-filter-id]": "definition.id",
        "[title]": "_observedName || _name"
    }
})
export class LgFiltersetPreviewItemComponent implements OnDestroy {
    private _elementRef = inject(ElementRef);
    private _injector = inject(Injector);
    private _tooltipService = inject(LgTooltipService);

    /**
     * Definition of filter (required).
     */
    @Input({ required: true }) set definition(value: IFilterDefinition) {
        this._definition = value;
        this._hasPopup = value.renderer.getPopupComponent != null;

        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }

        this._observableName = null;
        this._name = value.name;
        if (value.renderer.getPreviewName != null) {
            const result = value.renderer.getPreviewName();
            if (result) {
                if (isObservable(result)) {
                    this._subscription = result.subscribe(val => {
                        this._observedName = val;
                    });
                    this._observableName = result;
                } else if (result != null) {
                    this._name = result;
                }
            }
        }
    }

    get definition(): IFilterDefinition {
        return this._definition;
    }

    /**
     * @required
     * List of all filters.
     */
    @Input({ alias: "list", required: true }) filterSet!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    _hasPopup: boolean;
    _name: string;
    _observableName: Observable<string>;
    _observedName: string | null;

    private _definition: IFilterDefinition;
    private _tooltipApi: TooltipApi;
    private _subscription: Subscription;

    constructor() {
        this._tooltipApi = this._tooltipService.create({
            stay: true,
            position: "bottom-right",
            tooltipClass: "lg-filterset-preview__popup lg-tooltip",

            content: () => this._getContent(),

            target: () => this._elementRef,

            postShow: () => {
                this._tooltipApi
                    .getPortalReference<ComponentRef<FilterRendererComponentBase<any, any>>>()
                    .instance._initialize(
                        this._definition,
                        this.filterSet.filters,
                        true,
                        () => this._change(),
                        () => this._clear()
                    );
            }
        });
    }

    ngOnDestroy(): void {
        if (this._tooltipApi.visible) this._tooltipApi.hide();
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }

    private _change(): void {
        this.filterChange.next();
        if (!this._definition.renderer.previewVisible()) {
            this._tooltipApi.hide();
        }
    }

    @HostListener("click")
    _showPopup(): void {
        if (!this._hasPopup) return;
        this._tooltipApi.show();
    }

    _clear(): void {
        if (this._definition.renderer.clear()) {
            this._change();
            this._tooltipApi.hide();
        }
    }

    private _getContent(): ComponentPortal<FilterRendererComponentBase<any, any>> | null {
        const component = this._definition.renderer.getPopupComponent();
        if (!component) return null;
        return new ComponentPortal(component, null, this._injector);
    }
}
