<span *ngIf="_initialized">
    <span class="lg-year-month-range-selector-popup__year-selector">
        <span
            *ngIf="!_config?.singleYearPicking"
            class="lg-year-month-range-selector-popup__select-all"
            (click)="_selectAll(true)"
            >{{ ".Year_Month_Range_Selector__All_years" | lgTranslate }}</span
        >
        <span
            #year
            *ngFor="let y of _years"
            class="lg-year-month-range-selector-popup__item"
            [attr.data-id]="y"
            [ngClass]="_className(y, true)"
            (mouseover)="_onMouseOver($event, y, true)"
            (mouseout)="_onMouseOut($event, y, true)"
            (mousedown)="_onMouseDown(y, true)"
            title="{{ y }}"
            (click)="_onClick($event, y, true)"
            >{{ y }}</span
        >
    </span>
    <span *ngIf="_showMonthsPicker" class="lg-year-month-range-selector-popup__month-selector">
        <span
            *ngIf="!_config?.singleMonthPicking"
            class="lg-year-month-range-selector-popup__select-all"
            (click)="_selectAll()"
            >{{ ".Year_Month_Range_Selector__All_months" | lgTranslate }}</span
        >
        <span
            #month
            *ngFor="let m of _months; let index = index"
            class="lg-year-month-range-selector-popup__item"
            [attr.data-id]="index + 1"
            [ngClass]="_className(index + 1)"
            (mouseover)="_onMouseOver($event, index + 1)"
            (mouseout)="_onMouseOut($event, index + 1)"
            (mousedown)="_onMouseDown(index + 1)"
            title="{{ _getMonthTooltip(index + 1) | capitalize }}"
            (click)="_onClick($event, index + 1)"
            >{{ _shortNames[m] | removeSymbol : "DOT" | capitalize }}</span
        >
    </span>
</span>
