import { Component, inject, Input } from "@angular/core";

import { SidebarButtonBase } from "./sidebar-button-base";
import {
    LG_SCENARIO_DIALOG_CONFIG,
    LgScenarioDialog
} from "../lg-scenario-dialog/lg-scenario-dialog.component";
import {
    LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG,
    LgScenarioDialogWithPivot
} from "../lg-scenario-dialog/lg-scenario-dialog-with-pivot.component";

@Component({
    selector: "lg-sidebar-scenario",
    template: ``,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ["onTop", "isVisible", "order"]
})
export class LgSidebarScenarioComponent extends SidebarButtonBase {
    private _dialogConfig = inject(LG_SCENARIO_DIALOG_CONFIG, { optional: true });
    private _dialogWithPivotConfig = inject(LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG, {
        optional: true
    });

    private _scenarioDialog = inject(LgScenarioDialog);
    private _scenarioDialogWithPivot = inject(LgScenarioDialogWithPivot);

    @Input() useScenarioDialogWithPivot = false;

    constructor() {
        super();

        this._nameLc = "FW._ScenarioManagement.ScenarioManagement_Button";
        this._icon = "icon-sidebar-scenario";

        this.onClick.subscribe(() => {
            if (this.useScenarioDialogWithPivot) {
                this._scenarioDialogWithPivot.show(this._dialogWithPivotConfig);
            } else {
                this._scenarioDialog.show(this._dialogConfig);
            }
        });
    }
}
