import { Component, Input, HostBinding } from "@angular/core";
import { trigger, state, transition, style, animate } from "@angular/animations";

import { easingDefs } from "@logex/framework/utilities";

interface SliderAnimationParams {
    left: number;
    width: number;
}

interface SwitchAnimationInput {
    params: SliderAnimationParams;
}

interface SliderStateInput {
    value: string;
    params: SliderAnimationParams;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ModSwitchSliderDefaultParameters(): SwitchAnimationInput {
    return { params: { left: 0, width: 0 } };
}

@Component({
    standalone: true,
    selector: "mod-switch-slider",
    template: "",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    animations: [
        trigger("state", [
            state("initHidden, hide", style({ opacity: 0 })),
            state(
                "*",
                style({ transform: "translateX({{left}}px)", width: "{{width}}px" }),
                ModSwitchSliderDefaultParameters()
            ),
            transition(
                "* => slide0, * => slide1, * => slide2, * => slide3, * => slide4",
                animate(
                    `200ms ${easingDefs.easeInAdam}`,
                    style({ transform: "translateX({{left}}px)", width: "{{width}}px" })
                ),
                ModSwitchSliderDefaultParameters()
            ),
            transition("* => appear", [
                style({ transform: "translateX({{left}}px)", width: "{{width}}px" }),
                animate(`200ms ${easingDefs.easeInAdam}`, style({ opacity: 1 }))
            ]),
            transition("* => hide", [
                style({ transform: "translateX({{left}}px)", width: "{{width}}px" }),
                animate(`200ms ${easingDefs.easeOutAdam}`, style({ opacity: 0 }))
            ])
        ])
    ],
    host: {
        class: "mod-switch-slider"
    }
})
export class ModSwitchSliderComponent {
    @Input({ required: true }) width!: number;
    @Input({ required: true }) left!: number;
    @Input() set command(val: string) {
        this._command = val;
    }

    get command(): string {
        return this._command;
    }

    @HostBinding("@state") get _state(): SliderStateInput {
        return {
            value: this._command,
            params: this._getAnimationParams()
        };
    }

    private _command: string;

    private _getAnimationParams(): SliderAnimationParams {
        if (this.left == null || this.width == null) {
            return {
                left: 0,
                width: 0
            };
        } else {
            return {
                left: this.left,
                width: this.width
            };
        }
    }
}
