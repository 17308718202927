import { InjectionToken } from "@angular/core";

export const LG_APP_INFO = new InjectionToken<IAppInfo>("lgAppInfo");

export interface IAppInfo {
    environment: string;
    fullAppName: string;
    productId: string;
    toolInstanceName: string;
    versionNumber: string;
    overrideCurrencyPrefix: string;
    overrideCurrencySuffix: string;

    doNotDoGaTracking(): boolean;
    isProduction(): boolean;
}
