import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgFiltersetModule } from "@logex/framework/lg-filterset";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LG_APP_LOAD_INDICATOR, LgApplicationModule } from "@logex/framework/lg-application";
import {
    LG_SCENARIO_GATEWAY,
    LgScenarioDialogComponent,
    LgScenarioDialogWithPivotComponent,
    LgScenarioGateway
} from "./lg-scenario-dialog";
import {
    LgExportDefinitionsComponent,
    LgExportEntryComponent,
    LgLanguageSwitchComponent,
    LgSidebarAccountComponent,
    LgSidebarButtonComponent,
    LgSidebarComponent,
    LgSidebarDevWidgetComponent,
    LgSidebarExportsComponent,
    LgSidebarHelpComponent,
    LgSidebarImpersonationComponent,
    LgSidebarMenuComponent,
    LgSidebarMenuItemComponent,
    LgSidebarMergedHospitalSwitchComponent,
    LgSidebarPanelDirective,
    LgSidebarPanelHolderComponent,
    LgSidebarScenarioComponent,
    LgSidebarSettingsComponent,
    LgSidebarSwitchComponent,
    LgSidebarWidgetDirective
} from "./lg-sidebar";
import {
    LgBookmarksMenuComponent,
    LgFiltersetFormRowComponent,
    LgFiltersetRowComponent,
    LgFiltersPanelComponent,
    LgFiltersPanelFooterComponent
} from "./lg-filterset";
import {
    LgBookmarkEditDialog,
    LgBookmarkEditDialogComponent
} from "./lg-filterset/components/lg-bookmark-edit-dialog.component";
import {
    LgContentGridComponent,
    LgContentGridGroupComponent,
    LgContentGridItemComponent,
    LgContentGridMaximizeButtonComponent
} from "./lg-content-layout";
import { LgBreadcrumbComponent } from "./lg-breadcrumb";
import { LgBreadcrumbDataContextComponent } from "./lg-breadcrumb-data-context";
import {
    LgPanelChoiceComponent,
    LgPanelChoiceVariantComponent,
    LgPanelSwitchComponent,
    LgPanelSwitchTooltipComponent
} from "./lg-panel-switch";
import { LgDrilldownBreadcrumbComponent } from "./lg-drilldown-breadcrumb";
import { LgReleaseNotesDialogComponent, LgReleaseNotesDialogFactory } from "./lg-release-notes";
import {
    LgSlideoutButtonRowComponent,
    LgSlideoutComponent,
    LgSlideoutHolderComponent
} from "./lg-slideout";
import { LgLoaderService } from "./lg-loader";
import { LgSlideoutHolderHeaderComponent } from "./lg-slideout/lg-slideout-holder-header.component";
import { LgPanelGridModule } from "./lg-panel-grid";

const STANDALONE = [LgSidebarPanelDirective, LgSidebarWidgetDirective];

const SUBMODULES = [LgPanelGridModule];

@NgModule({
    imports: [
        CommonModule,
        LgLocalizationModule,
        UiCoreModule,
        LgFiltersetModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        LgPivotTableModule,
        UiToolboxModule,
        LgApplicationModule,
        ...SUBMODULES,
        ...STANDALONE
    ],
    exports: [
        LgSidebarComponent,
        LgSidebarPanelDirective,
        LgSidebarButtonComponent,
        LgSidebarScenarioComponent,
        LgSidebarWidgetDirective,
        LgSidebarHelpComponent,
        LgSidebarAccountComponent,
        LgSidebarImpersonationComponent,
        LgSidebarMenuComponent,
        LgSidebarDevWidgetComponent,
        LgSidebarSettingsComponent,
        LgSidebarSwitchComponent,
        LgSidebarMergedHospitalSwitchComponent,
        LgSidebarExportsComponent,
        LgLanguageSwitchComponent,
        LgScenarioDialogComponent,
        LgScenarioDialogWithPivotComponent,
        LgFiltersetRowComponent,
        LgFiltersPanelComponent,
        LgBookmarksMenuComponent,
        LgFiltersPanelFooterComponent,
        LgFiltersetFormRowComponent,
        LgContentGridMaximizeButtonComponent,
        LgContentGridComponent,
        LgContentGridGroupComponent,
        LgContentGridItemComponent,
        LgBreadcrumbComponent,
        LgBreadcrumbDataContextComponent,
        LgDrilldownBreadcrumbComponent,
        LgPanelSwitchComponent,
        LgPanelSwitchTooltipComponent,
        LgPanelChoiceVariantComponent,
        LgPanelChoiceComponent,
        LgExportDefinitionsComponent,
        LgExportEntryComponent,
        LgSlideoutComponent,
        LgSlideoutHolderComponent,
        LgSlideoutButtonRowComponent,
        ...SUBMODULES,
        ...STANDALONE
    ],
    declarations: [
        LgSidebarComponent,
        LgSidebarPanelHolderComponent,
        LgSidebarButtonComponent,
        LgSidebarScenarioComponent,
        LgSidebarHelpComponent,
        LgSidebarAccountComponent,
        LgSidebarImpersonationComponent,
        LgSidebarMenuComponent,
        LgSidebarMenuItemComponent,
        LgSidebarSettingsComponent,
        LgSidebarSwitchComponent,
        LgSidebarMergedHospitalSwitchComponent,
        LgSidebarDevWidgetComponent,
        LgSidebarExportsComponent,
        LgLanguageSwitchComponent,
        LgScenarioDialogComponent,
        LgScenarioDialogWithPivotComponent,
        LgFiltersetRowComponent,
        LgFiltersPanelComponent,
        LgBookmarksMenuComponent,
        LgFiltersPanelFooterComponent,
        LgFiltersetFormRowComponent,
        LgBookmarkEditDialogComponent,
        LgContentGridMaximizeButtonComponent,
        LgContentGridComponent,
        LgContentGridGroupComponent,
        LgContentGridItemComponent,
        LgBreadcrumbComponent,
        LgBreadcrumbDataContextComponent,
        LgDrilldownBreadcrumbComponent,
        LgPanelSwitchComponent,
        LgPanelSwitchTooltipComponent,
        LgPanelChoiceVariantComponent,
        LgPanelChoiceComponent,
        LgReleaseNotesDialogComponent,
        LgExportDefinitionsComponent,
        LgExportEntryComponent,
        LgSlideoutComponent,
        LgSlideoutHolderComponent,
        LgSlideoutHolderHeaderComponent,
        LgSlideoutButtonRowComponent
    ],
    providers: [
        LgBookmarkEditDialog,
        LgReleaseNotesDialogFactory,
        { provide: LG_SCENARIO_GATEWAY, useClass: LgScenarioGateway },
        { provide: LG_APP_LOAD_INDICATOR, useClass: LgLoaderService }
    ]
})
export class LgLayoutModule {
    // empty
}
