import { TemplateRef, OnDestroy, OnInit, Directive, inject } from "@angular/core";
import { LgSidebarService } from "./lg-sidebar.service";
import { toBoolean, toNumber } from "@logex/framework/utilities";

export interface SidebarWidget {
    widgetTemplate: TemplateRef<any> | null;
    onTop: boolean;
    isVisible: boolean;
    order: number;
    id: string;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SidebarWidgetBase implements OnInit, OnDestroy, SidebarWidget {
    protected _service = inject(LgSidebarService);
    protected _templateRef = inject(TemplateRef<any>);

    get widgetTemplate(): TemplateRef<any> {
        return this._templateRef;
    }

    set onTop(value: boolean) {
        const oldValue = this._onTop;
        this._onTop = toBoolean(value);
        if (oldValue !== this._onTop) {
            this._service.swapWidgetPosition(this);
        }
    }

    get onTop(): boolean {
        return this._onTop;
    }

    set isVisible(value: boolean) {
        const oldValue = this._isVisible;
        this._isVisible = toBoolean(value);
        if (oldValue !== this._isVisible) {
            this._service.handleWidgetVisibility(this);
        }
    }

    get isVisible(): boolean {
        return this._isVisible;
    }

    set order(value: number) {
        const oldValue = this._order;
        this._order = toNumber(value);
        if (oldValue !== this._order) {
            this._service.reorderWidget(this);
        }
    }

    get order(): number {
        return this._order;
    }

    set id(value: string) {
        this._id = value;
    }

    get id(): string {
        return this._id;
    }

    protected _initialized = false;
    protected _onTop = false;
    protected _isVisible = true;
    protected _order = 0;
    protected _id: string;

    ngOnInit(): void {
        this._initialized = true;
        this._service.addWidget(this);
    }

    ngOnDestroy(): void {
        this._service.removeWidget(this);
    }
}
