import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { LgDropdownComponent } from "../lg-dropdown/lg-dropdown.component";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-combo-button-dropdown",
    template: `
        <button
            type="button"
            [class]="'button lg-combo-button-dropdown__button'"
            [disabled]="disabled"
        >
            <lg-icon
                icon="icon-arrow-down"
                class="lg-combo-button-dropdown__button__icon"
            ></lg-icon>
        </button>
    `,
    host: {
        "[class.lg-combo-button-dropdown--active]": "_active"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [LgIconComponent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgComboButtonDropdownComponent),
            multi: true
        }
    ]
})
export class LgComboButtonDropdownComponent<
    T extends number | string
> extends LgDropdownComponent<T> {
    /**
     * Specifies if selected option should be erased after emit.
     */
    @Input() eraseSelectedOptionAfterEmit: boolean;

    /**
     * Emits selected values.
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output("select") readonly select = new EventEmitter<T>();

    @HostBinding("class") get _hostClass(): string {
        return "lg-combo-button-dropdown";
    }

    constructor() {
        super();
        this.hideSearch = true;
        this.matchWidth = false;
        this._attachOnLeft = false;
        this.popupClassName = "lg-dropdown-popup lg-dropdown-popup--combo-button";
        this.registerOnChange(this._onSelect);
    }

    private _onSelect(id: T): void {
        this.select.emit(id);

        if (this.eraseSelectedOptionAfterEmit) this.writeValue(null);
    }
}
