import { InjectionToken } from "@angular/core";
import { StringKeyOf } from "@logex/framework/types";
import _ from "lodash";

export const LG_DEFINITIONS_HIERARCHY_SERVICE = new InjectionToken<ILgDefinitionsHierarchyService>(
    "lgDefinitionsHierarchyService"
);

export type DefinitionSectionType<
    TDefinition,
    TSection extends keyof TDefinition
> = TDefinition[TSection] extends _.Dictionary<infer R> ? R : never;

export interface ILgDefinitionsHierarchyService<TDefinitions = any> {
    getAncestors(definition: keyof TDefinitions, maxDepth?: number): Array<keyof TDefinitions>;

    getDescendants(definition: keyof TDefinitions, maxDepth?: number): Array<keyof TDefinitions>;

    addIntermediateDefinitions(
        definitions: Array<StringKeyOf<TDefinitions>>
    ): Array<StringKeyOf<TDefinitions>>;

    getHierarchy<T extends Array<StringKeyOf<TDefinitions>>>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        ancestors?: T
    ): { [P in T[number]]?: DefinitionSectionType<TDefinitions, P> };

    getHierarchyKeys<TMapping extends { [k: string]: StringKeyOf<TDefinitions> }>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        mapping: TMapping
    ): { [P in keyof TMapping]: unknown };

    getHierarchyOrderBy<TMapping extends { [k: string]: StringKeyOf<TDefinitions> }>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        mapping: TMapping
    ): { [P in keyof TMapping]: unknown };
}
