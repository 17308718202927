import { isObject, isString, startsWith } from "lodash";
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { Router } from "@angular/router";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { ILookup } from "@logex/framework/types";
import { is, RegexValidatorType } from "@logex/framework/utilities";
import { NgClass, NgIf } from "@angular/common";
import { LgIconComponent } from "./lg-icon/lg-icon.component";

enum MessageType {
    Warning = 0,
    Error = 1,
    Errors = 2,
    Ok = 3
}

const ICON_OK = "";
const ICON_ERROR = "status-icon--error";
const ICON_WARN = "status-icon-warning"; // old one

const ICON_NAME_OK = "icon-check";
const ICON_NAME_ERROR = "";
const ICON_NAME_WARN = "";

@Component({
    standalone: true,
    selector: "lg-status-icon",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._StatusIcon")],
    imports: [NgClass, LgIconComponent, NgIf],
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            tabindex="0"
            class="status-icon {{ iconClass }} {{ class }}"
            [ngClass]="{ selected: selected, clickable: clickable }"
            [hidden]="!visible"
            title="{{ tooltip }}"
            (click)="clicked($event)"
        >
            <lg-icon *ngIf="iconName" [icon]="iconName" class="icon__color-blue"></lg-icon>
            {{ text }}
        </div>
    `
})
export class LgStatusIconComponent implements OnInit, OnChanges {
    _translateService = inject(LgTranslateService);
    private _router = inject(Router);

    /**
     * Icon type (required).
     *
     * success: "0" or "ok", error: otherwise.
     */
    @Input({ required: true }) type!: string;

    /**
     * Apply css class to component root.
     */
    @Input() class: string | null = null;

    /**
     * Specifies if item is selected (highlighted if yes).
     */
    @Input() selected = false;

    /**
     * Specifies if icon is clickable.
     */
    @Input() clickable = false;

    /**
     * Route link
     */
    @Input() href: string | null = null;

    /**
     * Emit values on click (if `href` is not specified).
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly click = new EventEmitter<string>();

    visible: boolean;
    iconClass: string;
    iconName: string;
    text: string;
    tooltip: string;
    messages: string | ILookup<string>;

    private _messageType: MessageType;
    private _defaultMessage: string;

    ngOnInit(): void {
        this._handleChange();
    }

    ngOnChanges(): void {
        this._handleChange();
    }

    clicked($event: UIEvent): void {
        $event.preventDefault();
        $event.stopPropagation();

        if (!this.clickable) {
            return;
        }

        if (this.href) {
            let url = this.href;
            if (startsWith(url, "#!")) {
                url = url.substring(2);
            }
            this._router.navigateByUrl(url);
        } else {
            this.click.emit(this.text);
        }
    }

    private _handleChange(): void {
        this.visible = true;

        if (is(this.type, RegexValidatorType.PositiveNumber)) {
            this._handleNumericInput();
        } else {
            this._handleStringInput();
        }

        this.tooltip = this._getTooltipMessage();
    }

    private _handleNumericInput(): void {
        const parsedValue = parseInt(this.type);
        let parsedValueString = `${parsedValue}`;

        if (parsedValue < 0) parsedValueString = "" + 0;
        if (parsedValue > 99) parsedValueString = "99+";

        if (parsedValue === 0) {
            this._setState(ICON_OK, "", ".NoErrors", MessageType.Ok, ICON_NAME_OK);
        } else {
            this._setState(
                ICON_ERROR,
                parsedValueString,
                ".HasErrors",
                parsedValue === 1 ? MessageType.Error : MessageType.Errors,
                ICON_NAME_ERROR
            );
        }
    }

    private _handleStringInput(): void {
        if (this.type === "warning") {
            this._setState(ICON_WARN, "", ".HasErrors", MessageType.Warning, ICON_NAME_WARN);
        } else if (this.type === "ok") {
            this._setState(ICON_OK, "", ".NoErrors", MessageType.Ok, ICON_NAME_OK);
        } else {
            this._setState("", "", "", undefined, "", false);
        }
    }

    private _setState(
        iconClass: string,
        text: string,
        defaultMessageResource: string,
        messageType: MessageType,
        iconName: string,
        visible = true
    ): void {
        this.iconClass = iconClass;
        this.iconName = iconName;
        this.text = text;
        this._defaultMessage = this._translateService.translate(defaultMessageResource);
        this._messageType = messageType;
        this.visible = visible;
    }

    private _getTooltipMessage(): string {
        if (this._messageType === undefined) {
            return "";
        }

        if (!this.messages) {
            return this._defaultMessage;
        }

        if (isString(this.messages)) {
            return this.messages;
        }

        if (isObject(this.messages)) {
            return this._getMessage(this.messages, this._messageType, this._defaultMessage);
        }

        console.error("lg-status-icon/@messages must contain object or string", this.messages);
        return "";
    }

    private _getMessage = (
        messages: ILookup<string>,
        messageType: MessageType,
        defaultMessage: string
    ): string => {
        const ma: string[] = [];
        ma.push(messages.warning);
        ma.push(messages.error);
        ma.push(messages.errors);
        ma.push(messages.ok);
        ma.push(defaultMessage);

        let msg = undefined;
        for (let i = messageType; i < ma.length && msg == null; i++) {
            msg = ma[i];
        }

        return msg;
    };
}
