import { NgZone, Injectable, inject } from "@angular/core";
import { isElementVerticalWriting } from "@logex/framework/utilities";

import { EventsPluginBase } from "./EventsPluginBase";

export type ResizeObservedEvent = CustomEvent<ResizeObserverEntry>;

enum RegistrationType {
    Generic = 0,
    Width = 1,
    Height = 2
}

type EventHandler = (event: Event) => void;

interface Registration {
    id: number;
    type: RegistrationType;
    handler: EventHandler;
    lastWidth: number | null;
    lastHeight: number | null;
}

@Injectable()
export class ResizeObservedEventsPlugin extends EventsPluginBase {
    private _ngZone = inject(NgZone);

    public supports(eventName: string): boolean {
        return this._parse(eventName) !== undefined;
    }

    public override addEventListener(
        element: Element,
        eventName: string,
        originalHandler: (event: Event) => void
    ): () => void {
        const registration: Registration = {
            id: ++this._identity,
            type: this._parse(eventName),
            handler: originalHandler,
            lastWidth: null,
            lastHeight: null
        };

        let list = this._handlerMap.get(element);
        if (!list) {
            list = [];
            this._handlerMap.set(element, list);
        }

        list.push(registration);
        this._count += 1;

        this._ngZone.runOutsideAngular(() => {
            if (!this._observer) {
                this._createObserver();
            }

            if (list.length === 1) {
                this._observer.observe(element);
            }
        });

        return () => {
            const pos = list.indexOf(registration);
            if (pos !== -1) {
                list.splice(pos, 1);
                if (list.length === 0) {
                    this._handlerMap.delete(element);
                    this._observer.unobserve(element);
                }

                --this._count;
                if (this._count === 0) {
                    this._destroyObserver();
                }
            }
        };
    }

    private _createObserver(): void {
        this._observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            try {
                for (const entry of entries) {
                    const registrations = this._handlerMap.get(entry.target);
                    if (registrations) {
                        const event = new CustomEvent<ResizeObserverEntry>("resizeObserved", {
                            detail: entry
                        });
                        const { inlineSize, blockSize } = event.detail.contentBoxSize[0];
                        const isVertical = isElementVerticalWriting(entry.target);
                        const width = isVertical ? blockSize : inlineSize;
                        const height = isVertical ? inlineSize : blockSize;
                        for (const registration of registrations) {
                            if (registration.type === RegistrationType.Generic) {
                                registration.handler(event);
                            } else if (registration.type === RegistrationType.Width) {
                                if (width !== registration.lastWidth) {
                                    registration.lastWidth = width;
                                    registration.handler(event);
                                }
                            } else if (height !== registration.lastHeight) {
                                registration.lastHeight = height;
                                registration.handler(event);
                            }
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });
    }

    private _destroyObserver(): void {
        this._observer.disconnect();
        this._observer = null;
    }

    private _parse(eventName: string): RegistrationType | undefined {
        switch (eventName) {
            case "resizeObserved":
                return RegistrationType.Generic;
            case "resizeObserved.width":
                return RegistrationType.Width;
            case "resizeObserved.height":
                return RegistrationType.Height;
        }
        return undefined;
    }

    private _count = 0;
    private _identity = 0;
    private _observer: ResizeObserver;
    private _handlerMap: Map<Element, Registration[]> = new Map();
}
