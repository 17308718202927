import _ from "lodash";
import {
    Component,
    Input,
    Output,
    ViewEncapsulation,
    forwardRef,
    ViewChild,
    EventEmitter,
    OnDestroy,
    AfterViewChecked,
    DoCheck,
    inject
} from "@angular/core";
import { Observable } from "rxjs";

import * as Pivots from "@logex/framework/lg-pivot";
import { LgConsole } from "@logex/framework/core";
import { toInteger, toBoolean, scheduleChangeDetection } from "@logex/framework/utilities";
import { LgPrimitive } from "@logex/framework/types";

import {
    LgPivotTableBodyRegister,
    IBodyRegister,
    IToggleEvent,
    IToggleLevelEvent,
    IHeaderFooterTemplate,
    ILevelExpander,
    LgPivotTableLevelExpander,
    IEmptyRowTemplate,
    VerticalPosition,
    IRowTemplate,
    IRowSeparatorTemplate
} from "./types";
import { LgPivotTableBodyComponent } from "./lg-pivot-table-body.component";
import { RowTemplateCollection } from "./helpers/row-template-collection";

export interface PreFilterEvent<TData = any, TTotals = any, TBuildTotals = TTotals> {
    definition: Pivots.INormalizedLogexPivotDefinition;
    data: TData[];
    table: LgPivotTableComponent<TData, TTotals, TBuildTotals>;
}

export interface PostFilterEvent<TData = any, TTotals = any, TBuildTotals = TTotals>
    extends PreFilterEvent<TData, TTotals, TBuildTotals> {
    filteredData: TData[];
    totals: TTotals;
}

// Note that the totals are those returned by calculateOnce block
export interface PostBuildEvent<TData = any, TTotals = any, TBuildTotals = TTotals>
    extends PreFilterEvent<TData, TTotals, TBuildTotals> {
    totals: TBuildTotals;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function LgPivotTableBodyComponentFactory(
    table: LgPivotTableComponent
): LgPivotTableBodyComponent {
    return table._body;
}

const BASE_ITEM_HEIGHT = 28;
const HIGHER_ITEM_HEIGHT = 36;

@Component({
    selector: "lg-pivot-table",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <lg-pivot-table-header-container
            [table]="this"
            [template]="_headerTemplate"
            [totals]="_totals"
            [header]="true"
        ></lg-pivot-table-header-container>
        <lg-pivot-table-body
            #body
            [class.flex-flexible]="stretchable"
            [definition]="definition"
            [filteredData]="filteredData"
            [itemHeight]="itemHeight"
            [higherRows]="higherRows"
            [separatorHeight]="separatorHeight"
            [pivotContext]="pivotContext"
            [renderExtraRows]="renderExtraRows"
            [ensureRowsOutsideView]="ensureRowsOutsideView"
            lgStretchableDisabled="stretchable"
            lgStretchableOptionsDisabled="stretchableOptions"
            [bodyClass]="bodyClass"
            [preserveFirstVisibleRow]="preserveFirstVisibleRow"
            [noDataAvailable]="!data || data.length === 0"
            (clearAllFilters)="clearAllFilters.next()"
            (toggle)="onToggle.next($event)"
            (toggleLevel)="onToggleLevel.next($event)"
            (maxVisibleLevelChange)="maxVisibleLevelChange.next($event)"
        >
        </lg-pivot-table-body>
        <lg-pivot-table-header-container
            [table]="this"
            [template]="_footerTemplate"
            [totals]="_totals"
            [header]="false"
        ></lg-pivot-table-header-container>
    `,
    host: {
        class: "lg-pivoted-table lg-pivot-table",
        "[class.flexcol]": "stretchable",
        "[class.lg-pivot-table--higher-rows]": "higherRows"
    },
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: LgPivotTableBodyRegister, useExisting: forwardRef(() => LgPivotTableComponent) },
        {
            provide: LgPivotTableLevelExpander,
            useExisting: forwardRef(() => LgPivotTableBodyComponent)
        },
        {
            provide: LgPivotTableBodyComponent,
            useFactory: LgPivotTableBodyComponentFactory,
            deps: [LgPivotTableComponent]
        }
    ]
})
export class LgPivotTableComponent<TData = any, TTotals = any, TBuildTotals = TTotals>
    implements IBodyRegister, ILevelExpander, AfterViewChecked, DoCheck, OnDestroy
{
    private _lgConsole = inject(LgConsole).withSource("Logex.Directives.LgPivotTableController");
    private _logexPivot = inject(Pivots.LogexPivotService);
    // ---------------------------------------------------------------------------------------------
    /**
     * Definition of the pivot. Should be always specified
     */
    @Input({ required: true }) set definition(value: Pivots.INormalizedLogexPivotDefinition) {
        if (value) {
            const normalized = this._logexPivot.prepareDefinition(value);
            this._definition = this._body.definition = normalized;
            this._savedState = null;
            this._resolvedPromise.then(() => {
                this._orderBy = this._orderBy || [];
                let newOrderBy = this._orderBy.slice(this._orderByOffset);
                if (!this._preserveOrderBy) newOrderBy = null;
                newOrderBy = this._logexPivot.gatherDefaultOrderByPerLevel(normalized, newOrderBy);
                if (this.orderByOffset) {
                    this._orderBy.length = this._orderByOffset;
                    this._orderBy = [...this._orderBy, ...newOrderBy];
                } else {
                    this._orderBy = newOrderBy;
                }
                this.orderByChange.next(this._orderBy);
            });
        } else {
            this._body.definition = null;
            this._definition = null;
        }
        this._buildNeeded = true;
    }

    get definition(): Pivots.INormalizedLogexPivotDefinition {
        return this._definition;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Source data for building the pivot. Optional for build=false trees (passed to buildAlreadyPivoted). Ignored for is-passive=true
     */
    @Input() set source(value: any[]) {
        this._source = value;
        this._buildNeeded = true;
    }

    get source(): any[] {
        return this._source;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Top level of unfiltered pivot
     */
    @Input() set data(value: TData[]) {
        this._data = value;
        this._filterNeeded = true;
    }

    get data(): TData[] {
        return this._data;
    }

    @Output() readonly dataChange = new EventEmitter<TData[]>();

    // ---------------------------------------------------------------------------------------------
    /**
     * Top level of filtered pivot
     */
    @Input() set filteredData(value: TData[]) {
        this._body.filteredData = this.filteredData;
        this._filteredData = value;
    }

    get filteredData(): TData[] {
        return this._filteredData;
    }

    @Output() readonly filteredDataChange = new EventEmitter<TData[]>();

    // ---------------------------------------------------------------------------------------------
    /**
     * Pivot totals
     */
    @Input() set totals(value: TTotals) {
        this._totals = value;
    }

    get totals(): TTotals {
        return this._totals;
    }

    @Output() readonly totalsChange = new EventEmitter<TTotals>();

    // ---------------------------------------------------------------------------------------------
    /**
     * Per-level sorting specification (ignored when passive="true", though may be still refered by the sort directives)
     */
    @Input() set orderBy(value: Pivots.IOrderByPerLevelSpecification) {
        if (value && !_.isArray(value)) {
            value = [value];
        }
        this._orderBy = value;
        // this._oldOrderBy = [...this._orderBy];
        // if we ever implement immutability. this._sortNeeded = true;
    }

    get orderBy(): Pivots.IOrderByPerLevelSpecification {
        return this._orderBy;
    }

    @Output() readonly orderByChange = new EventEmitter<Pivots.IOrderByPerLevelSpecification>();

    // ---------------------------------------------------------------------------------------------
    /**
     * Specifies offset in the orderBy parameter (i.e. at what position is actually stored level 0 etc). This
     * can be useful in drill-down situations when the table is showing only a branch of the whole pivot,
     * but we want to keep global orderBy covering the whole tree
     * */
    @Input() set orderByOffset(value: number) {
        this._orderByOffset = toInteger(value, 0, 0, null);
    }

    get orderByOffset(): number {
        return this._orderByOffset;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Context passed to all pivot functions (typically the page's controller)
     */
    @Input() pivotContext: any = null;

    // ---------------------------------------------------------------------------------------------
    /**
     * Height of one row (typically 28). This is preferred to having it specified individually per-row, unless needed. Note that
     * the buffer/visual-buffer settings are specified "in rows", so you should specify item-height (to some reasonable number)
     * even if you use per-row settings.
     */
    @Input() set itemHeight(value: number) {
        this._itemHeight = toInteger(value, BASE_ITEM_HEIGHT, 1, null);
        this._itemHeightSpecified = !!value;
    }

    get itemHeight(): number {
        return this._itemHeight;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Sets css class for table body to have more spacious rows with height of 36px. Note that the buffer/visual-buffer settings
     * are specified "in rows", item-height is preset to 36 as well if you do not override it.
     */
    @Input() set higherRows(value: boolean) {
        this._higherRows = toBoolean(value, false);
        if (!this._itemHeightSpecified) {
            this._itemHeight = this._higherRows ? HIGHER_ITEM_HEIGHT : BASE_ITEM_HEIGHT;
        }
    }

    get higherRows(): boolean {
        return this._higherRows;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Height of one separator row (typically 16).
     */
    @Input() set separatorHeight(value: number) {
        this._separatorHeight = toInteger(value, 16, 1, null);
    }

    get separatorHeight(): number {
        return this._separatorHeight;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * This specifies, how many extra rows outside the visible area should be rendered (to reduce the frequency of re-render on slow
     * scroll). "2" is reasonable.
     */
    @Input() set renderExtraRows(value: number) {
        this._renderExtraRows = toInteger(value);
    }

    get renderExtraRows(): number {
        return this._renderExtraRows;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * This specified, how many extra rows outside the visible area the page must exist rendered. For purely visual tables it can
     * be 0, however for tables with inputs you want it to be at least 1, so that the user can actually tab
     * from inputs on the last visible row (otherwise there is nothing to tab to)
     * This value should be less than "renderExtraRows". Typically we use 2 for renderExtraRows and 1 for ensureRowsOutsideView (these are in fact defaults).
     */
    @Input() set ensureRowsOutsideView(value: number) {
        this._ensureRowsOutsideView = toInteger(value);
    }

    get ensureRowsOutsideView(): number {
        return this._ensureRowsOutsideView;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * If true, the table won't be doing any filtering, sorting or calculations. This also automatically implies no build.
     * When the table is in passive mode, other code will need to take care of the pivot maintenance, and trigger rerenders
     * using refilter.
     */
    @Input() set passive(value: boolean) {
        this._passive = toBoolean(value);
    }

    get passive(): boolean {
        return this._passive;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * If false, the table won't build the pivot (however if source is specified, buildAreadyPivoted will be called)
     */
    @Input() set build(value: boolean | null) {
        this._build = toBoolean(value);
    }

    get build(): boolean {
        return this._build;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * If true or "persistent", the table attempts to preserve the expand state when a new source data are passed in (based on IDs
     * of the individual nodes: see LogexPivot.extractNodesState.
     * When "persistent", the stored states are merged across multiple switches (so if expanded specialisatin 301 disappears on first
     * data switch and comes back on second, it will be again expanded). If truthy, the state is stored only within one rebuild.
     */
    @Input() set preserveState(value: boolean | "persistent") {
        if (value === "persistent") {
            this._preserveState = value;
        } else {
            this._preserveState = toBoolean(value);
        }
    }

    get preserveState(): boolean | "persistent" {
        return this._preserveState;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Specifies, if the current order by settings should be only merged with defaultOrderBy from pivot definition,
     * instead of being overwritten
     */
    set preserveOrderBy(value: boolean) {
        this._preserveOrderBy = toBoolean(value);
    }

    get preserveOrderBy(): boolean {
        return this._preserveOrderBy;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Keeps scrolling position when number of items in pivot get equal to one, and restore once it more then one again
     */
    set preserveFirstVisibleRow(value: boolean) {
        this._preserveFirstVisibleRow = toBoolean(value);
    }

    get preserveFirstVisibleRow(): boolean {
        return this._preserveFirstVisibleRow;
    }

    // ---------------------------------------------------------------------------------------------
    /**
     * Specifies if the table is stretchable, as used by lgStretchable directive.
     */
    @Input() stretchable = false;

    /**
     * Additional stretchable options, directly passed to lgStretchableOptions
     */
    @Input() stretchableOptions: any = null;

    // ---------------------------------------------------------------------------------------------
    /**
     * Additional classes applied to the body div (the DIV with "body-inner" class)
     */
    @Input() bodyClass: string | null = null;

    // ---------------------------------------------------------------------------------------------
    @Output() readonly clearAllFilters = new EventEmitter<void>();

    // ---------------------------------------------------------------------------------------------
    get maxVisibleLevel(): number {
        return this._body ? this._body.maxVisibleLevel : -1;
    }

    @Output() readonly maxVisibleLevelChange = new EventEmitter<number>();

    // ---------------------------------------------------------------------------------------------
    /**
     * Event triggered when individual node is expanded/collapsed (called after re-render).
     * Note that this is not called when the whole level is expanded/collapsed!
     */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("toggle") readonly onToggle = new EventEmitter<IToggleEvent>();

    /**
     * Event triggered when the whole level is expanded/collapsed (called after re-render)
     */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("toggleLevel") readonly onToggleLevel = new EventEmitter<IToggleLevelEvent>();

    // ---------------------------------------------------------------------------------------------
    @Output("postBuild") readonly postBuild = new EventEmitter<
        PostBuildEvent<TData, TTotals, TBuildTotals>
    >();

    @Output("preFilter") readonly preFilter = new EventEmitter<
        PreFilterEvent<TData, TTotals, TBuildTotals>
    >();

    @Output("postFilter") readonly postFilter = new EventEmitter<
        PostFilterEvent<TData, TTotals, TBuildTotals>
    >();

    render(): void {
        this._body.render();
    }

    recalculate(): void {
        this._totals = this._logexPivot.evaluateCalculations(
            this._definition,
            this._filteredData,
            null,
            null,
            null,
            this.pivotContext
        );
        this.totalsChange.next(this._totals);
    }

    refilter(): void {
        if (this._passive) {
            this.render();
            return;
        }

        if ((!this._buildNeeded || !this._build) && !this._filterNeeded) {
            this._filterNeeded = true;
            scheduleChangeDetection();
        }
    }

    // ---------------------------------------------------------------------------------------------
    // Implementation
    // ---------------------------------------------------------------------------------------------
    @ViewChild("body", { static: true }) _body: LgPivotTableBodyComponent;

    _totals: TTotals = <any>{};
    _headerTemplate: IHeaderFooterTemplate<any>;
    _footerTemplate: IHeaderFooterTemplate<any>;

    private _definition: Pivots.INormalizedLogexPivotDefinition;
    private _source: any[];
    private _data: TData[];
    private _filteredData: TData[];
    private _orderBy: Pivots.IOrderByPerLevelSpecification = [];
    private _oldOrderBy: Pivots.IOrderByPerLevelSpecification = [];
    private _orderByOffset = 0;
    private _itemHeight = BASE_ITEM_HEIGHT;
    private _itemHeightSpecified = false;
    private _higherRows = false;
    private _separatorHeight = 16;
    private _renderExtraRows = 2;
    private _ensureRowsOutsideView = 1;
    private _build = true;
    private _passive = false;
    private _preserveState: "persistent" | boolean = false;
    private _preserveOrderBy = false;
    private _preserveFirstVisibleRow = false;

    private _savedState: _.Dictionary<any>;
    private _buildNeeded = false;
    private _sortNeeded = false;
    private _filterNeeded = false;
    private _resolvedPromise = Promise.resolve();

    addHeaderTemplate(template: IHeaderFooterTemplate<any>): void {
        this._headerTemplate = template;
    }

    removeHeaderTemplate(template: IHeaderFooterTemplate<any>): void {
        if (this._headerTemplate === template) {
            this._headerTemplate = null;
        }
    }

    addFooterTemplate(template: IHeaderFooterTemplate<any>): void {
        this._footerTemplate = template;
    }

    removeFooterTemplate(template: IHeaderFooterTemplate<any>): void {
        if (this._footerTemplate === template) {
            this._footerTemplate = null;
        }
    }

    get rowTemplates(): RowTemplateCollection<IRowTemplate> {
        return this._body.rowTemplates;
    }

    get rowSeparatorTemplates(): RowTemplateCollection<IRowSeparatorTemplate> {
        return this._body.rowSeparatorTemplates;
    }

    get rowFooterTemplates(): RowTemplateCollection<IRowSeparatorTemplate> {
        return this._body.rowFooterTemplates;
    }

    addEmptyRowTemplate(template: IEmptyRowTemplate): void {
        this._body.addEmptyRowTemplate(template);
    }

    removeEmptyRowTemplate(template: IEmptyRowTemplate): void {
        this._body.removeEmptyRowTemplate(template);
    }

    getAllExpanded(level: number): boolean {
        return this._body.getAllExpanded(level);
    }

    toggleLevel(level: number, expanded?: boolean): void {
        this._body.toggleLevel(level, expanded);
    }

    _notifyHover(element: any, level: number, hover: boolean): void {
        this._body._notifyHover(element, level, hover);
    }

    _hoverObservable(): Observable<any> {
        return this._body._hoverObservable();
    }

    _hoverLevelObservable(): Observable<number | null> {
        return this._body._hoverLevelObservable();
    }

    ngDoCheck(): void {
        this._sortNeeded = !_.isEqual(this._orderBy, this._oldOrderBy);
    }

    ngAfterViewChecked(): void {
        if (this._buildNeeded && (this._build || this._source)) {
            if (!this._source) return;
            this._resolvedPromise.then(() => {
                this._rebuildSource(this._source);
            });
        } else if (this._filterNeeded) {
            if (!this._data) return;
            this._resolvedPromise.then(() => {
                this._refilterDo();
            });
        } else if (this._sortNeeded) {
            if (!this._filteredData) return;
            this._resolvedPromise.then(() => {
                this._resort();
            });
        }
    }

    ngOnDestroy(): void {
        this._definition = null;
        this._data = null;
        this._filteredData = null;
    }

    _getOrderBy(level: number | string): Pivots.IOrderBySpecification | null {
        if (!this._definition || !this._orderBy) return null;
        return this._orderBy[this._getLocalLevelIndex(level) + this._orderByOffset];
    }

    _setOrderBy(level: number | string, sortBy: string | string[]): void {
        if (!this._definition) return;

        if (!this._orderBy == null) {
            this._orderBy = [];
        } else if (!_.isArray(this._orderBy)) {
            this._orderBy = [this._orderBy];
        }
        this._orderBy[this._getLocalLevelIndex(level) + this._orderByOffset] = sortBy;
        this._sortNeeded = true;
        this.orderByChange.next(this._orderBy);
    }

    /**
     * Make table row visible on the screen.
     *
     * @param position
     * @param ids - IDs that will lead crawler of the pivot tree to the node
     * @param autoFocus - `false`/`undefined` means code won't try to focus any input,
     *   `true` means try to focus first input,
     *   `number` means focus n-th input (0 === first)
     * @param autoExpand - set to true if you're not sure that all the parent nodes are already expanded,
     *   **can't be `true` if table is in passive mode**
     */
    ensureVisible(
        position: VerticalPosition,
        ids: LgPrimitive[],
        autoFocus: boolean | number,
        autoExpand: boolean
    ): void {
        if (autoExpand && this._passive) {
            throw new Error(
                "can't call `ensureVisible` with `autoExpand === true` if table is in passive mode"
            );
        }

        this._body.ensureVisible(position, ids, autoFocus, autoExpand);
    }

    private _getLocalLevelIndex(level: number | string): number | undefined {
        // eslint-disable-next-line eqeqeq
        if (level == +level) return +level;

        const index = this._definition.$levels[level].$levelIndex - this._definition.$levelIndex;
        if (index < 0) console.warn("Level outside the current tree ");
        return undefined;
    }

    // ---------------------------------------------------------------------------------------------
    //  Rebuild the tree
    // ---------------------------------------------------------------------------------------------
    private _rebuildSource(newSource: any[]): void {
        try {
            let state: _.Dictionary<any>;

            if (this._data && this._preserveState) {
                const merge = this._preserveState === "persistent";
                state = this._logexPivot.extractNodesState(
                    this._definition,
                    this._data,
                    ["$expanded"],
                    merge ? this._savedState : null
                );
                if (merge) this._savedState = state;
            }

            if (this._data) {
                this._body._renderAll();
            }

            const onceTotals: TBuildTotals = <any>{};
            if (!this._build) {
                if (!this._passive) {
                    this._data = newSource;
                    this._logexPivot.buildAlreadyPivoted(
                        this._definition,
                        newSource,
                        onceTotals,
                        this.pivotContext
                    );
                }
            } else {
                this._data = this._logexPivot.build(
                    this._definition,
                    newSource,
                    onceTotals,
                    this.pivotContext
                );
            }

            if (state) {
                this._logexPivot.applyNodesState(
                    this._definition,
                    this._data,
                    ["$expanded"],
                    state
                );
            }

            this.postBuild.next({
                definition: this._definition,
                data: this._data,
                totals: onceTotals,
                table: this
            });

            this.dataChange.next(this._data);

            this._refilterDo();
        } finally {
            this._buildNeeded = false;
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Refilter and sort the tree
    // ---------------------------------------------------------------------------------------------
    private _refilterDo(): void {
        if (!this._data) return;

        if (this._passive) {
            this.render();
            this._sortNeeded = false;
            this._filterNeeded = false;
            this._oldOrderBy = [...this._orderBy];
            return;
        }

        this._lgConsole.groupCollapsed("lgPivotTable:refilterDo");
        this._lgConsole.time("lgPivotTable:refilterDo");
        try {
            let t1 = Date.now();
            this.preFilter.next({
                definition: this._definition,
                data: this._data,
                table: this
            });
            this._lgConsole.perf("Prefilter %d ms", Date.now() - t1);

            t1 = Date.now();
            this._filteredData = this._logexPivot.filter(
                this._definition,
                this._data,
                this.pivotContext
            );
            this._lgConsole.perf("Filter %d ms", Date.now() - t1);

            t1 = Date.now();
            this._totals = this._logexPivot.evaluateCalculations(
                this._definition,
                this._filteredData,
                null,
                null,
                null,
                this.pivotContext
            );
            this._lgConsole.perf("Evaluate %d ms", Date.now() - t1);

            t1 = Date.now();
            this._filteredData = this._logexPivot.orderByFilteredPerLevel(
                this._definition,
                this._filteredData,
                (this._orderBy || []).slice(this._orderByOffset)
            );
            this._lgConsole.perf("Sort %d ms", Date.now() - t1);

            t1 = Date.now();
            this._lgConsole.group("lgPivotTable:refilterDo:postFilter");
            this.postFilter.next({
                definition: this._definition,
                data: this._data,
                table: this,
                filteredData: this._filteredData,
                totals: this._totals
            });
            this._lgConsole.perf("Postfilter %d ms", Date.now() - t1);

            // Do this before or after postFilter?
            this.filteredDataChange.next(this._filteredData);
            this.totalsChange.next(this._totals);

            t1 = Date.now();
            this._lgConsole.groupEnd();

            // this.;
            // this.lgConsole.perf( "Flatten %d ms", Date.now() - t1 );
            // t1 = Date.now();
        } finally {
            this._sortNeeded = false;
            this._filterNeeded = false;
            this._oldOrderBy = [...this._orderBy];
            this._lgConsole.timeEnd("lgPivotTable:refilterDo");
            this._lgConsole.groupEnd();
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Sort the tree
    // ---------------------------------------------------------------------------------------------
    private _resort(): void {
        if (!this._filteredData) return;

        if (this._passive) {
            this.render();
            this._sortNeeded = false;
            this._oldOrderBy = [...this._orderBy];
            return;
        }

        this._lgConsole.groupCollapsed("lgPivotTable:resort");
        this._lgConsole.time("lgPivotTable:resort");
        try {
            const t1 = Date.now();
            this._filteredData = this._logexPivot.orderByFilteredPerLevel(
                this._definition,
                this._filteredData,
                (this._orderBy || []).slice(this._orderByOffset)
            );
            this._lgConsole.perf("Sort %d ms", Date.now() - t1);

            this.filteredDataChange.next(this._filteredData);
        } finally {
            this._sortNeeded = false;
            this._oldOrderBy = [...this._orderBy];
            this._lgConsole.timeEnd("lgPivotTable:resort");
            this._lgConsole.groupEnd();
        }
    }
}
