import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    HostListener,
    InjectionToken,
    inject
} from "@angular/core";
import { ISnackbarButton, ISnackbarOptions } from "./lg-snackbar.types";
import { OverlayRef } from "@angular/cdk/overlay";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import { atNextFrame } from "@logex/framework/utilities";
import { NgForOf, NgIf } from "@angular/common";
import { LgIconComponent } from "../controls";
import { LgButtonComponent } from "../controls/lg-button.component";

export const LG_SNACKBAR_OPTIONS = new InjectionToken<ISnackbarOptions>("LgSnackbarOptions");

@Component({
    standalone: true,
    selector: "lg-snackbar",
    templateUrl: "./lg-snackbar.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { "[style.flex-grow]": "1" },
    imports: [NgIf, NgForOf, LgIconComponent, LgButtonComponent],
    animations: [
        trigger("basicAnimation", [
            state("closed", style({ opacity: 0 })),
            transition("* => opened", [
                style({ transform: "translateX(-100%)" }),
                animate("250ms ease-out")
            ]),
            transition("* => closed", animate(250))
        ]),
        trigger("moveUpAnimation", [
            transition("* => moved", [
                style({ transform: "translateY({{ moveUpOffset }}px)" }),
                animate("250ms ease-out")
            ])
        ])
    ]
})
export class LgSnackbarComponent implements AfterViewInit {
    options = inject(LG_SNACKBAR_OPTIONS);
    private _overlayRef = inject(OverlayRef);
    private _translate = inject(LgTranslateService);

    @HostBinding("@basicAnimation")
    get _animationBasicState(): string {
        return this._visibilityState;
    }

    @HostListener("@basicAnimation.done", ["$event"])
    _animationStateDone(event: AnimationEvent): void {
        if (event.toState === "closed") {
            this._overlayRef.detach();
        }
    }

    // ---------------------------------------------------------------------------------------------
    @HostBinding("@moveUpAnimation")
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    get _animationMoveUpState() {
        return {
            value: this._moveUpState,
            params: {
                moveUpOffset: this._moveUpOffset
            }
        };
    }

    @HostListener("@moveUpAnimation.done", ["$event"])
    _animationMoveUpDone(event: AnimationEvent): void {
        if (event.toState === "moved") {
            this._moveUpState = "initial";
        }
    }

    // ---------------------------------------------------------------------------------------------
    _visibilityState: "opened" | "closed";

    _moveUpState: "initial" | "moved";
    _moveUpOffset: number | undefined;

    _title: string;
    _text: string;

    constructor() {
        this._title = this.options.titleLc
            ? this._translate.translate(this.options.titleLc)
            : this.options.title;

        this._text = this.options.textLc
            ? this._translate.translate(this.options.textLc)
            : this.options.text;
    }

    ngAfterViewInit(): void {
        atNextFrame(() => (this._visibilityState = "opened"));
    }

    hide(): void {
        this._visibilityState = "closed";
    }

    _onButtonClick(button: ISnackbarButton): void {
        button.callback().then(close => {
            if (close) this.hide();
        });
    }

    moveUp(offset: number): void {
        this._moveUpOffset = offset;
        this._moveUpState = "moved";
    }
}
