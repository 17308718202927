import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject, of, asyncScheduler } from "rxjs";
import { concatMap, observeOn } from "rxjs/operators";
import { InjectionToken } from "@angular/core";
import {
    DefinitionDisplayMode,
    DefinitionSection,
    IDefinitions,
    ItemType,
    OrderByType
} from "./definitions.types";
import { StringKeyOf } from "@logex/framework/types";

export const LG_APP_DEFINITIONS = new InjectionToken<IDefinitions<object>>("lgAppDefinitions");

/**
 * Used as a base class for definitions holder object. Inheritor shoul define public members with individual definitions
 * and implement [[getResponseData]] method
 *
 * @deprecated Please base your definitions on [[ServerDefinitionsBase]] instead
 */
export class DefinitionsApiBase implements IDefinitions<object> {
    // ----------------------------------------------------------------------------------
    // Dependencies
    constructor(protected _httpClient: HttpClient) {
        // empty
    }

    // ----------------------------------------------------------------------------------
    // Fields
    loadState = 0; // 0 = not loaded, 1 = loading, 2 = loaded
    protected _loading = new ReplaySubject<void>(null);

    /**
     * Loads the definitions and signals the returned promise. If definitions are already loaded, then already resolved promise will be returned.
     *
     * @param callback Optional callback to execute after the definitions would be loaded.
     * @returns Promise that gets signaled when definitions are loaded.
     */
    ready(): Observable<void> {
        return this.load();
    }

    /**
     * Requests the definition from the backend (with default url api/definition/core). Override to change the source, or
     * do more than one request
     */
    protected requestData(): Observable<any> {
        return this._httpClient.get("api/definition/core");
    }

    /**
     * Loads definitions from server.
     */
    load(): Observable<void> {
        if (this.loadState === 0) {
            this.loadState = 1;

            this.requestData()
                .pipe(concatMap(response => this.getResponseData(response)))
                .subscribe(() => {
                    this.loadState = 2;
                    this._loading.next(null);
                });
        }
        return this._loading.pipe(observeOn(asyncScheduler));
    }

    /**
     * When implemented in derived definitions class, copies definitions from incoming data to *this*.
     *
     * @param data
     */
    protected getResponseData(_data: any): Observable<void> {
        return of(null);
    }

    /**
     * Reloads the definitions from server. You can then use [[ready]] method to get loader's promise.
     */
    reload(): Observable<void> {
        this.loadState = 0;
        return this.load();
    }

    // Method stubs to satisfy the interface
    clearCache(...sections: Array<keyof object>): void {
        throw new Error("Not implemented");
    }

    getDisplayName(
        sectionName: StringKeyOf<object>,
        code: any,
        displayMode?: DefinitionDisplayMode
    ): string {
        throw new Error("Not implemented");
    }

    getEntry<TField extends StringKeyOf<object>>(
        sectionName: TField,
        code: any
    ): ItemType<object, TField> {
        throw new Error("Not implemented");
    }

    getFallbackValue<TField extends StringKeyOf<object>>(
        sectionName: TField
    ): ItemType<object, TField> {
        throw new Error("Not implemented");
    }

    getOrderBy<TField extends StringKeyOf<object>>(
        sectionName: TField,
        code: any
    ): OrderByType<object, TField> {
        throw new Error("Not implemented");
    }

    getSection(
        sectionName: StringKeyOf<object>,
        checkIsLoaded: boolean = false
    ): DefinitionSection {
        throw new Error("Not implemented");
    }

    isLoaded<TField extends StringKeyOf<object>>(name: TField): boolean {
        throw new Error("Not implemented");
    }
}
