import { forwardRef, inject, Injectable, InjectionToken, Provider } from "@angular/core";
import { Observable, of, zip } from "rxjs";
import { map, take } from "rxjs/operators";

import { gatherObjectStaticFields } from "@logex/framework/utilities";

import { LgUserStorageService } from "./lg-user-storage.service";
import { IStoreIdentifier, StorageBinding } from "./lg-user-storage.types";

interface IStorageRecord {
    property: string;
    storageName: string;
    binding: StorageBinding;
}

const StorageRecordKey = "$lgStorageKeys";

export const LG_STORAGE_NAMESPACE = new InjectionToken<string>("LgStorageNamespace");

// ----------------------------------------------------------------------------
//
// eslint-disable-next-line @typescript-eslint/naming-convention
export function LgBindStorage(target: any, property: string): void;
// eslint-disable-next-line @typescript-eslint/naming-convention
export function LgBindStorage(
    name?: string,
    binding?: StorageBinding
): (target: any, property: string) => void;
// eslint-disable-next-line @typescript-eslint/naming-convention
export function LgBindStorage(
    targetOrName: any,
    propertyOrBinding?: any
): void | ((target: any, property: string) => void) {
    let storageName: string = null;
    let storageBinding: StorageBinding = null;

    const doDecorate = (target: any, property: string): void => {
        property = property.trim();
        if (storageName) storageName = storageName.trim();

        const newEntry: IStorageRecord = {
            property,
            storageName: storageName || property,
            binding: storageBinding || StorageBinding.Hospital
        };
        // needs to make sure we don't inject to the parent class' array
        const prototype = Object.getPrototypeOf(target);

        if (
            !Object.prototype.hasOwnProperty.call(target.constructor, StorageRecordKey) ||
            (prototype &&
                target.constructor[StorageRecordKey] === prototype.constructor[StorageRecordKey])
        ) {
            target.constructor[StorageRecordKey] = [newEntry];
        } else {
            target.constructor[StorageRecordKey].push(newEntry);
        }
    };

    if (typeof targetOrName === "string") {
        storageName = targetOrName;
        storageBinding = <StorageBinding>propertyOrBinding;
        return doDecorate;
    } else {
        doDecorate(targetOrName, <string>propertyOrBinding);
    }
}

// ----------------------------------------------------------------------------
//
@Injectable()
export class LgStorageLoader {
    private _storage = inject(LgUserStorageService);

    private _namespace: string;

    constructor() {
        const parent = inject(
            forwardRef(() => LgStorageLoader),
            { optional: true, skipSelf: true }
        );
        let useNamespace = inject(LG_STORAGE_NAMESPACE);

        if (useNamespace == null || useNamespace === "") {
            useNamespace = "/";
        }
        if (useNamespace.length > 1 && useNamespace[useNamespace.length - 1] === "/") {
            useNamespace = useNamespace.substring(0, useNamespace.length - 1);
        }
        this._namespace = this._combineNamespaces(parent && parent.namespace, useNamespace);
    }

    get namespace(): string {
        return this._namespace;
    }

    loadStorage(target: any, namespace?: string): Observable<void> {
        const fields = gatherObjectStaticFields(target, StorageRecordKey);
        const storages = new Map<string, string>();
        const requests: IStoreIdentifier[] = [];

        if (namespace) {
            namespace = this._combineNamespaces(this.namespace, namespace);
        } else {
            namespace = this.namespace;
        }

        for (const field of fields) {
            const records: IStorageRecord[] = field.value;

            for (const record of records) {
                const fullStorageName = this._combineNamespaces(namespace, record.storageName);
                if (!storages.has(fullStorageName)) {
                    storages.set(fullStorageName, record.property);
                    requests.push({
                        key: fullStorageName,
                        binding: record.binding
                    });
                }
            }
        }

        if (requests.length === 0) {
            return of(null);
        }

        const mapped = this._storage.preload(requests);

        return zip(
            ...requests.map(request => {
                return mapped.get(request.key).pipe(
                    map(value => {
                        const property = storages.get(request.key);

                        // If the property has default value, copy its entries to the fetched value if it's not already there.
                        // This allows us to easily specify default values, including new defaults that didn't exist there
                        const originalValue = target[property];
                        if (originalValue != null) {
                            for (const key in originalValue) {
                                if (!Object.prototype.hasOwnProperty.call(originalValue, key)) {
                                    continue;
                                }
                                if (value[key] !== undefined) continue;
                                value[key] = originalValue[key];
                            }
                        }

                        target[property] = value;
                        return value;
                    })
                );
            })
        ).pipe(
            map(() => null),
            take(1)
        );
    }

    private _combineNamespaces(parent: string | null, child: string): string {
        if (child[0] === "/") return child;
        return (parent || "") + "/" + child;
    }
}

// ----------------------------------------------------------------------------
//
export function useStorageNamespace(namespace?: string): Provider[] {
    return [
        {
            provide: LG_STORAGE_NAMESPACE,
            useValue: namespace
        },
        LgStorageLoader
    ];
}
