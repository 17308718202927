import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation
} from "@angular/core";

@Component({
    standalone: true,
    selector: "lg-status-pie",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <svg width="10px" height="10px" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 10 10">
            <linearGradient [id]="_svg.id" x1="0.5" y1="1" x2="0.5" y2="0">
                <stop [attr.offset]="'0%'" stop-opacity="1" stop-color="#005DE4" />
                <stop [attr.offset]="_divider" stop-opacity="1" stop-color="#005DE4" />
                <stop [attr.offset]="_divider" stop-opacity="1" stop-color="#E5E5E5" />
                <stop [attr.offset]="'100%'" stop-opacity="1" stop-color="#E5E5E5" />
            </linearGradient>
            <circle cx="5" cy="5" r="5" [attr.fill]="_svg.fill" stroke-width="0" />
        </svg>
    `
})
export class LgStatusPieComponent implements OnInit, OnChanges {
    /**
     * Filled part (value from 0 to 1, where 0 - 0%, 1 - 100%).
     */
    @Input({ required: true }) value!: number;

    _divider: string;
    _svg: {
        id: string;
        fill: string;
    };

    private static _nextId = 0;

    constructor() {
        const id = LgStatusPieComponent._nextId++;
        this._svg = {
            id: "statusPie-" + id,
            fill: `url(#statusPie-${id})`
        };
    }

    ngOnInit(): void {
        this._onChange();
    }

    ngOnChanges(): void {
        this._onChange();
    }

    private _onChange(): void {
        if (!this.value) {
            this.value = 0;
        }

        const clamped = Math.max(0, Math.min(0.999, this.value)) * 100;
        this._divider = clamped.toString() + "%";
    }
}
