import { Injectable, ElementRef, Renderer2, NgZone, RendererFactory2, inject } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";

import { makeVisibleForMeasurements } from "@logex/framework/utilities";

// todo: currently using mix of renderer and direct access (getClientBoundingRect). Should we leave that?
// ---------------------------------------------------------------------------------------------
//  Interfaces
// ---------------------------------------------------------------------------------------------
export type ScrollbarState = "normal" | "inactive" | "disabled";

export interface ScrollbarScrollEvent {
    position: number;
    oldPosition: number;
}

export interface ScrollbarOptionsBase {
    /**
     * Size of one step. Defaults to 20
     */
    step?: number;

    /**
     * Delay between automatic steps (when clicking above/below the handle) in ms. Defaults to 50
     */
    delay?: number;

    /**
     * Delay until the automatic steps start, in ms. Defaults to 600
     */
    firstDelay?: number;

    /**
     * Specifies, whether the unnecessary scrollbar disapears (when true) or is only disabled (when false). This toggles
     * between "normal" class and either "inactive" or "disabled". Defaults to false
     */
    auto?: boolean;

    /**
     * Class of the main scrollbar element. If you want to modify "lg-scrollbar", please include the base class.
     */
    className?: string;
}

export interface ScrollbarOptions extends ScrollbarOptionsBase {
    /**
     * Offset from the top (vertical) or left (horizontal) edge of the owner. Defaults to 0
     */
    offsetStart?: number;

    /**
     * Offset from the bottom (vertical) or right (horizontal) edge of the owner.
     */
    offsetEnd?: number;
}

// ---------------------------------------------------------------------------------------------
//  Api implementation
// ---------------------------------------------------------------------------------------------
export class ScrollbarApi {
    private readonly _renderer: Renderer2;
    private readonly _ngZone: NgZone;
    private readonly _owner: HTMLElement;
    private readonly _options: ScrollbarOptionsBase;
    private readonly _vertical: boolean;

    private _documentLength = 200;
    private _pageLength = 35;
    private _position = 0;
    private _scrollbarClass!: string;
    private _startArrowDisabled = false;
    private _endArrowDisabled = false;
    private _timer: number | null = null;
    private readonly _onStateChange = new BehaviorSubject<ScrollbarState | null>(null);
    private readonly _onScroll = new Subject<ScrollbarScrollEvent>();
    private _offsetStart = 0;
    private _offsetEnd = 0;

    private _holder!: HTMLElement;
    private _track!: HTMLElement;
    private _startArrow!: HTMLElement;
    private _endArrow!: HTMLElement;
    private _handle!: HTMLElement;
    private _mouseDownOff: () => void | null = null;
    private _mouseWheelOff: () => void | null = null;
    private _mouseMoveOff!: () => void;
    private _mouseEnterOff!: () => void;
    private _mouseLeaveOff!: () => void;
    private _mouseUpOff!: () => void;
    private _currentTarget: HTMLElement | null = null;
    private _watchHandle = false;
    private _pause = false;
    private _mousePosition = 0;
    private _referencePosition = 0;
    private _handlePosition = 0;
    private _handleSize = 0;
    private _fraction = 0;
    private _lastFractionZero: boolean | null = null;

    constructor(
        renderer: Renderer2,
        ngZone: NgZone,
        owner: HTMLElement,
        options: ScrollbarOptions,
        vertical: boolean
    ) {
        this._renderer = renderer;
        this._ngZone = ngZone;
        this._owner = owner;
        this._options = options;
        this._vertical = vertical;
        this._offsetStart = options.offsetStart ?? 0;
        this._offsetEnd = options.offsetEnd ?? 0;
        this._create();
    }

    /**
     * Destroy the scrollbar binding. If removeElelements is true, the DOM is also destroyed
     */
    destroy(removeElements = false): void {
        this._stopScroll();
        this._mouseDownOff();
        this._mouseDownOff = null;
        this._mouseWheelOff();
        this._mouseWheelOff = null;
        if (this._renderer.destroyNode) {
            this._renderer.destroyNode(this._holder);
            this._renderer.destroyNode(this._track);
            this._renderer.destroyNode(this._startArrow);
            this._renderer.destroyNode(this._endArrow);
            this._renderer.destroyNode(this._handle);
        }
        if (removeElements) {
            this._renderer.removeChild(this._owner, this._holder);
        }
    }

    documentLength(): number;
    documentLength(length: number): void;
    documentLength(param?: number): number | undefined {
        if (param !== undefined) {
            if (param !== null && param !== this._documentLength) {
                this._documentLength = param;
                this.setPosition(this._position);
                this.recalculate();
            }
            return undefined;
        } else {
            return this._documentLength;
        }
    }

    /**
     * Returns true if the scrollbar is at the top
     */
    isAtTop(): boolean {
        return this._position === 0;
    }

    /**
     * Returns true if the scrollbar is at the bottom
     */
    isAtBottom(): boolean {
        return this._position >= this._documentLength - this._pageLength;
    }

    /**
     * Get observable that will be triggered whenever the state (inactive/normal or disabled/normal) of the scrollbar changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onStateChange(): Observable<ScrollbarState> {
        return this._onStateChange.asObservable();
    }

    /**
     * Get the observable, which will be called whenever the current scroll position changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onScroll(): Observable<ScrollbarScrollEvent> {
        return this._onScroll.asObservable();
    }

    /**
     * Get or set the current start offset (top or left edge). If you change the offset, make sure to call recalculate()
     */
    offsetStart(): number;
    offsetStart(offset: number): void;
    offsetStart(param?: number): number | undefined {
        if (param !== undefined) {
            if (param !== null && param !== this._offsetStart) {
                this._renderer.setStyle(
                    this._holder,
                    this._vertical ? "top" : "left",
                    param + "px"
                );
                this._offsetStart = param;
            }
            return undefined;
        } else {
            return this._offsetStart;
        }
    }

    /**
     * Get or set the current end offset (bottom or right edge). If you change the offset, make sure to call recalculate()
     */
    offsetEnd(): number;
    offsetEnd(offset: number): void;
    offsetEnd(param?: number): number | undefined {
        if (param !== undefined) {
            if (param !== null && param !== this._offsetEnd) {
                this._renderer.setStyle(
                    this._holder,
                    this._vertical ? "bottom" : "right",
                    param + "px"
                );
                this._offsetEnd = param;
            }
            return undefined;
        } else {
            return this._offsetEnd;
        }
    }

    pageLength(): number;
    pageLength(length: number): void;
    pageLength(param?: number): number | undefined {
        if (param !== undefined) {
            if (param !== null && param !== this._pageLength) {
                this._pageLength = param;
                this.setPosition(this._position);
                this.recalculate();
            }
            return undefined;
        } else {
            return this._pageLength;
        }
    }

    position(): number;
    position(pos: number): number | undefined;
    position(param?: number): number | undefined {
        if (param !== undefined) {
            this.setPosition(param);
            return undefined;
        } else {
            return this._position;
        }
    }

    /**
     * Recalculate the scrollbar sizes. Call this when the owner height was modified
     */
    recalculate(): void {
        const trackSize = makeVisibleForMeasurements(this._track, () =>
            this._vertical ? this._track.offsetHeight : this._track.offsetWidth
        ); // no padding or border!

        if (this._documentLength) {
            this._handleSize =
                (trackSize * Math.min(this._pageLength, this._documentLength)) /
                this._documentLength;
            this._handleSize = Math.max(10, this._handleSize);
            this._fraction =
                this._documentLength <= this._pageLength
                    ? 0
                    : (trackSize - this._handleSize) / (this._documentLength - this._pageLength);
        } else {
            this._handleSize = trackSize;
            this._fraction = 0;
        }

        this._renderer.setStyle(
            this._handle,
            this._vertical ? "height" : "width",
            this._handleSize + "px"
        );
        this._render();

        if (isNaN(this._fraction) || this._fraction < 0) this._fraction = 0;
        const isZero = this._fraction === 0;
        if (isZero !== this._lastFractionZero) {
            if (this._options.auto) {
                if (isZero) {
                    this._renderer.addClass(this._holder, "lg-scrollbar--inactive");
                } else {
                    this._renderer.removeClass(this._holder, "lg-scrollbar--inactive");
                }
                this._onStateChange.next(isZero ? "inactive" : "normal");
            } else {
                if (isZero) {
                    this._renderer.addClass(this._holder, "lg-scrollbar--disabled");
                } else {
                    this._renderer.removeClass(this._holder, "lg-scrollbar--disabled");
                }
                this._onStateChange.next(isZero ? "disabled" : "normal");
            }
            this._lastFractionZero = isZero;
        }
    }

    scrollbarClass(): string;
    scrollbarClass(className: string): void;
    scrollbarClass(className?: string): string | undefined {
        if (className === undefined) return this._scrollbarClass;

        className = this._normalizeClassName(className);
        if (this._scrollbarClass === className) return undefined;

        this._scrollbarClass = className;
        this._setHolderClass();
        return undefined;
    }

    setPosition(to: number): boolean {
        const oldPosition = this._position;
        this._position = Math.max(0, Math.min(to, this._documentLength - this._pageLength));
        if (oldPosition !== this._position) {
            this._render();
            this._onScroll.next({ position: this._position, oldPosition });
            return true;
        }
        return false;
    }

    /**
     * Specifies the size of the document (that is, the whole scrollable content) and the size of one page (the content visible
     * inside the vieweport). If either of the paramerers is null, do not modify the previously set value.
     */
    setSizes(documentLength?: number, pageLength?: number): void {
        if (documentLength !== undefined && documentLength !== null)
            this._documentLength = documentLength;
        if (pageLength !== undefined && pageLength !== null) this._pageLength = pageLength;
        this.setPosition(this._position);
        this.recalculate();
    }

    measureWidth(respectVisibility = false): number {
        if (respectVisibility && this._options.auto && this._lastFractionZero) return 0;
        return this._vertical ? this._holder.offsetWidth : this._holder.offsetHeight;
    }

    private _create(): void {
        this._scrollbarClass = this._normalizeClassName(this._options.className);
        this._holder = this._renderer.createElement("div");
        this._setHolderClass();
        this._renderer.setStyle(
            this._holder,
            this._vertical ? "top" : "left",
            this._offsetStart + "px"
        );
        this._renderer.setStyle(
            this._holder,
            this._vertical ? "bottom" : "right",
            this._offsetEnd + "px"
        );
        this._track = this._renderer.createElement("div");
        this._renderer.addClass(this._track, "lg-scrollbar__track");
        this._renderer.appendChild(this._holder, this._track);
        this._startArrow = this._renderer.createElement("div");
        this._renderer.addClass(
            this._startArrow,
            this._vertical ? "lg-scrollbar__up" : "lg-scrollbar__left"
        );
        this._renderer.appendChild(this._holder, this._startArrow);
        this._endArrow = this._renderer.createElement("div");
        this._renderer.addClass(
            this._endArrow,
            this._vertical ? "lg-scrollbar__down" : "lg-scrollbar__right"
        );
        this._renderer.appendChild(this._holder, this._endArrow);
        this._handle = this._renderer.createElement("div");
        this._renderer.addClass(this._handle, "lg-scrollbar__handle");
        this._renderer.appendChild(this._track, this._handle);

        this._ngZone.runOutsideAngular(() => {
            this._mouseDownOff = this._renderer.listen(
                this._holder,
                "mousedown",
                this._mouseDown.bind(this)
            );
            // note: Chrome would like us to use passive listener here, but we need to block the wheel
            // from scrolling the whole page, and there doesn't seem to be other way than preventing default
            // in the event handler.
            this._mouseWheelOff = this._renderer.listen(
                this._track,
                "wheel",
                this._wheel.bind(this)
            );
        });
        this._renderer.appendChild(this._owner, this._holder);
    }

    private _render(): void {
        this._handlePosition = this._position * this._fraction;
        this._renderer.setStyle(
            this._handle,
            this._vertical ? "top" : "left",
            this._handlePosition + "px"
        );

        const startDisabledNow = this._position === 0;
        const endDisabledNow = this._position >= this._documentLength - this._pageLength;

        if (this._startArrowDisabled !== startDisabledNow) {
            const className = this._vertical ? "lg-scrollbar__up--end" : "lg-scrollbar__left--end";
            if (this._startArrowDisabled) {
                this._renderer.removeClass(this._startArrow, className);
            } else {
                this._renderer.addClass(this._startArrow, className);
            }
            this._startArrowDisabled = startDisabledNow;
        }

        if (this._endArrowDisabled !== endDisabledNow) {
            const className = this._vertical
                ? "lg-scrollbar__down--end"
                : "lg-scrollbar__right--end";
            if (this._endArrowDisabled) {
                this._renderer.removeClass(this._endArrow, className);
            } else {
                this._renderer.addClass(this._endArrow, className);
            }
            this._endArrowDisabled = endDisabledNow;
        }
    }

    private _startScroll(step: number): void {
        this._stopScroll();
        this._scrollBy(step);

        this._timer = window.setTimeout(() => this._timedStep(step), this._options.firstDelay);
    }

    private _timedStep(step: number): void {
        if (this._watchHandle) {
            this._pause =
                (step > 0 && this._mousePosition < this._handlePosition + this._handleSize) ||
                (step < 0 && this._mousePosition >= this._handlePosition);
        }
        if (!this._pause) {
            if (!this._scrollBy(step)) return;
        }
        this._timer = window.setTimeout(() => this._timedStep(step), this._options.delay);
    }

    private _stopScroll(): void {
        if (!this._timer) return;
        clearTimeout(this._timer);
        this._timer = null;
    }

    private _scrollBy(step: number): boolean {
        return this.setPosition(this._position + step);
    }

    private _wheel(e: WheelEvent): boolean {
        let delta: number;
        if (this._vertical) {
            delta = e.deltaY;
        } else {
            // we always use the vertical delta. TODO: check if this works even on touch devices
            delta = e.deltaY;
        }
        this._scrollBy(Math.round(0.5 * delta));
        return false;
    }

    private _mouseDown(e: MouseEvent): boolean {
        this._currentTarget = e.target as HTMLElement;
        if (this._currentTarget === this._startArrow) {
            this._watchHandle = false;
            this._startScroll(-this._options.step);
            this._trackHover(this._currentTarget);
        } else if (this._currentTarget === this._endArrow) {
            this._watchHandle = false;
            this._startScroll(this._options.step);
            this._trackHover(this._currentTarget);
        } else if (this._currentTarget === this._handle) {
            const rect = this._handle.getBoundingClientRect();
            if (this._vertical) {
                this._referencePosition = e.pageY - (rect.top + document.body.scrollTop);
            } else {
                this._referencePosition = e.pageX - (rect.left + document.body.scrollLeft);
            }
            this._mouseMoveOff = this._renderer.listen(
                document,
                "mousemove",
                this._mouseMove.bind(this)
            );
            this._renderer.addClass(this._track, "lg-scrollbar__track--active");
            this._renderer.addClass(this._handle, "lg-scrollbar__handle--active");
            this._renderer.addClass(this._holder, "lg-scrollbar--active");
        } else if (this._currentTarget === this._track) {
            this._watchHandle = true;
            const rect = this._track.getBoundingClientRect();
            if (this._vertical) {
                this._mousePosition = e.pageY - (rect.top + document.body.scrollTop);
            } else {
                this._mousePosition = e.pageX - (rect.left + document.body.scrollLeft);
            }
            this._referencePosition = this._mousePosition - this._handlePosition;
            if (this._referencePosition < 0) {
                this._startScroll(-this._pageLength);
            } else {
                this._startScroll(this._pageLength);
            }
            this._renderer.addClass(this._track, "lg-scrollbar__track--active");
            this._renderer.addClass(this._holder, "lg-scrollbar--active");
            this._mouseMoveOff = this._renderer.listen(
                document,
                "mousemove",
                this._watchMousePosition.bind(this)
            );
        } else {
            return false;
        }
        this._mouseUpOff = this._renderer.listen(document, "mouseup", this._mouseUp.bind(this));
        return false;
    }

    private _trackHover(target: HTMLElement): void {
        this._pause = false;
        this._mouseEnterOff = this._renderer.listen(target, "mouseenter", () => {
            this._pause = false;
        });
        this._mouseLeaveOff = this._renderer.listen(target, "mouseleave", () => {
            this._pause = true;
        });
    }

    private _mouseMove(e: MouseEvent): void {
        const rect = this._track.getBoundingClientRect();
        let position: number;
        if (this._vertical) {
            position = e.pageY - (rect.top + document.body.scrollTop) - this._referencePosition;
        } else {
            position = e.pageX - (rect.left + document.body.scrollLeft) - this._referencePosition;
        }
        this.setPosition(Math.round(position / this._fraction));
    }

    private _watchMousePosition(e: MouseEvent): void {
        const rect = this._track.getBoundingClientRect();
        if (this._vertical) {
            this._mousePosition = e.pageY - (rect.top + document.body.scrollTop);
        } else {
            this._mousePosition = e.pageX - (rect.left + document.body.scrollLeft);
        }
    }

    private _mouseUp(): void {
        this._stopScroll();
        if (this._mouseMoveOff) {
            this._mouseMoveOff();
            this._mouseMoveOff = null;
        }
        if (this._mouseUpOff) {
            this._mouseUpOff();
            this._mouseUpOff = null;
        }
        if (this._mouseEnterOff) {
            this._mouseEnterOff();
            this._mouseEnterOff = null;
        }
        if (this._mouseLeaveOff) {
            this._mouseLeaveOff();
            this._mouseLeaveOff = null;
        }
        this._renderer.removeClass(this._track, "lg-scrollbar__track--active");
        this._renderer.removeClass(this._handle, "lg-scrollbar__handle--active");
        this._renderer.removeClass(this._holder, "lg-scrollbar--active");
    }

    private _normalizeClassName(className: string | null | undefined): string {
        if (className) return className;
        return "lg-scrollbar";
    }

    private _setHolderClass(): void {
        this._renderer.setProperty(
            this._holder,
            "className",
            this._scrollbarClass +
                (this._vertical ? " lg-scrollbar--vertical" : " lg-scrollbar--horizontal")
        );
    }
}

// ---------------------------------------------------------------------------------------------
//  Implementation
// ---------------------------------------------------------------------------------------------
const defaultOptions: ScrollbarOptions = {
    step: 20,
    delay: 50,
    firstDelay: 600,
    auto: false,
    offsetStart: 0,
    offsetEnd: 0
};
@Injectable({ providedIn: "root" })
export class LgScrollbarService {
    private _ngZone = inject(NgZone);
    private _renderer: Renderer2;

    constructor() {
        const rendererFactory = inject(RendererFactory2);

        this._renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Create vertical scrollbar within the specified owner (DOM element). It is assumed that the owner has relative or absolute position.
     */
    createVertical(owner: ElementRef, options?: ScrollbarOptions): ScrollbarApi {
        options = {
            ...defaultOptions,
            ...options
        };

        const api = new ScrollbarApi(
            this._renderer,
            this._ngZone,
            owner.nativeElement,
            options,
            true
        );

        api.recalculate();
        setTimeout(() => api.recalculate(), 1000);

        return api;
    }

    /**
     * Create horizontal scrollbar within the specified owner (DOM element). It is assumed that the owner has relative or absolute position.
     */
    createHorizontal(owner: ElementRef, options?: ScrollbarOptions): ScrollbarApi {
        options = {
            ...defaultOptions,
            ...options
        };

        const api = new ScrollbarApi(
            this._renderer,
            this._ngZone,
            owner.nativeElement,
            options,
            false
        );

        api.recalculate();
        setTimeout(() => api.recalculate(), 1000);

        return api;
    }
}
