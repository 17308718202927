import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const LG_APP_LOAD_INDICATOR = new InjectionToken<IAppLoadIndicatorService>(
    "lgAppLoadIndicator"
);

export interface IAppLoadIndicatorService {
    show(id?: string, hideWhen?: Observable<any>): void;
    hide(id?: string): void;
}
