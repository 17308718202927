import {
    Component,
    Input,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef,
    ElementRef,
    ViewContainerRef,
    ViewChild,
    inject
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";

import { LgTooltipService, TooltipApi } from "@logex/framework/ui-core";

import type { LgFilterSet } from "../lg-filterset";

/*
TODO: add isReadySupport?
<div class="topbar__filterset-popup-content" ng-class="{ loadingOverlayBlock: !isReadyImp() }">

scope.isReadyImp = () => {
    const res = $scope.isReady;
    if( res != null )
        return res;
    return true;
};
*/

@Component({
    selector: "lg-topbar-filterset",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="topbar__filterset-icon"
            [ngClass]="{ active: _popupVisible }"
            (click)="_show($event)"
        ></div>
        <div class="topbar__filterset-preview-block">
            <lg-filterset-preview [definition]="definition"></lg-filterset-preview>
            <div
                class="topbar__filterset-preview-block__more"
                title="Not all filters could be shown"
            ></div>
        </div>
        <ng-template #popup let-definition let-clear="onClear">
            <div class="topbar__filterset-popup-content">
                <div class="line">
                    <label class="header">Filters</label>
                    <a
                        class="control"
                        (click)="clear($event)"
                        title="{{ 'FW._Common.Clear_selections' | lgTranslate }}"
                        ><div class="icon-16 icon-16-erase"></div
                    ></a>
                </div>
                <div lgScrollable class="filterset-scrollable" style="max-height:600px">
                    <lg-filterset-list [list]="definition"></lg-filterset-list>
                </div>
                <ng-container *ngTemplateOutlet="_extension"></ng-container>
            </div>
        </ng-template>
    `
})
export class LgTopbarFiltersetComponent {
    private _tooltipService = inject(LgTooltipService);
    private _viewContainerRef = inject(ViewContainerRef);

    /**
     * Definition of all filters (required).
     */
    @Input({ required: true }) definition!: LgFilterSet;

    @Output() readonly clear = new EventEmitter<void>();

    @ViewChild("popup") _popup: TemplateRef<{
        $implicit: LgFilterSet;
        onClear: (event: MouseEvent) => void;
    }>;

    @ContentChild("extension") _extension: TemplateRef<any>;

    _popupVisible = false;
    private _tooltipApi: TooltipApi;

    constructor() {
        this._tooltipApi = this._tooltipService.create({
            position: "bottom-right",
            tooltipClass: "topbar__filterset-popup lg-tooltip",
            stay: true,
            preHide: () => {
                this._popupVisible = false;
                return true;
            },
            content: () => this._getContent()
        });
    }

    _show($event: MouseEvent): void {
        this._popupVisible = true;
        this._tooltipApi.show({
            target: new ElementRef($event.target)
        });
    }

    private _getContent(): TemplatePortal {
        return new TemplatePortal(this._popup, this._viewContainerRef, {
            $implicit: this.definition,
            onClear: (event: MouseEvent) => {
                event.stopPropagation();
                event.preventDefault();
                this.definition.clearAll(true);
            }
        });
    }
}
