import { inject, Injectable, Injector } from "@angular/core";
import { Log, User, UserManager, UserManagerSettings } from "oidc-client";
import { Subject } from "rxjs";

import { LgConsole } from "@logex/framework/core";
import { ITokenAuthenticationService, UserIdentity } from "./authentication.types";
import { LgPromptDialog } from "@logex/framework/ui-core";

export interface OidcAuthServiceConfig {
    authority: string;
    clientId: string;
    audience: string;
    type: "auth0" | "generic";
    basePath: string; // If hosted in root then basePath should be "/"
    redirectUrl: string;
}

const DELAYS_IN_SECONDS = [5, 7, 11, 17, 23, 31, 43, 59];
const SESSION_STORAGE_KEY_NAME = "lgLoginStage";

@Injectable()
export class OidcAuthService implements ITokenAuthenticationService {
    private _injector = inject(Injector);
    private _lgConsole = inject(LgConsole).withSource("Logex.OidcAuthService");

    constructor() {
        this._loginPromise = new Promise<void>(resolve => {
            this._loginPromiseResolve = resolve;
        });
    }

    // ----------------------------------------------------------------------------------

    private _config: OidcAuthServiceConfig;
    private _userManager: UserManager;
    private _user: User;
    private _userIdentity: UserIdentity;
    private _loginPromise: Promise<void>;
    private _loginPromiseResolve: () => void;

    // ----------------------------------------------------------------------------------
    configure(config: OidcAuthServiceConfig): void {
        this._config = config;
    }

    async login(): Promise<boolean> {
        if (this.loggedIn) {
            throw Error("User can be only logged in once");
        }

        const userManager = this._getUserManager();
        let user: User;

        const loginStage = this._getLoginStage();
        if (loginStage === 0) {
            // If it's a fist stage of login process, then we first try to get a new auth_token from auth provider
            try {
                user = await userManager.signinSilent();
            } catch (e: any) {
                if (e.error === "login_required" || e.error === "consent_required") {
                    // If silent signin requested login, then record that in the state, so the next time we get
                    // the token from the session storage
                    this._setLoginStage(1);

                    // This call causes page redirection, so we stop the execution until the application will be restarted
                    await userManager.signinRedirect({ state: this._config.redirectUrl });
                    return false;
                }
                throw e;
            }
        } else if (loginStage > 0) {
            // If we are coming back from `signin-callback.html` then we need to take the user from session storage
            user = await userManager.getUser();
            if (user == null) {
                this._setLoginStage(0);
                await userManager.signinRedirect({ state: this._config.redirectUrl });
                return false;
            }
        }

        // User is finally logged in, so we can erase the login stage indicator
        this._setLoginStage(null);

        // Subscribe to event after user is successfully logged in
        this._userManager.events.addSilentRenewError(this._onSilentRenewError.bind(this));
        this._userManager.events.addAccessTokenExpired(this._onAccessTokenExpired.bind(this));

        this._user = user;
        this._loginPromiseResolve();

        return true;
    }

    private _getLoginStage(): number {
        const value = window.sessionStorage.getItem(SESSION_STORAGE_KEY_NAME);
        return parseInt(value) || 0;
    }

    private _setLoginStage(stage: number | null): void {
        if (stage != null) {
            window.sessionStorage.setItem(SESSION_STORAGE_KEY_NAME, stage.toString());
        } else {
            window.sessionStorage.removeItem(SESSION_STORAGE_KEY_NAME);
        }
    }

    private _getUserManager(): UserManager {
        if (this._userManager == null) {
            Log.logger = this._lgConsole;
            Log.level = 3;

            const settings = this._getUserManagerSettings();
            this._userManager = new UserManager(settings);

            this._userManager.events.addUserLoaded(user => {
                this._user = user;
            });
        }

        return this._userManager;
    }

    private _getUserManagerSettings(): UserManagerSettings {
        const currentAppUrl = `${window.location.protocol}//${window.location.host}${this._config.basePath}`;
        const settings = {
            authority: this._getNormalizedAuthority(),
            extraQueryParams: {
                audience: this._config.audience
            },
            client_id: this._config.clientId,
            redirect_uri: `${currentAppUrl}signin-callback.html`,
            silent_redirect_uri: `${currentAppUrl}silent-callback.html`,
            popup_redirect_uri: `${currentAppUrl}signin-popup-callback.html`,
            response_type: "id_token token",
            scope: "openid api",
            automaticSilentRenew: true
        } as UserManagerSettings;

        /*
         * Auth0 does not return the "end_session_endpoint" so we need to set it ourselves, but
         * oidc-client doesn't let us to specify an override for ONLY that. This means we need to
         * specify ALL of these endpoints. Additionally for the end_session_endpoint there are a
         * couple of possibilities:
         *
         * - Logout from application only
         *       end_session_endpoint: this._config.authority + "/v2/logout"
         *
         * - Federated logout (i.e. also try to log the user out from external IdP)
         *       end_session_endpoint: this._config.authority + "/v2/logout?federated"
         *
         * - Logout to a given URL (i.e. https://app-dev.logex.co.uk/LogexOnline), note that if this is not specified
         *   Auth0 will redirect the user to the first mentioned application in the "Allowed Logout URLs"
         *       end_session_endpoint: this._config.authority + "/v2/logout?returnTo=http%3A%2F%2Fapp-dev.logex.co.uk/LogexOnline"
         *
         * For more info see https://auth0.com/docs/logout/guides/logout-auth0
         */

        if (this._usingAuth0()) {
            const authority = this._getNormalizedAuthority();
            settings.metadata = {
                // auth0 issuer always ends with trailing slash
                issuer: `${authority}/`,
                authorization_endpoint: `${authority}/authorize`,
                userinfo_endpoint: `${authority}/userinfo`,
                end_session_endpoint: `${authority}/v2/logout?returnTo=${currentAppUrl}&client_id=${this._config.clientId}`,
                jwks_uri: `${authority}/.well-known/jwks.json`,
                token_endpoint: `${authority}/oauth/token`
            };
        }

        return settings;
    }

    private _usingAuth0(): boolean {
        return this._config.type === "auth0";
    }

    private _getNormalizedAuthority(): string {
        const protocolPrefixRegex = /^https?:\/\//i;
        let authority = this._config.authority.trim();

        // For better compatibility with auth0 js library, support authority value without protocol
        if (!protocolPrefixRegex.test(authority)) {
            authority = `https://${authority}`;
        }
        // Make sure the authority doesn't contain the trailing slash
        if (authority.slice(-1) === "/") {
            authority = authority.slice(0, -1);
        }

        return authority;
    }

    private _onSilentRenewError(err: unknown): void {
        Log.logger.error("Silent renew error.", err);
        if (!this._user.expired) {
            Log.logger.info("Access token is not expired yet, restarting silentRenew");
            this._userManager.stopSilentRenew();
            setTimeout(() => this._userManager.startSilentRenew(), 5000);
        }
    }

    private async _onAccessTokenExpired(): Promise<void> {
        Log.logger.debug("Access token is expired");

        // We have to obtain the service via injector, because injecting it in the constructor
        // creates circular dependency in runtime
        const promptDialog = this._injector.get(LgPromptDialog);

        // Show alert
        const closeInfoSubject = new Subject<void>();
        promptDialog.infoLc("FW._AuthTokenRenew.AlertTitle", "FW._AuthTokenRenew.TokenExpired", {
            forceClick$: closeInfoSubject.asObservable()
        });

        // Try to renew the token
        let isLoginRequired = false;
        let delayIdx = 0;
        while (true) {
            try {
                await this._userManager.signinSilent();
                break;
            } catch (e: any) {
                if (e.error === "login_required") {
                    isLoginRequired = true;
                    break;
                }

                // Wait some time before trying to sign in again
                await new Promise<void>(resolve => {
                    setTimeout(() => resolve(), DELAYS_IN_SECONDS[delayIdx] * 1000);
                    if (delayIdx < DELAYS_IN_SECONDS.length - 1) delayIdx++;
                });
            }
        }

        closeInfoSubject.next();
        closeInfoSubject.complete();
        await Promise.resolve();

        if (isLoginRequired) {
            Log.logger.info("Login required");

            const button = await promptDialog.confirmLc(
                "FW._AuthTokenRenew.AlertTitle",
                "FW._AuthTokenRenew.ReloginRequired",
                {
                    buttons: [
                        {
                            id: "relogin",
                            nameLc: "FW._AuthTokenRenew.Relogin",
                            isConfirmAction: true
                        },
                        {
                            id: "reload",
                            nameLc: "FW._AuthTokenRenew.Reload",
                            isCancelAction: true
                        }
                    ],
                    allowClose: false,
                    noKeyboard: true
                }
            );
            switch (button) {
                case "relogin":
                    // Here we should have blocked the application screen while login is in progress, but
                    // injection of LgLoaderService creates circular dependency during build, so we need to have
                    // some workaround for this. For now just removed this functionality.
                    // const lgLoaderService = this._injector.get<LgLoaderService>(LgLoaderService);
                    try {
                        // lgLoaderService.show("login");
                        await this._userManager.signinPopup();
                    } finally {
                        // lgLoaderService.hide("login");
                    }
                    break;

                case "reload":
                    await this._userManager.signinRedirect({ state: this._config.redirectUrl });
                    break;

                default:
                    throw Error("Should not happen");
            }
        }
    }

    logout(): Promise<void> {
        if (!this.isLoggedIn()) return Promise.resolve();

        return this._userManager.signoutRedirect();
    }

    async isLoggedIn(): Promise<boolean> {
        return this._loginPromise.then(() => true);
    }

    get loggedIn(): boolean {
        return this._user != null;
    }

    get user(): UserIdentity {
        if (this._user == null) {
            throw Error("User is not logged in");
        }

        if (this._userIdentity == null) {
            this._userIdentity = {
                id: this._user.profile["https://logex.nl/legacyuserid"] ?? this._user.profile.sub,
                login: this._user.profile["https://logex.nl/login"] ?? this._user.profile.login,
                name: this._user.profile["https://logex.nl/login"] ?? this._user.profile.login
            };
        }

        return this._userIdentity;
    }

    async getAccessToken(): Promise<string> {
        return this._user.access_token;
    }

    get idToken(): string {
        return this._user.id_token;
    }
}
