<div class="flex-stretch flexcol lg-sidebar-panel-scrollable lg-sidebar-panel-scrollable--full">
    <div
        [lgScrollable]
        [lgScrollableWrapper]="false"
        lgScrollbarClass="lg-scrollbar lg-scrollbar--sidebar"
        class="flex-stretch"
    >
        <lg-sidebar-menu-item
            *ngFor="let navNode of _navigation"
            [currentUrl]="_currentUrl"
            [navNode]="navNode"
            (navigate)="_navigate($event)"
        ></lg-sidebar-menu-item>
    </div>
</div>
