export const NEW_FILTER_ID = "NewFilter";

export interface FilterSetState {
    filterHostId: string;
    name: string;
    overwrite: boolean;
    shared: boolean;
    parts: Record<string, string>;

    stateId?: string;
    authorName?: string;
    dateInsert?: Date;
    isOwn?: boolean;
}

export type TBookmarkUiModel = Pick<FilterSetState, "name">;

export type FilterSetStateUiModel = TBookmarkUiModel & Pick<FilterSetState, "shared" | "overwrite">;
