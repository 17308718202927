import { Injectable, inject } from "@angular/core";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { SidebarWidget } from "./sidebar-widget-base";
import { sortBy } from "lodash";
import { LgConsole } from "@logex/framework/core";
import { SidebarButton } from "./sidebar-button-base";

@Injectable({
    providedIn: "root"
})
export class LgSidebarService {
    private _console = inject(LgConsole).withSource("Logex.LgLayout.LgSidebarService");

    topWidgets(): Observable<SidebarWidget[]> {
        return this._topWidgets$.asObservable();
    }

    bottomWidgets(): Observable<SidebarWidget[]> {
        return this._bottomWidgets$.asObservable();
    }

    widgetRemoved(): Observable<SidebarWidget> {
        return this._removed$.asObservable();
    }

    private _topWidgets$ = new BehaviorSubject<SidebarWidget[]>([]);
    private _bottomWidgets$ = new BehaviorSubject<SidebarWidget[]>([]);
    private _removed$ = new Subject<SidebarWidget>();
    private _topWidgets: SidebarWidget[] = [];
    private _bottomWidgets: SidebarWidget[] = [];

    addWidget(widget: SidebarWidget): void {
        if (!widget.isVisible) return;

        this._checkWidgetId(widget as SidebarButton);

        if (widget.onTop) {
            this._topWidgets.push(widget);
            this._emitOrderedTopWidgets();
        } else {
            this._bottomWidgets.push(widget);
            this._emitOrderedBottomWidgets();
        }
    }

    removeWidget(widget: SidebarWidget): void {
        let index = this._topWidgets.indexOf(widget);
        if (index > -1) {
            this._topWidgets.splice(index, 1);
            this._emitOrderedTopWidgets();
            this._removed$.next(widget);
            return;
        }
        index = this._bottomWidgets.indexOf(widget);
        if (index > -1) {
            this._bottomWidgets.splice(index, 1);
            this._emitOrderedBottomWidgets();
            this._removed$.next(widget);
        }
    }

    swapWidgetPosition(widget: SidebarWidget): void {
        if (widget.onTop) {
            const index = this._bottomWidgets.indexOf(widget);
            if (index < 0) return;
            this._bottomWidgets.splice(index, 1);
            this._topWidgets.push(widget);
            this._emitOrderedTopWidgets();
            this._emitOrderedBottomWidgets();
        } else {
            const index = this._topWidgets.indexOf(widget);
            if (index < 0) return;
            this._topWidgets.splice(index, 1);
            this._bottomWidgets.push(widget);
            this._emitOrderedTopWidgets();
            this._emitOrderedBottomWidgets();
        }
    }

    handleWidgetVisibility(widget: SidebarWidget): void {
        if (widget.isVisible) {
            this.addWidget(widget);
        } else {
            this.removeWidget(widget);
        }
    }

    reorderWidget(widget: SidebarWidget): void {
        if (widget.onTop) {
            this._emitOrderedTopWidgets();
        } else {
            this._emitOrderedBottomWidgets();
        }
    }

    private _checkWidgetId(widget: SidebarButton): void {
        if (widget.panelTemplate) {
            if (widget.id == null) {
                this._console.error("Missing id for SidebarButton");
                return;
            }
            if (widget.onTop) {
                if (this._topWidgets.some(x => x.id === widget.id)) {
                    this._console.error(`Duplicate id (${widget.id}) for SidebarButton`);
                }
            } else if (this._bottomWidgets.some(x => x.id === widget.id)) {
                this._console.error(`Duplicate id (${widget.id}) for SidebarButton`);
            }
        }
    }

    private _emitOrderedTopWidgets(): void {
        this._topWidgets = sortBy(this._topWidgets, [widget => widget.order]);
        this._topWidgets$.next(this._topWidgets);
    }

    private _emitOrderedBottomWidgets(): void {
        this._bottomWidgets = sortBy(this._bottomWidgets, [widget => widget.order]);
        this._bottomWidgets$.next(this._bottomWidgets);
    }
}
