import { NgModule, ModuleWithProviders, InjectionToken, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgExportsModule } from "@logex/framework/lg-exports";

import { LgFilterSetService } from "./lg-filterset.service";
import { LgPreprocessingFilterSetService } from "./lg-preprocessing-filterset.service";
import { LG_FILTERSET_RENDERER_FACTORIES } from "./filter-renderer";
import { LG_FILTERSET_PREPROCESSORS } from "./filter-preprocessor";

import {
    CheckboxFilterRendererFactory,
    CheckboxFilterRendererLineComponent,
    CheckboxFilterRendererPopupComponent
} from "./renderers/checkbox-filter-renderer";
import {
    ComboFilterRendererFactory,
    ComboFilterRendererLineComponent,
    ComboFilterRendererPopupComponent
} from "./renderers/combo-filter-renderer";
import { ComboFilterRenderer2Factory } from "./renderers/combo-filter2-renderer";
import {
    DateFilterRendererFactory,
    DateFilterLineComponent,
    DateFilterPopupComponent
} from "./renderers/date-filter-renderer";
import {
    DropdownFilterRendererFactory,
    DropdownFilterRendererLineComponent,
    DropdownFilterRendererPopupComponent
} from "./renderers/dropdown-filter-renderer";
import {
    InputRangeFilterRendererFactory,
    InputRangeFilterRendererLineComponent
} from "./renderers/input-range-filter-renderer";
import {
    RangeFilterRendererFactory,
    RangeFilterRendererLineComponent,
    RangeFilterRendererPopupComponent
} from "./renderers/range-filter-renderer";
import {
    SelectedItemFilterRendererFactory,
    SelectedFilterRendererPopupComponent
} from "./renderers/selected-item-filter-renderer";
import {
    SwitchFilterRendererFactory,
    SwitchFilterRendererLineComponent
} from "./renderers/switch-filter-renderer";
import {
    TristateFilterRendererFactory,
    TristateFilterRendererLineComponent,
    TristateFilterRendererPopupComponent
} from "./renderers/tristate-filter-renderer";
import {
    TristateSliderFilterRendererFactory,
    TristateSliderFilterRendererLineComponent
} from "./renderers/tristate-slider-filter-renderer";

import { ComboFilterPreprocessor } from "./preprocessors/combo-filter-preprocessor";
import { ComboFilter2Preprocessor } from "./preprocessors/combo-filter2-preprocessor";
import { DropdownFilterPreprocessor } from "./preprocessors/dropdown-filter-preprocessor";

import { LgFiltersetListComponent } from "./directives/lg-filterset-list.component";
import { LgFiltersetListItemComponent } from "./directives/lg-filterset-list-item.component";
import { LgFiltersetPreviewComponent } from "./directives/lg-filterset-preview.component";
import { LgFiltersetPreviewItemComponent } from "./directives/lg-filterset-preview-item.component";
import { LgTopbarFiltersetComponent } from "./directives/lg-topbar-filterset.component";
import { SelectableComboFilterRenderer2Factory } from "./renderers/selectable-combo-filter2-renderer";

export const LG_FILTERSET_FORROOT_GUARD = new InjectionToken<void>("LG_FILTERSET_FORROOT_GUARD");

export function lgFiltersetForRootGuardFn(service: any): void {
    if (service) {
        throw new Error(`ModuleWithProviders.forRoot() called twice`);
    }
}

@NgModule({
    imports: [CommonModule, FormsModule, LgLocalizationModule, UiCoreModule, LgExportsModule],
    exports: [
        LgFiltersetListComponent,
        LgFiltersetListItemComponent,
        LgFiltersetPreviewComponent,
        LgFiltersetPreviewItemComponent,
        LgTopbarFiltersetComponent,
        CheckboxFilterRendererLineComponent,
        CheckboxFilterRendererPopupComponent,
        ComboFilterRendererLineComponent,
        ComboFilterRendererPopupComponent,
        DateFilterLineComponent,
        DateFilterPopupComponent,
        DropdownFilterRendererLineComponent,
        DropdownFilterRendererPopupComponent,
        InputRangeFilterRendererLineComponent,
        RangeFilterRendererLineComponent,
        RangeFilterRendererPopupComponent,
        SelectedFilterRendererPopupComponent,
        SwitchFilterRendererLineComponent,
        TristateFilterRendererLineComponent,
        TristateFilterRendererPopupComponent,
        TristateSliderFilterRendererLineComponent
    ],
    declarations: [
        LgFiltersetListComponent,
        LgFiltersetListItemComponent,
        LgFiltersetPreviewComponent,
        LgFiltersetPreviewItemComponent,
        LgTopbarFiltersetComponent,
        CheckboxFilterRendererLineComponent,
        CheckboxFilterRendererPopupComponent,
        ComboFilterRendererLineComponent,
        ComboFilterRendererPopupComponent,
        DateFilterLineComponent,
        DateFilterPopupComponent,
        DropdownFilterRendererLineComponent,
        DropdownFilterRendererPopupComponent,
        InputRangeFilterRendererLineComponent,
        RangeFilterRendererLineComponent,
        RangeFilterRendererPopupComponent,
        SelectedFilterRendererPopupComponent,
        SwitchFilterRendererLineComponent,
        TristateFilterRendererLineComponent,
        TristateFilterRendererPopupComponent,
        TristateSliderFilterRendererLineComponent
    ]
})
export class LgFiltersetModule {
    public static forRoot(): ModuleWithProviders<LgFiltersetModule> {
        return {
            ngModule: LgFiltersetModule,
            providers: [
                {
                    provide: LG_FILTERSET_FORROOT_GUARD,
                    useFactory: lgFiltersetForRootGuardFn,
                    deps: [[LgFilterSetService, new Optional(), new SkipSelf()]]
                },

                LgFilterSetService,
                LgPreprocessingFilterSetService,

                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: CheckboxFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: ComboFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: ComboFilterRenderer2Factory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: DateFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: DropdownFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: InputRangeFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: RangeFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: SelectableComboFilterRenderer2Factory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: SelectedItemFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: SwitchFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: TristateFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_RENDERER_FACTORIES,
                    useClass: TristateSliderFilterRendererFactory,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_PREPROCESSORS,
                    useClass: ComboFilterPreprocessor,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_PREPROCESSORS,
                    useClass: ComboFilter2Preprocessor,
                    multi: true
                },
                {
                    provide: LG_FILTERSET_PREPROCESSORS,
                    useClass: DropdownFilterPreprocessor,
                    multi: true
                }
            ]
        };
    }
}
