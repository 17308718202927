<ng-container *ngIf="node.type === 1; else container">
    <div class="lg-panel-grid__node__stack" *ngIf="node.attachedPanel">
        <div class="lg-panel__header" (click)="_clickHeader(node)">
            <ng-container
                *ngTemplateOutlet="panelHeaderTemplate; context: { $implicit: node }"
            ></ng-container>
        </div>
        <ng-container *ngIf="node.attachedPanel as panel">
            <ng-container
                *ngTemplateOutlet="panel._panelTemplate; context: panel._context"
            ></ng-container>
        </ng-container>
    </div>
</ng-container>
<ng-template #container>
    <div *ngIf="_hasCollapsedParts; else regularContainer" class="lg-panel-grid__node__stack">
        <div class="lg-panel-grid__node__stack__top-headers">
            <div
                *ngFor="let headerRow of node.headersAbove; let last = last"
                class="lg-panel-grid__node__stack__headers"
                [class.lg-panel-grid__node__stack__headers--simple-row]="
                    node.simpleTabbedRow && last
                "
            >
                <div
                    *ngFor="let child of headerRow.nodes"
                    class="lg-panel__header lg-panel__header--tab-like"
                    [class.lg-panel__header--minimized]="child !== node.effectiveMaximizedChild"
                    [class.lg-panel__header--auto]="headerRow.columnHeaders"
                    [style.flexBasis.%]="_getMinimizedHeaderRatio(headerRow, child)"
                    [style.width.%]="_getMinimizedHeaderRatio(headerRow, child)"
                    (click)="_clickHeader(child)"
                >
                    <ng-container
                        *ngTemplateOutlet="panelHeaderTemplate; context: { $implicit: child }"
                    ></ng-container>
                    <div class="lg-panel__header__shade"></div>
                </div>
            </div>
        </div>
        <div
            class="lg-panel__header lg-panel__header--maximized"
            (click)="_clickHeader(node.effectiveMaximizedChild)"
            *ngIf="!node.simpleTabbedRow"
        >
            <ng-container
                *ngTemplateOutlet="
                    panelHeaderTemplate;
                    context: { $implicit: node.effectiveMaximizedChild }
                "
            ></ng-container>
        </div>
        <ng-container *ngIf="node.effectiveMaximizedChild.attachedPanel as panel">
            <ng-container
                *ngTemplateOutlet="panel._panelTemplate; context: panel._context"
            ></ng-container>
        </ng-container>
        <div class="lg-panel-grid__node__stack__bottom-headers">
            <div
                class="lg-panel-grid__node__stack__headers"
                *ngFor="let headerRow of node.headersBelow; let index = index"
                [style.zIndex]="node.headersBelow.length - index"
            >
                <div
                    *ngFor="let child of headerRow.nodes"
                    class="lg-panel__header lg-panel__header--minimized lg-panel__header--tab-like"
                    [class.lg-panel__header--auto]="headerRow.columnHeaders"
                    [style.flexBasis.%]="_getMinimizedHeaderRatio(headerRow, child)"
                    [style.width.%]="_getMinimizedHeaderRatio(headerRow, child)"
                    (click)="_clickHeader(child)"
                >
                    <ng-container
                        *ngTemplateOutlet="panelHeaderTemplate; context: { $implicit: child }"
                    ></ng-container>
                    <div class="lg-panel__header__shade"></div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #regularContainer>
        <lg-panel-grid-node
            *ngFor="let child of node.children"
            [node]="child"
            [attr.panel-id]="child.definition.id"
        ></lg-panel-grid-node>
    </ng-template>
</ng-template>

<ng-template #panelHeaderTemplate let-node>
    <ng-container *ngIf="node.attachedPanel as panel">
        <ng-container *ngFor="let header of panel._visibleHeaders">
            <ng-container
                *ngTemplateOutlet="header.template; context: panel._context"
            ></ng-container>
        </ng-container>
        <div class="lg-block__icons">
            <ng-container *ngFor="let icon of panel._visibleIcons">
                <ng-container
                    *ngTemplateOutlet="icon.template; context: panel._context"
                ></ng-container>
            </ng-container>
            <lg-panel-resize-menu [node]="node"></lg-panel-resize-menu>
        </div>
    </ng-container>
</ng-template>

<ng-template #panelBodyTemplate let-node> </ng-template>
