import _ from "lodash";
import { Injectable, isDevMode, inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import * as Pivots from "@logex/framework/lg-pivot";
import { isIterable } from "@logex/framework/utilities";
import {
    IFilterOption,
    IFilterSorterOption,
    ITranslateFilterOptionCallback
} from "@logex/framework/types";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import type { LgFilterSet } from "../lg-filterset";
import { IComboFilterDefinitionBase, ComboFilterRendererBase } from "./combo-filter-renderer";

export interface IComboFilter2WrappedId<T> {
    id: T;
    data: any;
}

// Combo box renderer 2 definition extensions ----------------------------------------------------------------------
export interface IComboFilter2Definition<T> extends IComboFilterDefinitionBase<T> {
    filterType: "combo2";

    /**
     * The function that should return IDs of the currently available selections for the filter.
     */
    source:
        | Pivots.IGatherFilterIdsFactoryResult<T>
        | (() => IterableIterator<T>)
        | (() => Promise<T[]>)
        | (() => Observable<T[]>)
        | (() => Array<IComboFilter2WrappedId<T>>)
        | (() => IterableIterator<IComboFilter2WrappedId<T>>)
        | (() => Observable<Array<IComboFilter2WrappedId<T>>>)
        | (() => Promise<Array<IComboFilter2WrappedId<T>>>);

    /**
     * The function that maps the array of IDs to sorted array of filter options.
     */
    mapToOptions: ITranslateFilterOptionCallback<T>;

    /**
     * Optional callback to prepare the filter stater for serialization. The callback must not modify the parameter
     * - if you need to do any tranformation, use map or clone.
     * Typical use would be to change scenario-specific IDs to something more general.
     * The callback should be always accompanied by matching deserializationPostprocess
     */
    serializationPreprocess?: (ids: T[]) => any;

    /**
     * Optional callback to convert a deserialized object back into the list of option IDs. See serializationPreprocess for details
     */
    deserializationPostprocess?: (state: any) => T[];

    /**
     * When true, the IDs are converted to numbers before serialization
     */
    idType: "string" | "number";

    renderer?: ComboFilterRenderer2<T>;

    /**
     * When true, show sorter icon with sort options witch depends on sorterOptions
     */
    showSorter?: boolean;

    /**
     * Contain sorting options
     */
    sorterOptions?: IFilterSorterOption[];

    /**
     * When true, null values won't trigger incompatible type alert message
     */
    allowNullValues?: boolean;
}

// Combo box renderer 2 --------------------------------------------------------------------------------------------
/**
 * Implementes combo-box filter. See IComboFilter2Definition for the list of additional options
 */
export class ComboFilterRenderer2<T> extends ComboFilterRendererBase implements IFilterRenderer {
    // ----------------------------------------------------------------------------------
    // Dependencies
    constructor(
        protected override definition: IComboFilter2Definition<any>,
        filters: any,
        logexPivot: Pivots.LogexPivotService
    ) {
        super(definition, filters, logexPivot);
        if (!this.definition.source) {
            throw Error(`Source definition for filter "${this.definition.id}" is not found.`);
        }
        if (!this.definition.mapToOptions) {
            throw Error(`Option mapping for filter "${this.definition.id}" is not found.`);
        }
    }

    source: () => IFilterOption[] | Promise<IFilterOption[]> | Observable<IFilterOption[]> = () => {
        const ids = this.definition.source();
        const mapOptionsCallback = this.definition.mapToOptions as ITranslateFilterOptionCallback<
            number | string
        >;
        const optionalDataCallback = (
            items: T[] | Array<IComboFilter2WrappedId<T>>
        ): IFilterOption[] => {
            if (items.length && items[0] !== null && typeof items[0] === "object") {
                const dataLookup: _.Dictionary<any> = {};
                const idsOnly = _.map(
                    items as unknown as Array<IComboFilter2WrappedId<number | string>>,
                    item => {
                        dataLookup[item.id] = item.data;
                        return item.id;
                    }
                );
                const options = mapOptionsCallback(idsOnly);
                return _.map(options, option => ({
                    ...option,
                    data: dataLookup[option.id] ?? option.data
                }));
            } else {
                return mapOptionsCallback(items as any[]);
            }
        };

        if (_.isArray(ids)) {
            return optionalDataCallback(ids as any[]);
        } else if ("then" in ids) {
            return ids.then(data => optionalDataCallback(data));
        } else if (isIterable(ids)) {
            return optionalDataCallback(Array.from(ids));
        } else {
            return ids.pipe(map(data => optionalDataCallback(data)));
        }
    };

    serialize(): string {
        if (!this.active()) return null;
        const state = this.filters[this.definition.storage];
        if (state.$empty) return "[]";
        let ids: any[] = _.keys(state);
        if (this.definition.idType === "number") {
            if (isDevMode()) {
                // https://logexgroup.atlassian.net/browse/NUF-116
                let isString = false;
                ids = _.map(ids, e => {
                    const res = e === "null" && this.definition.allowNullValues ? null : +e;
                    isString = isString || isNaN(res);
                    return res;
                });
                if (isString) {
                    alert(
                        "Attempted to convert string to number. Check console for id of incorrect filter. Do you have correct implementation?"
                    );
                    console.log(this.definition.name);
                }
            } else if (this.definition.allowNullValues) {
                ids = _.map(ids, e => (e === "null" ? null : +e));
            } else {
                ids = _.map(ids, e => +e);
            }
        } else if (this.definition.allowNullValues) {
            ids = _.map(ids, e => (e === "null" ? null : e));
        }

        if (this.definition.serializationPreprocess) {
            return JSON.stringify((this.definition.serializationPreprocess as Function)(ids));
        } else {
            return JSON.stringify(ids);
        }
    }

    deserialize(state: string): boolean {
        let newIds = JSON.parse(state);
        if (_.isPlainObject(newIds)) {
            // this could be serialization of the old combo filter, convert it to list of IDs
            newIds = _.keys(newIds);
            if (this.definition.idType === "number") {
                newIds = _.map(newIds, e => +e);
            }
        }
        if (this.definition.deserializationPostprocess) {
            newIds = this.definition.deserializationPostprocess(newIds);
        }
        const newState: any = {};
        if (_.isArray(newIds)) {
            if (newIds.length === 0) {
                newState.$empty = true;
            } else {
                const options = (
                    this.definition.mapToOptions as ITranslateFilterOptionCallback<any>
                )(newIds);
                for (const option of options) {
                    newState[option.id] = option.name;
                }
            }
        } else {
            newState.$empty = true;
        }
        if (!_.isEqual(this.filters[this.definition.storage], newState)) {
            this.filters[this.definition.storage] = newState;
            this._updatePreviewName();
            return true;
        }
        return false;
    }

    toggleItem(id: T): void {
        const state = this.filters[this.definition.storage];
        if (state[id]) {
            delete state[id];
            if (_.size(state) === 0) state.$empty = true;
        } else {
            state[id] = this.definition.mapToOptions([id])[0].name;
            delete state.$empty;
        }
        this.onChanged();
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable()
export class ComboFilterRenderer2Factory implements IFilterRendererFactory {
    private _pivotService = inject(Pivots.LogexPivotService);
    readonly name: string = "combo2";

    create(
        definition: IComboFilter2Definition<any>,
        filters: _.Dictionary<any>,
        _definitions: IFilterDefinition[],
        _filterSet: LgFilterSet
    ): IFilterRenderer {
        return new ComboFilterRenderer2(definition, filters, this._pivotService);
    }
}
