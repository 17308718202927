import { inject, Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, forkJoin, from, isObservable, Observable, Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import {
    ApplicationInsights,
    SeverityLevel,
    ICustomProperties
} from "@microsoft/applicationinsights-web";

import {
    ApplicationTraceSeverity,
    IApplicationEventTracer,
    LgApplicationEventTracerNames
} from "@logex/framework/core";
import {
    IApplicationInsightsConfiguration,
    IApplicationInsightsPostponeConfiguration,
    LG_APPLICATION_INSIGHTS_CONFIGURATION
} from "./application-insights-configuration";

function isFullConfiguration(
    config: IApplicationInsightsConfiguration | IApplicationInsightsPostponeConfiguration
): config is IApplicationInsightsConfiguration {
    return "instrumentationKey" in config;
}

interface IPromiseLike<T> {
    then(callback: (val: T) => any): any;
}
/**
 * Implementation of IApplicationEventTracer for ApplicationInsights.
 *
 * Usage:
 * For LgBackend applications, the configuration should be trivial - just register these 2 providers
 * in your app.module.ts
 * {
 *    provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
 *    useExisting: LgApplicationInsightsService,
 *    multi: true
 *  },
 *  {
 *      provide: LG_APPLICATION_INSIGHTS_CONFIGURATION,
 *      useExisting: LgBackendApplicationInsightsConfiguration
 *  }
 *
 * For other applications, the first step remains, however you will need to provide
 * your own implementation of IApplicationInsightsConfiguration.
 *
 * In general, the service supports 2 ways for initialization
 * - explicit call to the init() method
 * - automatic initialization at the end of first navigation event (this is used by the LgBackend config)
 *
 * The service supports 3 ways for providing the configuration
 * - config object provided through injection
 * - config object provided through injecting promise
 * - config object passed explicitly to the init() method
 * Note that in the first 2 situations, the configuration object doesn't necessary need to be fully filled. Apart from
 * the autoInit field, it is consulted only at the moment init is (explicitly or automatically) invoked.
 *
 * Note that in the third case, you still need to provide at least minimal config ( IApplicationInsightsPostponeConfiguration )
 * through the injector. You can use the LG_POSTPONE_APLICATION_INSIGHTS_CONFIGURATION for this purpose.
 *
 * \see IApplicationInsightsConfiguration
 */
@Injectable({ providedIn: "root" })
export class LgApplicationInsightsService implements IApplicationEventTracer, OnDestroy {
    private _router = inject(Router);

    private readonly _isReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    readonly isReady$ = this._isReady$.asObservable();
    readonly tracerName = LgApplicationEventTracerNames.ApplicationInsights;
    private readonly _destroyed$ = new Subject<void>();
    private readonly _navigation$: Observable<NavigationEnd>;
    private _appInsights: ApplicationInsights;
    private _isInitialized = false;
    private _config: IApplicationInsightsConfiguration | undefined;

    // TODO: using IPromiseLike, because Promise fails AoT. Check again with Angular 12
    // ----------------------------------------------------------------------------------
    constructor() {
        const _initialConfiguration = inject(LG_APPLICATION_INSIGHTS_CONFIGURATION);

        this._navigation$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._destroyed$)
        ) as any; // we know it's NavigationEnd

        forkJoin([
            from(Promise.resolve(_initialConfiguration)),
            this._navigation$.pipe(first())
        ]).subscribe(([config]) => {
            let ready: Promise<void>;
            if ("ready" in config && config.ready != null) {
                ready = config.ready;
            } else {
                ready = Promise.resolve();
            }
            ready.then(() => {
                if (!this._isInitialized && config.autoInit) {
                    this._config = config;
                    this.init();
                } else if (isFullConfiguration(config)) {
                    this._config = config;
                }
            });
        });
    }

    // ----------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._isReady$.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    // ----------------------------------------------------------------------------------
    init(finalConfiguration?: IApplicationInsightsConfiguration): void {
        if (this._isInitialized) {
            if (finalConfiguration) {
                throw new Error("ApplicationInsights are already configured");
            }
            return;
        }

        if (finalConfiguration) {
            this._config = finalConfiguration;
        } else if (this._config === undefined) {
            throw new Error("Incomplete ApplicationInsights configuration provided");
        }

        if (this._config.instrumentationKey == null || this._config.instrumentationKey === "")
            return;
        if (this._config.doNotDoAiTracking && this._config.doNotDoAiTracking()) return;

        this._appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this._config.instrumentationKey,
                enableCorsCorrelation: this._config.enableCorsCorrelation ?? true,
                enableAutoRouteTracking: true,
                disableCorrelationHeaders: this._config.disableCorrelationHeaders ?? false,
                correlationHeaderExcludedDomains: this._config.correlationHeaderExcludedDomains,
                correlationHeaderDomains: this._config.correlationHeaderDomains
            }
        });

        this._appInsights.loadAppInsights();

        if (isObservable(this._config.userId)) {
            this._config.userId.pipe(takeUntil(this._destroyed$)).subscribe(userId => {
                this._appInsights.setAuthenticatedUserContext(userId);
            });
        } else {
            this._appInsights.setAuthenticatedUserContext(this._config.userId);
        }

        if (this._config.customData != null) {
            const callback: () => ICustomProperties =
                typeof this._config.customData === "function"
                    ? () => this._config.customData.call(this._config) as ICustomProperties
                    : () => this._config.customData;
            this._appInsights.addTelemetryInitializer(item => {
                const data = callback();
                Object.keys(data).forEach(key => (item.data[key] = data[key]));
            });
        }

        if (this._config.telemetryInitializers) {
            this._config.telemetryInitializers.forEach(initializer =>
                this._appInsights.addTelemetryInitializer(initializer)
            );
        }

        if (this._config.finishInitialization) {
            this._config.finishInitialization.call(this._config, this._appInsights);
        }

        this._isInitialized = true;
        this._isReady$.next(true);
    }

    logPageView(path: string): void {
        // this._lgConsole.debug("AppInsights: logPageView", { path });
        this._appInsights.trackPageView({ uri: path });
    }

    pageChange(): void {
        // this._lgConsole.debug("AppInsights: pageChange");
        // In the current solution this method won't be called.
    }

    trackEvent(category: string, action: string, label?: string, value?: number): void {
        if (!this._isInitialized) {
            return;
        }

        this._appInsights.trackEvent({
            name: `${category} - ${action}`,
            properties: {
                category,
                action,
                label,
                value
            }
        });
    }

    trackTime(category: string, variable: string, value: number, label?: string): void {
        if (!this._isInitialized) {
            return;
        }

        this._appInsights.trackEvent(
            {
                name: `${category} - ${variable}`,
                properties: {
                    category,
                    variable,
                    label
                }
            },
            {
                processingTime: value
            }
        );
    }

    trackTrace(
        severity: ApplicationTraceSeverity,
        message: string,
        customProperties?: unknown
    ): void {
        if (!this._isInitialized) {
            return;
        }
        this._appInsights.trackTrace({
            message,
            severityLevel: this._convertSeverity(severity),
            properties: customProperties
        });
    }

    trackException(exception: Error, customProperties?: unknown): void {
        if (!this._isInitialized) {
            return;
        }
        this._appInsights.trackException({
            exception,
            properties: customProperties
        });
    }

    private _convertSeverity(severity: ApplicationTraceSeverity): SeverityLevel {
        switch (severity) {
            case ApplicationTraceSeverity.Critical:
                return SeverityLevel.Critical;
            case ApplicationTraceSeverity.Error:
                return SeverityLevel.Error;
            case ApplicationTraceSeverity.Information:
                return SeverityLevel.Information;
            case ApplicationTraceSeverity.Verbose:
                return SeverityLevel.Verbose;
            case ApplicationTraceSeverity.Warning:
                return SeverityLevel.Warning;
            default:
                return SeverityLevel.Verbose;
        }
    }

    get appInsights(): ApplicationInsights {
        return this._appInsights;
    }
}
