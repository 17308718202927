import { TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export type LgPanelSwitchState$ = BehaviorSubject<{
    choiceId: ChoiceIdType;
    variantId: ChoiceIdType;
}>;

export type ChoiceIdType = number | string;

export interface IPanelSwitchDefinition {
    choices: IPanelChoiceDefinition[];
}

export interface IPanelChoiceDefinition extends IPanelChoiceVariantDefinition {
    variants: IPanelChoiceVariantDefinition[];
    mainPaneTemplateRef: TemplateRef<any>;
}

export interface IPanelChoiceVariantDefinition {
    id: ChoiceIdType;
    name: string;
    icon: string;
    title?: string;
    additionalMenuItemsTemplateRef?: TemplateRef<any>;
    rightAdditionalMenuItemsTemplateRef?: TemplateRef<any>;
}

// export interface IChoiceMenuElement {
//     choiceId: ChoiceIdType;
//     variantId: ChoiceIdType;
//     name: string;
//     icon: string;
// }
