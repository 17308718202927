import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

const CACHE_SIZE = 1;
const URL = "api/user/general";

interface IWrap {
    storage: IUserInfo;
}

export interface IUserInfo {
    id?: number;
}

@Injectable({ providedIn: "root" })
export class LgUserInfoService {
    private _httpClient = inject(HttpClient);

    private _userInfoCache: Observable<IUserInfo>;

    get userInfo(): Observable<IUserInfo> {
        if (!this._userInfoCache) {
            this._userInfoCache = this._requestUserInfo().pipe(shareReplay(CACHE_SIZE));
        }

        return this._userInfoCache;
    }

    sync(): void {
        if (!this._userInfoCache) {
            throw Error("Data not loaded");
        }

        this._userInfoCache.subscribe(x => this._requestSync(x));
    }

    private _requestUserInfo(): Observable<IUserInfo> {
        return this._httpClient.get<IWrap>(URL).pipe(map(response => response.storage));
    }

    private _requestSync(current: IUserInfo): void {
        this._httpClient
            .post(URL, { storage: current })
            .pipe(map(response => (response as IWrap).storage));
    }
}
