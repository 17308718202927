import { DbFilterSetStateSaveObject } from "../lg-filterset-state-gateway.types";

import { FilterSetState } from "../lg-filterset.types";

export function mapFiltersetStateToDb(set: FilterSetState): DbFilterSetStateSaveObject {
    return {
        state_id: set.stateId,
        filter_host_id: set.filterHostId,
        name: set.name,
        overwrite: set.overwrite,
        shared: set.shared,
        parts: Object.entries(set.parts).map(([filterId, storage]) => ({
            filter_id: filterId,
            storage,
            state_id: set.stateId
        }))
    };
}
