import {
    Component,
    ViewChild,
    OnDestroy,
    ViewEncapsulation,
    ElementRef,
    EventEmitter,
    OnInit,
    NgZone,
    ChangeDetectorRef,
    HostBinding,
    HostListener,
    inject
} from "@angular/core";
import { trigger, state, style, transition, animate, AnimationEvent } from "@angular/animations";
import { Subject, Observable } from "rxjs";
import { Portal } from "@angular/cdk/portal";
import { ConnectionPositionPair } from "@angular/cdk/overlay";

import { TooltipPosition } from "./lg-tooltip.types";
import { ResizeWatcher } from "@logex/framework/utilities";
import { LgPortalOutletDirective } from "../templating/lg-portal-outlet.directive";
import { NgIf } from "@angular/common";

// ---------------------------------------------------------------------------------------------
export interface ExtendedConnectionPositionPair extends ConnectionPositionPair {
    arrowLeft: number | null;
    arrowRight: number | null;
    arrowTop: number | null;
    arrowBottom: number | null;
    className: TooltipPosition;
}

// interface ICalculatedPosition {
//     left: number;
//     top: number;
//     className: string;
//     onRight: boolean;
//     onBottom: boolean;
//     onSide: boolean;
//     arrowLeft: number | null;
//     arrowRight: number | null;
//     arrowTop: number | null;
//     arrowBottom: number | null;
// }

@Component({
    standalone: true,
    selector: "lg-tooltip-holder",
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("state", [
            state("initial, void, hidden", style({ opacity: 0 })),
            state("visible", style({ opacity: 1 })),
            transition("* => *", animate("{{animationDuration}}ms"))
        ])
    ],
    imports: [LgPortalOutletDirective, NgIf],
    template: `
        <div class="arrow-holder" #arrowHolder></div>
        <span [innerHTML]="_sanitizedMessage" lgPortalOutlet #outlet="lgPortalOutlet"></span>
        <div class="close" *ngIf="hasClose" (click)="_closeClick()"></div>
    `
})
export class LgTooltipHolderComponent implements OnDestroy, OnInit {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);

    @ViewChild("outlet", { static: true }) _portalOutlet: LgPortalOutletDirective;
    @ViewChild("arrowHolder", { static: true }) _arrowHolder: ElementRef;

    @HostBinding("class")
    get className(): string {
        return (this.tooltipClass || "lg-tooltip") + " " + (this._actualPosition || "");
    }

    tooltipClass: string;
    animationEnabled = true;

    @HostBinding("class.with-close")
    hasClose: boolean;

    _visibility: "initial" | "hidden" | "visible" = "initial";

    @HostBinding("@state")
    get _state(): any {
        return {
            value: this._visibility,
            params: this._getAnimationParams()
        };
    }

    requestClose: EventEmitter<void> = new EventEmitter();
    hover: EventEmitter<boolean> = new EventEmitter();
    requestReposition: EventEmitter<void> = new EventEmitter();
    ensureVisibility: boolean;
    position: TooltipPosition;

    get portalReference(): any {
        return this._portalReference;
    }

    private readonly _onHide: Subject<void> = new Subject();
    private readonly _onHideStart: Subject<void> = new Subject();
    private _actualPosition: TooltipPosition;
    private _resizeWatcher: ResizeWatcher;
    private _portalReference: any = null;

    // ----------------------------------------------------------------------------------
    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._detachPortal();
        this._message = value;
        this._sanitizedMessage = value == null ? "" : value;
        this._changeDetectorRef.markForCheck();
        this._portalReference = null;
    }

    // ----------------------------------------------------------------------------------
    get portal(): Portal<any> {
        return this._portal;
    }

    set portal(value: Portal<any>) {
        if (this._portal && this._portal !== value) {
            this._detachPortal();
            this._portalReference = null;
        }
        this._portal = value;
        if (this._portal) {
            this._portalReference = this._portalOutlet.attach(this._portal);
        }
        this._changeDetectorRef.markForCheck();
    }

    // ----------------------------------------------------------------------------------
    hide(immediately?: boolean): void {
        if (immediately) this.animationEnabled = false;
        this._visibility = "hidden";
        this._onHideStart.next();
        this.hover.complete();
        this._changeDetectorRef.markForCheck();
    }

    // ----------------------------------------------------------------------------------
    _sanitizedMessage = "";
    private _message: string;
    private _portal: Portal<any>;

    private _detachPortal(): void {
        if (this._portalOutlet.attached) {
            this._portalOutlet.detach();
        }
    }

    // ----------------------------------------------------------------------------------
    _closeClick(): void {
        this.requestClose.emit();
    }

    // ----------------------------------------------------------------------------------
    @HostListener("mouseenter")
    _onMouseEnter(): void {
        this.hover.emit(true);
    }

    // ----------------------------------------------------------------------------------
    @HostListener("mouseleave")
    _onMouseLeave(): void {
        this.hover.emit(false);
    }

    // ----------------------------------------------------------------------------------
    ngOnInit(): void {
        if (this._visibility === "hidden") return;
        this._visibility = "visible";
        this._changeDetectorRef.markForCheck();
        if (this.ensureVisibility) {
            this._resizeWatcher = new ResizeWatcher(
                this._ngZone,
                this._elementRef.nativeElement,
                () => {
                    this.requestReposition.next();
                }
            );
        }
    }

    // ----------------------------------------------------------------------------------
    beforeHidden(): Observable<void> {
        return this._onHideStart.asObservable();
    }

    // ----------------------------------------------------------------------------------
    afterHidden(): Observable<void> {
        return this._onHide.asObservable();
    }

    // ----------------------------------------------------------------------------------
    ngOnDestroy(): void {
        if (this._resizeWatcher) {
            this._resizeWatcher.stop();
            this._resizeWatcher = null;
        }
        this._detachPortal();
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("@state.start")
    _animationStart(): void {
        // currently empty
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("@state.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._onHide.next();
        }
    }

    setPosition(newPosition: ExtendedConnectionPositionPair): void {
        // doing this because otherwise the change detector takes too much time during scrolling
        if (this._actualPosition) {
            this._elementRef.nativeElement.classList.remove(this._actualPosition);
        }

        const arrowHolder = this._arrowHolder.nativeElement;
        this._actualPosition = newPosition.className;
        if (this._actualPosition) {
            this._elementRef.nativeElement.classList.add(this._actualPosition);
        }

        arrowHolder.style.left =
            newPosition.arrowLeft === null ? "auto" : newPosition.arrowLeft + "px";
        arrowHolder.style.right =
            newPosition.arrowRight === null ? "auto" : newPosition.arrowRight + "px";
        arrowHolder.style.top =
            newPosition.arrowTop === null ? "auto" : newPosition.arrowTop + "px";
        arrowHolder.style.bottom =
            newPosition.arrowBottom === null ? "auto" : newPosition.arrowBottom + "px";
        this._changeDetectorRef.detectChanges();
        this._ngZone.run(() => undefined);
    }

    // ---------------------------------------------------------------------------------------------
    private _getAnimationParams(): any {
        return {
            animationDuration: this.animationEnabled ? 200 : 0
        };
    }
}
