import type { LgTranslateService } from "@logex/framework/lg-localization";
import type { IPivotTableLevelHeaderDefinition, IPivotTableLevelHeader } from "../types";

export function convertPivotLevelHeaders(
    levels: IPivotTableLevelHeaderDefinition,
    lgTranslate: LgTranslateService
): IPivotTableLevelHeader[] {
    if (!levels || levels.length === 0) {
        return [];
    }

    return levels.map((levelDef): IPivotTableLevelHeader => {
        if (levelDef === null || levelDef === undefined) return undefined;

        if (levelDef === true) {
            return { hidden: true };
        }

        if (Array.isArray(levelDef)) {
            return {
                header: levelDef[2] || lgTranslate.translate(levelDef[0]),
                orderBy: levelDef[1]
            };
        } else {
            return {
                header: levelDef.headerHtml
                    ? undefined
                    : levelDef.header
                    ? levelDef.header
                    : lgTranslate.translate(levelDef.headerLc),
                headerHtml: levelDef.headerHtml,
                orderBy: levelDef.orderBy,
                hidden: levelDef.hidden
            };
        }
    });
}
