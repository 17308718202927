<lg-dropdown
    *ngIf="showScenarioButton || showHospitalButton"
    className="lg-breadcrumb-data-context__dropdown"
    [textOverride]="'.Switch' | lgTranslate"
    [definition]="_dropdownDef"
    (currentChange)="_switch($event)"
    [hideArrow]="true"
    [hideSearch]="true"
    [matchWidth]="'content'"
></lg-dropdown>

<span
    *ngIf="!hideHospital"
    class="lg-breadcrumb-data-context__hospital"
    (click)="_switchHospital()"
    tabindex="0"
    >{{ _userInfo.ziekenhuisnaam }}</span
>
<span
    *ngIf="_version && !hideScenario && !!_version.scenario_naam"
    class="lg-breadcrumb-data-context__version"
>
    {{ "Scenario" | lgTranslate }}:
    <span
        class="lg-breadcrumb-data-context__version__name"
        (click)="_onScenarioClicked()"
        tabindex="0"
        [lgTooltip]="_version.scenario_omschrijving ?? ''"
    >
        {{ _version.scenario_naam }}
    </span>
</span>

<div class="lg-breadcrumb-data-context__icons">
    <lg-icon
        *ngIf="_version.is_primary"
        icon="icon-star"
        [inline]="true"
        class="lg-breadcrumb-data-context__icon__star"
        [lgTooltip]="'.ThisDatasetContainsPrimaryVersion' | lgTranslate"
    ></lg-icon>
    <lg-icon
        *ngIf="_version.locked && !hideLock"
        icon="icon-lock"
        [inline]="true"
        [lgTooltip]="'.ThisScenarioIsLocked' | lgTranslate"
    ></lg-icon>
</div>
