/*
import * as angular from "angular";
import * as types from "@logex/framework/types";
import * as Pivots from "../pivots/logex-pivot";
import * as Xlsx from "../xlsx/logex-xlsx-types";

import {
    ILgTranslateBound,
    ILgTranslate
} from "../localization/logex-translate";

import { GaTrackingService } from "../integration/GaTrackingService";

import {
    CheckboxFilterRenderer,
    ComboFilterRenderer,
    ComboFilterRenderer2,
    DateFilterRenderer,
    DropdownFilterRenderer,
    InputRangeFilterRenderer,
    RangeFilterRenderer,
    SelectedItemFilterRenderer,
    TristateFilterRenderer
} from "./filterset-renderers"

*/
import _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";
import { LgFormatTypePipe } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "./filter-definition";
import { IFilterRendererFactory, LG_FILTERSET_RENDERER_FACTORIES } from "./filter-renderer";
import { LgFilterSet } from "./lg-filterset";

// FilterSetService ---------------------------------------------------------------------------------------
@Injectable()
export class LgFilterSetService {
    private _formatPipe = inject(LgFormatTypePipe);
    private _trackingService = inject(LG_APPLICATION_EVENT_TRACER);
    private _filterTypes: _.Dictionary<IFilterRendererFactory>;

    constructor() {
        const filterTypes = inject<IFilterRendererFactory[]>(LG_FILTERSET_RENDERER_FACTORIES);

        this._filterTypes = _.keyBy(filterTypes, f => f.name.toUpperCase());
    }

    public create(
        definitions: IFilterDefinition[],
        translationService: LgTranslateService,
        context?: any
    ): LgFilterSet {
        return new LgFilterSet(
            definitions,
            translationService,
            this._trackingService,
            context,
            this._filterTypes,
            this._formatPipe
        );
    }
}

/*
// FilterSetProvider --------------------------------------------------------------------------------------
export class FilterSetProvider {
    public filterTypes: types.IStringLookup<IFilterRendererConstructor> = {};

    public constructor() {
        this.$get.$inject = ["$injector", "logex.pivot", "lgTranslate"];
        this.registerType( "combo", ComboFilterRenderer );
        this.registerType( "combo2", ComboFilterRenderer2 );
        this.registerType( "selected", SelectedItemFilterRenderer );
        this.registerType( "checkbox", CheckboxFilterRenderer );
        this.registerType( "tristate", TristateFilterRenderer );
        this.registerType( "dropdown", DropdownFilterRenderer );
        this.registerType( "range", RangeFilterRenderer );
        this.registerType( "date", DateFilterRenderer );
        this.registerType( "inputRange", InputRangeFilterRenderer );
    }

    public registerType( name: string, cons: IFilterRendererConstructor ) {
        this.filterTypes[name.toUpperCase()] = cons;
    }

    public $get( $injector, logexPivot, lgTranslate ) {
        return new FilterSetService( $injector, logexPivot, this.filterTypes, lgTranslate );
    }
}

*/
