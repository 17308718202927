/* TODO:
  - consider unifying the 2 classes, or move all but the nodes from provider to service
  - interpolation / internationalizaiton
*/
import _ from "lodash";
import { Injectable, InjectionToken, inject } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import {
    combineLatest,
    isObservable,
    lastValueFrom,
    Observable,
    of,
    ReplaySubject,
    Subject
} from "rxjs";
import { shareReplay, map, takeUntil, tap, first, debounceTime } from "rxjs/operators";
import { ActivatedRouteSnapshot, ROUTER_CONFIGURATION, Router, UrlSegment } from "@angular/router";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgRouterStateService } from "@logex/framework/ui-core";

import { LG_USER_INFO } from "../user/user.types";
import { INavNode, INavNodeMaterialized, ProcessedNavNode } from "./navigation.types";
import { NavigationProcessing } from "./navigation-processing";

export const LG_NAVIGATION = new InjectionToken<INavNode[] | Observable<INavNode[]>>(
    "lgNavigation"
);

export interface INavigationChangeHandler {
    onNavigationChanged(navigationService: LgNavigationService): void;
}

export const LG_NAVIGATION_CHANGE_HANDLER = new InjectionToken<INavigationChangeHandler>(
    "lgNavigationChangeHandler"
);

export interface ILgAfterNavigationEvent {
    node: ProcessedNavNode;
    result: boolean | null;
}

@Injectable({ providedIn: "root" })
/**
 * The LgNavigation service implements functionality necessary to render our navigation elements (both
 * menu and breadcrumbs), together with the possibility to check access permissions, and identify current
 * nodes. It exists independently of the routes, because we need to be able to show our complete menu
 * even before lazy-loaded modules are loaded from the server.
 *
 * The service supports both static, and somehow dynamic navigation (observable hide/disable values on
 * individual items, or the whole definition can be changed). This, in theory, every method provided
 * should be asynchronous. That however makes many trivial operations rather messy, in context when
 * you know that data are prepared. We therefore expose plenty of methods with the postfix sync, which
 * assume that the definition is prepared. When calling them, making sure one of the following is true
 * - you know the navigation definition is in fact static (this assumption should never be done in framework
 *   code)
 * - you're calling one of the sync methods as reaction to one of the asynchronous ones ()
 * - you've awaited ready()
 * - you're calling the api from one of the authorized component (thus role-guard call passed already)
 *
 * The service allows us to customize the exact behaviour by providing your own implementation of
 * NavigationProcessing. This class controls the behaviour if inaccessible items (are they rendered
 * as disabled, or hidden) and also the automatic numbering behaviour.
 * Please note, that the definition is re-processed every time any of its dynamic elements change (f.ex
 * one of the disabled observables emmits ).
 *
 * All methods returning navigation elements return copy of the navigation nodes, and you are thus free
 * to modify them.
 */
export class LgNavigationService {
    private _appBase = inject(APP_BASE_HREF, { optional: true });
    private _navigationProviderProcessing = inject(NavigationProcessing);
    private _router = inject(Router);
    private _routerConfiguration = inject(ROUTER_CONFIGURATION);
    private _routerState = inject(LgRouterStateService);
    private _translateService = inject(LgTranslateService);
    private _userInfo = inject(LG_USER_INFO);

    constructor() {
        const changeHandler = inject(LG_NAVIGATION_CHANGE_HANDLER, { optional: true });
        let navigation = inject(LG_NAVIGATION);

        this._urlPrefix = this._routerConfiguration.useHash ? "#" : "";
        if (this._appBase) this._urlPrefix += this._appBase;

        if (!isObservable(navigation)) {
            navigation = of(navigation);
        }

        const handleChange = new Subject<void>();
        if (changeHandler) {
            handleChange.pipe(debounceTime(1)).subscribe(() => {
                changeHandler.onNavigationChanged(this);
            });
        }

        navigation.subscribe(definition => {
            this._definitonChange$.next();

            const modifications: Array<Observable<unknown>> = [of(true)];

            const remap = (level: INavNode[]): INavNodeMaterialized[] =>
                level.map(item => {
                    const result: INavNodeMaterialized = _.mapValues(item, (value: any, key) => {
                        if (key === "children") {
                            return value ? remap(value as INavNode[]) : undefined;
                        }
                        if (isObservable(value)) {
                            modifications.push(
                                value.pipe(
                                    tap((currentValue: any) => {
                                        (result as any)[key] = currentValue;
                                    })
                                )
                            );
                            return undefined;
                        }
                        return value;
                    }) as any;

                    return result;
                });

            const normalized: INavNodeMaterialized[] = remap(definition);

            combineLatest(modifications)
                .pipe(takeUntil(this._definitonChange$))
                .subscribe(() => {
                    this._idLookup = {};
                    this._pathLookup = {};
                    const isFirst = this._processedNavigation == null;
                    this._processedNavigation = this._processTree(null, normalized, null, 0, [1]);
                    this._definitionProcessed$.next();
                    if (!isFirst && changeHandler) {
                        handleChange.next();
                    }
                });
        });

        this._currentNode$ = combineLatest([
            this._routerState.deepestSnapshot(),
            this._definitionProcessed$
        ]).pipe(
            map(() => this.getCurrentNodeSync()),
            shareReplay(1)
        );

        this._currentNodePath$ = combineLatest([
            this._routerState.deepestSnapshot(),
            this._definitionProcessed$
        ]).pipe(
            map(() => this.getCurrentNodePathSync()),
            shareReplay(1)
        );
    }

    // ----------------------------------------------------------------------------------
    // Fields

    private _idLookup: _.Dictionary<ProcessedNavNode>;
    private _pathLookup: _.Dictionary<ProcessedNavNode>;
    private _processedNavigation: ProcessedNavNode[];
    private _urlPrefix: string;
    private _currentNode$: Observable<ProcessedNavNode | undefined>;
    private _currentNodePath$: Observable<ProcessedNavNode[] | undefined>;
    private readonly _beforeNavigation$ = new Subject<ProcessedNavNode>();
    private readonly _afterNavigation$ = new Subject<ILgAfterNavigationEvent>();
    private readonly _definitonChange$ = new Subject<void>();
    private readonly _definitionProcessed$ = new ReplaySubject<void>();
    private readonly _ready$ = this._definitionProcessed$.pipe(first(), shareReplay(1));

    /**
     * Get the prefix that's automatically added to all urls. This is based on location strategy and APP_BASE_HREF,
     * so typically would be either empty string (html5 routing), or #! (hashbang routing)
     */
    getUrlPrefix(): string {
        return this._urlPrefix;
    }

    /**
     * Get root navigation definition
     *
     * @param             skipHidden  should hidden nodes be skipped? (default)
     */
    getTopNavigation(skipHidden?: boolean): Promise<ProcessedNavNode[]> {
        return this.ready().then(() => this.getTopNavigationSync(skipHidden));
    }

    /**
     * Get the root navigation definition.
     * If the navigation definition is dynamic, you need to make sure it is ready()
     *
     * @param             skipHidden  should hidden nodes be skipped? (default)
     */
    getTopNavigationSync(skipHidden?: boolean): ProcessedNavNode[] {
        const rootNodesHolder = this._idLookup.root;
        const rootNodes = rootNodesHolder ? rootNodesHolder.children : this._processedNavigation;
        return this._copyNodes(rootNodes, this._urlPrefix, skipHidden);
    }

    /**
     * Get observable returning root navigation definition. The observable will trigger whenever the definition changes
     *
     * @param             skipHidden  should hidden nodes be skipped? (default)
     */
    getTopNavigation$(skipHidden?: boolean): Observable<ProcessedNavNode[]> {
        return this._definitionProcessed$.pipe(map(() => this.getTopNavigationSync(skipHidden)));
    }

    /**
     * Get navigation under the specified id.
     *
     * @param              id          id of the navigation subtree
     * @param              pathPrefix  path prefix override (otherwise default is used)
     * @param             skipHidden  should hidden nodes be skipped? (default)
     *
     * @return     return promise of copy of the navigation nodes under the parent
     */
    getNavigation(
        id: string,
        pathPrefix?: string,
        skipHidden?: boolean
    ): Promise<ProcessedNavNode[]> {
        return this.ready().then(() => this.getNavigationSync(id, pathPrefix, skipHidden));
    }

    /**
     * Get navigation under the specified id.
     * If the navigation definition is dynamic, you need to make sure it is ready()
     *
     * @param              id          id of the navigation subtree
     * @param              pathPrefix  path prefix override (otherwise default is used)
     * @param             skipHidden  should hidden nodes be skipped? (default)
     *
     * @return              return copy of the navigation nodes under the parent
     */
    getNavigationSync(id: string, pathPrefix?: string, skipHidden?: boolean): ProcessedNavNode[] {
        const nodesHolder = this._idLookup[id];
        if (!nodesHolder) return [];
        if (pathPrefix == null) pathPrefix = this._urlPrefix;
        return this._copyNodes(nodesHolder.children, pathPrefix, skipHidden);
    }

    /**
     * Get navigation under the specified id. The observable will trigger whenever the definition changes
     *
     * @param              id          id of the navigation subtree
     * @param              pathPrefix  path prefix override (otherwise default is used)
     * @param             skipHidden  should hidden nodes be skipped? (default)
     */
    getNavigation$(
        id: string,
        pathPrefix?: string,
        skipHidden?: boolean
    ): Observable<ProcessedNavNode[]> {
        return this._definitionProcessed$.pipe(
            map(() => this.getNavigationSync(id, pathPrefix, skipHidden))
        );
    }

    /**
     * Get parent of the current node. Exception is thrown if it doesn't have any
     */
    getCurrentNodeParent(): Promise<ProcessedNavNode | undefined> {
        return this.ready().then(() => {
            const currentNode = this._findCurrentNode();
            if (!currentNode) return undefined;

            if (!currentNode.parent)
                throw new Error(
                    `getCurrentNodeParent: Navigation node "${currentNode.id}" has no parent`
                );
            return currentNode.parent.copy(this._urlPrefix, false, (target, source) => {
                if (source === currentNode) target.current = true;
            });
        });
    }

    /**
     * Return Observable emitting the current navigation node.
     * This reacts to both navigation and definition changes
     */
    currentNode$(): Observable<ProcessedNavNode> {
        return this._currentNode$;
    }

    /**
     * Return current navigation node. If the definition is dynamic, you must make sure that you want until the data are ready()
     */
    getCurrentNodeSync(): ProcessedNavNode | undefined {
        const currentNode = this._findCurrentNode();
        if (!currentNode) return undefined;

        return currentNode.copy(this._urlPrefix, false, (target, source) => {
            if (source === currentNode) target.current = true;
        });
    }

    /**
     * Return node corresponding to the path, or undefined.
     * If the definition is dynamic, you must make sure that you wait until the data are ready()
     */
    getNodeByPathSync(path: string): ProcessedNavNode | undefined {
        // Convert the path into path of INavNode objects
        const pathNode = this._findNodeByPath(this._splitPath(path));
        if (!pathNode) return undefined;

        return pathNode.copy(this._urlPrefix, false);
    }

    /**
     * Return the node with specified id. Throws if the id doesn't exist.
     * If the definition is dynamic, you must make sure that you wait until the data are ready()
     */
    getNodeByIdSync(id: string): ProcessedNavNode {
        const idNode = this._idLookup[id];
        if (!idNode) throw new Error(`Unknown navigationId "${id}"`);

        return idNode.copy(this._urlPrefix, false);
    }

    /**
     * Determines, if the specified route can be accessed (in the current definition)
     */
    async canAccessRoute(route: ActivatedRouteSnapshot, isAnonymous: boolean): Promise<boolean> {
        await this.ready();
        try {
            const node = this._findCurrentNode(route);
            if (!node) return false;
            if (isAnonymous && !node.anonymous) return false;
            return !node.noAccessRights;
        } catch (error) {
            return false;
        }
    }

    /**
     * Get observable emitting the path to the current node. The observable reacts to both navigation and definition changes
     */
    currentNodePath$(): Observable<ProcessedNavNode[]> {
        return this._currentNodePath$;
    }

    /**
     * Get the path to the current node. If the definition is dynamic, make sure to wait until it is ready()
     * */
    getCurrentNodePathSync(): ProcessedNavNode[] {
        let currentNode = this._findCurrentNode();
        if (!currentNode) return [];

        const path = [currentNode];
        while (currentNode.parent) {
            currentNode = currentNode.parent;
            path.unshift(currentNode);
        }

        const result: ProcessedNavNode[] = [];

        currentNode.copy(this._urlPrefix, false, (target, source) => {
            if (source === path[0]) {
                result.push(target);
                path.shift();
                if (!path.length) target.current = true;
            }
        });

        return result;
    }

    /**
     * Navigate to the specified navigation node.
     *
     * @param  node  to navigate to.
     *
     * @return promise from Router.navigateByUrl, indicating the success. Returns undefined for external urls
     */
    navigateTo(node: ProcessedNavNode): Promise<boolean | null> | undefined {
        if (node.isExternalLink) {
            /*
                Since we are leaving for external tools, beforeNavigation and afterNavigation observables are unused
                In the future, if we wish to somehow react to clicking on external link,
                move this method below emit of _beforeNavigation$ observable and emit from _afterNavigation$
            */
            window.open(
                node.getHref({}),
                node.inNewTab ? "_blank" : "_self",
                "noopener noreferrer"
            );
            return undefined;
        }

        this._beforeNavigation$.next(node);

        // Todo: decide if we need to do something more sophisticated with respect to parameters bound to a level
        const currentSnapshot = this._routerState.currentDeepestSnapshot;
        let path = node.getHref(currentSnapshot ? currentSnapshot.params : {}) || "";
        if (path.length > 1 && path[path.length - 1] === "/")
            path = path.substring(0, path.length - 1);

        if (path.indexOf(this._urlPrefix) === 0) path = path.substring(this._urlPrefix.length);
        return this._router.navigateByUrl(path).then(result => {
            this._afterNavigation$.next({ node, result });
            return result;
        });
    }

    /** Returns the first node that the user can access. If the definition is dynamic, make sure to wait for ready() */
    findFirstAccessibleNodeSync(): ProcessedNavNode | null {
        const root = this.getTopNavigationSync(true);

        // todo: do we really need to go all the way to the bottom of the tree?
        const walk = (nodes: ProcessedNavNode[]): ProcessedNavNode | null => {
            for (const node of nodes) {
                if (!node.noAccessRights && !node.hidden) {
                    if (!node.children || !node.children.length) return node;
                    const accessibleChild = walk(node.children);
                    if (accessibleChild) return accessibleChild;
                }
            }
            return null;
        };

        return walk(root);
    }

    /**
     * Return observable of events triggered before navigateTo() is processed.
     * Please note that this observable doesn't react to navigation issued through angular router */
    beforeNavigation$(): Observable<ProcessedNavNode> {
        return this._beforeNavigation$.asObservable();
    }

    /**
     * Return observable of events triggered after navigateTo() is processed.
     * Please note that this observable doesn't react to navigation issued through angular router */
    afterNavigation$(): Observable<ILgAfterNavigationEvent> {
        return this._afterNavigation$.asObservable();
    }

    /**
     * Return promise that resolves when the first definition is ready. If you are calling any of the *Sync
     * methods directly (rather than from one of the promises or observables), you should await this
     * promise, unless you know your application doesn't have dynamic navigation definition.
     */
    ready(): Promise<void> {
        return lastValueFrom(this._ready$);
    }

    /**
     * Return observable that emmits every time new definition is ready
     */
    definitionReady$(): Observable<void> {
        return this._definitionProcessed$.asObservable();
    }

    // ----------------------------------------------------------------------------------
    private _splitPath(path: string): UrlSegment[] {
        return _.map(
            _.filter(path.split("/"), (x: string) => x.length > 0),
            x =>
                <UrlSegment>{
                    parameters: null,
                    path: x,
                    parameterMap: null
                }
        );
    }

    // ----------------------------------------------------------------------------------
    //
    private _findNodeByPath(currentPathSegments: UrlSegment[]): ProcessedNavNode | undefined {
        let path = "";
        let lastMatch: ProcessedNavNode = this._pathLookup[""];
        for (const step of currentPathSegments) {
            if (step.path) {
                path = path ? path + "/" + step.path : step.path;
            }
            const match = this._pathLookup[path];
            if (match) lastMatch = match;
        }
        return lastMatch;
    }

    // ----------------------------------------------------------------------------------
    //
    private _getNavigationId(route: ActivatedRouteSnapshot): string {
        const id =
            route.routeConfig && route.routeConfig.data && route.routeConfig.data.navigationId;
        if (id) return id;

        if (route.parent) return this._getNavigationId(route.parent);
        return null;
    }

    // ----------------------------------------------------------------------------------
    //
    private _findCurrentNode(
        currentRouteSnapshot?: ActivatedRouteSnapshot
    ): ProcessedNavNode | undefined {
        // Get path of the current page
        if (!currentRouteSnapshot) {
            currentRouteSnapshot = this._routerState.currentDeepestSnapshot;
            if (!currentRouteSnapshot) return undefined;
        }

        const id = this._getNavigationId(currentRouteSnapshot);

        if (id) {
            const idNode = this._idLookup[id];
            if (!idNode) {
                console.error(`Unknown navigationId "${id}"`);
            } else {
                return idNode;
            }
        }

        let path: UrlSegment[] = [];
        while (currentRouteSnapshot) {
            path = [...currentRouteSnapshot.url, ...path];
            currentRouteSnapshot = currentRouteSnapshot.parent;
        }
        const pathNode = this._findNodeByPath(path);
        return pathNode;
    }

    // ----------------------------------------------------------------------------------
    private _processTree(
        parent: ProcessedNavNode,
        nodes: INavNodeMaterialized[],
        parentPath: string | null,
        depth: number,
        numberings: number[],
        disableNumbering = false
    ): ProcessedNavNode[] {
        const result: ProcessedNavNode[] = [];

        for (const node of nodes) {
            if (node.removed) continue;

            let path: string;
            if (node.path[0] === "/") {
                path = node.path.substr(1);
            } else if (node.path) {
                path = (parentPath ? parentPath + "/" : "") + node.path;
            } else {
                path = parentPath ?? "";
            }

            let name = node.name;
            if (node.lid) {
                let key = node.lid;
                if (key[0] === ".") key = "APP._Navigation" + key;
                name = this._translateService.translate(key);
            }

            let pageTitle = node.pageTitle;
            if (node.pageTitleLC) {
                let key = node.pageTitleLC;
                if (key[0] === ".") key = "APP._Navigation" + key;
                pageTitle = this._translateService.translate(key);
            }

            const processed = ProcessedNavNode.fromDefinition(node, path, name, pageTitle, parent);
            this._pathLookup[path] = processed;

            if (processed.id) {
                if (this._idLookup[processed.id])
                    throw new Error(
                        `Item with ID "${processed.id}" appears more than once in a navigation tree`
                    );
                this._idLookup[processed.id] = processed;
            }

            let assignNumber = false;

            this._navigationProviderProcessing.evaluateAccess(processed, parent, this._userInfo);

            assignNumber =
                !disableNumbering &&
                this._navigationProviderProcessing.evaluateNumbering(processed, depth, numberings);

            if (assignNumber) {
                processed.namePrefix = this._navigationProviderProcessing.getNumberingPrefix(
                    processed,
                    depth,
                    numberings
                );
            }

            if (node.children) {
                processed.children = this._processTree(
                    processed,
                    node.children,
                    path,
                    depth + 1,
                    [...numberings, 1],
                    !assignNumber
                );
            }

            if (assignNumber) {
                numberings.push(numberings.pop() + 1);
            }

            result.push(processed);
        }
        return result;
    }

    // ----------------------------------------------------------------------------------
    //
    private _copyNodes(
        nodes: ProcessedNavNode[],
        pathPrefix: string,
        skipHidden = true,
        cb?: (node: ProcessedNavNode) => void
    ): ProcessedNavNode[] {
        return _.reduce(
            nodes,
            (list, x) => {
                const item = x.copy(pathPrefix, skipHidden, cb);
                if (item) list.push(item);
                return list;
            },
            []
        );
    }

    // ----------------------------------------------------------------------------------
    //
    /*
    public getTemplateForCurrentRoute(): any {
        // TODO
        const current = this.$route.current;
        const controller = current.locals.$scope[current.controllerAs];

        const html = controller.getHtml();

        this.console.debug( html );

        return html;
        return null;
    }
        */

    // ----------------------------------------------------------------------------------
    //
    /*
    public getDataPropOfCurrentRoute( prop: string ) {
        const currentRoute = this.activatedRoute.snapshot;

        let value = null;

        if ( currentRoute && currentRoute.component ) {
            const steps = this._findNodeByPath( currentRoute.url );

            let foundValueUpInTree;

            while ( steps.length && !foundValueUpInTree ) {
                const step = steps.pop();
                if ( step[prop] ) {
                    foundValueUpInTree = step[prop];
                }
            }

            if ( foundValueUpInTree ) {
                value = foundValueUpInTree;
            }
        }

        return value;
    }*/
}
