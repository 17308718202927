import _ from "lodash";
import { Injectable, Injector, TemplateRef } from "@angular/core";

import {
    PlaceholderContent,
    IWatcher,
    IWatcherContentCallback,
    IContextSetterSubscription,
    IWatcherContextCallback
} from "./internal.types";

interface ContextSetterSubscriptionImpl extends IContextSetterSubscription {
    context: any;
}

// TODO: add parent/child relation for lazy modules
/**
 * Service that implements the global store for the placeholders. The store also notifies all
 * "subscribers" about any change. The storage service works in cooperation with lg-placeholder
 * and [lgPlaceholderContent].
 *
 * Note that the storage is app-global (this is by design), so make sure to use descriptive
 * names for the content.
 */
@Injectable({ providedIn: "root" })
export class LgPlaceholderStorageService {
    /**
     * Add a watcher, which will be notified any time the content of the specified name changes.
     * The watcher is also called after the registration itself (asynchronously).
     *
     * Note that the callback is called also when the content becomes undefined (passing null as the content
     * parameter)
     *
     * @param name is the name of the content (case insensitive)
     * @param contentCallback is the callback which will be called when content changes
     * @param contextCallback is the callback which will be called when context changes
     * @returns the deregistration function (similar to angular's observe).
     */
    watchContent(
        name: string,
        contentCallback: IWatcherContentCallback,
        contextCallback: IWatcherContextCallback
    ): () => void {
        name = name.toLowerCase().trim();
        let entries = this._watchers[name];
        if (!entries) {
            this._watchers[name] = entries = [];
        }

        const store: IWatcher = {
            content: contentCallback,
            context: contextCallback
        };
        entries.push(store);

        // We call context first as it wont' trigger any rendering on placeholder without content
        const contextEntries = this._contextStacks[name];
        if (contextEntries) {
            const lastEntry = contextEntries[contextEntries.length - 1];
            contextCallback(name, lastEntry);
        } else {
            contextCallback(name, null);
        }

        const contentEntries = this._contentStacks[name];
        if (contentEntries) {
            const lastEntry = contentEntries[contentEntries.length - 1];
            contentCallback(name, lastEntry);
        } else {
            contentCallback(name, null);
        }

        return () => {
            _.pull(entries, store);
            if (entries.length === 0) delete this._watchers[name];
        };
    }

    /**
     * Add content to the store. The content store works as a stack, so the newly registered content
     * overwrites any previous one, and upon removal the original is restored.
     *
     * @param name of the content (case insensitive)
     * @content is the template reference
     * @return function, which removes the content from the stack. It is not necessary to remove the contents
     * in the proper(stack-wise) order.
     */
    addContent(
        name: string,
        content: TemplateRef<any>,
        injector?: Injector | undefined
    ): () => void {
        if (name == null || content == null) throw new Error("Invalid parameters to addContent");

        name = name.toLowerCase().trim();
        let entries = this._contentStacks[name];
        if (!entries) {
            this._contentStacks[name] = entries = [];
        }
        const entry = { template: content, injector };
        entries.push(entry);

        this._sendContentUpdate(name, entry);

        return () => {
            const sendEvent = entries[entries.length - 1] === entry;
            _.pull(entries, entry);

            if (entries.length === 0) {
                delete this._contentStacks[name];
            }
            if (sendEvent) {
                this._sendContentUpdate(name, entries.length ? entries[entries.length - 1] : null);
            }
        };
    }

    /**
     * Add context to the store. The context store works as a stack, so the newly registered context
     * overwrites any previous one, and upon removal the original is restored.
     *
     * @param name of the context (case insensitive)
     * @context is the context object (which cannot be null)
     * @return function, which removes the context from the stack. It is not necessary to remove the contexts
     * in the proper(stack-wise) order.
     */
    addContext(name: string, context: any): IContextSetterSubscription {
        if (name == null || context == null) throw new Error("Invalid parameters to addContent");

        name = name.toLowerCase().trim();
        let entries = this._contextStacks[name];
        if (!entries) {
            this._contextStacks[name] = entries = [];
        }

        const entry: ContextSetterSubscriptionImpl = {
            context,
            unsubscribe: () => {
                // const sendChangeEvent = entries[entries.length - 1] === entry;
                _.pull(entries, entry);

                if (entries.length === 0) {
                    delete this._contextStacks[name];
                }
                this._sendContextUpdate(
                    name,
                    entries.length ? entries[entries.length - 1].context : null
                );
            },
            update: (newContext: any) => {
                entry.context = newContext;
                if (entries[entries.length - 1] === entry) {
                    this._sendContextUpdate(name, newContext);
                }
            }
        };
        entries.push(entry);

        this._sendContextUpdate(name, context);

        return entry;
    }

    /**
     * Return the content currently stored under the specified name (or return null).
     *
     * @param name of the content (case insensitive)
     */
    getContent(name: string): PlaceholderContent | null {
        name = name.toLowerCase().trim();
        const entries = this._contentStacks[name];
        if (!entries) return null;
        return entries[entries.length - 1];
    }

    /**
     * Return the context currently stored under the specified name (or return null).
     *
     * @param name of the context (case insensitive)
     */
    getContext(name: string): any | null {
        name = name.toLowerCase().trim();
        const entries = this._contextStacks[name];
        if (!entries) return null;
        return entries[entries.length - 1];
    }

    private _contentStacks: _.Dictionary<PlaceholderContent[]> = {};
    private _watchers: _.Dictionary<IWatcher[]> = {};
    private _contextStacks: _.Dictionary<ContextSetterSubscriptionImpl[]> = {};

    private _sendContentUpdate(name: string, content: PlaceholderContent | null): void {
        const watcherList = this._watchers[name];
        if (!watcherList) return;

        for (const watcher of watcherList) {
            watcher.content(name, content);
        }
    }

    private _sendContextUpdate(name: string, context: any | null): void {
        const watcherList = this._watchers[name];
        if (!watcherList) return;

        for (const watcher of watcherList) {
            watcher.context(name, context);
        }
    }
}
