import * as _ from "lodash";
import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgSimpleChanges } from "@logex/framework/types";
import { toInteger } from "@logex/framework/utilities";
import { LgObserveSizeService } from "@logex/framework/ui-core";
import {
    LgPanelGridChangeEvent,
    LgPanelGridDefinition,
    LgPanelGridService,
    LgPanelState
} from "../service";
import { LgPanelComponent } from "./lg-panel.component";
import { LgPanelGridNodeComponent } from "./lg-panel-grid-node.component";
import { NgIf } from "@angular/common";

@Component({
    standalone: true,
    selector: "lg-panel-grid",
    template: `
        <lg-panel-grid-node
            *ngIf="_gridService._root"
            [node]="_gridService._root"
        ></lg-panel-grid-node>
    `,
    providers: [LgPanelGridService],
    encapsulation: ViewEncapsulation.None,
    imports: [LgPanelGridNodeComponent, NgIf],
    host: {
        class: "flex-flexible lg-panel-grid"
    }
})
export class LgPanelGridComponent implements OnDestroy, OnChanges {
    _gridService = inject(LgPanelGridService);
    private _sizeObserver = inject(LgObserveSizeService);

    @Input() definition: LgPanelGridDefinition | null = null;

    @Input() set gridWidth(value: number) {
        this._gridWidth = toInteger(value, 12, 1, 12);
    }

    get gridWidth(): number {
        return this._gridWidth;
    }

    @Input() set gridHeight(value: number) {
        this._gridHeight = toInteger(value, 12, 1, 12);
    }

    get gridHeight(): number {
        return this._gridHeight;
    }

    @Output() readonly stateChange = new EventEmitter<LgPanelGridChangeEvent>();

    @Output() readonly fullscreenChange = new EventEmitter<LgPanelGridChangeEvent>();

    private _gridWidth = 12;
    private _gridHeight = 12;
    private readonly _destroyed$ = new Subject<void>();

    constructor() {
        this._gridService
            .stateChanges()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(event => {
                let wasFullscreen = false;
                let isFullscreen = false;
                this._sizeObserver.recalculate();
                _.each(event, state => {
                    wasFullscreen =
                        wasFullscreen || state.previousState === LgPanelState.Fullscreen;
                    isFullscreen = isFullscreen || state.currentState === LgPanelState.Fullscreen;
                });
                this.stateChange.next(event);
                if (wasFullscreen !== isFullscreen) this.fullscreenChange.next(isFullscreen);
            });
    }

    ngOnChanges(changes: LgSimpleChanges<LgPanelGridComponent>): void {
        if (changes.definition || changes.gridWidth || changes.gridHeight) {
            this._gridService.setGrid(this.definition, this._gridWidth, this._gridHeight);
        }
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _addPanel(panel: LgPanelComponent): void {
        this._gridService.addPanel(panel);
    }

    _removePanel(panel: LgPanelComponent): void {
        this._gridService.removePanel(panel);
    }

    _updatePanelId(panel: LgPanelComponent, previousId: string): void {
        this._gridService.updatePanelId(panel, previousId);
    }

    _updatePanelLocation(panel: LgPanelComponent): void {
        this._gridService.updatePanelLocation(panel);
    }
}
