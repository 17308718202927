import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from "@angular/forms";

const validateMinFactory = (min: string | number): ValidatorFn => {
    return (control: AbstractControl) => {
        const isValid = min == null || control.value == null || control.value >= min;

        return isValid ? null : { min: { valid: false } };
    };
};

@Directive({
    standalone: true,
    selector: "[min][ngModel]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValidator, multi: true }]
})
export class MinValidator implements Validator, OnChanges {
    validator: ValidatorFn;
    private _onChanges: () => void;

    /**
     * Minimum allowed value (required).
     */
    @Input({ required: true }) min!: string | number;

    constructor() {
        this.validator = validateMinFactory(this.min);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("min" in changes) {
            this.validator = validateMinFactory(this.min);
            if (this._onChanges) {
                this._onChanges();
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }

    public registerOnValidatorChange(fn: () => void): void {
        this._onChanges = fn;
    }
}
