<div
    ng2FileDrop
    class="upload-form"
    [class.upload-form--dragging]="_dragging"
    [class.upload-form--active]="_uploader.isUploading"
    [class.upload-form--files-ready]="_getFiles().length || _uploader?.queue?.length"
    [uploader]="_uploader"
    (fileOver)="_draggingOverInput($event)"
    (onFileDrop)="_dropped($any($event))"
>
    <div
        *ngIf="!_uploader.isUploading && !_getFiles().length && !_uploader?.queue?.length"
        class="upload-form__image"
    ></div>
    <div *ngIf="!_uploader.isUploading" class="upload-form__caption">
        {{ "FW._Uploader.DropAreaText1" | lgTranslate }}
        <br *ngIf="!_getFiles().length && !_uploader?.queue?.length" />
        {{ "FW._Uploader.DropAreaText2" | lgTranslate }}
        <label for="file{{ _instance }}" lgTranslate="FW._Uploader.DropAreaText3"></label>.

        <input
            type="file"
            id="file{{ _instance }}"
            (change)="_fileSelected($event)"
            [multiple]="_multipleFilesAllowed"
            class="upload-form__input"
            #fileInput
        />
    </div>
    <div *ngIf="_uploader.isUploading" class="upload-form__progress-bar">
        <div class="upload-form__progress-bar__text">
            Uploading {{ _uploader.queue.length }} file
            <span *ngIf="_uploader.queue.length > 1">s</span>.
        </div>
        <div class="upload-form__progress-bar__progress">
            <lg-progress-bar
                [current]="_getProgress(_uploader)"
                [total]="_getLength(_uploader)"
                style="width: 100%"
            ></lg-progress-bar>
        </div>
    </div>
</div>
