<div class="form-group">
    <div class="form-row">
        <div class="confirm-message" [innerHTML]="_message"></div>
    </div>
</div>
<div
    class="form-group form-group--buttons"
    [class.form-group--buttons-left]="_options.buttonsOnLeft"
>
    <div class="form-row">
        <lg-button
            *ngFor="let button of _options.buttons"
            [buttonClass]="button.class ?? ''"
            [text]="button.name ?? ''"
            [icon]="button.icon ?? null"
            (click)="_buttonClick(button)"
        >
        </lg-button>
    </div>
</div>
