import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

import { IPanelChoiceVariantDefinition } from "./lg-panel.types";

@Component({
    selector: "lg-panel-choice-variant",
    template: ""
})
export class LgPanelChoiceVariantComponent {
    /**
     * Choice identifier (required).
     */
    @Input({ required: true }) id!: number;

    /**
     * Choice name (required).
     */
    @Input({ required: true }) name!: string;

    /**
     * Choice icon (required)
     */
    @Input({ required: true }) icon!: string;

    @Input() title?: string;

    @ContentChild("additionalMenuItemsForVariant")
    additionalMenuItems: TemplateRef<any>;

    @ContentChild("additionalMenuItemsForVariant2")
    additionalMenuItems2: TemplateRef<any>;

    getDefinition(): IPanelChoiceVariantDefinition {
        return {
            id: this.id,
            name: this.name,
            icon: this.icon,
            title: this.title,
            additionalMenuItemsTemplateRef: this.additionalMenuItems,
            rightAdditionalMenuItemsTemplateRef: this.additionalMenuItems2
        };
    }
}
