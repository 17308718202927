// Possibly change location

import * as _ from "lodash";
import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { urlConcat } from "@logex/framework/utilities";

export interface UserSettingsServiceConfig {
    instance: string;
    environment: string;
    url: string;
}

// ----------------------------------------------------------------------------------

export enum WellKnownSettingCode {
    context = "$context",
    uiSettings = "$uiSettings"
}

export interface ContextSettings {
    clientId: number;
    scenarioId: number;
}

export interface UiSettings {
    language: string;
}

export interface SettingKey {
    storageId: string;
    scenarioId?: number;
    clientId?: number;
}

export interface KeyValuePair<TKey = SettingKey, TValue = any> {
    key: TKey;
    value: TValue;
}

// ----------------------------------------------------------------------------------

@Injectable({
    providedIn: "root"
})
export class UserSettingsService {
    protected _http = inject(HttpClient);

    private _instance: string;
    private _environment: string;
    private _url: string;

    initialize(cfg: UserSettingsServiceConfig): void {
        this._instance = cfg.instance;
        this._environment = cfg.environment;
        this._url = cfg.url;
    }

    get(...keys: any[]): Observable<KeyValuePair[]> {
        // If url is not configured, return just return null
        if (this._url == null) {
            return null;
        }

        if (_.isEmpty(keys)) {
            throw Error("keys must be specified");
        }

        return this._http.post<any>(
            urlConcat(this._url, "/userSettings/get"),
            {
                instance: this._instance,
                environment: this._environment,
                keys: _.map(keys, x => JSON.stringify(x))
            },
            {
                headers: { "api-version": "1.0" }
            }
        );
    }

    set(...entries: KeyValuePair[]): Observable<void> {
        // If url is not configured, return just return null
        if (this._url == null) {
            return null;
        }

        return this._http.post<void>(
            urlConcat(this._url, "/userSettings/set"),
            {
                instance: this._instance,
                environment: this._environment,
                entries: _.map(entries, x => ({
                    key: JSON.stringify(x.key),
                    value: JSON.stringify(x.value)
                }))
            },
            { headers: { "api-version": "1.0" } }
        );
    }
}
