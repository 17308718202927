import _ from "lodash";
import { Injectable, inject } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";

import { LgFilterSetService } from "./lg-filterset.service";
import { IFilterDefinition } from "./filter-definition";
import { LgFilterSet } from "./lg-filterset";
import { IFilterPreprocessor, LG_FILTERSET_PREPROCESSORS } from "./filter-preprocessor";

// Preprocessing FilterSetService -------------------------------------------------------------------------
@Injectable()
export class LgPreprocessingFilterSetService {
    private _filterSetService = inject(LgFilterSetService);

    private _preprocessors: _.Dictionary<IFilterPreprocessor>;

    constructor() {
        const preprocessors = inject<IFilterPreprocessor[]>(LG_FILTERSET_PREPROCESSORS);

        this._preprocessors = _.keyBy(preprocessors, p => p.name.toUpperCase());
    }

    public create(
        definitions: IFilterDefinition[],
        translationService: LgTranslateService,
        context?: any
    ): LgFilterSet {
        const prepared: IFilterDefinition[] = [];

        for (const item of definitions) {
            if (!item.filterType) {
                console.error("Missing filter type ", item);
                prepared.push(item as any);
                continue;
            }
            const preprocessor = this._preprocessors[item.filterType.toUpperCase()];
            if (preprocessor) {
                if (!item.context) item.context = context;
                prepared.push(preprocessor.preprocess(item, context));
            } else {
                prepared.push(item as any);
            }
        }

        return this._filterSetService.create(prepared, translationService, context);
    }
}
