import { Component, Input, OnChanges } from "@angular/core";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";
import { LgPrimitive } from "@logex/framework/types";
import { LgPivotInstance } from "@logex/framework/lg-pivot";
import { VerticalPosition } from "./types";

export interface RenderableComponent {
    render(): void;

    ensureVisible(
        position: VerticalPosition,
        ids: LgPrimitive[],
        autoFocus: boolean | number,
        autoExpand: boolean
    ): void;
}

@Component({
    selector: "lg-pivot-instance-sync",
    template: ``
})
export class LgPivotInstanceSyncComponent implements OnChanges {
    /**
     * Helper class that encapsulates common pivot-related functions (required).
     */
    @Input({ required: true }) pivotInstance!: LgPivotInstance;

    /**
     * Renderable component (required).
     */
    @Input({ required: true }) component!: RenderableComponent;

    // ----------------------------------------------------------------------------------
    private _subscription: Subscription;

    // ----------------------------------------------------------------------------------
    ngOnChanges(): void {
        if (this._subscription != null) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }

        this._connectPivot();
    }

    private _connectPivot(): void {
        if (this.pivotInstance == null || this.component == null) return;

        this._subscription = this.pivotInstance.actions$
            ?.pipe(debounceTime(0))
            .subscribe(action => {
                switch (action.type) {
                    case "render":
                        this.component.render();
                        break;
                    case "ensureVisible":
                        this.component.ensureVisible("middle", action.payload.ids, false, false);
                        break;
                }
            });
    }
}
