import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

export interface IVersion {
    id?: number | null;
    periodName?: string | null; // remove?
    scenario_naam?: string | null;
    scenario_omschrijving?: string | null;
    date_created?: string | null;
    is_primary?: boolean | null;
    is_test?: boolean | null;
    locked?: boolean | null;
    is_readonly?: boolean | null;
    data_version_naam?: string | null;
    display_version_id?: number;
    ziekenhuiscode?: number;
}

/**
 * @deprecated To be merged with ScenarioService, so use it instead
 */
@Injectable({ providedIn: "root" })
export class VersionService<TVersion extends IVersion> {
    protected http = inject(HttpClient);

    protected _version = new ReplaySubject<TVersion>();
    readonly version = this._version.asObservable();

    initialize(): void {
        this.reload();
    }

    reload(): void {
        this.http.get<TVersion>("api/version/current").subscribe(version => {
            this._version.next(version);
        });
    }

    set(version: TVersion): void {
        this._version.next(version);
    }

    get(): Observable<TVersion> {
        return this.version;
    }
}
