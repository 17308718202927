import * as _ from "lodash";
import { inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Observable, ReplaySubject } from "rxjs";

import { LgConsole } from "@logex/framework/core";
import { urlConcat } from "@logex/framework/utilities";

import { LG_APP_CONFIGURATION } from "../application/app-configuration";
import {
    instanceOfTokenAuthService,
    ITokenAuthenticationService,
    LG_AUTHENTICATION_SERVICE
} from "../auth/authentication.types";
import { IMessageBusService, ISubscriptionTicket } from "./lg-message-bus.types";

// ----------------------------------------------------------------------------------
@Injectable({
    providedIn: "root"
})
export class LgMessageBusService implements IMessageBusService {
    private _authenticationService = inject(LG_AUTHENTICATION_SERVICE);
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _lgConsole = inject(LgConsole).withSource(
        "Logex.Infrastructure.MessageBus.MessageBusService"
    );

    static connectionIdParamName = "X-SignalR-ConnectionId";

    // ----------------------------------------------------------------------------------
    constructor() {
        this._connectionEventSubscription = {};
        this._connectionEstablished$ = new ReplaySubject<boolean>();

        this._connect();
    }

    // ----------------------------------------------------------------------------------
    private _connection: HubConnection;
    private _connectionEstablished$: ReplaySubject<boolean>;
    private readonly _connectionEventSubscription: _.Dictionary<Function[]>;

    get connectionEstablished$(): Observable<boolean> {
        return this._connectionEstablished$.asObservable();
    }

    // ----------------------------------------------------------------------------------
    private _connect(): void {
        if (this._connection != null) return;

        const connect = (): void => {
            this._connection = new HubConnectionBuilder()
                .withUrl(
                    urlConcat(this._appConfiguration.applicationRoot, "/hubs/application"),
                    instanceOfTokenAuthService(this._authenticationService)
                        ? {
                              accessTokenFactory: () =>
                                  (
                                      this._authenticationService as ITokenAuthenticationService
                                  ).getAccessToken()
                          }
                        : {}
                )
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();

            this._connection
                .start()
                .then(() => {
                    this._connectionEstablished$.next(true);
                })
                .catch(() => {
                    this._lgConsole.error("Error establishing message bus connection");
                    this._connectionEstablished$.next(false);
                });
        };

        if (this._appConfiguration.applicationRoot != null) {
            connect();
        } else {
            this._appConfiguration.ready.then(() => {
                connect();
            });
        }
    }

    private _isConnected(): boolean {
        return this._connection != null;
    }

    // ----------------------------------------------------------------------------------
    getConnectionId(): string {
        if (this._isConnected()) {
            return this._connection.connectionId;
        } else {
            return null;
        }
    }

    on(message: string, callback: (...args: any[]) => void): ISubscriptionTicket {
        if (!this._isConnected()) {
            throw Error("SignalR connection is not established");
        }

        this._connection.on(message, callback);
        return new SubscriptionTicket(this._connection, message, callback);
    }

    onConnectionEvent(eventName: string, cb: Function, scope?: any): ISubscriptionTicket {
        if (!this._isConnected()) {
            throw Error("SignalR connection is not established");
        }

        let subscriptions = this._connectionEventSubscription[eventName];
        if (!subscriptions) {
            subscriptions = [];
            this._connectionEventSubscription[eventName] = subscriptions;
        }

        if (scope) {
            cb = cb.bind(scope);
        }

        subscriptions.push(cb);

        return new ConnectionEventSubscriptionTicket(subscriptions, cb);
    }
}

class SubscriptionTicket implements ISubscriptionTicket {
    constructor(
        private _connection: signalR.HubConnection,
        private _message: string,
        private _callback: (...args: any[]) => void
    ) {}

    cancel(): void {
        this._connection.off(this._message, this._callback);
    }
}

class ConnectionEventSubscriptionTicket implements ISubscriptionTicket {
    constructor(private _subscriptions: Function[], private _callback: Function) {}

    cancel(): void {
        _.pull(this._subscriptions, this._callback);
    }
}
