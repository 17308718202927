import { inject, Injectable, InjectionToken } from "@angular/core";
import { Observable, of, firstValueFrom, lastValueFrom, BehaviorSubject } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";

import {
    BookmarkStoreCreateError,
    ILgBookmarkStore,
    ILgKeyedBookmarkStore
} from "./lg-bookmarks-state.types";
import { FilterSetState, FilterSetStateUiModel } from "./lg-filterset.types";
import { LgFiltersetStateGateway } from "./lg-filterset-state.gateway";
import { LgBookmarkEditDialog } from "../components/lg-bookmark-edit-dialog.component";

export const LG_BOOKMARK_STORE = new InjectionToken<ILgBookmarkStore[]>("LgBookmarkStoreToken", {
    providedIn: "root",
    factory: () => [new LgFilterSetStateStore()]
});

export class LgKeyedFilterSetStateStore implements ILgKeyedBookmarkStore {
    private _loading = false;
    private _cachedStates: BehaviorSubject<FilterSetState[]> | undefined;

    constructor(
        private _key: string,
        private _gateway: LgFiltersetStateGateway,
        private _editDialog: LgBookmarkEditDialog
    ) {}

    getBookmarks(): Observable<FilterSetState[]> {
        if (this._loading) {
            return this._cachedStates!;
        }

        this._loading = true;
        this._cachedStates = new BehaviorSubject<FilterSetState[]>([]);
        this._gateway.load(this._key).subscribe({
            next: states => {
                this._cachedStates.next(states);
            },
            complete: () => {
                this._loading = false;
            }
        });
        return this._cachedStates.asObservable();
    }

    getBookmark(id: string): Promise<FilterSetState | undefined> {
        return firstValueFrom(this._cachedStates ?? this.getBookmarks()).then(states =>
            states.find(state => state.stateId === id)
        );
    }

    delete(id: string): Promise<void> {
        return lastValueFrom(this._gateway.deleteState(id)).then(() => {
            const states = this._cachedStates.value.filter(s => s.stateId !== id);
            this._cachedStates.next(states);
        });
    }

    async updateFilters(id: string, stateParts: Record<string, string>): Promise<void> {
        const currentState = await this.getBookmark(id);
        if (!currentState) throw new Error("Bookmark not found");
        const newState = { ...currentState, parts: stateParts };
        await this._doSave(newState);
    }

    async startEdit(id: string): Promise<void> {
        const currentState = await this.getBookmark(id);
        if (!currentState) throw new Error("Bookmark not found");
        const choices = await this._showEditDialog(currentState);
        const newState = { ...currentState, ...choices };
        await this._doSave(newState);
    }

    async startSaveAs(stateParts: Record<string, string>): Promise<string> {
        const choices = await this._showEditDialog();
        const newState: FilterSetState = {
            filterHostId: this._key,
            isOwn: true,
            dateInsert: new Date(),
            ...choices,
            parts: stateParts
        };

        try {
            return this._doSave(newState);
        } catch (e: any) {
            throw new BookmarkStoreCreateError(choices.name, e.message ?? "Save failed", e);
        }
    }

    private _showEditDialog(currentState?: FilterSetStateUiModel): Promise<FilterSetStateUiModel> {
        const existingNames = this._cachedStates.value.map(existingState => existingState.name);
        return this._editDialog.show(existingNames, currentState);
    }

    private _doSave(state: FilterSetState): Promise<string> {
        return lastValueFrom(this._gateway.save(state)).then(id => {
            const states = [...this._cachedStates.value];
            if (state.stateId == null) {
                state.stateId = id;
                states.push(state);
            } else {
                const index = states.findIndex(s => s.stateId === state.stateId);
                if (index !== -1) {
                    states[index] = state;
                }
            }
            this._cachedStates.next(states);
            return state.stateId!;
        });
    }
}

@Injectable()
export class LgFilterSetStateStore implements ILgBookmarkStore {
    private readonly _menuName: string;
    private readonly _emptyGroupText: string;
    private readonly _gateway = inject(LgFiltersetStateGateway);
    private readonly _editDialog = inject(LgBookmarkEditDialog);

    constructor() {
        const translateService = inject(LgTranslateService);

        this._menuName = translateService.translate(
            "FW._Directives._FiltersetSlideout._BookmarksMenu.Bottom_group_title"
        );

        this._emptyGroupText = translateService.translate(
            "FW._Directives._FiltersetSlideout._BookmarksMenu.No_saved_bookmark"
        );
    }

    getKeyedStore(key: string): ILgKeyedBookmarkStore {
        return new LgKeyedFilterSetStateStore(key, this._gateway, this._editDialog);
    }

    get menuGroupName(): Observable<string> {
        return of(this._menuName);
    }

    get isReadOnly(): Observable<boolean> {
        return of(false);
    }

    get emptyGroupText(): Observable<string> {
        return of(this._emptyGroupText);
    }

    readonly isExclusive = false;

    readonly priority = 0;
}
