import type { IFilterDefinition } from "./filter-definition";
import type { IFilterRenderer } from "./filter-renderer";

export abstract class FilterRendererComponentBase<
    T extends IFilterDefinition,
    R extends IFilterRenderer
> {
    _definition: T;
    _onChange: () => void;
    _clear: () => void;
    _filters: any;
    _renderer: R;
    _isPopup: boolean;

    _initialize(
        definition: T,
        filters: any,
        isPopup: boolean,
        onChange: () => void,
        clear: () => void
    ): void {
        this._definition = definition;
        this._filters = filters;
        this._isPopup = isPopup;
        this._onChange = onChange;
        this._clear = clear;
        this._renderer = this._definition.renderer as R;
    }

    _triggerChange(): void {
        this._onChange();
    }
}
