import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FileUploadModule } from "ng2-file-upload";

import { CoreModule } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LG_FILTERSET_RENDERER_FACTORIES } from "@logex/framework/lg-filterset";

import { LgDownloadFormComponent } from "./download/lg-download-form.component";
import { LgExportMenuComponent } from "./download/lg-export-menu";
import { LgExportDialogComponent } from "./download/lg-export-dialog.component";
import { LgMultilevelPickerDialogComponent } from "./multilevel-picker/lg-multilevel-picker-dialog.component";
import {
    ItemClusterFilterRendererLineComponent,
    ItemClusterFilterRendererPopupComponent,
    ItemClusterFilterRendererFactory
} from "./itemcluster-filter/lg-itemcluster-filter-renderer";
import { LgItemClusterDialogComponent } from "./itemcluster-filter/lg-itemcluster-dialog.component";
import { LgUploadFormComponent } from "./uploader/lg-upload-form.component";
import { LgUploadTableComponent } from "./uploader/lg-upload-table.component";
import { LgComparisonSourceIndicatorComponent } from "./lg-comparison-source/lg-comparison-source-indicator.component";
import { MiComparisonSourceMenuPopupComponent } from "./lg-comparison-source/mi-comparison-source-menu-popup.component";
import { MiComparisonSourceService } from "./lg-comparison-source/mi-comparison-source.service";
import { LgComparisonSourceMenuComponent } from "./lg-comparison-source/lg-comparison-source-menu.component";
import { LgCommentDialogComponent } from "./comments/lg-comment-dialog.component";
import { LgCommentIconComponent } from "./comments/lg-comment-icon.component";
import { LgCopyMenuComponent } from "./copy-paste/lg-copy-menu.component";
import { LgCopyButtonComponent } from "./copy-paste/lg-copy-button.component";
import { LgPasteButtonComponent } from "./copy-paste/lg-paste-button.component";
import { LgPasteDialogComponent } from "./copy-paste/lg-paste-dialog.component";
import { LgUploadResumableComponent } from "./uploader/lg-upload-resumable.component";
import { LgHelpTooltipComponent } from "./help-tooltips";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        UiCoreModule,
        LgLocalizationModule,
        FileUploadModule
    ],
    exports: [
        LgCommentIconComponent,
        LgCopyMenuComponent,
        LgCopyButtonComponent,
        LgUploadFormComponent,
        LgUploadTableComponent,
        LgUploadResumableComponent,
        LgPasteButtonComponent,
        LgDownloadFormComponent,
        LgExportMenuComponent,
        LgMultilevelPickerDialogComponent,
        ItemClusterFilterRendererLineComponent,
        ItemClusterFilterRendererPopupComponent,
        LgItemClusterDialogComponent,
        LgComparisonSourceIndicatorComponent,
        LgComparisonSourceMenuComponent,
        LgHelpTooltipComponent
    ],
    declarations: [
        LgCommentIconComponent,
        LgCopyMenuComponent,
        LgCopyButtonComponent,
        LgUploadFormComponent,
        LgUploadTableComponent,
        LgUploadResumableComponent,
        LgPasteButtonComponent,
        LgCommentDialogComponent,
        // LgTopbarErrorTooltipComponent,
        LgPasteDialogComponent,
        LgDownloadFormComponent,
        LgExportMenuComponent,
        LgExportDialogComponent,
        LgMultilevelPickerDialogComponent,
        ItemClusterFilterRendererLineComponent,
        ItemClusterFilterRendererPopupComponent,
        LgItemClusterDialogComponent,
        LgComparisonSourceIndicatorComponent,
        LgComparisonSourceMenuComponent,
        MiComparisonSourceMenuPopupComponent,
        LgHelpTooltipComponent
    ],
    providers: [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: ItemClusterFilterRendererFactory,
            multi: true
        },
        MiComparisonSourceService
    ]
})
export class UiToolboxModule {
    // empty
}
