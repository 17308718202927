/* eslint-disable no-undef */
// eslint-disable-next-line
/// <reference types="node" />

import { APP_INITIALIZER, NgModule, Optional } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { A11yModule } from "@angular/cdk/a11y";
import { EVENT_MANAGER_PLUGINS } from "@angular/platform-browser";

import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { CoreModule } from "@logex/framework/core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

// Behavior
import {
    LgCaptureFocusDirective,
    LgClickForInputDirective,
    LgCopyHandlerDirective,
    LgDefaultFocusDirective,
    LgDetachedPreserveScrollDirective,
    LgDetachmentNotifierDirective,
    LgMarkFocusOnDirective,
    LgNextInputOnEnterDirective,
    LgObserveSizeDirective,
    LgOnValidityChangeDirective,
    LgPasteHandlerDirective,
    LgRectangleComponent,
    LgSelectOnFocusDirective,
    LgStopPropagationDirective,
    lgTableInputNavigatorDirective,
    LgUndoOnEscDirective
} from "./behavior";

// Controls
import {
    LG_ICON_LOADER_CONTENT,
    LG_ICON_LOADER_URL,
    LgCalendarComponent,
    LgCalendarTooltipComponent,
    LgCellLoaderOverlayDirective,
    LgCollapsibleDialogBlockComponent,
    LgComboButtonComponent,
    LgComboButtonDropdownComponent,
    LgDropdownComponent,
    LgDropdownPopupComponent,
    LgDynamicInputComponent,
    LgFoldingLoaderComponent,
    LgGridDropdownComponent,
    LgGridInputComponent,
    LgHelpInfoComponent,
    LgHelpInfoPopupComponent,
    LgIconComponent,
    LgIconLoaderService,
    LgIconMenuComponent,
    LgItemSelectorComponent,
    LgLoaderComponent,
    LgLoaderOverlayDirective,
    LgLoaderTabComponent,
    LgLoaderWorkingComponent,
    LgLoadingBarsComponent,
    LgLoadingBarsOverlayDirective,
    LgMonthRangePreviewComponent,
    LgMonthRangeSelectorComponent,
    LgMonthRangeSelectorPopupComponent,
    LgMultiFilterButtonComponent,
    LgMultiFilterComponent,
    LgMultiFilterPopupComponent,
    LgMultilevelSelectComponent,
    LgMultiselectDirective,
    LgNewPasswordComponent,
    LgNewPasswordRequirementComponent,
    LgProgressBarComponent,
    LgQuickNotificationComponent,
    LgQuickSettingsMenuButtonComponent,
    LgQuickSettingsMenuPopupComponent,
    LgRangeSliderComponent,
    LgReferenceDropdownComponent,
    LgReferenceDropdownPopupComponent,
    LgSelectionCheckboxComponent,
    LgSimpleDropdownComponent,
    LgSimpleDropdownPopupComponent,
    LgSliderComponent,
    LgSliderSwitchComponent,
    LgSpinnerComponent,
    LgStatusIconComponent,
    LgStatusPieComponent,
    LgStyledCheckbox,
    LgStyledRadioDirective,
    LgSwitchComponent,
    LgTagsSelectorComponent,
    LgTagsSelectorPopupComponent,
    LgTagsSelectorTagComponent,
    LgTimePickerComponent,
    LgTimePickerPopupComponent,
    LgTristateSliderComponent,
    LgWorkingOverlayDirective,
    LgYearMonthRangePreviewComponent,
    LgYearMonthRangeSelectorComponent,
    LgYearMonthRangeSelectorPopupComponent,
    MaxValidator,
    MinValidator
} from "./controls";

import { LgFormatterDirective } from "./controls/inputs/formatters/lg-formatter.directive";

import { LgMultiCheckboxComponent } from "./controls/inputs/lg-multi-checkbox.component";

import { LgButtonComponent } from "./controls/lg-button.component";
import { LgSubmitButtonComponent } from "./controls/lg-submit-button.component";
import { LgDigestIndicatorComponent } from "./controls/lg-digest-indicator";
import { ModSwitchSliderComponent } from "./controls/lg-switch/mod-switch-slider.component";

import { LgQuickSettingsMenuComponent } from "./controls/lg-quick-settings-menu/lg-quick-settings-menu.component";

// Events
import { HoverEventsPlugin, ResizeObservedEventsPlugin } from "./events";

// Helpers
// lg-dialog
import { LgDialogFactory, LgDialogHolderComponent, LgDialogService } from "./lg-dialog";

// lg-overlay
import { WaitOverlayComponent } from "./lg-overlay";

// lg-tooltip
import {
    LgSimpleTooltipDirective,
    LgTooltipDirective,
    LgTooltipHolderComponent
} from "./lg-tooltip";

// lg-snackbar
import { LgSnackbarComponent } from "./lg-snackbar";

// Navigation
// Pipes
import {
    LgCapitalizePipe,
    LgFormatFloatPipe,
    LgFormatIntegerPipe,
    LgFormatMoneyPipe,
    LgFormatMoneyScaledPipe,
    LgFormatPercentPipe,
    LgFormatPercentSimplePipe,
    LgFormatTextPipe,
    LgFormatTypePipe,
    LgFormatUnitPipe,
    LgHighlightByRegexPipe,
    LgHighlightPipe,
    LgLeftPadPipe,
    LgMarkSymbolsPipe,
    LgMonthNamePipe,
    LgNewlinesPipe,
    LgRemoveSymbolPipe,
    LgTypeCastPipe,
    LgTypeGuardPipe
} from "./pipes";

// Scrolling
import { LgScrollableDirective, LgViewportScrollableDirective } from "./scrolling";

// Tables
import {
    DEFAULT_COLUMN_TYPE_CONFIGURATION,
    LgColDefinitionComponent,
    LgColDefinitionDirective,
    LgColDirective,
    LgColRowComponent,
    LgColRowDirective,
    LgColWidthDirective,
    LgSortByColumnDirective
} from "./tables";
import {
    LgColComponent,
    LgColInheritComponent,
    LgColLikeComponent
} from "./tables/lg-col-definition/components/lg-col.component";

// Templating
import {
    LgPlaceholderComponent,
    LgPlaceholderContentDirective,
    LgPlaceholderContextComponent,
    LgPortalOutletDirective,
    LgSiblingPlaceholderComponent
} from "./templating";

// Text
import {
    LgBigGrowthDirective,
    LgBigPercentDirective,
    LgColoredNumberDirective,
    LgLongTextDirective
} from "./text";

// Virtual-scrolling
import { LgVirtualForOf } from "./virtual-scrolling";
import { LgMonthRangeSliderComponent } from "./controls/lg-month-range-slider/lg-month-range-slider.component";
import { LgHoverableIconComponent } from "./controls/lg-icon/lg-hoverable-icon.component";

import iconAtlas from "./controls/lg-icon/lg-framework-icons.def";

const DIRECTIVES = [
    // Behavior
    LgCaptureFocusDirective,
    LgClickForInputDirective,
    LgDefaultFocusDirective,
    LgFormatterDirective,
    LgMarkFocusOnDirective,
    LgNextInputOnEnterDirective,
    LgOnValidityChangeDirective,
    LgSelectOnFocusDirective,
    lgTableInputNavigatorDirective,
    LgUndoOnEscDirective,
    LgObserveSizeDirective,
    LgDetachmentNotifierDirective,
    LgDetachedPreserveScrollDirective,
    LgStopPropagationDirective,
    LgCopyHandlerDirective,
    LgPasteHandlerDirective,
    // Controls
    LgFormatterDirective,
    LgLoaderOverlayDirective,
    LgWorkingOverlayDirective,
    LgCellLoaderOverlayDirective,
    LgLoadingBarsOverlayDirective,
    LgMultiselectDirective,
    LgStyledRadioDirective,
    MaxValidator,
    MinValidator,
    // lg-tooltip
    LgSimpleTooltipDirective,
    LgTooltipDirective,
    // Scrolling
    LgScrollableDirective,
    LgViewportScrollableDirective,
    // Tables
    LgColDefinitionDirective,
    LgColRowDirective,
    LgColWidthDirective,
    LgColDirective,
    LgSortByColumnDirective,
    // Templating
    LgPortalOutletDirective,
    // Text
    LgBigGrowthDirective,
    LgBigPercentDirective,
    LgColoredNumberDirective,
    LgLongTextDirective,
    // Virtual-scrolling
    LgVirtualForOf
];

const PIPES = [
    LgHighlightPipe,
    LgHighlightByRegexPipe,
    LgCapitalizePipe,
    LgFormatMoneyPipe,
    LgFormatMoneyScaledPipe,
    LgFormatFloatPipe,
    LgFormatIntegerPipe,
    LgFormatPercentSimplePipe,
    LgFormatPercentPipe,
    LgFormatTextPipe,
    LgFormatTypePipe,
    LgFormatUnitPipe,
    LgLeftPadPipe,
    LgMonthNamePipe,
    LgRemoveSymbolPipe,
    LgNewlinesPipe,
    LgMarkSymbolsPipe,
    LgTypeCastPipe,
    LgTypeGuardPipe
];

const STANDALONE = [
    // Behavior
    LgComboButtonComponent,
    LgComboButtonDropdownComponent,
    LgRectangleComponent,
    // Controls
    LgButtonComponent,
    LgCalendarTooltipComponent,
    LgCalendarComponent,
    LgCollapsibleDialogBlockComponent,
    LgDropdownComponent,
    LgHelpInfoComponent,
    LgHelpInfoPopupComponent,
    LgSpinnerComponent,
    LgStyledCheckbox,
    LgIconComponent,
    LgIconMenuComponent,
    LgHoverableIconComponent,
    LgMultiFilterButtonComponent,
    LgMultilevelSelectComponent,
    LgNewPasswordComponent,
    LgNewPasswordRequirementComponent,
    LgQuickNotificationComponent,
    LgQuickSettingsMenuComponent,
    LgQuickSettingsMenuButtonComponent,
    LgQuickSettingsMenuPopupComponent,
    LgRangeSliderComponent,
    LgSimpleDropdownComponent,
    LgSimpleDropdownPopupComponent,
    LgSliderComponent,
    LgSnackbarComponent,
    LgSwitchComponent,
    LgTagsSelectorComponent,
    LgTagsSelectorTagComponent,
    LgTagsSelectorPopupComponent,
    LgTimePickerComponent,
    LgTimePickerPopupComponent,
    LgYearMonthRangeSelectorComponent,
    LgYearMonthRangeSelectorPopupComponent,
    LgYearMonthRangePreviewComponent,
    LgSubmitButtonComponent,
    LgDigestIndicatorComponent,
    LgProgressBarComponent,
    LgSelectionCheckboxComponent,
    LgSliderSwitchComponent,
    LgStatusIconComponent,
    LgStatusPieComponent,
    LgTristateSliderComponent,
    LgTooltipHolderComponent,
    // lg-loader
    LgLoaderComponent,
    LgFoldingLoaderComponent,
    LgLoaderTabComponent,
    LgLoaderWorkingComponent,
    LgLoadingBarsComponent,
    // Month range
    LgMonthRangeSelectorComponent,
    LgMonthRangeSelectorPopupComponent,
    LgMonthRangePreviewComponent,
    LgMonthRangeSliderComponent,
    // Templating
    LgPlaceholderContentDirective,
    LgPlaceholderContextComponent,
    LgPlaceholderComponent,
    LgSiblingPlaceholderComponent,
    ...DIRECTIVES,
    ...PIPES
];

export function appInitializerFactory(
    urls: string[],
    contents: string[],
    loader: LgIconLoaderService
): () => Promise<void> {
    return loader._loadAtlasesAtStartup(urls ?? [], contents ?? []);
}

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        ScrollingModule,
        FormsModule,
        A11yModule,
        CoreModule,
        LgLocalizationModule,
        LgStyledCheckbox,
        ModSwitchSliderComponent,
        ...STANDALONE
    ],
    exports: [
        OverlayModule,
        PortalModule,
        ScrollingModule,
        LgCaptureFocusDirective,
        LgClickForInputDirective,
        LgDefaultFocusDirective,
        LgMarkFocusOnDirective,
        LgNextInputOnEnterDirective,
        LgOnValidityChangeDirective,
        LgSelectOnFocusDirective,
        lgTableInputNavigatorDirective,
        LgUndoOnEscDirective,
        LgObserveSizeDirective,
        LgRectangleComponent,
        LgDetachmentNotifierDirective,
        LgDetachedPreserveScrollDirective,
        LgStopPropagationDirective,
        LgCopyHandlerDirective,
        LgPasteHandlerDirective,
        // Controls
        LgDynamicInputComponent,
        LgGridInputComponent,
        LgMultiCheckboxComponent,
        LgMultiFilterComponent,
        LgMultiFilterPopupComponent,
        LgGridDropdownComponent,
        LgReferenceDropdownPopupComponent,
        LgReferenceDropdownComponent,
        LgDropdownPopupComponent,
        LgItemSelectorComponent,
        // Helpers
        LgDialogHolderComponent,
        WaitOverlayComponent,
        // Tables
        LgColDefinitionComponent,
        LgColRowComponent,
        LgColComponent,
        LgColInheritComponent,
        LgColLikeComponent,
        ...STANDALONE
    ],
    declarations: [
        // Controls
        LgDynamicInputComponent,
        LgGridInputComponent,
        LgGridDropdownComponent,
        LgReferenceDropdownPopupComponent,
        LgReferenceDropdownComponent,
        LgDropdownPopupComponent,
        LgMultiCheckboxComponent,
        LgMultiFilterComponent,
        LgMultiFilterPopupComponent,
        LgItemSelectorComponent,
        LgDialogHolderComponent,
        WaitOverlayComponent,
        // Tables
        LgColDefinitionComponent,
        LgColRowComponent,
        LgColComponent,
        LgColInheritComponent,
        LgColLikeComponent
    ],
    providers: [
        {
            provide: EVENT_MANAGER_PLUGINS,
            useClass: HoverEventsPlugin,
            multi: true
        },
        {
            provide: EVENT_MANAGER_PLUGINS,
            useClass: ResizeObservedEventsPlugin,
            multi: true
        },
        LgDialogFactory,
        LgDialogService,
        ...DEFAULT_COLUMN_TYPE_CONFIGURATION,
        DatePipe,
        LgRemoveSymbolPipe,
        LgMonthNamePipe,
        LgCapitalizePipe,
        LgFormatTypePipe,
        LgLeftPadPipe,
        LgFormatMoneyPipe,
        {
            provide: LG_ICON_LOADER_CONTENT,
            useValue: iconAtlas,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [
                [new Optional(), LG_ICON_LOADER_URL],
                [new Optional(), LG_ICON_LOADER_CONTENT],
                LgIconLoaderService
            ],
            multi: true
        }
    ]
})
export class UiCoreModule {}
