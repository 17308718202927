import _ from "lodash";
import { InjectionToken } from "@angular/core";

export const LG_COL_DEFINITION_TYPE_CONFIGURATION = new InjectionToken<TableTypeConfiguration>(
    "lgColDefinitionTypeConfiguration"
);

export interface ColumnTypeConfiguration {
    paddingLeft?: number | undefined;
    paddingRight?: number | undefined;
    paddingLeftOfFirst?: number | undefined;
    paddingRightOfLast?: number | undefined;
    defaultWidth?: number | undefined;
    classNames: string[];
    isHidden?: boolean | undefined;
}

export interface TableTypeConfiguration {
    name: string;
    defaultRowWidth?: number | undefined;
    defaultPadding?: number | undefined;
    defaultEndsPadding?: number | undefined;
    defaultColumnClass?: string | undefined;
    inherit?: string | undefined;

    columns: _.Dictionary<ColumnTypeConfiguration>;
}
