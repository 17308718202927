import { Component } from "@angular/core";
import { Observable } from "rxjs";

import { IPanelChoiceVariantDefinition, LgPanelSwitchState$ } from "./lg-panel.types";

export interface ILgPanelSwitchTooltipComponentProps {
    // note 'choices$' will normally be IPanelChoiceDefinition object but we need just the subset of properties and it's more universal this way
    choices$: Observable<IPanelChoiceVariantDefinition[]>;
    currentSelection$: LgPanelSwitchState$;
}

@Component({
    selector: "lg-panel-switch-tooltip",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <ng-container *ngIf="_initialized">
            <div
                class="lg-panel-selection-tooltip__choice"
                *ngFor="let entry of _choices$ | async; let first = first; let last = last"
                [class.lg-panel-selection-tooltip__choice--first]="first"
                [class.lg-panel-selection-tooltip__choice--last]="last"
                [ngClass]="{
                    'lg-panel-selection-tooltip__choice--active':
                        entry.id === (_currentSelection$ | async)?.choiceId
                }"
                (click)="onClick(entry)"
            >
                <span class="lg-panel-selection-tooltip__choice--entry">
                    <span class="{{ entry.icon }} lg-panel-selection-tooltip__choice-icon"></span>
                    {{ entry.name }}
                </span>
            </div>
        </ng-container>
    `
})
export class LgPanelSwitchTooltipComponent {
    _choices$: Observable<IPanelChoiceVariantDefinition[]>;
    _currentSelection$: LgPanelSwitchState$;
    _initialized: boolean;

    constructor() {
        this._initialized = false;
    }

    initialize({ choices$, currentSelection$ }: ILgPanelSwitchTooltipComponentProps): void {
        this._currentSelection$ = currentSelection$;
        this._choices$ = choices$;
        this._initialized = true;
    }

    onClick({ id }: IPanelChoiceVariantDefinition): void {
        this._currentSelection$.next({ choiceId: id, variantId: 1 }); // TODO when we support multiple choice - multiple variants we could use variantIds
    }
}
