import _ from "lodash";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import {
    ColumnTypeConfiguration,
    TableTypeConfiguration,
    LG_COL_DEFINITION_TYPE_CONFIGURATION
} from "./column-type-configuration";
import { LgColDefinitionSource } from "./definition-column-source";
import { LgColDefinitionPrepared } from "./column-definition-prepared";

@Injectable({ providedIn: "root" })
export class LgColDefinitionService {
    private _typeConfigs: _.Dictionary<TableTypeConfiguration> = {};

    // ---------------------------------------------------------------------------------------------
    public constructor() {
        const configurations = inject<TableTypeConfiguration[]>(
            LG_COL_DEFINITION_TYPE_CONFIGURATION
        );
        this._setupTypeConfiguration(configurations);
    }

    // ---------------------------------------------------------------------------------------------
    public prepareDefinition(
        definition: LgColDefinitionSource,
        tick$: Observable<void>
    ): LgColDefinitionPrepared {
        const name = definition.tableType || "default";
        let configuration = this._typeConfigs[name];
        if (!configuration) {
            console.error("lgColDefinition unknown table type " + name);
            configuration = this._typeConfigs.default;
        }

        return new LgColDefinitionPrepared(definition, configuration, tick$);
    }

    // ---------------------------------------------------------------------------------------------
    private _setupTypeConfiguration(configurations: TableTypeConfiguration[]): void {
        this._typeConfigs = {};

        for (const table of configurations) {
            const tableCopy: TableTypeConfiguration = {
                name: table.name.toLowerCase(),
                columns: {},
                defaultRowWidth: table.defaultRowWidth,
                defaultPadding: table.defaultPadding,
                defaultEndsPadding: table.defaultEndsPadding,
                defaultColumnClass: table.defaultColumnClass,
                inherit: table.inherit
            };

            _.each(table.columns, (column, colName) => {
                const columnCopy: ColumnTypeConfiguration = {
                    classNames: column.classNames || [],
                    defaultWidth: column.defaultWidth,
                    isHidden: column.isHidden,
                    paddingLeft: column.paddingLeft,
                    paddingLeftOfFirst: column.paddingLeftOfFirst,
                    paddingRight: column.paddingRight,
                    paddingRightOfLast: column.paddingRightOfLast
                };
                tableCopy.columns[colName.toLowerCase()] = columnCopy;
            });

            this._typeConfigs[tableCopy.name] = tableCopy;
        }

        // process inheritances
        const processed: _.Dictionary<boolean> = {};

        _.each(this._typeConfigs, table => {
            if (table.inherit) this._setupTypeInheritance(table, processed);
        });
    }

    // ---------------------------------------------------------------------------------------------
    private _setupTypeInheritance(
        table: TableTypeConfiguration,
        processed: _.Dictionary<boolean>
    ): void {
        const parentName = table.inherit?.toLowerCase();
        const parentTable = parentName ? this._typeConfigs[parentName] : undefined;

        if (!parentTable)
            throw new Error(
                `Unknown parent table configuration ${parentName}  used by table ${table.name}`
            );
        if (processed[parentName!] === false)
            throw new Error(
                `Dependency loop detected while processing table ${table.name} inheriting from table ${parentName}`
            );

        processed[table.name] = false;

        if (parentTable.inherit && !processed[parentName!])
            this._setupTypeInheritance(parentTable, processed);

        if (table.defaultRowWidth == null) table.defaultRowWidth = parentTable.defaultRowWidth;
        if (table.defaultPadding == null) table.defaultPadding = parentTable.defaultPadding;
        if (table.defaultEndsPadding == null)
            table.defaultEndsPadding = parentTable.defaultEndsPadding;
        if (table.defaultColumnClass == null)
            table.defaultColumnClass = parentTable.defaultColumnClass;

        _.each(parentTable.columns, (column, name) => {
            if (!table.columns[name]) {
                table.columns[name] = _.clone(column);
            }
        });

        processed[table.name] = true;
    }
}
