import { Injectable, Inject } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild } from "@angular/router";
import { IUser, LG_USER_INFO } from "../user/user.types";

@Injectable({ providedIn: "root" })
export class AnonymousGuardService implements CanActivate, CanActivateChild {
    constructor(private _router: Router, @Inject(LG_USER_INFO) private _userInfo: IUser) {}

    public canActivate(_route: ActivatedRouteSnapshot): boolean {
        if (this._userInfo.id) {
            this.redirect();
            return false;
        }

        return true;
    }

    public canActivateChild(route: ActivatedRouteSnapshot): boolean {
        return this.canActivate(route);
    }

    public redirect(): void {
        this._router.navigate([""]);
    }
}
