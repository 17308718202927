import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { Attributes, IdentifyOptions, StartOptions, Userflow } from "userflow.js";

export interface IUserflowConfigurationBase {
    /**
     *Userflow.js Token(environment key), specific for each environment.
     Can be specific for a project/environment,
     should be saved somewhere in the project settings (Webconfig, app.config, Octopus variables, etc.)
     */
    envinromentKey: string;
    /**
     * User ID which used by userflow for identification purposes
     */
    userId: string;

    /**
     * Used to override default navigation. A standard usedflow navigation causes full page load
     * by window.location.href = url which may be not desirable for SPA
     */
    setNavigation?: (url: string) => void;

    /**
     * Allows to customize URLs before they leaves the browser and will be sent to userflows's server.
     * More about usage on https://userflow.com/docs/userflow-js#seturlfilter
     */
    setUrlFilter?: (url: string) => string;

    /**
     * Tells userflow.js which HTML element attributes can be used for collecting and then saving
     * information about the elements in order to find them later onpage and anchor the userflow content
     * (tooltip, launcher, ect.). More about element inference at https://userflow.com/docs/dev/element-inference
     */
    inferenceAttributeNames?: string[];

    /**
     * Restricts HTML element attribute values from being used for element inference
     */
    inferenceAttributesFilters?: {
        [key: string]: Array<((attributeName: string) => boolean) | RegExp>;
    };

    /**
     * Restricts CSS class names from being used for element inference
     */
    inferenceClassNames?: Array<((className: string) => boolean) | RegExp>;
}

export interface IUserflowConfiguration extends IUserflowConfigurationBase {
    ready: Promise<void>;
}

/** Service which encapsulates userflow.js*/
export interface IUserflowService {
    /** Signals that userflow.js has been initialized */
    ready$: Observable<void>;

    /** Initialize userlow.js */
    initialize(config: IUserflowConfigurationBase): void;

    /** Associates the currently identified user with a group, and optionally updates the group's attributes*/
    setUserGroup: (groupId: string, attrs: { [key: string]: unknown }) => Promise<void>;

    /**
     * Force userflow.js to show permanently closed content again. For example
     * if a user being tired of onboarding trainings has closed checklist they can click on "Training overview" button
     * and come back to training later.
     */
    showContent: (contentId: string) => Promise<void>;

    /** Ends all currently active Userflow content (flows, checklists etc.), if a user doesn't have any active content nothing happens */
    endAll: () => Promise<void>;

    /** Makes Userflow forget about the current session and immediately hides any active flows */
    reset: () => void;

    /** Updates attributes for a user that has already been identified with userflow.identify*/
    patchUserAttrs: (attrs: { [key: string]: unknown }) => Promise<void>;
}

/** Stubs userflow object, if it hasn't been initialized */
export interface INoopUserflow {
    updateUser: (attributes: Attributes, opts?: IdentifyOptions) => Promise<void>;
    group: (groupId: string, attributes: Attributes) => Promise<void>;
    isIdentified: () => false;
    start: (contentId: string, opts?: StartOptions) => Promise<void>;
    endAll: () => Promise<void>;
    reset: () => void;
}

export type LgUserflow = Userflow | INoopUserflow;

export const LG_USERFLOW_CONFIGURATION = new InjectionToken<IUserflowConfiguration>(
    "lgUserflowConfiguration"
);

export const LG_USERFLOW_SERVICE = new InjectionToken<IUserflowService>("lgUserflowservice");
