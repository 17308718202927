<div class="lg-panel-switch__header">
    <h2>{{ title }}</h2>
    <div class="lg-panel-switch__header__icons">
        <ng-container *ngIf="_leftAdditionalItems$ | async as leftItems">
            <ng-container *ngFor="let item of leftItems">
                <ng-container *ngTemplateOutlet="item"></ng-container>
            </ng-container>
        </ng-container>
        <lg-icon-menu
            [definition]="_dropdownDefinition"
            [current]="(_currentChoice$ | async).id"
            (currentChange)="_choiceChange($event)"
            [icon]="(_currentChoice$ | async).icon"
            class="lg-panel__header-icon"
        >
        </lg-icon-menu>
        <ng-container *ngIf="_rightAdditionalItems$ | async as rightItems">
            <ng-container *ngFor="let item of rightItems">
                <ng-container *ngTemplateOutlet="item"></ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<div class="lg-panel-switch__body">
    <ng-container
        *ngTemplateOutlet="_getPanelTemplate(); context: { $implicit: _state$ }"
    ></ng-container>
</div>
