import * as _ from "lodash";
import { HttpResponse } from "@angular/common/http";
import { MaybeStaleData } from "./ServerGatewayBase.types";

const staleHeaderRegex = /^110 - "stale(?::((?:[a-z0-9-]+,?)+))?"$/i;

export function asMaybeStaleData<T>(response: HttpResponse<T>): MaybeStaleData<T> {
    let isStale = false;
    let jobs: string[] = null;

    if (response.headers != null && "getAll" in response.headers) {
        const warnings = response.headers.getAll("Warning");
        const staleDataWarnings = _.filter(_.map(warnings, x => staleHeaderRegex.exec(x)));
        isStale = !_.isEmpty(staleDataWarnings);
        jobs = _.flatten(_.filter(_.map(staleDataWarnings, x => x[1]?.split(","))));
    }

    return {
        data: response.body,
        isStale,
        jobs
    };
}
