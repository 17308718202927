import * as _ from "lodash";
import {
    Injectable,
    InjectionToken,
    EnvironmentInjector,
    runInInjectionContext,
    inject
} from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { LgConsole } from "@logex/framework/core";

import { LgGoogleTranslateDetectionFactoryService } from "./lg-google-translate-detection.service";
import { TranslateService } from "@ngx-translate/core";
import { LG_LOCALIZATION_SETTINGS, LocalizationJson } from "./lg-localization.types";
import { lastValueFrom } from "rxjs";

export type LocalizationSourceCallback = (
    locale: string,
    overlay?: string
) => string | Promise<string | null> | null;

export const LG_LOCALIZATION_URL_PREFIX = new InjectionToken<string>("LG_LOCALIZATION_URL_PREFIX");
export const LG_TRANSLATE_STARTUP_SERVICE_ACTIVE = new InjectionToken<boolean>(
    "LG_TRANSLATE_STARTUP_SERVICE_ACTIVE"
);
export const LG_LOCALIZATION_SOURCE_URLS = new InjectionToken<LocalizationSourceCallback[]>(
    "LG_LOCALIZATION_SOURCE_URLS"
);

export const LG_BASE_TRANSLATIONS = new InjectionToken<Object>("LG_BASE_TRANSLATIONS");

// ----------------------------------------------------------------------------------
//
@Injectable({ providedIn: "root" })
export class LgTranslateStartupService {
    private _environmentInjector = inject(EnvironmentInjector);
    private _getSourceUrls? = inject(LG_LOCALIZATION_SOURCE_URLS, { optional: true });
    private _http = inject(HttpClient);
    private _lgConsole = inject(LgConsole);
    private readonly _settings = inject(LG_LOCALIZATION_SETTINGS);
    private _translateService = inject(TranslateService);
    private readonly _urlPrefix = inject(LG_LOCALIZATION_URL_PREFIX);

    constructor() {
        const gtdfc = inject(LgGoogleTranslateDetectionFactoryService);

        // call `create` just for the side-effect, so we start observing the <head> element
        // Reason: we need to start checking if the page is translated by Google asap,
        // and we can't rely on all pages using lgTranslate directive (which would instantiate
        // the observer otherwise)
        gtdfc.create();

        if (this._getSourceUrls?.length && this._urlPrefix) {
            this._lgConsole.warn(
                "LgTranslateStartupService: Both LG_LOCALIZATION_URL_PREFIX and LG_LOCALIZATION_SOURCE_URLS have been set. Specifying custom source url does not require to use url prefix."
            );
        }
    }

    private _initialized = false;
    private _processedLanguages: string[] = [];

    initialize(): () => Promise<void> {
        return (): Promise<void> =>
            this._settings.bootstrapDone.then(() => {
                if (this._getSourceUrls == null || this._getSourceUrls.length === 0) {
                    this._getSourceUrls = [this._getLocalizationUrl.bind(this)];
                }

                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve, reject) => {
                    try {
                        this._translateService.addLangs(this._settings.availableLanguages);

                        if (
                            this._settings.fallbackLanguage &&
                            this._settings.fallbackLanguage !== this._settings.preferredLanguage &&
                            !this._processedLanguages.includes(this._settings.fallbackLanguage)
                        ) {
                            await this._configureLanguage(this._settings.fallbackLanguage);
                            this._translateService.setDefaultLang(this._settings.fallbackLanguage);
                            this._processedLanguages.push(this._settings.fallbackLanguage);
                        }

                        if (this._settings.preferredLanguage) {
                            await this._configureLanguage(this._settings.preferredLanguage);
                            this._translateService.use(this._settings.preferredLanguage);
                            this._processedLanguages.push(this._settings.preferredLanguage);
                        }

                        _.each(
                            this._settings.languages,
                            (dictionaries: LocalizationJson[], key: string) => {
                                const target: LocalizationJson = _.defaultsDeep(
                                    {},
                                    ...dictionaries.reverse()
                                );
                                this._translateService.setTranslation(key, target, true);
                                this._processedLanguages.push(key);
                            }
                        );

                        this._initialized = true;

                        resolve();
                    } catch (e) {
                        console.error("Error initializing languages", e);

                        reject(e);
                    }
                });
            });
    }

    public async preloadFallbackLanguage(language: string): Promise<void> {
        if (this._initialized) {
            return;
        }

        try {
            const strings = await this._configureLanguage(language);
            this._translateService.setTranslation(language, strings, true);
            this._translateService.setDefaultLang(language);
            this._processedLanguages.push(language);
        } catch (e) {
            console.error("Error while preloading fallback language", e);
        }
    }

    public async addLanguageOverlay(language: string, overlay: string): Promise<void> {
        if (!this._initialized) {
            console.warn(
                `Unable to add language overlay "${overlay}" for ${language} locale before the initialization`
            );
            return;
        }

        try {
            const strings = await this._configureLanguage(language, overlay);
            this._translateService.setTranslation(language, strings, true);
            this._processedLanguages.push(language);
        } catch {
            console.warn(
                `Unable to load and apply the language overlay "${overlay}" for ${language} locale`
            );
        }
    }

    protected async _configureLanguage(
        language: string,
        overlay?: string
    ): Promise<LocalizationJson> {
        // Load language files
        const sourceUrls = runInInjectionContext(this._environmentInjector, () =>
            this._getSourceUrls!.map(getSourceUrl => getSourceUrl(language, overlay))
        );
        const sources = sourceUrls.map(sourceUrl => {
            if (typeof sourceUrl === "string") {
                return this._getLocalization(sourceUrl);
            }
            return Promise.resolve(sourceUrl).then(url => this._getLocalization(url));
        });
        const sourceStrings = await Promise.all(sources);
        const strings: LocalizationJson = {};
        for (const string of sourceStrings) {
            _.merge(strings, string);
        }
        this._settings.addStrings(language, strings);

        return strings;
    }

    protected _getLocalizationUrl(language: string, overlay?: string): string {
        return `${this._urlPrefix}/${language}${overlay != null ? "-" + overlay : ""}.json`;
    }

    private _getLocalization(url: string | null): Promise<LocalizationJson> | null {
        if (url == null) return null;
        return lastValueFrom(this._http.get<LocalizationJson>(url));
    }
}

// ----------------------------------------------------------------------------------
//
// eslint-disable-next-line @typescript-eslint/naming-convention
export function LgTranslateStartupServiceFactory(
    startup: LgTranslateStartupService,
    active: boolean
): () => Promise<void> {
    return active ? startup.initialize() : () => Promise.resolve();
}
