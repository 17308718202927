import {
    Component,
    Input,
    HostBinding,
    HostListener,
    Output,
    EventEmitter,
    ViewEncapsulation
} from "@angular/core";
import { AnimationEvent, trigger, state, style, transition, animate } from "@angular/animations";
import { easingDefs } from "@logex/framework/utilities";
import { ISidebar, SidebarContext } from "./sidebar-context";
import { SidebarButton } from "./sidebar-button-base";

@Component({
    selector: "lg-sidebar-panel-holder",
    template: `
        <h1 class="lg-sidebar-panel-title">{{ _visiblePanel?.nameLc | lgTranslate }}</h1>
        <ng-template
            *ngTemplateOutlet="_visiblePanel?.panelTemplate; context: _context"
        ></ng-template>
        <lg-icon
            class="lg-sidebar-panel-pin"
            [icon]="_pinned ? 'icon-pin_down' : 'icon-pin'"
            (click)="onPinClicked.next()"
        ></lg-icon>
    `,
    host: {
        "[class]": "'lg-sidebar-panel-holder'",
        "[class.lg-sidebar-panel-holder--slideout]": "_visiblePanel!=null && !_pinned"
    },
    animations: [
        trigger("visible", [
            state("false", style({ transform: "translateX(-100%)" })),
            state("true", style({ transform: "translateX(0)" })),
            transition("* => true", animate(`300ms ${easingDefs.easeOutAdam}`)),
            transition("* => false", animate(`300ms ${easingDefs.easeInAdam}`))
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class LgSidebarPanelHolderComponent {
    /**
     * Panel widget config
     */
    @Input() public set panel(panel: SidebarButton) {
        if (panel) {
            this._panel = panel;
            this._visiblePanel = panel;
            this._visible = true;
        } else {
            this._panel = null;
            this._visible = false;
        }
    }

    public get panel(): SidebarButton {
        return this._panel;
    }

    /**
     * Specifies if panel is pinned.
     *
     * @default false
     */
    @Input("pinned") _pinned = false;

    /**
     * Sidebar handler
     */
    @Input() set sidebar(_sidebar: ISidebar) {
        this._context = new SidebarContext(_sidebar);
    }

    get sidebar(): ISidebar {
        return this._context.$implicit;
    }

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("pinClicked") readonly onPinClicked = new EventEmitter<void>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("hidden") readonly onHidden = new EventEmitter<void>();

    _visiblePanel: SidebarButton;

    private _panel: SidebarButton;

    @HostBinding("@visible") _visible = false;

    @HostListener("@visible.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        if ((event.toState as any) === false && !this._visible) {
            this._visiblePanel = null;
            this.onHidden.next();
        }
    }

    _context: SidebarContext;
}
