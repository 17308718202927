import {
    Component,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation
} from "@angular/core";
import { ComponentPortal } from "@angular/cdk/portal";
import { Overlay } from "@angular/cdk/overlay";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgSimpleChanges } from "@logex/framework/types";
import { IOverlayResultApi, LgIconComponent, LgOverlayService } from "@logex/framework/ui-core";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgPanelResizeMenuPopupComponent } from "./lg-panel-resize-menu-popup.component";
import {
    ILgPanelComponent,
    LgPanelGridNode,
    LgPanelGridService,
    LgPanelResizeAction,
    LgPanelResizeTypeDefinition
} from "../service";
import { NgIf } from "@angular/common";

const RESIZE_DEFINITIONS: LgPanelResizeTypeDefinition[] = [
    {
        type: "toFullScreen",
        iconName: "icon-fullscreen-enter2",
        tooltipLc: ".Maximize",
        isDefault: node => {
            return !node.isMaximized && !(node.parent && node.parent.hasMaximizedChild);
        },
        showIf: (_, gridService) => {
            return !gridService.hasFullscreenPanel();
        }
    },
    {
        type: "toDefault",
        iconName: "icon-fullscreen-exit2",
        tooltipLc: ".Restore",
        isDefault: () => {
            return true;
        },
        showIf: (node, gridService) => {
            return (
                gridService.hasFullscreenPanel() || (node.parent && node.parent.hasMaximizedChild)
            );
        }
    },
    {
        type: "expand",
        iconName: "icon-two-row-layout",
        tooltipLc: ".Expand_to_row",
        isDefault: () => false,
        showIf: node => {
            return node.parent && !node.parent.hasMaximizedChild && !node.parent.isColumn;
        }
    },
    {
        type: "expand",
        iconName: "icon-two-column-layout",
        tooltipLc: ".Expand_to_column",
        isDefault: () => false,
        showIf: node => {
            return node.parent && !node.parent.hasMaximizedChild && node.parent.isColumn;
        }
    }
];

@Component({
    standalone: true,
    selector: "lg-panel-resize-menu",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            *ngIf="_visibleItem"
            class="lg-content-grid-maximize-button lg-block__button"
            [title]="_visibleItem.tooltipLc | lgTranslate"
        >
            <lg-icon
                [icon]="_visibleItem.iconName"
                (mouseenter)="_onMouseEnter()"
                (mouseleave)="_onMouseLeave()"
            ></lg-icon>
        </div>
    `,
    viewProviders: [useTranslationNamespace("FW._Directives._lgPanelGrid")],
    host: {
        class: "lg-panel-resize-menu"
    },
    imports: [LgTranslatePipe, LgIconComponent, NgIf],
    encapsulation: ViewEncapsulation.None
})
export class LgPanelResizeMenuComponent implements OnChanges, OnInit, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _gridService = inject(LgPanelGridService);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _renderer = inject(Renderer2);

    /**
     * Panel grid node instance (required).
     */
    @Input({ required: true }) node!: LgPanelGridNode;

    @HostListener("click", ["$event"]) _onClick($event: UIEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        this._executeAction(this._visibleItem.type);
    }

    _visibleItem: LgPanelResizeTypeDefinition;
    _menuItems: LgPanelResizeTypeDefinition[] = [];
    _showMenuOnHover: boolean;

    private readonly _destroyed$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi;
    private _backdropUnsubscribe: () => void;
    private _popupInstance: LgPanelResizeMenuPopupComponent;
    private _isPopupVisible: boolean;
    private _canShowPopup = false;
    private _resizeActionsSubscription: Subscription = null;
    private _lastPanel: ILgPanelComponent = null;

    constructor() {
        this._gridService
            .stateChanges()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._onMenuItemsChange();
                this._subscribeResizeActions();
            });
    }

    ngOnChanges(changes: LgSimpleChanges<LgPanelResizeMenuComponent>): void {
        if (changes.node) {
            this._onMenuItemsChange();
            this._subscribeResizeActions();
        }
    }

    ngOnInit(): void {
        // Make sure the menu doesn't show if the button appeared under the mouse (user has to mouse out first)
        setTimeout(() => {
            this._canShowPopup = !this._isHovered();
        }, 0);
    }

    ngOnDestroy(): void {
        this._unsubscribeResizeActions();
        this._destroyed$.next();
        this._destroyed$.complete();
        this._hidePopup();
    }

    _onMouseLeave(): void {
        this._canShowPopup = true;
    }

    _onMouseEnter(): void {
        if (!this._canShowPopup) return;

        // debounce in case user just mouses around
        setTimeout(() => {
            if (this._isHovered()) {
                if (this._menuItems && this._menuItems.length > 1) {
                    this._showPopup();
                }
            }
        }, 1);
    }

    private _isHovered(): boolean {
        return this._elementRef.nativeElement.querySelector(":hover") !== null;
    }

    private _executeAction(action: LgPanelResizeAction): void {
        if (!this.node || !this.node.attachedPanel) return;

        switch (action) {
            case "toDefault":
                if (this._gridService.hasFullscreenPanel()) {
                    this._gridService.exitFullscreen();
                } else {
                    this._gridService.restorePanel(this.node.attachedPanel);
                }
                break;
            case "toFullScreen":
                this._gridService.maximizePanel(this.node.attachedPanel, true);
                break;
            case "expand":
                this._gridService.maximizePanel(this.node.attachedPanel, false);
                break;
        }
    }

    private _onMenuItemsChange(): void {
        if (!this.node || !this.node.attachedPanel) {
            this._menuItems = [];
            return;
        }
        const items = RESIZE_DEFINITIONS.filter(
            x =>
                this.node.attachedPanel.resizeActions.indexOf(x.type) !== -1 &&
                x.showIf(this.node, this._gridService)
        );
        this._visibleItem =
            items.filter(x => x.isDefault(this.node, this._gridService))[0] || items[0];
        this._menuItems = [
            this._visibleItem,
            ...items.filter(x => x.type !== this._visibleItem.type)
        ];
        this._showMenuOnHover = this._menuItems.length > 1;
    }

    private _subscribeResizeActions(): void {
        const attachedPanel = this.node ? this.node.attachedPanel : null;
        if (attachedPanel !== this._lastPanel) {
            this._unsubscribeResizeActions();
            this._lastPanel = attachedPanel;
            if (attachedPanel)
                this._resizeActionsSubscription = attachedPanel._resizeActionsChange.subscribe(() =>
                    this._onMenuItemsChange()
                );
        }
    }

    private _unsubscribeResizeActions(): void {
        if (this._resizeActionsSubscription) {
            this._resizeActionsSubscription.unsubscribe();
            this._resizeActionsSubscription = null;
        }
    }

    private _showPopup(): void {
        if (this._isPopupVisible) return;

        // currently there are maximum 2 resize options available at a time so popup always fits on the screen
        // leaving the logic to show the popup above the icon in case we add more items or if it doesn't fit on the screen for lower resolutions
        const displayAbove = false;

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                {
                    originX: "start",
                    originY: displayAbove ? "bottom" : "top",
                    overlayX: "start",
                    overlayY: displayAbove ? "bottom" : "top"
                }
            ])
            .withDefaultOffsetY(displayAbove ? -8 : 8)
            .withDefaultOffsetX(2);

        this._overlayInstance = this._overlayService.show({
            hasBackdrop: true,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgPanelResizeMenuPopupComponent>(
            LgPanelResizeMenuPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        this._popupInstance
            .initialize(this._menuItems)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(x => {
                if (x) {
                    this._hidePopup();
                    if (x !== "hide") this._executeAction(x);
                }
            });

        this._backdropUnsubscribe = this._renderer.listen(
            this._overlayInstance.overlayRef.backdropElement,
            "mouseenter",
            () => {
                this._hidePopup();
            }
        );

        this._isPopupVisible = true;
    }

    private _hidePopup(): void {
        if (this._overlayInstance) {
            this._overlayInstance.hide();
            this._overlayInstance = null;
        }

        if (this._backdropUnsubscribe) {
            this._backdropUnsubscribe();
            this._backdropUnsubscribe = null;
        }

        this._isPopupVisible = false;
    }
}
