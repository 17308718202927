import _ from "lodash";
import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,
    OnInit,
    ViewEncapsulation,
    HostBinding,
    forwardRef,
    inject
} from "@angular/core";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";

import { Subject } from "rxjs";
import { takeUntil, first } from "rxjs/operators";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { toBoolean, toInteger } from "@logex/framework/utilities";
import { IColumnFilterDictionary, IFilterSorterOption } from "@logex/framework/types";
import { LgOverlayService, IOverlayResultApi } from "../../lg-overlay/lg-overlay.service";

import {
    LgMultiFilterPopupComponent,
    LgMultiFilterSource
} from "./lg-multi-filter-popup.component";
import { LgMultiFilterLook, LgMultiFilterItemCustomization } from "./lg-multi-filter.types";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
@Component({
    selector: "lg-multi-filter",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="lg-multi-filter__frame"
            [class.lg-multi-filter__frame--disabled]="_disabled"
            [class.lg-multi-filter__frame--selected]="!_empty"
            [class.lg-multi-filter__frame--active]="_active"
        >
            <div
                class="lg-multi-filter__frame__placeholder"
                [hidden]="!_empty"
                [lgLongText]="(_wide && wideLabel) || placeholder"
                lgSimpleTooltip="={{ tooltip }}"
            ></div>
            <div
                class="lg-multi-filter__frame__count"
                [hidden]="_empty"
                *ngIf="_showSelected === 0"
            >
                {{ ".Selected" | lgTranslate: { count: _size } }}
            </div>
            <div class="lg-multi-filter__option-list" *ngIf="_showSelected > 0 && !_empty">
                <div
                    *ngFor="let option of _activeOptions"
                    class="lg-multi-filter__option-list__item"
                    [lgLongText]="option"
                ></div>
                <div class="lg-multi-filter__option-list__more" *ngIf="_plusMore">
                    {{ ".Selected_more" | lgTranslate: { count: _plusMore } }}
                </div>
            </div>
            <div
                class="lg-multi-filter__frame__clear"
                [hidden]="_empty || disabled || !clearable"
                (click)="_clear($event)"
            >
                <lg-icon icon="icon-close"></lg-icon>
            </div>
        </div>
        <div
            class="lg-multi-filter__label"
            [class.lg-multi-filter__label--disabled]="_disabled"
            *ngIf="_wide && !_empty && !_active"
        >
            <div
                [title]="wideLabel || placeholder"
                lgSimpleTooltip="={{ tooltip }}"
                [tooltipPosition]="'top-right'"
            >
                {{ wideLabel || placeholder }}
                <div></div>
            </div>
        </div>
    `,
    viewProviders: [useTranslationNamespace("FW._Directives._lgMultiFilter")],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgMultiFilterComponent),
            multi: true
        }
    ]
})
export class LgMultiFilterComponent implements OnDestroy, OnInit, ControlValueAccessor {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);

    /**
     * Reduces the size of the input field if `true`.
     *
     * @default false
     */
    @Input() set condensed(value: boolean | "true" | "false") {
        this._condensed = toBoolean(value);
        this._setHostClass();
    }

    get condensed(): boolean {
        return this._condensed;
    }

    @Input() set wide(value: boolean | "true" | "false") {
        this._wide = toBoolean(value);
        this._setHostClass();
    }

    get wide(): boolean {
        return this._wide;
    }

    @Input() set disabled(value: boolean | "true" | "false") {
        this._disabled = toBoolean(value);
        this._setHostClass();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() placeholder?: string;

    @Input() tooltip?: string;

    @Input() wideLabel?: string | undefined;

    @Input() set showSelected(value: number | string) {
        const previous = this._showSelected;
        this._showSelected = toInteger(value, 0, 0, null);
        if (this._showSelected && previous !== this._showSelected) {
            this._refilter();
        } else {
            this._setHostClass();
        }
    }

    get showSelected(): number {
        return this._showSelected;
    }

    @Input() set filter(filter: IColumnFilterDictionary) {
        this._filter = filter;
        this._refilter();
    }

    get filter(): IColumnFilterDictionary {
        return this._filter;
    }

    @Input({ required: true }) set source(value: LgMultiFilterSource) {
        this._source = value;
    }

    get source(): LgMultiFilterSource {
        return this._source;
    }

    @Input() showOnInit = false;

    /**
     * @description
     * Hides popup dropdown header.
     */
    @Input() hidePopupHeader = false;

    /**
     * @description
     * Triggered when user close the popup or confirm his changes.
     */
    @Output() readonly filterChange = new EventEmitter<IColumnFilterDictionary>();

    /**
     * @description
     * Triggered immediately when user changes checkbox selection in popup dropdown.
     * It also applies to select all and invert selection actions.
     * This event is NOT triggered when user close the popup or confirm his changes.
     */
    @Output() readonly popupSelectionChange = new EventEmitter<IColumnFilterDictionary>();

    @Output() readonly activeChange = new EventEmitter<boolean>();

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-native
    @Output("show") readonly onShow = new EventEmitter<void>();

    @Input() set look(val: LgMultiFilterLook) {
        this._look = val;
        this._setHostClass();
    }

    get look(): LgMultiFilterLook {
        return this._look;
    }

    @Input() set maxRows(val: number | string) {
        this._maxRows = toInteger(val, 0, 0, null);
        if (this._showSelected > 0) {
            this._refilter();
        }
    }

    get maxRows(): number {
        return this._maxRows;
    }

    @Input() set showDataCounts(val: boolean | "true" | "false") {
        this._showDataCounts = toBoolean(val, false);
    }

    get showDataCounts(): boolean {
        return this._showDataCounts;
    }

    @Input() set clearable(val: boolean | "true" | "false") {
        this._clearable = toBoolean(val, true);
    }

    get clearable(): boolean {
        return this._clearable;
    }

    @Input() popupItemCustomization: LgMultiFilterItemCustomization | null = null;

    @Input() showSorter = false;

    @Input() sorterOptions?: IFilterSorterOption[];

    @HostBinding("class") _hostClass = "lg-multi-filter";

    // --------------------------------------------------------------------------------------------------------
    _empty = true;
    _size = 0;
    _active = false;
    _disabled = false;
    _condensed = false;
    _wide = false;
    _showSelected = 0;
    _activeOptions: string[] | null = null;
    _maxRows = 0;
    _plusMore = 0;
    _clearable = true;
    private _showDataCounts = false;
    private _filter: IColumnFilterDictionary = { $empty: true };
    private _destroyed$ = new Subject<void>();
    private _source!: LgMultiFilterSource;
    private _popupHidden$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi;
    private _popupInstance: LgMultiFilterPopupComponent;
    private _look: LgMultiFilterLook = "default";
    private _popupSelectionChange$ = new Subject<IColumnFilterDictionary>();

    // --------------------------------------------------------------------------------------------------------
    public constructor() {
        this._popupSelectionChange$.subscribe(value => this.popupSelectionChange.emit(value));
    }

    // ---------------------------------------------------------------------------------------------------------
    // Value accessor methods
    private _onChangeFn: (value: IColumnFilterDictionary) => void;
    private _onTouchedFn: () => void;

    writeValue(obj: any): void {
        this._filter = obj;
        this._refilter();
        this._changeDetectorRef.markForCheck();
    }

    registerOnChange(fn: (value: IColumnFilterDictionary) => void): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this._disabled = isDisabled;
        this._doClose(true);
        this._setHostClass();
    }

    // --------------------------------------------------------------------------------------------------------
    private _refilter(): void {
        this._empty = true;
        this._size = 0;

        if (this._filter && !this._filter.$empty) {
            this._empty = false;
            if (this._showSelected > 0) {
                this._activeOptions = _(this._filter).values().value();
                this._size = this._activeOptions.length;
                this._activeOptions = _.take(this._activeOptions, this._showSelected);
                this._plusMore = this._size - this._activeOptions.length;
            } else {
                this._size = _.size(this._filter);
                this._plusMore = 0;
            }
        } else {
            this._activeOptions = null;
            this._plusMore = 0;
        }
        if (this._active) {
            this._popupInstance.setSelection(this._filter);
        }
        this._setHostClass();
    }

    // --------------------------------------------------------------------------------------------------------
    _clear($event: MouseEvent): void {
        if (this._disabled || !this._clearable) return;

        $event.stopPropagation();
        $event.preventDefault();
        if (this._onChangeFn) {
            this._onTouchedFn();
            this._onChangeFn({ $empty: true });
            this.writeValue({ $empty: true });
        }
        this._filter = { $empty: true };
        this.filterChange.next(this._filter);
    }

    // --------------------------------------------------------------------------------------------------------
    @HostListener("click")
    _onClick(): boolean {
        if (!this._disabled) {
            this._doShow();
        }
        return false;
    }

    // --------------------------------------------------------------------------------------------------------
    private _doClose(immediately?: boolean): void {
        if (!this._active) return;

        this._active = false;
        this._popupHidden$.next();
        this._popupHidden$.complete();
        this.activeChange.emit(false);

        if (immediately) {
            this._overlayInstance.hide();
        } else {
            const overlayInstance = this._overlayInstance;
            this._popupInstance
                .hide()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance.hide();
                });
        }

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;
        if (this._onTouchedFn) this._onTouchedFn();

        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _doShow(): void {
        this._popupHidden$ = new Subject<void>();

        // HACK: Exploits the fact that CDK overlay strategy uses only getBoundingClientRect to get position of anchor element
        const element = this._elementRef.nativeElement as HTMLElement;
        const elementRect = element.getBoundingClientRect();
        const cachedElementRef = new ElementRef({
            getBoundingClientRect: () => {
                const bcr = this._elementRef.nativeElement.getBoundingClientRect();
                if (!(bcr.left === 0 && bcr.top === 0 && bcr.width === 0 && bcr.height === 0)) {
                    return elementRect;
                } else {
                    return bcr;
                }
            }
        });
        let strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .setOrigin(cachedElementRef);
        if (this.look !== "grid") {
            strategy = strategy.withPositions([
                { originX: "end", originY: "top", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "bottom" }
            ]);
        } else {
            strategy = strategy.withPositions([
                { originX: "start", originY: "top", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "bottom" }
            ]);
        }

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => {
                if (this._popupInstance) this._popupInstance._attemptClose();
            },
            hasBackdrop: true,
            trapFocus: true,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgMultiFilterPopupComponent>(
            LgMultiFilterPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance._updatePosition(change);
        });

        this._popupInstance
            ._initialize({
                target: this._elementRef,
                filter: this._filter,
                placeholder: this.placeholder,
                source: this._source,
                show: this.onShow,
                condensed: this.condensed,
                wide: this.wide,
                reposition: () => strategy.apply(),
                look: this.look,
                readonly: this.disabled,
                showDataCounts: this._showDataCounts,
                itemCustomization: this.popupItemCustomization,
                selectionChange$: this._popupSelectionChange$,
                hideHeader: this.hidePopupHeader,
                showSorter: this.showSorter,
                sorterOptions: this.sorterOptions
            })
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                this._doClose();
                if (result !== undefined) {
                    const changed = !_.isEqual(this._filter, result);
                    if (changed) {
                        if (this._onChangeFn) {
                            this.writeValue(result);
                            this._onChangeFn(result);
                        }
                        this._filter = result;
                        this.filterChange.next(result);
                    }
                }
            });

        this._active = true;
        this.activeChange.emit(true);

        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    ngOnInit(): void {
        if (this.showOnInit) {
            setTimeout(() => this._onClick(), 0);
        }
    }

    ngOnDestroy(): void {
        if (this._active) {
            this._doClose(true);
        }

        this._destroyed$.next();
        this._destroyed$.complete();

        this._popupSelectionChange$.complete();
    }

    private _setHostClass(): void {
        let cls = "lg-multi-filter";

        if (this.look === "grid") {
            cls += " lg-multi-filter--grid";
        }

        if (this._wide) {
            cls += " lg-multi-filter--wide";
        }

        if (this._condensed) {
            cls += " lg-multi-filter--condensed";
        }

        if (this._showSelected > 0 && !this._empty) {
            cls += " lg-multi-filter--with-options";
        }

        if (this._disabled) {
            cls += " lg-multi-filter--disabled";
        }

        this._hostClass = cls;
        if (
            (this._maxRows > 0 || this._look === "grid") &&
            this._showSelected > 0 &&
            !this._empty
        ) {
            Promise.resolve().then(() => this._ensureMaxRows(0));
        }
    }

    private _ensureMaxRows(depth: number): void {
        if (this._activeOptions.length === 0) return;

        const maxRows = this._look === "grid" ? 1 : this._maxRows;
        const labels = this._elementRef.nativeElement.querySelectorAll<HTMLElement>(
            ".lg-multi-filter__option-list div"
        );

        if (labels.length === 0) return;

        const tops = _.map(labels, label => label.offsetTop);
        const offset = tops[0];
        // note: offsetHeight wouldn't account for margins
        const offsetOfSecondRow = _.find(tops, top => top !== offset);
        if (offsetOfSecondRow === undefined) return;
        const itemHeight = offsetOfSecondRow - offset;

        const rows = 1 + Math.round((tops[labels.length - 1] - offset) / itemHeight);
        if (rows <= maxRows) return;

        let keep = 1;
        for (let i = 1; i < labels.length; ++i) {
            const row = 1 + Math.round((tops[i] - offset) / itemHeight);
            if (row <= maxRows) {
                ++keep;
            } else {
                break;
            }
        }

        const hasMore = this._size > this._activeOptions.length;
        if (hasMore && keep === this._activeOptions.length) --keep;
        this._activeOptions = _.take(this._activeOptions, keep);
        this._plusMore = this._size - this._activeOptions.length;
        // Repeat because of +more
        if (depth < 2) {
            this._changeDetectorRef.detectChanges();
            Promise.resolve().then(() => this._ensureMaxRows(depth + 1));
        }
    }
}
