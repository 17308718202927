import { cloneDeep, each, isEqual } from "lodash";
import { Injectable, NgZone, inject } from "@angular/core";
import { BehaviorSubject, Observable, asapScheduler, firstValueFrom } from "rxjs";
import { filter, observeOn } from "rxjs/operators";
import { IStringLookup } from "@logex/framework/types";
import {
    StorageBinding,
    IStoreIdentifier,
    LG_USER_STORAGE_SERVICE_GATEWAY
} from "./lg-user-storage.types";

interface IInternalStorage {
    binding: StorageBinding;
    subject: BehaviorSubject<any>;
}

const DEFAULT_BINDING = StorageBinding.Hospital;

@Injectable({ providedIn: "root" })
export class LgUserStorageService {
    private _gateway = inject(LG_USER_STORAGE_SERVICE_GATEWAY);
    private _ngZone = inject(NgZone);

    private _cache: Map<string, IInternalStorage>;
    private _last: Map<string, any>;
    private _time: number;
    private _syncing: boolean;

    constructor() {
        this._cache = new Map();
        this._last = new Map();

        this._ngZone.onStable.subscribe(() => this._scheduleSync());
        window.addEventListener("onbeforeunload", () => this._sync());
    }

    get<T = any>(key: string, binding: StorageBinding): Observable<T> {
        const fullKey = this._getFullKey(key);

        return this._load([{ key: fullKey, binding: binding || StorageBinding.Hospital }]).get(
            fullKey
        );
    }

    getAsPromise<T = any>(key: string, binding: StorageBinding): Promise<T> {
        return firstValueFrom(this.get<T>(key, binding));
    }

    preload(keys: string[], binding: StorageBinding): Map<string, Observable<any>>;
    preload(identifiers: IStoreIdentifier[]): Map<string, Observable<any>>;
    preload(
        data: IStoreIdentifier[] | string[],
        binding?: StorageBinding
    ): Map<string, Observable<any>> {
        if (!data || !data.length) {
            throw new Error("No keys or identifiers");
        }

        let identifiers: IStoreIdentifier[];
        if (typeof data[0] === "string") {
            identifiers = (data as string[]).map(key => ({
                key,
                binding: binding || DEFAULT_BINDING
            }));
        } else {
            identifiers = data as IStoreIdentifier[];
        }

        return this._load(identifiers);
    }

    getFromCache<T = any>(key: string): T {
        key = this._getFullKey(key);
        const stored = this._cache.get(key);

        if (!stored || !stored.subject.getValue()) {
            throw Error("storage.getDirect: key " + key + " not loaded");
        }

        return stored.subject.getValue();
    }

    flush(): void {
        this._sync();
        this._cache = new Map();
        this._last = new Map();
    }

    private _scheduleSync(): boolean {
        if (!this._time && !this._syncing) {
            this._time = window.setTimeout(() => this._sync(), 5000);
        }

        return false;
    }

    private _load(identifiers: IStoreIdentifier[]): Map<string, Observable<any>> {
        this._fetchStorage(this._getIdentifiersToFetch(identifiers));

        return this._getMappedResults(identifiers.map(x => x.key));
    }

    private _sync(): void {
        this._time = null;
        const toSave: IStringLookup<{ data: any; binding: StorageBinding }> = {};
        let doSave = false;

        this._cache.forEach((stored, key) => {
            const currentValue = stored.subject.getValue();
            const lastValue = this._last.get(key);
            if (!currentValue || isEqual(currentValue, lastValue)) {
                // Don't try to save entries before they fetched
                return;
            }

            toSave[key] = {
                data: cloneDeep(currentValue),
                binding: stored.binding
            };

            doSave = true;
        });

        if (doSave) {
            this._syncing = true;
            this._gateway.set(toSave).subscribe(
                result => this._onSyncSuccess(result, toSave),
                () => (this._syncing = false) // will try again later
            );
        }
    }

    private _onSyncSuccess(
        result: any,
        toSave: IStringLookup<{ data: any; binding: StorageBinding }>
    ): void {
        this._syncing = false;
        if (result.ok) {
            each(toSave, (v, key) => {
                this._last.set(key, v.data);
            });
        }
    }

    private _getIdentifiersToFetch(identifiers: IStoreIdentifier[]): IStoreIdentifier[] {
        const toLoad: IStoreIdentifier[] = [];

        for (const identifier of identifiers) {
            const stored = this._cache.get(identifier.key);

            if (!stored) {
                this._cache.set(identifier.key, {
                    binding: identifier.binding,
                    subject: new BehaviorSubject(undefined)
                });
                toLoad.push(identifier);
            } else if (stored.binding !== identifier.binding) {
                console.error("Inconsistent binding for key " + identifier.key);
            }
        }

        return toLoad;
    }

    private _fetchStorage(identifiersToLoad: IStoreIdentifier[]): void {
        if (!identifiersToLoad.length) {
            return;
        }

        this._gateway.get(identifiersToLoad).subscribe(
            response => this._onLoadSuccess(identifiersToLoad, response.list),
            error => this._onLoadError(identifiersToLoad, error)
        );
    }

    private _getMappedResults(requestedKeys: string[]): Map<string, Observable<any>> {
        const result = new Map<string, Observable<any>>();

        requestedKeys.forEach(key => {
            const stored = this._cache.get(key);
            if (stored) {
                result.set(
                    key,
                    stored.subject.pipe(
                        filter(v => v !== undefined),
                        observeOn(asapScheduler)
                    )
                );
            }
        });

        return result;
    }

    private _onLoadSuccess(
        identifiersLoaded: IStoreIdentifier[],
        fetched: IStringLookup<any>
    ): void {
        identifiersLoaded.forEach(identifier => {
            let storedSubject = this._cache.get(identifier.key);

            if (!storedSubject) {
                storedSubject = {
                    binding: identifier.binding,
                    subject: new BehaviorSubject(fetched[identifier.key] || {})
                };
                this._cache.set(identifier.key, storedSubject);
            } else {
                storedSubject.subject.next(fetched[identifier.key] || {});
            }

            this._last.set(identifier.key, cloneDeep(storedSubject.subject.getValue()));
        });
    }

    private _onLoadError(identifiersLoaded: IStoreIdentifier[], error: any): void {
        identifiersLoaded.forEach(identifier => {
            const stored = this._cache.get(identifier.key);

            if (stored && !stored.subject.getValue().data) {
                stored.subject.error(error);
                this._cache.delete(identifier.key);
            }
        });
    }

    private _getFullKey(key: string = null): string {
        console.assert(key.indexOf("/") !== -1);

        return key;

        // TODO we should come up with solution that is URL independent
        // key = key || "default";

        // return key.charAt( 0 ) == "/"
        //     ? key
        //     : this._router.url.toLocaleLowerCase() + "/" + key;
    }
}
