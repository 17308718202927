import _ from "lodash";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from "@angular/core";
import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import { ViewportRuler } from "@angular/cdk/overlay";
import { ComponentPortal, TemplatePortal } from "@angular/cdk/portal";
import { fromEvent, Observable, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";

import { easingDefs } from "@logex/framework/utilities";

import { DialogType, IDialogOptions, OverridableDialogOptions } from "./lg-dialog.types";
import { LgPortalOutletDirective } from "../templating";

const MIN_DIALOG_TITLE_VISIBILITY = 0.5;

@Component({
    selector: "lg-dialog-holder",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            #dialogTitle
            class="lg-dialog__title {{ _dialogType }}"
            (mousedown)="_onMouseDown($event)"
        >
            <lg-icon
                [icon]="_options.icon"
                class="lg-dialog__title--icon"
                *ngIf="_options.icon"
                [inline]="true"
            ></lg-icon>
            <span class="lg-dialog__title--text" [lgLongText]="_options.title"></span>
            <div class="lg-dialog__title--template">
                <ng-container *ngTemplateOutlet="_options.dialogHeaderTemplate"></ng-container>
            </div>
            <a
                *ngIf="_options.helpUrl"
                class="lg-dialog__help-button"
                target="_blank"
                [href]="_options.helpUrl"
            >
                <lg-icon icon="icon-help"></lg-icon>
            </a>
            <div
                *ngIf="_options.allowMaximize"
                class="lg-dialog__maximize-button"
                (click)="_maximize()"
            >
                <lg-icon icon="icon-fullscreen-enter2"></lg-icon>
            </div>
            <div *ngIf="_options.allowClose" class="lg-dialog__close-button" (click)="_tryClose()">
                <lg-icon icon="icon-close"></lg-icon>
            </div>
        </div>
        <div class="template-loader" *ngIf="!_ready" [lgLoaderOverlay]="true"></div>
        <div
            [class.lg-dialog--with-buttons]="_options.dialogButtons"
            lgScrollable
            [lgScrollableDirection]="_options.scrollerType"
        >
            <div class="body {{ _options.dialogBodyClass }}" [hidden]="!_ready" #body>
                <ng-template #outlet="lgPortalOutlet" lgPortalOutlet></ng-template>
            </div>
        </div>
        <div class="lg-dialog__buttons-wrapper">
            <div class="lg-dialog__buttons" *ngIf="_options.dialogButtons">
                <lg-button
                    *ngFor="let button of _options.dialogButtons"
                    [textLc]="button.textLc"
                    [buttonClass]="button.class"
                    (click)="button.onClick()"
                    [isDisabled]="(button.isDisabled$ | async) ?? false"
                ></lg-button>
            </div>
        </div>
    `,

    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    animations: [
        trigger("state", [
            state("visible", style({ transform: "none", opacity: 1 })),
            state(
                "void",
                style({ transform: "scale(0.9)", opacity: 0, "transform-origin": "50% 50%" })
            ),
            state(
                "hidden",
                style({ transform: "scale(0.9)", opacity: 0, "transform-origin": "50% 50%" })
            ),
            transition("* => *", animate(`250ms ${easingDefs.easeOutCubic}`))
        ]),

        trigger("shift", [
            state("true,false", style({ left: "{{shift}}px" }), { params: { shift: 0 } }),
            transition("* => true, true => false", animate(`200ms ${easingDefs.easeOutCubic}`))
        ])
    ],

    host: {
        "[class]": "_dialogClass",
        "[style]": "_dialogStyle"
    }
})
export class LgDialogHolderComponent implements AfterViewInit, OnDestroy, OnInit {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _viewportRuler = inject(ViewportRuler);

    @ViewChild("body", { static: true })
    public _body: ElementRef;

    @ViewChild("outlet", { static: true }) _outlet: LgPortalOutletDirective;

    @ViewChild("dialogTitle", { static: true }) _dialogTitle: ElementRef;

    _requestClose: EventEmitter<void> = new EventEmitter();

    _dialogClass = "lg-dialog";
    _dialogStyle: { [style: string]: string } = {};
    _maximized = false;

    _options: IDialogOptions = {};
    _ready = false;

    private _relatedTo: LgDialogHolderComponent;
    private _relatedToWasPushed = false;
    private _firstReady = true;
    private _pushedTo: number | undefined;
    private _pushed: boolean | undefined;
    private _movedByUser = false;

    private _hidden = new Subject<void>();
    private _destroyed = new Subject<void>();

    public _dialogType: DialogType;

    @HostBinding("@state")
    public _visibility: "void" | "visible" | "hidden" = "void";

    @HostBinding("@shift")
    public get _shift(): any {
        return {
            value: this._pushed,
            params: {
                shift: this._pushedTo || 0
            }
        };
    }

    @HostListener("@state.done", ["$event"])
    public _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._hidden.next();
            this._hidden.complete();
        }
    }

    initialize(
        options: IDialogOptions,
        relatedTo: LgDialogHolderComponent,
        focusFirstTabbable: () => boolean
    ): void {
        this._setDialogType(options.type);
        this._updateDialogStyle(options);

        this._options = _.clone(options);
        this._relatedTo = relatedTo;

        if (this._options.ready) {
            this._options.ready.pipe(takeUntil(this._hidden)).subscribe(ready => {
                this._ready = ready;
                if (ready && this._firstReady) {
                    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
                        this._center(false);
                        focusFirstTabbable();
                    });
                    this._firstReady = false;
                }
                this._changeDetectorRef.markForCheck();
            });
        } else {
            this._ready = true;
        }

        this._visibility = "visible";
        // this._center( true );
        this._changeDetectorRef.markForCheck();
    }

    _onMouseDown(event: MouseEvent): boolean {
        const dialogTitleRect = this._dialogTitle.nativeElement.getBoundingClientRect();
        const leftMin = 0 - dialogTitleRect.width * MIN_DIALOG_TITLE_VISIBILITY;
        const leftMax = window.innerWidth - dialogTitleRect.width * MIN_DIALOG_TITLE_VISIBILITY;
        const topMin = 0 - dialogTitleRect.height * MIN_DIALOG_TITLE_VISIBILITY;
        const topMax = window.innerHeight - dialogTitleRect.height * MIN_DIALOG_TITLE_VISIBILITY;

        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        const refX = event.pageX - rect.left;
        const refY = event.pageY - rect.top;

        let dragging = true;

        this._ngZone.runOutsideAngular(() => {
            fromEvent<MouseEvent>(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed),
                    takeWhile(() => dragging)
                )
                .subscribe((moveEvent: MouseEvent) => {
                    const left = moveEvent.pageX - refX;
                    const top = moveEvent.pageY - refY;

                    if (left > leftMin && left < leftMax) {
                        this._renderer.setStyle(
                            this._elementRef.nativeElement,
                            "left",
                            left + "px"
                        );
                        this._movedByUser = true;
                    }

                    if (top > topMin && top < topMax) {
                        this._renderer.setStyle(this._elementRef.nativeElement, "top", top + "px");
                        this._movedByUser = true;
                    }

                    return false;
                });
        });

        fromEvent(document, "mouseup")
            .pipe(takeUntil(this._destroyed), take(1))
            .subscribe(() => {
                dragging = false;
                return false;
            });

        return false;
    }

    _tryClose(): void {
        this._requestClose.next();
    }

    ngOnInit(): void {
        this._center(true);
    }

    ngAfterViewInit(): void {
        this._center(true);
    }

    _center(force: boolean): boolean {
        const viewport = this._viewportRuler.getViewportRect();

        if (!force) {
            const bodyRect = this._body.nativeElement.getBoundingClientRect();
            if (!bodyRect.height) return false;
        }

        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        const w = rect.width;
        let h = rect.height;
        if (this._options.minHeight) {
            h = Math.max(this._options.minHeight, h);
        }

        let x = Math.max(0, (viewport.width - w) / 2);

        if (this._relatedTo && x > 128) {
            // arbitrary space limit to allow the pushing
            if (this._relatedToWasPushed || this._relatedTo._canBePushedLeft()) {
                const separation = 12; // between dialogs
                const margin = 76; // screen edte
                const relatedWidth = this._relatedTo._getWidth();
                const totalWidth = w + relatedWidth + separation;
                if (totalWidth < viewport.width - 2 * margin) {
                    // pushed relative to centre
                    const relatedLeft = (viewport.width - totalWidth) / 2;
                    this._relatedTo._pushLeft(relatedLeft);
                    x = relatedLeft + relatedWidth + separation;
                } else {
                    // pushed relative to edges
                    this._relatedTo._pushLeft(margin);
                    x = viewport.width - margin - w;
                }
                this._relatedToWasPushed = true;
            }
        }

        const y = Math.max(0, (viewport.height - h) / 2);
        this._renderer.setStyle(this._elementRef.nativeElement, "left", x + "px");
        const yOffset = y > 50 ? -35 : 0;
        this._renderer.setStyle(this._elementRef.nativeElement, "top", y + yOffset + "px");
        this._movedByUser = false;
        return true;
    }

    _maybeCenter(overridePosition: boolean): void {
        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        const fitOnScreen =
            rect.top > 0 &&
            rect.bottom < window.innerHeight &&
            rect.left > 0 &&
            rect.right < window.innerWidth;

        if (!fitOnScreen && (!this._movedByUser || overridePosition)) {
            this._center(true);
        }
    }

    _maximize(): void {
        this._maximized = !this._maximized;
        this._updateDialogClass();

        window.requestAnimationFrame(() => {
            this._center(true);
        });
    }

    hide(): Observable<void> {
        this._visibility = "hidden";
        if (this._relatedToWasPushed) {
            this._relatedTo._pushBack();
            this._relatedToWasPushed = false;
        }
        this._changeDetectorRef.markForCheck();

        return this._hidden.asObservable();
    }

    updateOptions(optionsOverride: OverridableDialogOptions) {
        this._options = _.extend({}, this._options, optionsOverride);
        if (optionsOverride.type) {
            this._setDialogType(optionsOverride.type);
        }
        if (optionsOverride.minHeight) {
            this._center(false);
        }
        this._updateDialogStyle(this._options);
    }

    _attachTemplate<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        return this._outlet.attachTemplatePortal(portal);
    }

    _attachComponent<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        return this._outlet.attachComponentPortal(portal);
    }

    private get _dialogSizeClass(): string {
        return this._maximized ? "lg-dialog--maximized" : "lg-dialog--regular";
    }

    private _dialogHeightClass(options: IDialogOptions): string {
        return options.dialogHeight ? "lg-dialog--fixed-height" : "";
    }

    private _getWidth(): number {
        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        return rect.width;
    }

    private _canBePushedLeft(): boolean {
        if (this._visibility !== "visible") return false;
        const viewport = this._viewportRuler.getViewportRect();
        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        const x = Math.max(0, (viewport.width - rect.width) / 2);

        // only if the current position is close to being centered
        return Math.abs(x - rect.left) < 50;
    }

    private _updateDialogClass(options?: IDialogOptions): void {
        options = options ?? this._options;
        this._dialogClass = [
            "lg-dialog",
            options.dialogClass,
            this._dialogSizeClass,
            this._dialogHeightClass(options)
        ]
            .filter(item => !!item)
            .join(" ");
    }

    private _updateDialogStyle(options?: IDialogOptions): void {
        options = options ?? this._options;
        this._dialogStyle = {};
        if (options.dialogHeight) {
            this._dialogStyle = { height: options.dialogHeight };
        }
        this._updateDialogClass(options);
    }

    private _pushLeft(x: number): void {
        this._pushedTo = x;
        this._pushed = true;
        this._changeDetectorRef.markForCheck();
    }

    private _pushBack(): void {
        if (this._visibility !== "visible") {
            return;
        }

        const viewport = this._viewportRuler.getViewportRect();
        const rect = this._elementRef.nativeElement.getBoundingClientRect();
        this._pushedTo = Math.max(0, (viewport.width - rect.width) / 2);
        this._pushed = false;
        this._changeDetectorRef.markForCheck();
    }

    private _setDialogType(dialogType: DialogType) {
        this._dialogType = dialogType;
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }
}
