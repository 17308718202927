import { Pipe, PipeTransform } from "@angular/core";
import { isString } from "lodash";

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "capitalize",
    pure: true
})
export class LgCapitalizePipe implements PipeTransform {
    public transform(value: string): string {
        return isString(value) && value.length !== 0
            ? value.substring(0, 1).toUpperCase() + value.substring(1)
            : value;
    }
}
