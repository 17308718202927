import { Component, inject, Injectable, TemplateRef } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { first } from "rxjs/operators";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    LgPromptDialog,
    LgDialogRef
} from "@logex/framework/ui-core";

export interface ICommentDialogOptions {
    parameters?: {
        target?: any;
        hasIcon?: boolean;
        [index: string]: any;
    };

    getComment?: () => string;

    save: (comment: string) => Observable<any>;

    finished?: (comment: string) => void;
}

/*
    * This directive implements the common comment dialog. It is activated by a trigger, similarly to regular dialogs. Usage example
    * <lg-comment-dialog no-edit="is_readonly || !setUnlocked" trigger="commentDialogTrigger">
    *     <div class="form-row"><label>Hoofdgroep:</label><div class="form-row__controls"><div class="text-frame">{{ledgerLevel1Definition[ledgerlevel1.ledgerlevel1_id].level1_omschrijving}}</div></div></div>
    *     <div class="form-row" ng-if="ledgerlevel2"><label>Kostensoort:</label><div class="form-row__controls"><div class="text-frame">{{ledgerLevel2Definition[ledgerlevel2.ledgerlevel2_id].level2_omschrijving}}</div></div></div>
    * </lg-comment-dialog>
    *
    * The directive itself has only 2 parameters - the trigger, and no-edit which specifies, whether the comment is editable, or read only. Note that the no-edit equation
    * is evaluated in the local scope (see below).
    * The content of the directive specifies the info-section, which is shown in top half of the dialog (above the input itself). It will be evaluated in the local scope (see below).
    *
    * When the dialog is created, a new local scope is created as a child of the main scope (where the directive exists) and filled by one of 2 properties (or both). The scope is
    * then used to evaluate the info section and to evaluate no-edit attribute.
    *
    * The trigger should be an object with up to 5 properties:
    * - parameters [optional]: object that will be used to populate the local scope
    * - prepare: function(localScope)  [optional]: function that can be used to populate the local scope. If both exists, this is called after using parameters
    * - getComment: function()  [optional]: function that must return the current comment to be edited. If the function is missing, it is assumed that the localScope was populated
    *      with object "target" with property "comment", so localScope.target.comment is used instead
    * - finished: function(comment) [optional]: function, which will be called to set the comment after succesfull save to the database. If missing, a direct assignment to
    *      localScope.target.comment is done instead (see getComment)
    * - save: function(comment) [required]: function, which should save the specified comment to the database. The function must either return a promise, or result of $resource().save().
    *      The promise should be also return of http request: we expect, that it will resolve to data containing "ok", as is usual for our REST api
    *
    *     $scope.showCommentDialog = function (ledgerlevel1, ledgerlevel2) {
        var target = ledgerlevel2 || ledgerlevel1;
        $scope.commentDialogTrigger = {
            prepare: function ($scope) {
                $scope.ledgerlevel1 = ledgerlevel1;
                $scope.ledgerlevel2 = ledgerlevel2;
                $scope.target = target;
            },
            save: function (comment) {
                var parameters = { comment: comment, set_id: $scope.settings.indexatie_set_id };
                if (ledgerlevel2) {
                    parameters.ledgerlevel2_id = ledgerlevel2.ledgerlevel2_id;
                } else {
                    parameters.ledgerlevel1_id = ledgerlevel1.ledgerlevel1_id;
                }
                return $resource('api/settings/indexatie/comment').save(parameters);
            }
        };
    }

    */

@Component({
    selector: "lg-comment-dialog",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="form-group" *ngIf="_template">
            <ng-container *ngTemplateOutlet="_template; context: _data">></ng-container>
        </div>
        <div class="form-group">
            <div class="form-row form-row--input">
                <label class="form-row__label">{{ ".Comment_label" | lgTranslate }}:</label>
                <div class="form-row__controls">
                    <textarea
                        placeholder="{{ '.Comment_placeholder' | lgTranslate }}"
                        [(ngModel)]="_comment"
                        [disabled]="_readonly"
                        name="comment"
                        [rows]="3"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="form-group form-group--buttons">
            <div class="form-row" *ngIf="!_readonly">
                <lg-button
                    *ngIf="!_isNew"
                    class="form-row--button-left"
                    buttonClass="button--error"
                    (click)="_remove()"
                    textLc=".Delete_button"
                ></lg-button>
                <lg-button
                    *ngIf="_comment?.length"
                    (click)="_save()"
                    buttonClass="button--primary"
                    textLc=".Save_button"
                ></lg-button>
                <lg-button buttonClass="" (click)="_cancel()" textLc=".Cancel_button"></lg-button>
            </div>
            <div class="form-row" *ngIf="_readonly">
                <lg-button
                    buttonClass=""
                    (click)="_cancel()"
                    textLc=".Close_button"
                    hotkey="esc"
                ></lg-button>
            </div>
        </div>
    `,
    viewProviders: [useTranslationNamespace("FW._Dialogs._EditComments")]
})
export class LgCommentDialogComponent implements IDialogComponent<LgCommentDialogComponent> {
    private _lgTranslate = inject(LgTranslateService);
    private _dialogRef = inject(LgDialogRef<LgCommentDialogComponent>);
    private _promptDialog = inject(LgPromptDialog).bindViewContainerRef();

    public _readonly = false;

    public _data!: {
        $implicit: { [index: string]: any; target?: any; hasIcon?: boolean } | undefined;
        readonly: boolean;
    };

    public _comment: string | undefined;

    public _isNew!: boolean;

    public _title!: string;

    public _icon!: string;

    public _dialogClass = "lg-dialog lg-dialog--3col";

    public _allowClose = true;

    public _allowMaximize = true;

    public _template!: TemplateRef<any>;

    private _done = new Subject<boolean>();

    private _options!: ICommentDialogOptions;

    public show(
        infoTemplate: TemplateRef<any>,
        readonly: boolean,
        options: ICommentDialogOptions
    ): Observable<boolean> {
        this._options = options;

        // get the current comment
        if (options.getComment) {
            this._comment = options.getComment();
        } else if (options.parameters && options.parameters.target) {
            this._comment = options.parameters.target.comment;
        } else {
            console.error("lg-comment-dialog: comment not specified ");
        }

        this._isNew = !this._comment;
        if (options.parameters && options.parameters.hasIcon) {
            this._icon = !readonly && !this._comment ? "icon-comment-add" : "icon-comment";
        } else {
            this._icon = "";
        }

        this._title = this._lgTranslate.translate("FW._Common.Comment");

        this._template = infoTemplate;
        this._readonly = readonly;

        this._data = {
            $implicit: options.parameters,
            readonly
        };

        return this._done.asObservable();
    }

    public _cancel(): void {
        this._done.next(false);
        this._done.complete();
        this._dialogRef.close();
    }

    public _save(): void {
        const comment: string = this._comment ?? "";

        this._options
            .save(comment)
            .pipe(first())
            .subscribe(
                () => {
                    if (this._options.finished) {
                        this._options.finished(comment);
                    } else if (this._options.parameters && this._options.parameters.target) {
                        this._options.parameters.target.comment = comment;
                    }
                    this._done.next(true);
                    this._done.complete();
                    this._dialogRef.close(true);
                },
                error => {
                    this._promptDialog.alert(
                        this._lgTranslate.translate(".Error_title"),
                        (error.data || {}).message ||
                            this._lgTranslate.translate(".Error_default_text")
                    );
                }
            );
    }

    public _remove(): void {
        this._promptDialog
            .confirmLc(".Confirm_delete_title", ".Confirm_delete_text")
            .then(choice => {
                if (choice === "ok") {
                    if (this._isNew) {
                        this._done.next(false);
                        this._done.complete();
                        this._dialogRef.close(false);
                    } else {
                        this._comment = "";
                        this._save();
                    }
                }
            });
    }
}

@Injectable({ providedIn: "root" })
export class LgCommentDialogFactory extends getDialogFactoryBase(
    LgCommentDialogComponent,
    "show"
) {}
