import { ITranslateFilterOptionCallback } from "@logex/framework/types";
import { ITranslateFilterOptionFactoryOptions } from "./combo-filter2-preprocessor";

export function translateFilterOptionFactory<T>(
    options: ITranslateFilterOptionFactoryOptions
): ITranslateFilterOptionCallback<T> {
    /*
    Combinations:
        - namesource from callback vs direct lookup
        - name with dash or without
        - sort source from property, name or id
    */
    let unknown = options.nameMissing || "Onbekend";
    unknown = unknown.replace(/"/g, `\\"`);
    let functionSource = `if ( !ids) return [];\n
        var lookup = `;
    if (typeof options.nameSource === "function") {
        functionSource += `nameSource();\n`;
    } else {
        functionSource += `nameSource;\n`;
    }

    let nameCode = `definition ? definition["${options.nameProperty}"] : "${unknown}"`;
    if (options.nameDash) {
        nameCode = `entry + " - " + (${nameCode})`;
    }

    functionSource += `var i = 0, l = ids.length, result = [], entry, definition, name;\n
        for ( ; i<l; ++i ) {
            entry = ids[i];\n
            definition = lookup[entry];\n
            name = ${nameCode};\n
            result.push({\n
            id: entry,\n
            name: name,\n`;

    if (!options.sortProperty) {
        functionSource += `order: name\n`;
    } else if (options.sortProperty === "=") {
        functionSource += `order: entry\n`;
    } else {
        functionSource += `order: definition ? definition["${options.sortProperty}"] : ""\n`;
    }

    functionSource += `})\n;
    }\n
    return _.sortBy(result, "order");\n`;

    // eslint-disable-next-line no-new-func
    const translation = new Function("nameSource", "ids", functionSource);
    return (ids: T[]) => {
        return translation(options.nameSource, ids);
    };
}
