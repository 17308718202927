import _ from "lodash";
import {
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import {
    IQuickSettingsIconDefinition,
    IQuickSettingsMenuItem,
    IQuickSettingsMenuRegularItem,
    QuickSettingsMenuItemId
} from "./lg-quick-settings-menu.types";
import { LgQuickSettingsMenuHost } from "./lg-quick-settings-menu-host";
import { LgQuickSettingsMenuPopupComponent } from "./lg-quick-settings-menu-popup.component";
import { LgButtonComponent } from "../lg-button.component";

@Component({
    standalone: true,
    selector: "lg-quick-settings-menu-button",
    template: `
        <lg-button
            [isDisabled]="disabled"
            [text]="text"
            [icon]="icon"
            buttonClass="button {{ buttonClass ?? '' }}"
            (click)="_showMenu($event)"
        ></lg-button>
    `,
    host: {
        class: "lg-quick-settings-menu-button",
        "[class.lg-quick-settings-menu-button--active]": "_popupActive"
    },
    imports: [LgButtonComponent]
})
export class LgQuickSettingsMenuButtonComponent
    extends LgQuickSettingsMenuHost
    implements OnChanges, OnDestroy
{
    private _elementRef = inject(ElementRef);

    /**
     * Button icon.
     */
    @Input() icon?: string;

    /**
     * Definition of menu items (required).
     */
    @Input({ required: true }) definition!: IQuickSettingsMenuItem[];

    @Input() value: any = null;

    /**
     * Button label.
     */
    @Input() text?: string;

    @Input("class") customClassName?: string;

    /**
     * Apply css class to the button element.
     *
     * @default "button--condensed"
     */
    @Input() buttonClass = "button--condensed";

    /**
     * Currently works only in combination with `rightIcons` from `IQuickSettingsMenuChoice`,
     */
    @Input() iconDefinitions: IQuickSettingsIconDefinition[] = [];

    /**
     *  Used for highlighting 'active' rows similarly to `lg-breadcrumb`
     *  But this component leaves responsibility to say which items are active on the consumer
     */
    @Input() selectedItems?: IQuickSettingsMenuRegularItem[] | QuickSettingsMenuItemId[];

    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value, false);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Specifies if menu option items should be compacted.
     *
     * @default false
     */
    @Input() set compact(value: boolean | "true" | "false") {
        this._compact = toBoolean(value, false);
    }

    get compact(): boolean {
        return this._compact;
    }

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly change = new EventEmitter<any>();

    _disabled = false;
    private _compact: boolean | null = null;

    private _alwaysLeftIcon = false;

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition && this._popupInstance) {
            this._popupInstance.updateDefinition(this.definition, this.selectedItems);
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showMenu($event: MouseEvent): void {
        if (this._popupActive || this._disabled) return;

        $event.stopPropagation();

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]);

        super
            ._showSettingsPopup(this._elementRef, strategy, true, {
                definition: this.definition,
                value: _.cloneDeep(this.value),
                depth: 0,
                compact: this._compact,
                alwaysLeftIcon: this._alwaysLeftIcon,
                iconDefinitions: this.iconDefinitions,
                selectedItems: this.selectedItems
            })
            .subscribe(
                result => {
                    this._close();
                    if (!_.isEqual(this.value, result)) {
                        this.value = result;
                        this.change.next(result);
                    }
                },
                null,
                () => this._close()
            );
    }

    private _close(): void {
        this._hideSettingsPopup();
    }
}
