import { Injectable } from "@angular/core";
import { LgSlideoutVariant } from "../lg-slideout";
import { FiltersPanelViewMode } from "../lg-filterset";
import { ILgFwUiStateService, LgFwUiState, LgFwUiStateSidebarButton } from "./lg-fw-ui-state.types";

const LOCAL_STORAGE_KEY = "/fwUiState";

@Injectable({ providedIn: "root" })
export class LgLocalStorageFwUiStateService implements ILgFwUiStateService {
    private _state: LgFwUiState;

    constructor() {
        this.initialize();
    }

    initialize(): void {
        const state = window.localStorage.getItem(LOCAL_STORAGE_KEY);
        if (state) {
            this._state = JSON.parse(state);
        } else {
            this._state = new LgFwUiState();
        }
    }

    setSidebarPinned(variant: LgSlideoutVariant, pinned: boolean): void {
        if (variant === "right") {
            this.setRightSidebarPinned(pinned);
        } else {
            this.setLeftSidebarPinned(pinned);
        }
    }

    getSidebarPinned(variant: LgSlideoutVariant): Promise<boolean> {
        if (variant === "right") {
            return this.getRightSidebarPinned();
        } else {
            return this.getLeftSidebarPinned();
        }
    }

    getSidebarPinnedPanelId(variant: LgSlideoutVariant): Promise<string> {
        if (variant === "right") {
            return Promise.resolve(this._state.rightSidebarPinnedPanelId);
        } else {
            return Promise.resolve(this._state.leftSidebarPinnedPanelId);
        }
    }

    setSidebarPinnedPanelId(variant: LgSlideoutVariant, panelId: string): void {
        if (variant === "right") {
            this._state.rightSidebarPinnedPanelId = panelId;
        } else {
            this._state.leftSidebarPinnedPanelId = panelId;
        }
        return this._saveState();
    }

    setLeftSidebarPinned(pinned: boolean): void {
        this._state.leftSidebarPinned = pinned;
        this._saveState();
    }

    getLeftSidebarPinned(): Promise<boolean> {
        return Promise.resolve(this._state.leftSidebarPinned);
    }

    setRightSidebarPinned(pinned: boolean): void {
        this._state.rightSidebarPinned = pinned;
        this._saveState();
    }

    getRightSidebarPinned(): Promise<boolean> {
        return Promise.resolve(this._state.rightSidebarPinned);
    }

    setSidebarButton(widget: LgFwUiStateSidebarButton): void {
        this._state.sidebarWidget = widget;
        this._saveState();
    }

    getSidebarButton(): Promise<LgFwUiStateSidebarButton> {
        return Promise.resolve(this._state.sidebarWidget);
    }

    setFilterPanelMode(mode: FiltersPanelViewMode): void {
        this._state.filtersPanelViewMode = mode;
        this._saveState();
    }

    getFilterPanelMode(): Promise<FiltersPanelViewMode | undefined> {
        return Promise.resolve(this._state.filtersPanelViewMode);
    }

    private _saveState(): void {
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this._state));
    }
}
