import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { ExportDefinition } from "./lg-sidebar-export.types";

@Injectable({ providedIn: "root" })
export class LgSidebarExportsService {
    private _pageExports: ExportDefinition[];
    private _subComponentExports: ExportDefinition[];
    private _exports$: BehaviorSubject<ExportDefinition[]>;

    get exports$(): Observable<ExportDefinition[]> {
        return this._exports$.asObservable();
    }

    constructor() {
        this._pageExports = [];
        this._subComponentExports = [];
        this._exports$ = new BehaviorSubject([]);
    }

    setPageExportsAndFlushTheRest(exportDefs: ExportDefinition[]): void {
        this._pageExports = exportDefs;
        this._subComponentExports = [];

        this._notifySubscribers();
    }

    setPageExports(exports: ExportDefinition[]): void {
        this._pageExports = exports;

        this._notifySubscribers();
    }

    addSubComponentExport(exportDef: ExportDefinition): void {
        this._subComponentExports.push(exportDef);

        this._notifySubscribers();
    }

    removeSubComponentExport(id: string): void {
        this._subComponentExports = this._subComponentExports.filter(x => x.id !== id);

        this._notifySubscribers();
    }

    private _notifySubscribers(): void {
        this._exports$.next([...this._pageExports, ...this._subComponentExports]);
    }
}
