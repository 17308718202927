import { Component, inject } from "@angular/core";
import { ClientInfo, LG_APP_CONTROL, LG_APP_SESSION } from "@logex/framework/lg-application";

// ----------------------------------------------------------------------------------
//
@Component({
    selector: "lg-sidebar-merged-hospital-switch",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <button
            *ngIf="_appControlService.canSwitchClient | async"
            [lgTooltip]="tooltip"
            [lgTooltipOnClick]="true"
            [lgTooltipDelay]="10000"
            lgTooltipClass="lg-tooltip lg-tooltip-menu"
        >
            Selecteer locatie
        </button>

        <ng-template #tooltip>
            <a
                *ngFor="let client of _clientsPromise | async"
                class="lg-tooltip-menu__item"
                [class.lg-tooltip-menu__item--active]="client.id === this._session.clientId"
                (click)="_switchClient(client.id)"
                href="javascript:false"
            >
                {{ client.name }}
            </a>
        </ng-template>
    `
})
export class LgSidebarMergedHospitalSwitchComponent {
    _appControlService = inject(LG_APP_CONTROL);
    _session = inject(LG_APP_SESSION);

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        this._clientsPromise = this._appControlService.getClients();
    }

    // ----------------------------------------------------------------------------------
    // Fields
    _clientsPromise: Promise<ClientInfo[]>;

    // ----------------------------------------------------------------------------------

    // async _click(): Promise<void> {
    //     if (!this._tooltip) {
    //         this._clients = await this._appControlService.getClients();
    //         this._generateTooltip(clients);
    //     }
    //
    //     this._tooltip.show();
    // }
    //
    // private _generateTooltip(clients: ClientInfo[]) {
    //     this._tooltip = this._tooltipService.create({
    //         content: clients.map( x => `
    //             <a class="lg-tooltip-menu__item ${
    //                     x.code === this._session.clientCode
    //                         ? "lg-tooltip-menu__item--active"
    //                         : ""
    //                 }"
    //                 (click)="_switchClient(x.code)"
    //             >
    //                 ${x.name}
    //             </a>` )
    //             .join(""),
    //         position: "bottom-right",
    //         stay: true,
    //         distance: 4,
    //         target: this._elementRef,
    //         tooltipClass: "lg-tooltip lg-tooltip-menu",
    //     });
    // }

    public _switchClient(id: number): void {
        this._appControlService.switchClient(id);
    }
}
