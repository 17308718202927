// todo: consider redoing the refresh mechanism (i.e. do not trigger bookmark reload by calling getBookmarks )
import { Component, Input, OnChanges, OnDestroy, inject } from "@angular/core";
import { switchMap, takeUntil } from "rxjs/operators";
import { combineLatest, ReplaySubject, Subject } from "rxjs";

import {
    QuickSettingsMenuType,
    IQuickSettingsMenuChoice,
    IQuickSettingsIconDefinition,
    QuickSettingsMenuItemId,
    IQuickSettingsSubmenu,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgSimpleChanges } from "@logex/framework/types";

import { FilterSetState } from "../services/lg-filterset.types";
import { LgBookmarkModel } from "../services/lg-bookmark-model";
import { LgBookmarksStateService } from "../services/lg-bookmarks-state.service";
import { ILgBookmarkGroup } from "../services/lg-bookmarks-state.types";

interface LgBookmarksMenuTopPartDefinition {
    type: QuickSettingsMenuType.Group;
    children: Array<IQuickSettingsMenuChoice | IQuickSettingsSubmenu>;
}

interface LgBookmarksMenuBottomPartDefinition {
    type: QuickSettingsMenuType.Group;
    name: string;
    children: IQuickSettingsMenuChoice[];
}

type LgBookmarksMenuDefinition = Array<
    LgBookmarksMenuTopPartDefinition | LgBookmarksMenuBottomPartDefinition
>;

interface MenuItemContext {
    storeId: number;
    state: FilterSetState;
}

@Component({
    selector: "lg-bookmarks-menu",
    templateUrl: "./lg-bookmarks-menu.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._FiltersetSlideout._BookmarksMenu")],
    host: {
        class: "lg-bookmarks-menu"
    }
})
export class LgBookmarksMenuComponent implements OnChanges, OnDestroy {
    private _bookmarkState = inject(LgBookmarksStateService);
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    /**
     * Filter set definition (required).
     */
    @Input({ required: true }) filterSet!: LgFilterSet;

    /**
     * Filters state key (required).
     */
    @Input({ required: true }) filterSetStateKey!: string;

    _icon: "icon-bookmark" | "icon-bookmark-updated" = "icon-bookmark";
    _iconDefinitions: IQuickSettingsIconDefinition[];
    _definition: LgBookmarksMenuDefinition;
    _selectedMenuItem: QuickSettingsMenuItemId;
    _currentBookmark: LgBookmarkModel;
    _bookmarks: ILgBookmarkGroup[] = [];

    private _ignoreFilterOnChangesNow: any;
    private readonly _filterSet$ = new Subject<LgFilterSet>();
    private readonly _filterSetStateKey$ = new Subject<string>();
    private readonly _destroyed$ = new ReplaySubject<void>(1);

    constructor() {
        this._setIconDefinitions();
        this._subscribeToCurrentBookmark();
        this._subscribeToStoreChanges();
        this._markCurrentApiAsModifiedWhenFilterSetChanges();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    ngOnChanges(changes: LgSimpleChanges<LgBookmarksMenuComponent>): void {
        if (changes.filterSet) {
            this._filterSet$.next(this.filterSet);
        }
        if (changes.filterSetStateKey) {
            this._filterSetStateKey$.next(this.filterSetStateKey);
        }
    }

    private _subscribeToCurrentBookmark(): void {
        const currentBookmark$ = this._filterSetStateKey$.pipe(
            switchMap(key => this._bookmarkState.getCurrentBookmark(key))
        );

        // Update current bookmark
        combineLatest([this._filterSet$, currentBookmark$])
            .pipe(takeUntil(this._destroyed$))
            .subscribe(([filterSet, bookmark]) => {
                this._currentBookmark = bookmark;
                this._icon = "icon-bookmark";
                this._selectedMenuItem = this._currentBookmark.isExisting
                    ? this._getBookmarkMenuId(this._currentBookmark)
                    : null;
                if (filterSet.isAnyActive()) {
                    // note: need to give the subscription below first, so we do it async
                    Promise.resolve().then(() =>
                        bookmark.filtersWereChanged(this.filterSet.serialize())
                    );
                }
            });

        // Update modified icon
        currentBookmark$
            .pipe(
                switchMap(bookmark => bookmark.changed$),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                this._updateMenuDefinition();
                const showAsModified = this._currentBookmark.isExisting
                    ? this._currentBookmark.wasModified
                    : this.filterSet.isAnyActive();
                this._icon = showAsModified ? "icon-bookmark-updated" : "icon-bookmark";
            });
    }

    private _subscribeToStoreChanges(): void {
        this._filterSetStateKey$
            .pipe(
                switchMap(key => this._bookmarkState.getBookmarks(key)),
                takeUntil(this._destroyed$)
            )
            .subscribe(groups => {
                this._bookmarks = groups;

                if (
                    this._currentBookmark &&
                    this._currentBookmark.isExisting &&
                    !groups.some(
                        group =>
                            group.storeId === this._currentBookmark.storeId &&
                            group.bookmarks.some(x => x.stateId === this._currentBookmark.id)
                    )
                ) {
                    this._bookmarkState.selectNewBookmark(this.filterSetStateKey);
                }

                this._updateMenuDefinition();
            });
    }

    private _markCurrentApiAsModifiedWhenFilterSetChanges(): void {
        this._filterSet$
            .pipe(
                switchMap(set => set.onChanged),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                if (this._ignoreFilterOnChangesNow) return;

                if (!this.filterSet.isAnyActive()) {
                    this._bookmarkState.selectNewBookmark(this.filterSetStateKey);
                }

                this._currentBookmark.filtersWereChanged(this.filterSet.serialize());
            });
    }

    private async _onEditIconClicked(storeId: number, state: FilterSetState): Promise<void> {
        try {
            await this._bookmarkState.startEdit(this.filterSetStateKey, storeId, state.stateId!);
        } catch (e) {
            this._showSavingFailedDialog(state.name);
            throw e;
        }
    }

    private _onExistingBookmarkRowClicked(
        group: ILgBookmarkGroup,
        state: FilterSetState,
        id: string
    ): void {
        this._selectedMenuItem = id;
        const revertToSaved =
            group.storeId === this._currentBookmark.storeId &&
            state.stateId === this._currentBookmark.id;
        if (revertToSaved && !this._currentBookmark.wasModified) {
            return;
        }
        let newState: FilterSetState;
        if (revertToSaved) {
            this._currentBookmark.reset();
            newState = this._currentBookmark.getState();
        } else {
            newState = state;
        }
        this._ignoreFilterOnChangesNow = true;
        this.filterSet.deserialize(newState.parts, revertToSaved || newState.overwrite);
        this._ignoreFilterOnChangesNow = false;
        if (!revertToSaved) {
            this._bookmarkState.selectBookmark(
                this.filterSetStateKey,
                group.storeId,
                state.stateId!
            );
        }
        this._updateMenuDefinition();
    }

    private _updateMenuDefinition(): void {
        const def: LgBookmarksMenuDefinition = [];

        const topPartItems = this._getTopPartMenuItems();
        if (topPartItems.length) {
            def.push({
                type: QuickSettingsMenuType.Group,
                children: topPartItems
            });
        }

        this._bookmarks
            .filter(group => group.bookmarks.length || group.emptyGroupText !== "")
            .forEach(group => {
                def.push({
                    type: QuickSettingsMenuType.Group,
                    name: group.name,
                    children: this._getBottomPartMenuItems(group)
                });
            });

        this._definition = def;
    }

    private _getTopPartMenuItems(): Array<IQuickSettingsMenuChoice | IQuickSettingsSubmenu> {
        const result: Array<IQuickSettingsMenuChoice | IQuickSettingsSubmenu> = [];
        if (
            this._currentBookmark.isExisting &&
            this._currentBookmark.wasModified &&
            this._currentBookmark.isOwn &&
            !this._currentBookmark.isReadonly
        ) {
            result.push({
                name: this._lgTranslate.translate(".Update_bookmark"),
                type: QuickSettingsMenuType.Choice,
                onClick: () => this._openUpdateExistingDialog()
            });
        }

        if (this._currentBookmark.isExisting) {
            const entry = this._getSaveMenuItem(".Save_as_new");
            if (entry) result.push(entry);
        } else if (this._currentBookmark.wasModified) {
            const entry = this._getSaveMenuItem(".Save");
            if (entry) result.push(entry);
        }
        return result;
    }

    private _getSaveMenuItem(
        nameLc: string
    ): IQuickSettingsMenuChoice | IQuickSettingsSubmenu | undefined {
        const editable = this._bookmarks.filter(group => !group.readOnly);
        if (editable.length === 0) return undefined;
        const exclusive = editable.filter(group => group.exclusive);
        if (exclusive.length > 0 || editable.length === 1) {
            const storeId = exclusive.length ? exclusive[0].storeId : editable[0].storeId;
            return {
                name: this._lgTranslate.translate(nameLc),
                type: QuickSettingsMenuType.Choice,
                onClick: () => this._showSaveAsNewDialog(storeId)
            } satisfies IQuickSettingsMenuChoice;
        } else {
            return {
                name: this._lgTranslate.translate(nameLc),
                type: QuickSettingsMenuType.Submenu,
                children: editable.map(
                    group =>
                        ({
                            type: QuickSettingsMenuType.Choice,
                            name: group.name,
                            onClick: () => this._showSaveAsNewDialog(group.storeId)
                        } satisfies IQuickSettingsMenuChoice)
                )
            };
        }
    }

    private _getBottomPartMenuItems(group: ILgBookmarkGroup): IQuickSettingsMenuChoice[] {
        if (!group.bookmarks.length) {
            return [
                {
                    name: group.emptyGroupText,
                    type: QuickSettingsMenuType.Choice,
                    onClick: () => {
                        // empty
                    }
                }
            ];
        }

        return group.bookmarks
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(state => {
                const modified =
                    this._currentBookmark.id === state.stateId && this._currentBookmark.wasModified;
                const id = this._getBookmarkMenuId(group.storeId, state);
                return {
                    id,
                    name: `${state.name}${modified ? " *" : ""}`,
                    type: QuickSettingsMenuType.Choice,
                    rightIcons: state.isOwn && !group.readOnly ? ["icon-edit", "icon-delete"] : [],
                    onClick: () => this._onExistingBookmarkRowClicked(group, state, id),
                    context: { storeId: group.storeId, state } satisfies MenuItemContext
                } satisfies IQuickSettingsMenuChoice;
            });
    }

    private _setIconDefinitions(): void {
        this._iconDefinitions = [
            this._getIconDefinition("icon-delete", ".Icon_delete_title", (storeId, state) => {
                this._showDeleteConfirmationDialog(storeId, state);
            }),
            this._getIconDefinition("icon-edit", ".Icon_edit_title", (storeId, state) => {
                this._onEditIconClicked(storeId, state);
            })
        ];
    }

    private async _showSaveAsNewDialog(storeId: number): Promise<void> {
        try {
            await this._bookmarkState.startSaveAs(
                this.filterSetStateKey,
                storeId,
                this.filterSet.serialize()
            );
        } catch (e: any) {
            this._bookmarkState.selectNewBookmark(this.filterSetStateKey);
            let name: string | undefined = e.bookmarkName;
            if (name == null)
                name = this._lgTranslate.translate("FW._Directives._FiltersetSlideout.New_filter");
            this._showSavingFailedDialog(name);
            console.error(e);
        }
    }

    private _openUpdateExistingDialog(): void {
        this._promptDialog
            .warning(
                this._lgTranslate.translate(this._dialogNamespace("Update_title")),
                this._lgTranslate.translate(this._dialogNamespace("Update_text"), {
                    name: `<strong>${this._currentBookmark.name}</strong>`
                }),
                {
                    allowClose: true,
                    buttons: [
                        {
                            id: "confirm",
                            name: this._lgTranslate.translate(
                                this._dialogNamespace("Update_confirm_button")
                            ),
                            class: "button--primary",
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate(
                                this._dialogNamespace("Update_cancel_button")
                            ),
                            isCancelAction: true,
                            isReject: true
                        }
                    ]
                }
            )
            .then(() =>
                this._bookmarkState.updateFilters(
                    this.filterSetStateKey,
                    this._currentBookmark.storeId,
                    this._currentBookmark.id,
                    this.filterSet.serialize()
                )
            )
            .catch(e => {
                if (e !== 2) {
                    console.error(e);
                    this._showSavingFailedDialog(this._currentBookmark.name);
                }
            });
    }

    private _showDeleteConfirmationDialog(storeId: number, state: FilterSetState): void {
        this._promptDialog
            .alert(
                this._lgTranslate.translate(this._dialogNamespace("Delete_title")),
                this._lgTranslate.translate(this._dialogNamespace("Delete_text"), {
                    name: `<strong>${state.name}</strong>`
                }),
                {
                    allowClose: true,
                    buttons: [
                        {
                            id: "delete",
                            name: this._lgTranslate.translate(
                                this._dialogNamespace("Delete_confirmButton")
                            ),
                            class: "button--primary",
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate(
                                this._dialogNamespace("Delete_cancelButton")
                            ),
                            isCancelAction: true
                        }
                    ]
                }
            )
            .then(choice => {
                if (choice === "delete") {
                    return this._bookmarkState.delete(
                        this.filterSetStateKey,
                        storeId,
                        state.stateId
                    );
                } else {
                    return Promise.resolve();
                }
            })
            .catch(e => {
                console.error(e);
            });
    }

    private _showSavingFailedDialog(name: string): void {
        this._promptDialog.alert(
            this._lgTranslate.translate(this._dialogNamespace("Saving_failed_title")),
            this._lgTranslate.translate(this._dialogNamespace("Saving_failed_text"), {
                name: `<strong>${name}</strong>`
            }),
            {
                allowClose: true,
                buttons: [
                    {
                        id: "delete",
                        name: this._lgTranslate.translate(
                            this._dialogNamespace("Saving_failed_confirm_button")
                        ),
                        isConfirmAction: true
                    }
                ]
            }
        );
    }

    private _getIconDefinition(
        icon: string,
        titleLc: string,
        cb: (storeId: number, state: FilterSetState) => void
    ): IQuickSettingsIconDefinition {
        return {
            icon,
            title: this._lgTranslate.translate(titleLc),
            clickable: true,
            onClick: (event, _, item: MenuItemContext) => {
                event.stopPropagation();
                cb(item.storeId, item.state);
            }
        };
    }

    private _dialogNamespace(n: string): string {
        return `FW._Directives._FiltersetSlideout._StateDialogs.${n}`;
    }

    private _getBookmarkMenuId(bookmark: LgBookmarkModel): string;
    private _getBookmarkMenuId(storeId: number, state: FilterSetState): string;
    private _getBookmarkMenuId(param0: number | LgBookmarkModel, state?: FilterSetState): string {
        if (typeof param0 === "number") {
            return `${param0}:${state.stateId}`;
        } else {
            return `${param0.storeId}:${param0.id}`;
        }
    }
}
