import {
    AfterContentInit,
    Component,
    ContentChildren,
    QueryList,
    OnDestroy,
    inject
} from "@angular/core";

import { LgExportEntryComponent } from "./lg-export-entry.component";
import { LgSidebarExportsService } from "./lg-sidebar-exports.service";

@Component({
    selector: "lg-export-definitions",
    template: ""
})
export class LgExportDefinitionsComponent implements AfterContentInit, OnDestroy {
    private _exportService = inject(LgSidebarExportsService);

    @ContentChildren(LgExportEntryComponent) exportEntries: QueryList<LgExportEntryComponent>;

    ngAfterContentInit(): void {
        this._exportService.setPageExportsAndFlushTheRest(
            this.exportEntries.map(x => x.getDefinition())
        );

        this.exportEntries.changes.subscribe(x =>
            this._exportService.setPageExports(
                (x._results as QueryList<LgExportEntryComponent>).map(e => e.getDefinition())
            )
        );
    }

    ngOnDestroy(): void {
        this._exportService.setPageExportsAndFlushTheRest([]);
    }
}
