import { InjectionToken } from "@angular/core";

export const LG_MATOMO_CONFIGURATION = new InjectionToken<
    IMatomoConfiguration | IMatomoPostponeConfiguration
>("lgMatomoConfiguration");

export interface IMatomoPostponeConfiguration {
    autoInit: false;
}

export const LG_POSTPONE_MATOMO_CONFIGURATION: IMatomoPostponeConfiguration = { autoInit: false };

export interface IMatomoConfiguration {
    /**
     * URL of the matomo tracer (see MatomoStatsUrl)
     */
    statUrl: string;

    /**
     * SiteID, as registered in Matomo UI
     */
    siteId: number;

    /**
     * IDs for commonly used (logex / mrdm) dimensions
     */
    commonDimensionIds: IMatomoCommonDimensionIds;

    /**
     * Definitions of custom dimensions that should be automatically registered
     */
    customDimensions?: IMatomoDimension[] | ICustomDimensionCallback;

    /**
     * Name of the current registry (MRDM). Used only if commonDImensionIds includes registry
     */
    registry?: string;

    /**
     * Specify, whether Matomo should be auto-initialized on first page change. When false,
     * you'll have to call its init method yourself.
     *
     * @see LG_POSTPONE_MATOMO_CONFIGURATION
     */
    autoInit: boolean;

    /**
     * Specify, whether Matomo should automatically send in page change events. This can be
     * used even when autoInit is false
     */
    autoTrack: boolean;

    /**
     * Specify, whether Matomo should use userName instead of userId.
     * Default set to false.
     */
    useUserName?: boolean;

    /**
     * Callback determining, if matomo should be disabled. This can serve f.ex to disable it when running
     * on localhost
     *
     * @return  {boolean} [return description]
     */
    doNotDoMatTracking(): boolean;
}

export interface IMatomoCommonDimensionIds {
    organizationLogexDataCode?: number;
    applicationId?: number;
    applicationInstanceName?: number;
    versionNumber?: number;
    registry?: number;
}

export interface IMatomoDimension {
    id: number;
    name?: string;
    value: number | string;
}

export type ICustomDimensionCallback = () => IMatomoDimension[];

export enum MatomoStatsUrl {
    MRDM = "https://stats.mrdm.eu/",
    Logex = "https://stats.logex.com/"
}
