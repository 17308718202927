<div class="flex-stretch flexcol lg-sidebar-panel-scrollable lg-sidebar-panel-scrollable--full">
    <div
        [lgScrollable]
        [lgScrollableWrapper]="false"
        lgScrollbarClass="lg-scrollbar lg-scrollbar--sidebar"
        class="flex-stretch"
    >
        <ul class="lg-sidebar-menu">
            <div *ngIf="_languageSwitchVisible" class="lg-sidebar-menu__language-switch">
                <div class="lg-sidebar-menu__language-switch__header">
                    {{ "FW._Language_switch.Language" | lgTranslate }}
                </div>
                <lg-language-switch></lg-language-switch>
            </div>
            <lg-sidebar-menu-item
                *ngFor="let navNode of _navigation"
                [currentUrl]="_currentUrl"
                [navNode]="navNode"
                (navigate)="_navigate($event)"
            ></lg-sidebar-menu-item>
        </ul>
    </div>
</div>
