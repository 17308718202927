import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    Injector,
    inject
} from "@angular/core";
import { CdkPortalOutlet, ComponentPortal } from "@angular/cdk/portal";

import type { IFilterDefinition } from "../filter-definition";
import type { LgFilterSet } from "../lg-filterset";

@Component({
    selector: "lg-filterset-list-item",
    template: ` <ng-template [cdkPortalOutlet] #outlet></ng-template> `,
    host: {
        class: "lg-filterset-list__item"
    }
})
export class LgFiltersetListItemComponent implements OnChanges {
    private _injector = inject(Injector);

    /**
     * Definition of filter (required).
     */
    @Input({ required: true }) definition!: IFilterDefinition;

    /**
     * List of all filters (required).
     */
    @Input({ alias: "list", required: true }) filterSet!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    @ViewChild("outlet", { read: CdkPortalOutlet, static: true }) _outlet: CdkPortalOutlet;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition) {
            if (this._outlet.attached) {
                this._outlet.detach();
            }
            if (this.definition && this.definition.renderer) {
                const portal = new ComponentPortal(
                    this.definition.renderer.getFilterLineComponent(),
                    null,
                    this._injector
                );
                const instance = this._outlet.attachComponentPortal(portal).instance;

                instance._initialize(
                    this.definition,
                    this.filterSet.filters,
                    false,
                    () => this._change(),
                    () => undefined
                );
            }
        }
    }

    private _change(): void {
        this.filterChange.next();
    }
}
