import { MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { inject, Injectable } from "@angular/core";
import { LG_BASE_TRANSLATIONS } from "./lg-translate-startup.service";

@Injectable()
export class LgMissingTranslationHandler implements MissingTranslationHandler {
    translations = inject(LG_BASE_TRANSLATIONS) ?? {};

    handle(params: MissingTranslationHandlerParams): string {
        const { key, translateService, interpolateParams } = params;
        return translateService.parser.interpolate(
            translateService.parser.getValue(this.translations, key),
            interpolateParams
        );
    }
}
