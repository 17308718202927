import _ from "lodash";
import { ServerDefinitionsBase } from "../base";
import { StringKeyOf } from "@logex/framework/types";
import {
    DefinitionSectionType,
    ILgDefinitionsHierarchyService
} from "./definitions-hierarchy.types";

type PrependTuple<P, T extends any[]> = ((a: P, ...t: T) => void) extends (...u: infer U) => void
    ? U
    : never;

type AncestorsMapEntry = [level: number, definition: string, field: string];
type AncestorResolutionPathStep = [level: number, definition: string, from: string, field: string];
interface ConfigEntry {
    definition: string;
    refersTo?: Array<{ field: string; definition: string }>;
    referredBy?: string[];
    ancestors?: AncestorsMapEntry[];
    ancestorPaths?: _.Dictionary<AncestorResolutionPathStep[]>;
    descendants?: Array<[number, string]>; // level, definition
}

export type MappingConfiguration<TDefinitions> = {
    [S in StringKeyOf<TDefinitions>]?: {
        [P in keyof DefinitionSectionType<TDefinitions, S>]?: StringKeyOf<TDefinitions>;
    };
};

export class LgDefinitionsHierarchyService<TDefinitions>
    implements ILgDefinitionsHierarchyService<TDefinitions>
{
    constructor(
        private _definitions: ServerDefinitionsBase<TDefinitions>,
        mapping?: MappingConfiguration<TDefinitions>
    ) {
        if (mapping != null) {
            this.configure(mapping);
        }
    }

    // ----------------------------------------------------------------------------------
    private _config: ConfigEntry[];
    private _lookup: _.Dictionary<ConfigEntry>;

    // ----------------------------------------------------------------------------------
    configure(mapping: MappingConfiguration<TDefinitions>): void {
        const config: ConfigEntry[] = _.map(
            mapping,
            (refs: _.Dictionary<StringKeyOf<TDefinitions>>, def) =>
                ({
                    definition: def,
                    refersTo: _.map(refs, (ref, field) => ({ field, definition: ref as string })),
                    referredBy: []
                } as ConfigEntry)
        );

        const references = _(config)
            .map(from => _.map(from.refersTo, to => [from.definition, to.definition]))
            .flatten()
            .groupBy(x => x[1])
            .mapValues(x => _.map(x, y => y[0]))
            .value();

        _.each(references, (froms, to) => {
            const entry = _.find(config, { definition: to });
            if (entry != null) {
                entry.referredBy = froms;
            } else {
                config.push({
                    definition: to,
                    refersTo: [],
                    referredBy: froms
                });
            }
        });

        this._config = config;
        this._lookup = _.keyBy(this._config, x => x.definition);
    }

    getAncestors(
        definition: StringKeyOf<TDefinitions>,
        maxDepth?: number
    ): Array<StringKeyOf<TDefinitions>> {
        const config = this._lookup[definition as string];

        if (config == null) return [];

        this._fillAncestors(config);

        return _.map(
            this._filterByDepth(config.ancestors, maxDepth),
            x => x[1] as StringKeyOf<TDefinitions>
        );
    }

    private _fillAncestors(start: ConfigEntry): void {
        if (start.ancestors != null) return;

        start.ancestors = this._getReferredDefinitions(start.definition, x =>
            _.map(x.refersTo, y => [y.definition, y.field])
        );
    }

    getDescendants(
        definition: StringKeyOf<TDefinitions>,
        maxDepth?: number
    ): Array<StringKeyOf<TDefinitions>> {
        const config = this._lookup[definition as string];

        if (config == null) return [];

        // Fill descendants if not done yet
        if (config.descendants == null) {
            config.descendants = this._getReferredDefinitions(config.definition, referred =>
                _.map(referred.referredBy, x => [x])
            );
        }

        return _.map(
            this._filterByDepth(config.descendants, maxDepth),
            x => x[1] as StringKeyOf<TDefinitions>
        );
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _getReferredDefinitions<TLevelData extends [string, ...any[]]>(
        start: string,
        fn: (x: ConfigEntry) => TLevelData[]
    ) {
        const res: Array<PrependTuple<number, TLevelData>> = [];

        let level = 1;
        let current = [start as string];

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const nextLevel = _.flatten(
                _.map(current, x => {
                    const entry = this._lookup[x];
                    return entry != null ? fn(entry) : [];
                })
            );
            current = _.map(nextLevel, x => _.first(x));

            if (_.isEmpty(current)) break;

            res.push(..._.map(nextLevel, x => [level, ...x] as PrependTuple<number, TLevelData>));

            level++;
        }

        return res;
    }

    private _filterByDepth<T extends [number, ...any[]]>(entries: T[], maxDepth: number): T[] {
        return maxDepth !== undefined ? _.filter(entries, x => x[0] <= maxDepth) : entries;
    }

    addIntermediateDefinitions(
        definitions: Array<StringKeyOf<TDefinitions>>
    ): Array<StringKeyOf<TDefinitions>> {
        const allRequiredAncestors = _.flatten(
            _.map(definitions, def => {
                const ancestors = this.getAncestors(def);
                const requestedAncestors = _.intersection(definitions, ancestors);
                if (_.isEmpty(requestedAncestors)) return [def];

                const steps = this._getAncestorsResolutionPath(def, requestedAncestors);
                const res = _.uniq(_.flatten(_.map(steps, x => [x[1], x[2]])));
                return res as Array<StringKeyOf<TDefinitions>>;
            })
        );

        return _.uniq(allRequiredAncestors);
    }

    getHierarchy<T extends Array<StringKeyOf<TDefinitions>>>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        ancestors?: T
    ): { [P in T[number]]?: DefinitionSectionType<TDefinitions, P> } {
        if (ancestors == null) ancestors = this.getAncestors(definition) as any;

        const steps = this._getAncestorsResolutionPath(definition, ancestors as any);

        const res: any = {
            [definition]: this._definitions.getEntry(definition, key)
        };

        for (const step of steps) {
            const [, targetDef, srcDef, field] = step;
            const fieldValue = res[srcDef]?.[field];
            res[targetDef] =
                fieldValue != null
                    ? this._definitions.getEntry(targetDef as any, fieldValue)
                    : null;
        }

        return _.transform(
            res,
            (a: Record<string, any>, v, k: string) => {
                if (_.includes(ancestors, k)) a[k] = v;
                return a;
            },
            {}
        ) as any;
    }

    getHierarchyKeys<TMapping extends { [field: string]: StringKeyOf<TDefinitions> }>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        mapping: TMapping
    ): { [P in keyof TMapping]: unknown } {
        const ancestors = _.values(mapping);
        const fieldsByAncestors = _.transform(
            mapping,
            (acc, value, k: string) => {
                if (acc[value] != null)
                    throw Error(
                        `Ancestor definition ${value} occurs more than once in the mapping - for fields ${acc[value]} and ${k}`
                    );
                acc[value] = k as StringKeyOf<TMapping>;
                return acc;
            },
            {} as _.Dictionary<StringKeyOf<TMapping>>
        );

        const steps = this._getAncestorsResolutionPath(definition, ancestors);

        const temp: _.Dictionary<() => unknown> = {
            [definition]: _.memoize(() => this._definitions.getEntry(definition, key))
        };

        const res: { [P in keyof TMapping]: unknown } = {} as any;

        for (const step of steps) {
            const [, targetDef, srcDef, field] = step;
            const fieldValue = (temp as any)[srcDef]()?.[field];

            // Store key value to the result object
            const resField = fieldsByAncestors[targetDef];
            if (resField != null) {
                res[resField] = fieldValue ?? null;
            }

            temp[targetDef] = _.memoize(() =>
                fieldValue != null ? this._definitions.getEntry(targetDef as any, fieldValue) : null
            );
        }

        return res;
    }

    getHierarchyOrderBy<TMapping extends { [k: string]: StringKeyOf<TDefinitions> }>(
        definition: StringKeyOf<TDefinitions>,
        key: unknown,
        mapping: TMapping
    ): { [P in keyof TMapping]: unknown } {
        const hierarchyKeys = this.getHierarchyKeys(definition, key, mapping);
        return _.mapValues(hierarchyKeys, (v, k) => {
            const def = mapping[k];
            return this._definitions.getOrderBy(def, v);
        });
    }

    private _getAncestorsResolutionPath(
        definition: StringKeyOf<TDefinitions>,
        ancestors: string[]
    ): AncestorResolutionPathStep[] {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const findPath = (ancestorsMap: AncestorsMapEntry[], start: number) => {
            let [distance, def] = ancestorsMap[start];
            const path: AncestorsMapEntry[] = [ancestorsMap[start]];

            if (distance > 1) {
                let node = this._lookup[def];
                for (let j = start - 1; j >= 0; j--) {
                    const x = ancestorsMap[j];
                    if (x[0] === distance) {
                        // We already found a step on this level - skip it
                    } else if (x[0] === distance - 1) {
                        // Check if this is the step we were looking for
                        if (_.includes(node.referredBy, x[1])) {
                            [distance, def] = x;
                            node = this._lookup[def];
                            path.unshift(x);
                        }
                    } else {
                        // This is step on level (distance - 2). We shouldn't get here at all.
                        throw Error(`There is a gap in definition ancestors path before ${x[1]}`);
                    }
                }
            }

            return path;
        };

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        function toResolutionPath(startDef: string, path: AncestorsMapEntry[]) {
            const res: AncestorResolutionPathStep[] = [];
            let base = startDef;
            for (const step of path) {
                res.push([step[0], step[1], base, step[2]]);
                base = step[1];
            }
            return res;
        }

        // ---
        const config = this._lookup[definition];

        if (config == null) {
            throw new Error(`Definition ${definition} is not registered in the hierarchy.`);
        }

        // Find all ancestors of the requested definition object
        this._fillAncestors(config);
        const ancestorsMap = config.ancestors;

        const requestedDefinitions = new Set(ancestors);

        const paths: AncestorResolutionPathStep[] = [];

        for (let i = 0; i < ancestorsMap.length; i++) {
            const def = ancestorsMap[i][1];
            if (requestedDefinitions.has(def)) {
                // Check if we already have this in the cache
                let path: AncestorResolutionPathStep[] = config.ancestorPaths?.[def];
                if (path == null) {
                    path = toResolutionPath(definition as string, findPath(ancestorsMap, i));
                    config.ancestorPaths = config.ancestorPaths ?? {};
                    config.ancestorPaths[def] = path;
                }

                paths.push(...path);
                requestedDefinitions.delete(def);
            }
        }

        if (requestedDefinitions.size !== 0) {
            throw Error(
                `Definition(s) ${[...requestedDefinitions].join(
                    ","
                )} cannot be derived from definition ${definition}`
            );
        }

        return _.sortBy(_.uniqWith(paths, _.isEqual), "distance");
    }
}
