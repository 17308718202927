import _ from "lodash";
import { Component } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { ILgFormatterOptions } from "@logex/framework/core";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { LgFormatTypePipe } from "@logex/framework/ui-core";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { IRangeFilterValue } from "./range-filter-renderer";

// Input range renderer --------------------------------------------------------------------------------------------
export interface IInputRangeFilterDefinition extends IFilterDefinition {
    filterType: "inputRange";

    min?: number;
    max?: number;

    format?: "percent" | "eur" | "int" | "float";
    decimals?: number;

    default?: IRangeFilterValue;
}

// @dynamic
export class InputRangeFilterRenderer implements IFilterRenderer {
    default: IRangeFilterValue;
    formatter: string;
    formatterOptions: ILgFormatterOptions;

    constructor(
        private _definition: IInputRangeFilterDefinition,
        private _filters: any,
        private _lgTranslate: LgTranslateService,
        private _formatPipe: LgFormatTypePipe
    ) {
        switch (this._definition.format) {
            case "percent":
                if (_definition.decimals == null) _definition.decimals = 2;
                this.formatter = "percent";
                this.formatterOptions = {
                    decimals: _definition.decimals,
                    forceFormat: true,
                    viewScale: 2
                };
                break;
            case "eur":
                if (_definition.decimals == null) _definition.decimals = 0;
                this.formatter = "money";
                this.formatterOptions = {
                    forceFormat: true,
                    decimals: _definition.decimals
                };
                break;
            case "int":
                this.formatter = "int";
                this.formatterOptions = {
                    forceFormat: true,
                    decimals: 0
                };
                break;
            case "float":
            default:
                if (_definition.decimals == null) _definition.decimals = 2;
                this._definition.format = "float";
                this.formatter = "float";
                this.formatterOptions = {
                    forceFormat: true,
                    decimals: _definition.decimals
                };
                break;
        }

        this.default = <any>_.extend(
            {
                from: null,
                to: null,
                enabled: false
            },
            this._definition.default
        );
    }

    createStorage(): void {
        if (this._filters[this._definition.storage] == null) {
            this._filters[this._definition.storage] = {
                from: this.default.from,
                to: this.default.to
            };
        }
    }

    clear(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        if (!current) {
            // shouldn't happen
            this.createStorage();
            return false;
        }
        const changed =
            current.from !== this.default.from ||
            current.to !== this.default.to ||
            current.enabled !== this.default.enabled;
        current.from = this.default.from;
        current.to = this.default.to;
        current.enabled = this.default.enabled;
        return changed;
    }

    active(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        return current.enabled;
    }

    previewVisible(): boolean {
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        return current.enabled;
    }

    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<IInputRangeFilterDefinition, InputRangeFilterRenderer>
    > {
        return InputRangeFilterRendererLineComponent;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name,
            activeFn: () => this.active(),
            exportFn: () => {
                const format = this.formatter;
                let from: string, to: string;
                const current: IRangeFilterValue = this._filters[this._definition.storage];
                if (current.from == null) {
                    // from = this.definition.minusInfinityText;
                    from = "...";
                } else {
                    // from = this.$scope.$eval( current.from.toString() + "|" + format );
                    from = this._formatPipe.transform(current.from.toString(), format);
                }
                if (current.to == null) {
                    // to = this.definition.plusInfinityText;
                    to = "...";
                } else {
                    // to = this.$scope.$eval( current.to.toString() + "|" + format );
                    to = this._formatPipe.transform(current.to.toString(), format);
                }

                return [
                    from +
                        this._lgTranslate.translate(
                            "FW._Directives.InputRangeFilterRenderer_Export_To"
                        ) +
                        to
                ];
            }
        };
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<IInputRangeFilterDefinition, InputRangeFilterRenderer>
    > {
        return InputRangeFilterRendererLineComponent;
    }

    inputChanged(from: boolean, value: number): boolean {
        if (isNaN(value)) return false;
        const current: IRangeFilterValue = this._filters[this._definition.storage];
        if (from) {
            if (value != null && current.to != null && value > current.to) return false;
            current.from = value;
        } else {
            if (value != null && current.from != null && value < current.from) return false;
            current.to = value;
        }
        current.enabled = current.from != null || current.to != null;
        return true;
    }

    /**
     * Utility generator for the filtering function. Example usage:
     * filters:( context: any) => {
     *    ...
     *    margin: INputRangeFilterRenderer.getFilterFunction( () => context.filterDefinition.filters["margin"] ),
     *   ...
     *  }
     */
    static getFilterFunction(
        getStorage: () => any,
        nullAsZero?: boolean
    ): (val: number) => boolean | "$empty" {
        return function (val: number) {
            const current: IRangeFilterValue = getStorage() || { enabled: false, min: 0, max: 0 };
            if (arguments.length === 0) return current.enabled ? true : "$empty";
            if (!current.enabled) return true;
            if (nullAsZero && !val) val = 0;
            return (
                (current.from == null || current.from <= val) &&
                (current.to == null || current.to >= val)
            );
        };
    }

    serialize(): string {
        if (!this.active()) return null;
        return JSON.stringify(this._filters[this._definition.storage]);
    }

    deserialize(state: string): boolean {
        const newState = JSON.parse(state);
        if (!newState) return false;
        const parsed: IRangeFilterValue = {
            enabled: newState.enabled,
            from: newState.from === undefined ? this.default.from : newState.from,
            to: newState.to === undefined ? this.default.to : newState.to
        };
        if (isNaN(parsed.from) || isNaN(parsed.to)) return false;
        if (parsed.from > parsed.to) return false;
        if (this._definition.min != null) {
            parsed.from = Math.max(this._definition.min, parsed.from);
            parsed.to = Math.max(this._definition.min, parsed.to);
        }
        if (this._definition.max != null) {
            parsed.from = Math.min(this._definition.max, parsed.from);
            parsed.to = Math.min(this._definition.max, parsed.to);
        }
        if (!_.isEqual(parsed, this._filters[this._definition.storage])) {
            this._filters[this._definition.storage] = parsed;
            return true;
        }
        return false;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class InputRangeFilterRendererFactory implements IFilterRendererFactory {
    readonly name: string = "inputRange";

    create(
        definition: IInputRangeFilterDefinition,
        filters: _.Dictionary<any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet,
        formatPipe: LgFormatTypePipe
    ): IFilterRenderer {
        return new InputRangeFilterRenderer(definition, filters, filterSet.lgTranslate, formatPipe);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-filter-preview__popup__header" *ngIf="_isPopup">
            {{ "FW._Directives.InputRangeFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{
                    'FW._Directives.InputRangeFilterRenderer_Clear_selections' | lgTranslate
                }}"
                (click)="this._clear()"
            ></div>
        </div>

        <div class="filter__control filter__control--wide" lgStopPropagation="change">
            <div class="input-range-filter-control">
                <lg-dynamic-input
                    class="input-range-filter-control__input"
                    label="{{
                        'FW._Directives.InputRangeFilterRenderer_Placeholder_From'
                            | lgTranslate : { label: _definition.label }
                    }}"
                    [ngModel]="_filters[_definition.storage].from"
                    (ngModelChange)="_changed(true, $event)"
                    [formatter]="_renderer.formatter"
                    [formatterOptions]="_renderer.formatterOptions"
                    [min]="_definition.min"
                    [max]="_filters[_definition.storage].to"
                >
                </lg-dynamic-input>
                <div class="input-range-filter-control__separator">–</div>
                <lg-dynamic-input
                    class="input-range-filter-control__input"
                    label="{{
                        'FW._Directives.InputRangeFilterRenderer_Placeholder_To'
                            | lgTranslate : { label: _definition.label }
                    }}"
                    [ngModel]="_filters[_definition.storage].to"
                    (ngModelChange)="_changed(false, $event)"
                    [formatter]="_renderer.formatter"
                    [formatterOptions]="_renderer.formatterOptions"
                    [min]="_filters[_definition.storage].from"
                    [max]="_definition.max"
                >
                </lg-dynamic-input>
            </div>
        </div>
    `
})
// before last enclosing div
// <div style="clear:both"></div>
export class InputRangeFilterRendererLineComponent extends FilterRendererComponentBase<
    IInputRangeFilterDefinition,
    InputRangeFilterRenderer
> {
    _changed(from: boolean, value: number): void {
        if (this._renderer.inputChanged(from, value)) {
            this._triggerChange();
        }
    }
}
