import {
    ElementRef,
    Renderer2,
    Component,
    Input,
    Output,
    EventEmitter,
    inject
} from "@angular/core";

// ----------------------------------------------------------------------------------
//
@Component({
    selector: "lg-upload-table",
    templateUrl: "./lg-upload-table.component.html"
})
export class LgUploadTableComponent {
    _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    @Input("file") _file: File | null = null;
    @Input("files") _files: File[] = [];

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("onFilesChange") readonly onFilesChange = new EventEmitter<any>();

    constructor() {
        // Adding styles this way in case component will be used as a base
        this._renderer.addClass(this._elementRef.nativeElement, "lg-upload-table");
    }

    _getFiles(): File[] {
        if (this._file) {
            return [this._file];
        } else {
            return this._files;
        }
    }

    trackByFn(_index: number, file: File): string {
        return file.name;
    }

    _removeFile(index: number): void {
        if (this._file) {
            this._file = null;
            this.onFilesChange.emit([]);
        } else if (this._files) {
            this._files.splice(index, 1);
            this.onFilesChange.emit(this._files);
        }
    }
}
