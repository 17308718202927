import _ from "lodash";
import { IUser } from "./user.types";

export class EmptyUserInfo implements IUser {
    public id = 0;
    public name = "";
    public userid = "";
    public ziekenhuiscode = 0;
    public ziekenhuisnaam = "";
    public roles: _.Dictionary<boolean> = {};
    public title = "";

    public hasPermission(role: string): boolean {
        return this.roles[role] || false;
    }
}
