import { inject, Injectable } from "@angular/core";
import { LG_USER_STORAGE_SERVICE_GATEWAY, StorageBinding } from "@logex/framework/lg-application";
import { LgSlideoutVariant } from "../lg-slideout";
import { lastValueFrom, of } from "rxjs";
import { FiltersPanelViewMode } from "../lg-filterset";
import { catchError, map, tap } from "rxjs/operators";
import { ILgFwUiStateService, LgFwUiState, LgFwUiStateSidebarButton } from "./lg-fw-ui-state.types";

const USER_STORAGE_KEY = "/fwUiState";

@Injectable({ providedIn: "root" })
export class LgUserStorageFwUiStateService implements ILgFwUiStateService {
    private _gateway = inject(LG_USER_STORAGE_SERVICE_GATEWAY);

    private _currentState: LgFwUiState;
    private _isReady$: Promise<LgFwUiState>;

    constructor() {
        this._init();
    }

    setSidebarPinned(variant: LgSlideoutVariant, pinned: boolean): void {
        if (variant === "right") {
            this.setRightSidebarPinned(pinned);
        } else {
            this.setLeftSidebarPinned(pinned);
        }
    }

    async getSidebarPinned(variant: LgSlideoutVariant): Promise<boolean> {
        if (variant === "right") {
            return this.getRightSidebarPinned();
        } else {
            return this.getLeftSidebarPinned();
        }
    }

    setLeftSidebarPinned(pinned: boolean): void {
        this._currentState.leftSidebarPinned = pinned;
        this._saveState();
    }

    async getLeftSidebarPinned(): Promise<boolean> {
        await this._isReady$;
        return this._currentState.leftSidebarPinned;
    }

    setRightSidebarPinned(pinned: boolean): void {
        this._currentState.rightSidebarPinned = pinned;
        this._saveState();
    }

    async getRightSidebarPinned(): Promise<boolean> {
        await this._isReady$;
        return this._currentState.rightSidebarPinned;
    }

    getSidebarPinnedPanelId(variant: LgSlideoutVariant): Promise<string> {
        if (variant === "right") {
            return Promise.resolve(this._currentState.rightSidebarPinnedPanelId);
        } else {
            return Promise.resolve(this._currentState.leftSidebarPinnedPanelId);
        }
    }

    setSidebarPinnedPanelId(variant: LgSlideoutVariant, panelVariant: string): void {
        if (variant === "right") {
            this._currentState.rightSidebarPinnedPanelId = panelVariant;
        } else {
            this._currentState.leftSidebarPinnedPanelId = panelVariant;
        }
        return this._saveState();
    }

    setSidebarButton(widget: LgFwUiStateSidebarButton): void {
        this._currentState.sidebarWidget = widget;
        this._saveState();
    }

    async getSidebarButton(): Promise<LgFwUiStateSidebarButton> {
        await this._isReady$;
        return this._currentState.sidebarWidget;
    }

    setFilterPanelMode(mode: FiltersPanelViewMode): void {
        this._currentState.filtersPanelViewMode = mode;
        this._saveState();
    }

    async getFilterPanelMode(): Promise<FiltersPanelViewMode | undefined> {
        await this._isReady$;
        return this._currentState.filtersPanelViewMode;
    }

    private _init(): void {
        this._isReady$ = lastValueFrom(
            this._gateway
                .get([
                    {
                        key: USER_STORAGE_KEY,
                        binding: StorageBinding.Hospital
                    }
                ])
                .pipe(
                    map(res => {
                        if (res && res.list[USER_STORAGE_KEY]) {
                            return res.list[USER_STORAGE_KEY] as LgFwUiState;
                        }
                        return new LgFwUiState();
                    }),
                    catchError(() => of(new LgFwUiState())),
                    tap(state => (this._currentState = state))
                )
        );
    }

    private _saveState(): void {
        this._gateway
            .set({
                [USER_STORAGE_KEY]: {
                    data: this._currentState,
                    binding: StorageBinding.Hospital
                }
            })
            .subscribe();
    }
}
