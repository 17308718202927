import _ from "lodash";
import { Injector, PipeTransform, Injectable, inject } from "@angular/core";

import * as Pivots from "@logex/framework/lg-pivot";
import { ILookup } from "@logex/framework/types";

import { IComboFilter2Definition } from "../renderers/combo-filter2-renderer";
import { IFilterPreprocessor } from "../filter-preprocessor";
import { IFilterDefinition } from "../filter-definition";
import { translateFilterOptionFactory } from "./translate-filter-option-factory";

// Combo box preprocessor 2 definition extensions ------------------------------------------------------------------
export type IComboFilter2DefinitionSeed<T> = Partial<IComboFilter2Definition<T>> & {
    filterType: "combo2";

    id: string;

    /**
     * sourceDefinition for the filter, as used by logexPivot.gatherFilterIdsFactory
     */
    sourceDefinition?: Pivots.IGatherFilterIdsFactoryOptions<T>;

    /**
     * Base parameters for sourceDefinition, as use dby logexPivot.gatherFilterIdsFactory
     */
    sourceDefinitionBase?: Pivots.IGatherFilterIdsFactoryOptions<T>;

    /**
     * Definition of the option naming, which will be automatically translated to mapToFunction
     */
    optionDefinition?: ITranslateFilterOptionFactoryOptions;

    /**
     * Callback that translates the ID to name of the option
     */
    optionName?: (id: T) => string;

    /**
     * Name of the angular filter that translates the option ID to name
     */
    optionNameFilter?: PipeTransform;

    /**
     * Specifies whether the options should be sorted by their ID, or name
     */
    optionOrderById?: boolean;
};

export interface ITranslateFilterOptionFactoryOptions {
    /**
     * Name lookup, or callback accepting that returns it. This will be used to create the option names if optionName is missing
     */
    nameSource?: ILookup<any> | (() => ILookup<any>);

    /**
     * Name of the option name property within the lookup table. Typically "omschrijving", but there is no default.
     * Used only when nameSource is specified
     */
    nameProperty?: string;

    /**
     * Name to use, if the ID doesn't exist in the lookup. Defaults to empty string
     * Used only when nameSource is specified
     */
    nameMissing?: string;

    /**
     * If true, the name will be formed as ID-NAME. If false, only NAME is used.
     * Used only when nameSource is specified
     */
    nameDash?: boolean;

    /**
     * Name of the property containing the sort order of the option. If empty, name is used. If "=", id is used
     */
    sortProperty?: string;
}

// Combo box 2 preprocessor ----------------------------------------------------------------------------------------
@Injectable()
export class ComboFilter2Preprocessor implements IFilterPreprocessor {
    private _injector = inject(Injector);
    private _logexPivot = inject(Pivots.LogexPivotService);

    public readonly name = "Combo2";

    public preprocess<T>(
        definition: IComboFilter2DefinitionSeed<T>,
        _context: any
    ): IFilterDefinition {
        if (definition.sourceDefinition) {
            definition.source = this._logexPivot.gatherFilterIdsFactory<any>(
                definition.sourceDefinition,
                definition.sourceDefinitionBase,
                definition.context
            );
        }

        if (!definition.optionDefinition && !definition.optionName && definition.optionNameFilter) {
            const pipe = this._injector.get(definition.optionNameFilter) as PipeTransform;
            definition.optionName = (id: T) => pipe.transform(id);
        }

        if (definition.optionDefinition) {
            definition.mapToOptions = translateFilterOptionFactory(definition.optionDefinition);
        } else if (definition.optionName) {
            if (definition.optionOrderById) {
                definition.mapToOptions = (ids: any[]) =>
                    _.sortBy(
                        _.map(ids, id => {
                            const name: string = (definition.optionName as Function)(id);
                            return {
                                id,
                                name,
                                order: id
                            };
                        }),
                        "order"
                    );
            } else {
                definition.mapToOptions = (ids: any[]) =>
                    _.sortBy(
                        _.map(ids, id => {
                            const name: string = (definition.optionName as Function)(id);
                            return {
                                id,
                                name,
                                order: name
                            };
                        }),
                        "order"
                    );
            }
        }
        return definition;
    }
}
