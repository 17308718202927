import { Injectable, inject } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { ITokenAuthenticationService, LG_AUTHENTICATION_SERVICE } from "../auth/index";

@Injectable()
export class LgAuthorizationInterceptor implements HttpInterceptor {
    private _promptDialog = inject(LgPromptDialog);
    private _authenticationService = inject(
        LG_AUTHENTICATION_SERVICE
    ) as ITokenAuthenticationService;

    private _translate = inject(LgTranslateService);

    private static _sessionDialogVisible = false;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this._authenticationService.loggedIn) return this._next(next, req);

        return from(this._authenticationService.getAccessToken()).pipe(
            switchMap(token => {
                let headers = req.headers;

                if (this._authenticationService.loggedIn) {
                    headers = headers.set("Authorization", `Bearer ${token}`);
                }

                let modifiedRequest: HttpRequest<any> = req;
                if (headers !== req.headers) {
                    modifiedRequest = req.clone({
                        headers
                    });
                }
                return this._next(next, modifiedRequest);
            })
        );
    }

    private _next(
        next: HttpHandler,
        modifiedRequest: HttpRequest<any>
    ): Observable<HttpEvent<any>> {
        return next.handle(modifiedRequest).pipe(
            tap({
                error: e => {
                    if (e.status && e.status === 401) {
                        if (!LgAuthorizationInterceptor._sessionDialogVisible) {
                            LgAuthorizationInterceptor._sessionDialogVisible = true;
                            this._promptDialog
                                .alertLc("FW.FAILED_REQUEST_TO_SERVER", "FW.SESSION_EXPIRED", {
                                    allowClose: false
                                })
                                .then(() => {
                                    window.location.reload();
                                });
                        }
                        e.force_quiet = true;
                    }
                }
            })
        );
    }
}
