import { Component, Input, HostListener } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-comment-icon",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <lg-icon
            icon="icon-comment"
            class="table__column-icon {{ noEdit ? '' : 'table__row__no-hover-only' }}"
            [title]="'FW._Directives.CommentIcon_View' | lgTranslate"
            *ngIf="hasComment"
        ></lg-icon>
        <lg-icon
            icon="icon-comment-edit"
            class="table__column-icon table__row__hover-only"
            [title]="'FW._Directives.CommentIcon_Edit' | lgTranslate"
            *ngIf="hasComment && !noEdit"
        ></lg-icon>
        <lg-icon
            icon="icon-comment-add"
            class="table__column-icon clickable table__row__hover-only"
            [title]="'FW._Directives.CommentIcon_Add' | lgTranslate"
            *ngIf="!hasComment && !noEdit"
        ></lg-icon>
    `,
    host: {
        "[class.block-element]": "true",
        "[class.clickable]": "true"
    }
})
export class LgCommentIconComponent {
    @Input() set noEdit(value: boolean) {
        this._noEdit = toBoolean(value);
    }

    get noEdit(): boolean {
        return this._noEdit;
    }

    @Input() set hasComment(value: boolean) {
        this._hasComment = toBoolean(value);
    }

    get hasComment(): boolean {
        return this._hasComment;
    }

    private _noEdit = false;
    private _hasComment = false;

    @HostListener("click", ["$event"])
    _clickHandler(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
